import { useState } from "react";
import TextContent from "./TextContent";
import ColorContent from "./ColorContent";
import ImageContent from "./ImageContent";
import useStore, { getState, setState } from "../../../../hooks/useStore";
import { Box, Button } from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import { MdOutlineColorLens } from "react-icons/md";
import { IoTextSharp } from "react-icons/io5";
import { BsImageAlt } from "react-icons/bs";
const MobileNav = ({ fabricCanvas }) => {
  const [activeNav, setActiveNav] = useState(0);

  const toggleNav = (index) => {
    setActiveNav(index);
  };
  return (
    <>
      <Box className="fixed bottom-0 left-0 right-0 border-t border-gray-300 bg-white grid grid-cols-3 z-50">
        <button
          onClick={() => toggleNav(1)}
          className={`px-1 py-2 flex flex-col justify-center items-center text-black-400 ${
            activeNav == 1 && "bg-blue-500 text-black-100"
          } hover:text-black-100 hover:bg-blue-500 text-center`}
        >
          <Box
            style={{
              background: "teal",
              color: "white",
              width: "32px",
              height: "32px",
              borderRadius: "8px",
            }}
          >
            <MdOutlineColorLens size="30px" />
          </Box>
          Colors
        </button>
        <button
          onClick={() => toggleNav(2)}
          className={`px-1 py-2 flex flex-col justify-center items-center text-black-400 ${
            activeNav == 2 && "bg-blue-500 text-black-100"
          } hover:text-black-100 hover:bg-blue-500 text-center`}
        >
          <Box
            style={{
              width: "32px",
              color: "white",
              height: "32px",
              background: "teal",
              borderRadius: "8px",
            }}
          >
            <IoTextSharp size="30px" />
          </Box>
          Texts
        </button>
        <button
          onClick={() => toggleNav(3)}
          className={`px-1 py-2 flex flex-col justify-center items-center text-black-400 ${
            activeNav == 3 && "bg-blue-500 text-black-100"
          } hover:text-black-100 hover:bg-blue-500 text-center`}
        >
          <Box
            style={{
              width: "32px",
              color: "white",
              height: "32px",
              background: "teal",
              borderRadius: "8px",
            }}
          ><BsImageAlt  size="29px" /></Box>
          Images
        </button>
      </Box>
      <Box
        className={`fixed transition-all h-[375px] duration-500 ease-in-out w-4/5 bg-white z-10 ${
          activeNav == 0 && !getState().addTextEnabled
            ? "bottom-[-400px]"
            : "bottom-[73px]"
        } `}
      >
        <button onClick={() => toggleNav(0)} className="absolute right-0 p-2">
          <CloseIcon />
        </button>
        {activeNav == 1 && (
          <Box className="p-3">
            <Box className="pb-3" fontWeight={"bold"} textAlign="center">
              Customize Colors
            </Box>
            <Box className="overflow-y-auto">
              <ColorContent fabricCanvas={fabricCanvas} />
            </Box>
          </Box>
        )}
        {activeNav == 2 && (
          <Box className="p-3">
            <Box className="pb-3" fontWeight={"bold"} textAlign="center">
              Customize Texts
            </Box>

            <Box className="overflow-y-auto">
              <TextContent fabricCanvas={fabricCanvas} />
            </Box>
          </Box>
        )}
        {activeNav == 3 && (
          <Box className="p-3">
            <Box className="pb-3" fontWeight={"bold"} textAlign="center">
              Customize Images
            </Box>
            <Box className="overflow-y-auto">
              <ImageContent fabricCanvas={fabricCanvas} />
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
};

export default MobileNav;
