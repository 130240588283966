/* eslint-disable react/prop-types */
/* eslint-disable no-redeclare */
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { Md3DRotation } from "react-icons/md";
import { Box, VStack, StackDivider } from "@chakra-ui/react";
import { TiZoomOutline } from "react-icons/ti";
import React, { Component } from "react";
import { fabric } from "fabric";
import * as THREE from "three";
import { Vector2 } from "three";
import { addText, addImage, patchFabricJs, addSvgImage } from "../util/fabric";
import useStore, { setState, getState } from "../hooks/useStore";
import gsap, { Power0, Power1 } from "gsap";
import threedrotateImg from "assets/img/3drotate.png";
import zoomicoImg from "assets/img/zoomico.png";
let prodId = "";
let catId = "";
let designId = "";
let garmentId = "";
function sessionSet(key, value, expirationInMin = 60) {
  let expirationDate = new Date(new Date().getTime() + 60000 * expirationInMin);
  let newValue = {
    value: value,
    expirationDate: expirationDate.toISOString(),
  };
  sessionStorage.setItem(key, JSON.stringify(newValue));
}
class ThreeScene extends Component {
  async componentDidMount() {
    setState({
      is2D: false,
    });
    if (window.location.hash.split("/").length > 3) {
      catId = window.location.hash.split("/")[3];
      prodId = window.location.hash.split("/")[4];
      designId = window.location.hash.split("/")[5];
      garmentId = window.location.hash.split("/")[6];
    }
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    const fetchCompData = async () => {
      try {
        const response = await fetch(
          "https://staging.tolloapp.com/designapi/api/Company/1/no",
          requestOptions
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        sessionSet("company", data);
        setState({ company: data });
        this.loadmod();
      } catch (error) {
        console.log(error);
      }
    };
    if (sessionStorage.getItem("company") == undefined) {
      fetchCompData();
    } else {
      this.loadmod();
    }
  }

  loadmod = () => {
    setState({
      company: JSON.parse(sessionStorage.getItem("company")).value,
    });
    if (Object.keys(getState().company).length === 0) {
      setState({
        company: JSON.parse(sessionStorage.getItem("company")).value,
      });
    }
    setState({
      garment: {
        id: garmentId,
        sport_id: catId,
        product_id: prodId,
        design_id: designId,
        company_id: getState().company.id,
        fit_id: 1,
        package_id: getState().company.package_id,
      },
    });
    var company_sport = getState().company.company_sport.filter(
      (x) => x.id == catId
    );
    if (company_sport != null) {
      var cat_prod_designs = company_sport[0].products.filter(
        (x) => x.id == prodId
      );
      if (cat_prod_designs != null) {
        var cat_prod_designs_model = cat_prod_designs[0].designs.filter(
          (x) => x.id == designId
        );
        this.props.designModel.objModel = cat_prod_designs[0].modelimagepath;
        this.props.designModel.svgTexture =
          cat_prod_designs_model[0].svgfilepath;
      }
    }

    this.objectSizeTotal = 7334422;
    this.isMobile = window.innerWidth < 700;
    this.rotateIcon =
      "data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgMCAyNCAyNCIgd2lkdGg9IjI0Ij48cGF0aCBkPSJNMCAwaDI0djI0SDB6IiBmaWxsPSJub25lIi8+PHBhdGggZD0iTTEyIDZ2M2w0LTQtNC00djNjLTQuNDIgMC04IDMuNTgtOCA4IDAgMS41Ny40NiAzLjAzIDEuMjQgNC4yNkw2LjcgMTQuOGMtLjQ1LS44My0uNy0xLjc5LS43LTIuOCAwLTMuMzEgMi42OS02IDYtNnptNi43NiAxLjc0TDE3LjMgOS4yYy40NC44NC43IDEuNzkuNyAyLjggMCAzLjMxLTIuNjkgNi02IDZ2LTNsLTQgNCA0IDR2LTNjNC40MiAwIDgtMy41OCA4LTggMC0xLjU3LS40Ni0zLjAzLTEuMjQtNC4yNnoiLz48L3N2Zz4=";
    this.img = document.createElement("img");
    this.img.src = this.rotateIcon;
    if (!this.isMobile) {
      setState({
        dimension: {
          width: 2048,
          height: 2048,
        },
      });
    } else {
      setState({
        dimension: {
          width: 1024,
          height: 1024,
        },
      });
    }
    this.fabricCanvas = new fabric.Canvas("designCanvas", {
      preserveObjectStacking: true,
      width: getState().dimension.width,
      height: getState().dimension.height,
      selection: false,
    });

    fabric.loadSVGFromURL(
      // process.env.PUBLIC_URL + `/textures/hoody_2.svg`,
      this.props.designModel.svgTexture,
      (objects) => {
        var svgData = fabric.util.groupSVGElements(objects, {
          width: getState().dimension.width,
          height: getState().dimension.height,
          selectable: false,
          crossOrigin: "anonymous",
        });
        svgData.top = 0;
        svgData.left = 0;
        let currentColors = [];
        for (let i = 0; i < objects.length; i++) {
          currentColors.push({
            id: objects[i].id,
            fill: objects[i].fill,
          });
        }
        setState({
          svgGroup: svgData,
          colors: currentColors,
        });
        this.fabricCanvas.remove(this.fabricCanvas._objects[0]);
        this.fabricCanvas.add(svgData);
        this.fabricCanvas.sendToBack(svgData);
        this.props.setIsLoading(false);
      }
    );
    this.props.setFabricCanvas(this.fabricCanvas);
    fabric.Object.prototype.objectCaching = false;
    this.materials = [];
    this.onClickPosition = new THREE.Vector2();
    this.getUv = { x: 0, y: 0 };
    this.raycaster = new THREE.Raycaster();
    this.mouse = new THREE.Vector2();
    this.pointer = new THREE.Vector2();

    this.scene = new THREE.Scene();
    this.renderer = new THREE.WebGLRenderer({
      alpha: true,
      antialias: true,
      preserveDrawingBuffer: true,
    });
    this.renderer.toneMappingExposure = 1;
    let size = this.isMobile ? window.innerWidth : 700;
    this.renderer.setSize(size, size);
    this.renderer.domElement.id = "renderer";
    this.mount.appendChild(this.renderer.domElement);
    this.camera = new THREE.PerspectiveCamera(30, 1, 1, 1200);
    this.camera.position.set(55, 0, 0);
    this.loader = new OBJLoader(this.manager);

    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.controls.maxPolarAngle = ((Math.PI / 2) * 10) / 9;
    this.controls.minPolarAngle = ((Math.PI / 2) * 7) / 9;
    this.controls.minDistance = 20;
    this.controls.minZoom = 20;
    this.controls.maxDistance = 55;
    this.controls.maxZoom = 55;
    this.controls.enablePan = true;
    this.controls.zoomToCursor = true;
    this.controls.enableDamping = true;
    this.controls.dampingFactor = 0.1;
    this.controls.zoomSpeed = 2;
    this.needReset = false;
    this.controls.addEventListener("change", (e) => {
      if (e.target.getDistance() == 55 && this.needReset) {
        gsap
          .to(this.controls.target, {
            duration: 0.7,
            x: 0,
            y: 0,
            z: 0,
            ease: Power0.easeInOut,
          })
          .then(() => {
            this.needReset = false;
          });
        gsap
          .to(this.controls.object.position, {
            duration: 0.5,
            x: 50,
            y: 0,
            z: 0,
            ease: Power0.easeInOut,
          })
          .then(() => {
            this.needReset = false;
          });
      } else if (e.target.getDistance() < 50) {
        this.needReset = true;
      }
    });

    this.object = new THREE.Object3D();

    this.lightHolder = new THREE.Group();
    const ambientLight = new THREE.AmbientLight(0xffffff, 1);
    this.lightHolder.add(ambientLight);
    const leftDirectionalLight = new THREE.DirectionalLight(0xffffff, 2);
    leftDirectionalLight.position.set(100, 20, 150);
    this.lightHolder.add(leftDirectionalLight);
    const rightDirectionalLight = new THREE.DirectionalLight(0xffffff, 1);
    rightDirectionalLight.position.set(100, 50, -50);
    this.lightHolder.add(rightDirectionalLight);
    this.scene.add(this.lightHolder);

    // // Create a directional light
    // const directionalLight = new THREE.DirectionalLight(0xffffff, 3); // White light with intensity 2
    // directionalLight.position.set(1, 1, 1); // Set the position of the light
    // this.scene.add(directionalLight);

    // // Optionally, you can add ambient light to the scene to brighten up objects
    // const ambientLight1 = new THREE.AmbientLight(0xffffff,2); // White ambient light with intensity 0.5
    // this.scene.add(ambientLight1);

    // // Optionally, you can also add a hemisphere light for more balanced lighting
    // const hemisphereLight = new THREE.HemisphereLight(0xffffff, 0x080820, 2); // Sky color, ground color, intensity
    // this.scene.add(hemisphereLight);

    // window.addEventListener('resize', this.handleWindowSize)
    this.textureLoader = new THREE.TextureLoader();
    this.normalMap = this.textureLoader.load(
      process.env.PUBLIC_URL + "/textures/normal.jpg"
    );
    setState({
      tempFabric: {
        id: 1,
      },
    });
    this.ambientMap = this.textureLoader.load(
      process.env.PUBLIC_URL + "/textures/ambient.png"
    );
    this.roughnessMap = this.textureLoader.load(
      process.env.PUBLIC_URL + "/textures/roughness.png"
    );
    // this.diffuse = this.textureLoader.load(
    //   process.env.PUBLIC_URL + "/textures/basket.png"
    // );
    this.logo = this.textureLoader.load(process.env.PUBLIC_URL + "/vite.svg");
    this.renderer.setPixelRatio(window.devicePixelRatio);

    this.renderer.gammaInput = true;
    this.renderer.gammaOutput = true;
    this.renderer.shadowMap.enabled = true;
    this.renderer.shadowMap.soft = true;
    this.renderer.outputColorSpace = THREE.LinearSRGBColorSpace;
    this.manager = new THREE.LoadingManager();
    this.canvasTexture = new THREE.Texture(this.fabricCanvas.getElement());
    this.canvasTexture.anisotropy = this.renderer.capabilities.getMaxAnisotropy();
    this.textureMaterial = new THREE.MeshStandardMaterial({
      map: this.canvasTexture,
      normalMap: this.normalMap,
      normalScale: new Vector2(0.3, 0.3),
      // aoMap: this.ambientMap,
      // roughnessMap: this.roughnessMap
    });
    this.shirtMaterial = new THREE.MeshStandardMaterial({
      color: 0xf1f1f1,
      normalMap: this.normalMap,
      normalScale: new Vector2(0.3, 0.3),
      //aoMap: this.ambientMap,
      //roughnessMap: this.roughnessMap,
    });
    this.canvasTexture.flipY = true;
    this.loadObj();
    this.animation();
    this.renderer.render(this.scene, this.camera);
    this.raycastContainer = this.renderer.domElement;
    const canvas = document.getElementsByTagName("canvas");
    if (canvas.length == 5) {
      canvas[0].remove();
    }

    if (!this.isMobile) {
      this.raycastContainer.addEventListener(
        "mousedown",
        this.onMouseClick,
        false
      );
    } else {
      this.raycastContainer.addEventListener("touchstart", this.onTouch, false);
    }
    this.raycastContainer.addEventListener(
      "scroll",
      (e) => {
        this.controls.enabled = true;
      },
      false
    );

    this.fabricCanvas.on("mouse:down", (e) => {
      if (
        (e.target && e.target.text) ||
        (e.target && e.target._element) ||
        (e.target && e.target.id && e.target.id.includes("image"))
      ) {
        this.controls.enabled = false;
      } else {
        this.controls.enabled = true;
      }
    });
    this.fabricCanvas.on("mouse:up", (e) => {
      this.controls.enabled = true;
    });
  };
  addTextToCanvas = () => {
    addText(this.fabricCanvas, this.getUv);
  };
  addImageToCanvas = () => {
    if (getState().tempImage.ext != "svg") {
      addImage(this.fabricCanvas, this.getUv);
    } else {
      addSvgImage(this.fabricCanvas, this.getUv);
    }
  };
  changeFabricStyle = (newTexturePath) => {
    var newTexture = this.textureLoader.load(newTexturePath);

    // Update the existing material with the new texture
    this.textureMaterial.normalMap = newTexture;

    // Ensure the new texture is applied
    this.textureMaterial.needsUpdate = true;
  };
  getRealPosition = (axis, value) => {
    let CORRECTION_VALUE = axis === "x" ? 4.5 : 5.5;
    return (
      Math.round(value * getState().dimension.width) - CORRECTION_VALUE + 5
    );
  };
  getMousePosition = (dom, x, y) => {
    var rect = dom.getBoundingClientRect();
    return [(x - rect.left) / rect.width, (y - rect.top) / rect.height];
  };
  getIntersects = (point, objects) => {
    this.mouse.set(point.x * 2 - 1, -(point.y * 2) + 1);
    this.raycaster.setFromCamera(this.mouse, this.camera);
    return this.raycaster.intersectObjects(objects);
  };
  getPositionOnScene = (sceneContainer, evt) => {
    const rect = sceneContainer.getBoundingClientRect();
    let array = [];
    if (evt.changedTouches) {
      array = [
        (evt.changedTouches[0].clientX - rect.left) / rect.width,
        (evt.changedTouches[0].clientY - rect.top) / rect.height,
      ];
    } else {
      array = [
        (evt.clientX - rect.left) / rect.width,
        (evt.clientY - rect.top) / rect.height,
      ];
    }
    this.onClickPosition.fromArray(array);
    var intersects = this.getIntersects(
      this.onClickPosition,
      this.object.children
    );
    if (intersects.length > 0 && intersects[0].uv) {
      var uv = intersects[0].uv;
      this.getUv = uv;
      if (intersects[0].object.material.map != null)
        intersects[0].object.material.map.transformUv(uv);
      return {
        x: this.getRealPosition("x", uv.x),
        y: this.getRealPosition("y", uv.y),
      };
    }
    return null;
  };
  getPositionOnSceneTouch = (sceneContainer, evt) => {
    var array = this.getMousePosition(
      sceneContainer,
      evt.changedTouches[0].clientX,
      evt.changedTouches[0].clientY
    );
    this.onClickPosition.fromArray(array);
    var intersects = this.getIntersects(
      this.onClickPosition,
      this.object.children
    );
    if (intersects.length > 0 && intersects[0].uv) {
      var uv = intersects[0].uv;
      this.getUv = uv;
      intersects[0].object.material.map.transformUv(uv);
      this.getUv = uv;
      return {
        x: this.getRealPosition("x", uv.x),
        y: this.getRealPosition("y", uv.y),
      };
    }
    return null;
  };
  onMouseClick = (evt) => {
    evt.preventDefault();
    const positionOnScene = this.getPositionOnScene(this.raycastContainer, evt);
    if (positionOnScene) {
      const canvasRect = this.fabricCanvas._offset;
      const simEvt = new MouseEvent(evt.type, {
        clientX: canvasRect.left + positionOnScene.x,
        clientY: canvasRect.top + positionOnScene.y,
      });
      this.fabricCanvas.upperCanvasEl.dispatchEvent(simEvt);
      if (getState().addImageEnabled) {
        this.addImageToCanvas();
        gsap.to(this.object.scale, {
          duration: 0.5,
          x: 30,
          y: 30,
          z: 30,
          ease: Power0.easeInOut,
        });
        setState({
          addImageEnabled: false,
          evented: false,
        });
      }
    }
  };
  onTouch = (evt) => {
    evt.preventDefault();
    const positionOnScene = this.getPositionOnScene(this.raycastContainer, evt);
    if (positionOnScene) {
      const canvasRect = this.fabricCanvas._offset;
      const simEvt = new MouseEvent(evt.type, {
        clientX: canvasRect.left + positionOnScene.x,
        clientY: canvasRect.top + positionOnScene.y,
      });
      this.fabricCanvas.upperCanvasEl.dispatchEvent(simEvt);
      if (getState().addImageEnabled) {
        this.addImageToCanvas();
        gsap.to(this.object.scale, {
          duration: 0.5,
          x: 30,
          y: 30,
          z: 30,
          ease: Power0.easeInOut,
        });
        setState({
          addImageEnabled: false,
          evented: false,
        });
      }
    }
  };
  loadObj = () => {
    this.loader.load(
      // process.env.PUBLIC_URL + "/models/hoody2.obj",
      this.props.designModel.objModel,
      (obj) => {
        obj.traverse((child) => {
          if (child instanceof THREE.Mesh && child.name.includes("out")) {
            child.material = this.textureMaterial;
          }
          if (child instanceof THREE.Mesh && child.name.includes("in")) {
            child.material = this.textureMaterial;
          }
        });
        this.object = obj;
        obj.name = "mainmodel";
        //obj.children[0].material = this.shirtMaterial;
        obj.scale.set(30, 30, 30);
        obj.receiveShadow = true;
        obj.castShadow = true;
        obj.rotation.set(0, Math.PI * -2, 0);
        new gsap.to(obj.rotation, 2, {
          y: Math.PI / 2,
          ease: Power0.easeInOut,
        });
        this.props.setThreeObject(this.object);
        let rotateY = this.object.rotation.clone();
        sessionStorage.setItem("rotatey", JSON.stringify(rotateY));
        this.fabricCanvas.on("after:render", function () {
          // obj.children[1].material.map.needsUpdate = true;
          obj.traverse((child) => {
            if (child instanceof THREE.Mesh && child.name.includes("out")) {
              child.material.map.needsUpdate = true;
            }
          });
        });
        // var circle = new fabric.Circle({
        //     radius: 20,
        //     left: 400,
        //     top: 411.5,
        //     fill: "red",
        // });
        // this.fabricCanvas.add(circle);
        // this.fabricCanvas.renderAll()
        this.scene.add(obj);
      },
      (xhr) => {
        //this.props.setIsLoading(true)
        this.props.setProgress(
          Number((xhr.loaded / this.objectSizeTotal) * 100)
        );
        if ((xhr.loaded / xhr.total) * 100 == 100) {
          // this.props.setIsLoading(false)
        }
      }
    );
  };
  renderThree = () => {
    this.fabricCanvas.renderAll();
    this.textureMaterial.needsUpdate = true;
    this.renderer.render(this.scene, this.camera);
    if (this.props.evented) {
      if (this.props.addTextEnabled || getState().addImageEnabled) {
        // gsap.to(this.object.scale, { duration: 0.5, x: 35, y: 35, z: 35, ease: Power0.easeInOut }).then(() => {
        //     setState({
        //         evented: false
        //     })
        // })
      }
    }
  };

  initPatch = () => {
    patchFabricJs(
      this.fabricCanvas,
      this.renderer,
      this.getUv,
      this.getMousePosition,
      this.onClickPosition,
      this.getIntersects,
      this.object,
      this.getRealPosition
    );
  };
  animation = () => {
    requestAnimationFrame(this.animation);
    this.controls.update();
    this.controls.saveState();
    this.renderThree();
    this.lightHolder.quaternion.copy(this.camera.quaternion);
    if (!getState().is2D) {
      this.initPatch();
    }
  };

  // handleWindowSize = () => {
  //     this.camera.aspect = window.innerWidth / window.innerHeight;
  //     this.camera.updateProjectionMatrix()
  //     this.renderer.setSize(window.innerWidth, window.innerHeight)
  //     this.renderer.render(this.scene, this.camera)
  // }
  constructor(props) {
    super(props);
    setState({
      addFabricEnabled: false,
    });
  }
  componentDidUpdate(prevProps, prevState) {
    if (getState().addFabricEnabled) {
      this.changeFabricStyle(getState().tempFabric.path);
      gsap.to(this.object.scale, {
        duration: 0.5,
        x: 30,
        y: 30,
        z: 30,
        ease: Power0.easeInOut,
      });
      setState({
        addFabricEnabled: false,
      });
    }
  }
  render() {
    function threedclick(obj) {
      obj.rotateY(Math.PI / 2);
    }
    return (
      <div
        style={getState().addImageEnabled ? { zIndex: "200" } : {}}
        className="h-screen flex justify-center items-center dmodel"
        ref={(mount) => {
          this.mount = mount;
        }}
      >
        <VStack
          divider={<StackDivider borderColor="gray.200" />}
          spacing={4}
          align="stretch"
        >
          <Box>
            <img
              src={threedrotateImg}
              className="tabimg"
              onClick={() => threedclick(this.object)}
              alt="3D"
            />
          </Box>
          <Box>
            <img src={zoomicoImg} className="tabimg" alt="zoom" />
          </Box>
        </VStack>
      </div>
    );
  }
}

export default ThreeScene;
