/* eslint-disable react/prop-types */
import {
  Box,
  Button,
  Input,
  Select,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  HStack,
} from "@chakra-ui/react";
import { AddIcon, CloseIcon } from "@chakra-ui/icons";
import useStore, { setState, getState } from "../../../../hooks/useStore";

const RosterContent = () => {
  const handleNameChange = (id, newName) => {
    const updatedItems = getState().allPlayers.map((item) =>
      item.id === id ? { ...item, name: newName } : item
    );
    setState({
      allPlayers: updatedItems,
    });
  };
  const handleNumberChange = (id, newNo) => {
    const updatedItems = getState().allPlayers.map((item) =>
      item.id === id ? { ...item, number: newNo } : item
    );
    setState({
      allPlayers: updatedItems,
    });
  };
  const handleSizeChange = (id, newSize) => {
    const updatedItems = getState().allPlayers.map((item) =>
      item.id === id ? { ...item, size: newSize } : item
    );
    setState({
      allPlayers: updatedItems,
    });
  };
  return (
    <Box className="flex flex-col">
      <Button
        colorScheme="teal"
        borderColor="teal.300"
        color="teal.300"
        variant="outline"
        className="py-4 px-5 rounded-md bg-sky-700 text-white flex items-center"
        onClick={() => {
          const player = {
            id: Date.now(),
            name: "",
            number: "",
            size: "L",
          };
          setState((prevState) => {
            return {
              allPlayers: [...prevState.allPlayers, player],
            };
          });
        }}
      >
        Add Player
      </Button>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Player Name</Th>
            <Th>Number</Th>
            <Th>Size</Th>
            <Th>Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {getState().allPlayers.length > 0 &&
            getState().allPlayers.map((player, index) => {
              return (
                <Tr key={index}>
                  <Td>
                    <Input
                      size="sm"
                      onChange={(e) =>
                        handleNameChange(player.id, e.target.value)
                      }
                      value={player.name}
                    />
                  </Td>
                  <Td>
                    <Input
                      size="sm"
                      onChange={(e) =>
                        handleNumberChange(player.id, e.target.value)
                      }
                      value={player.number}
                    />
                  </Td>
                  <Td>
                    <Box w="72px">
                      <Select
                        size="sm"
                        value={player.size}
                        onChange={(e) =>
                          handleSizeChange(player.id, e.target.value)
                        }
                      >
                        <option value="2XS">2XS</option>
                        <option value="XS">XS</option>
                        <option value="S">S</option>
                        <option value="M">M</option>
                        <option value="L">L</option>
                        <option value="XL">XL</option>
                        <option value="2XL">2XL</option>
                        <option value="3XL">3XL</option>
                        <option value="4XL">4XL</option>
                        <option value="5XL">5XL</option>
                        <option value="6XL">6XL</option>
                      </Select>
                    </Box>
                  </Td>
                  <Td>
                    <HStack>
                      <AddIcon
                        boxSize={3}
                        className="cursorhov"
                        color="teal.300"
                        onClick={() => {
                          const player = {
                            id: Date.now(),
                            name: "",
                            number: "",
                            size: "L",
                          };
                          setState((prevState) => {
                            return {
                              allPlayers: [...prevState.allPlayers, player],
                            };
                          });
                        }}
                      />
                      <CloseIcon
                        boxSize={3}
                        color="red.500"
                        className="cursorhov"
                        onClick={() => {
                          setState({
                            allPlayers: getState().allPlayers.filter(
                              (_, i) => i !== index
                            ),
                          });
                        }}
                      />
                    </HStack>
                  </Td>
                </Tr>
              );
            })}
        </Tbody>
      </Table>
    </Box>
  );
};

export default RosterContent;
