/*eslint-disable*/
import React from "react";
import { Flex, Link, List, ListItem, Text, background } from "@chakra-ui/react";
import bgImage from "assets/img/SidebarHelpImage.png";
export default function Footer(props) {
  return (
    <Flex
      bgImage={bgImage}
      flexDirection={{
        base: "column",
        xl: "row",
      }}
      alignItems={{
        base: "center",
        xl: "start",
      }}
      className="footercls"
      justifyContent="space-between"
      px="30px"
      pb="20px"
    >
      <Text
        color="gray.400"
        textAlign={{
          base: "center",
          xl: "start",
        }}
        mb={{ base: "20px", xl: "0px" }}
      >
        &copy; {1900 + new Date().getYear()}{" "}
        <Text as="span">All Rights Reserved</Text>
      </Text>

      <Text
        color="gray.400"
        textAlign={{
          base: "center",
          xl: "start",
        }}
        mb={{ base: "20px", xl: "0px" }}
      >
        Dyesport Makers of custom performance apparel.
        <br /> (608) 326-0910
        <Text as="span"></Text>
      </Text>

      <Text
        color="gray.400"
        textAlign={{
          base: "center",
          xl: "start",
        }}
        mb={{ base: "20px", xl: "0px" }}
      >
        <Text as="span">
          Made by 3d Configurator Tool <br /> (Your Sports Apparel Configuration
          Tool)
          <br />
          Powered by Appxponent Technology
        </Text>
      </Text>
    </Flex>
  );
}
