/* eslint-disable react/prop-types */
import {
  Text,
  Popover,
  PopoverTrigger,
  Button,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  Center,
  PopoverBody,
  SimpleGrid,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";
import { colorPallete } from "../../../../constant";
import useStore, { setState, getState } from "../../../../hooks/useStore";

const ColorContent = ({ fabricCanvas }) => {
  const isMobile = getState().dimension.width == 1024;
  let mainArr = [
    { id: "front" },
    { id: "back" },
    { id: "side" },
    { id: "hand" },
    { id: "collar" },
  ];
  let accentArr = [{ id: "accent" }, { id: "element" }, { id: "logo" }];
  const isInMainArr = (obj1) =>
    mainArr.some((obj2) => obj1.id.toLowerCase() == obj2.id.toLowerCase());
  const isInAccentArr = (obj1) =>
    accentArr.some((obj2) =>
      obj1.id.toLowerCase().includes(obj2.id.toLowerCase())
    );
  const isInMiscArr = (obj1) =>
    ![...mainArr, ...accentArr].some((obj2) =>
      obj1.id.toLowerCase().includes(obj2.id.toLowerCase())
    );
  const mainLayers = getState().colors.filter(isInMainArr);
  const accentLayers = getState().colors.filter(isInAccentArr);
  const miscLayers = getState().colors.filter(isInMiscArr);
  return (
    <>
      <div className={`p-4 ${isMobile && "flex flex-wrap"}`}>
        <Tabs isFitted variant="enclosed">
          <TabList>
            <Tab _selected={{ color: "white", bg: "teal.300" }}>Main</Tab>
            <Tab _selected={{ color: "white", bg: "teal.300" }}>Accent</Tab>
            {miscLayers.length > 0 && (
              <Tab _selected={{ color: "white", bg: "teal.300" }}>Misc</Tab>
            )}
          </TabList>
          <TabPanels>
            <TabPanel className="colortab">
              {mainLayers.map((data, index) => (
                <div
                  key={index}
                  className={`flex items-center ${isMobile ? "m-3" : "my-3"}`}
                >
                  <Popover
                    closeOnBlur={true}
                    closeOnEsc={true}
                    gutter={25}
                    placement="right"
                    variant="picker"
                  >
                    <PopoverTrigger>
                      <Button
                        border={"4px solid #F4F4    F4"}
                        aria-label={data.fill}
                        background={data.fill}
                        height="46px"
                        width="46px"
                        padding={0}
                        minWidth="unset"
                        borderRadius={23}
                      ></Button>
                    </PopoverTrigger>
                    <PopoverContent className="colorpop" width="300px">
                      <PopoverArrow />
                      <PopoverCloseButton color="black" />
                      <PopoverBody paddingX={5} paddingY={8} height="450px">
                        <SimpleGrid columns={5} spacing={2}>
                          {colorPallete.mainPallete1.map((color, index) => (
                            <div key={index} className="my-0">
                              {color.map((c, index) => (
                                <Center key={index} className="my-1">
                                  <button
                                    style={{
                                      background: c.color,
                                      borderRadius: "16px",
                                      width: "32px",
                                      height: "32px",
                                    }}
                                    onClick={() => {
                                      const svg = fabricCanvas
                                        .getObjects()
                                        .find(
                                          (fab) =>
                                            fab.height ==
                                            getState().dimension.width
                                        );
                                      const selectedLayer = svg._objects.find(
                                        (obj) => obj.id == data.id
                                      );
                                      selectedLayer.set("fill", c.color);
                                      fabricCanvas.renderAll();
                                      data.fill = c.color;
                                      setState({
                                        evented: true,
                                        changedColor: true,
                                      });
                                    }}
                                  ></button>
                                  {/* <Button
                                                                    className="transition-all"
                                                                    border={c.color == data.fill ? '2px solid rgb(59,130,246)' : ''}
                                                                    aria-label={c.color}
                                                                    background={c.color}
                                                                    height="32px"
                                                                    width="32px"
                                                                    padding={0}
                                                                    minWidth="unset"
                                                                    borderRadius={16}
                                                                    _hover={{ background: c }}
                                                                    onClick={() => {
                                                                        const svg = fabricCanvas.getObjects().find(fab => fab.height == getState().dimension.width)
                                                                        const selectedLayer = svg._objects.find(obj => obj.id == data.id)
                                                                        selectedLayer.set('fill', c.color)
                                                                        fabricCanvas.renderAll();
                                                                        data.fill = c.color
                                                                        setState({
                                                                            evented: true,
                                                                            changedColor: true
                                                                        })
                                                                    }}
                                                                ></Button> */}
                                </Center>
                              ))}
                            </div>
                          ))}
                        </SimpleGrid>
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                  <Text className="capitalize ms-3">
                    {data.id.split("-")[0]} {data.id.split("-")[1]}
                  </Text>
                </div>
              ))}
            </TabPanel>
            <TabPanel className="colortab">
              {accentLayers.map((data, index) => (
                <div
                  key={index}
                  className={`flex items-center ${isMobile ? "m-3" : "my-3"}`}
                >
                  <Popover
                    closeOnBlur={true}
                    closeOnEsc={true}
                    gutter={25}
                    placement="right"
                    variant="picker"
                  >
                    <PopoverTrigger>
                      <Button
                        border={"4px solid #F4F4    F4"}
                        aria-label={data.fill}
                        background={data.fill}
                        height="46px"
                        width="46px"
                        padding={0}
                        minWidth="unset"
                        borderRadius={23}
                      ></Button>
                    </PopoverTrigger>
                    <PopoverContent className="colorpop"  width="300px">
                      <PopoverArrow />
                      <PopoverCloseButton color="black" />
                      <PopoverBody paddingX={5} paddingY={8} height="450px">
                        <SimpleGrid columns={5} spacing={2}>
                          {colorPallete.mainPallete1.map((color, index) => (
                            <div key={index} className="my-0">
                              {color.map((c, index) => (
                                <Center key={index} className="my-1">
                                  <button
                                    style={{
                                      background: c.color,
                                      borderRadius: "16px",
                                      width: "32px",
                                      height: "32px",
                                    }}
                                    onClick={() => {
                                      const svg = fabricCanvas
                                        .getObjects()
                                        .find(
                                          (fab) =>
                                            fab.height ==
                                            getState().dimension.width
                                        );
                                      const selectedLayer = svg._objects.find(
                                        (obj) => obj.id == data.id
                                      );
                                      selectedLayer.set("fill", c.color);
                                      fabricCanvas.renderAll();
                                      data.fill = c.color;
                                      setState({
                                        evented: true,
                                        changedColor: true,
                                      });
                                    }}
                                  ></button>
                                  {/* <Button
                                                                    className="transition-all"
                                                                    border={c.color == data.fill ? '2px solid rgb(59,130,246)' : ''}
                                                                    aria-label={c.color}
                                                                    background={c.color}
                                                                    height="32px"
                                                                    width="32px"
                                                                    padding={0}
                                                                    minWidth="unset"
                                                                    borderRadius={16}
                                                                    _hover={{ background: c }}
                                                                    onClick={() => {
                                                                        const svg = fabricCanvas.getObjects().find(fab => fab.height == getState().dimension.width)
                                                                        const selectedLayer = svg._objects.find(obj => obj.id == data.id)
                                                                        selectedLayer.set('fill', c.color)
                                                                        fabricCanvas.renderAll();
                                                                        data.fill = c.color
                                                                        setState({
                                                                            evented: true,
                                                                            changedColor: true
                                                                        })
                                                                    }}
                                                                ></Button> */}
                                </Center>
                              ))}
                            </div>
                          ))}
                        </SimpleGrid>
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                  <Text className="capitalize ms-3">
                    {data.id.split("-")[0]} {data.id.split("-")[1]}
                  </Text>
                </div>
              ))}
            </TabPanel>
            <TabPanel  className="colortab">
              {miscLayers.map((data, index) => (
                <div
                  key={index}
                  className={`flex items-center ${isMobile ? "m-3" : "my-3"}`}
                >
                  <Popover
                    closeOnBlur={true}
                    closeOnEsc={true}
                    gutter={25}
                    placement="right"
                    variant="picker"
                  >
                    <PopoverTrigger>
                      <Button
                        border={"4px solid #F4F4    F4"}
                        aria-label={data.fill}
                        background={data.fill}
                        height="46px"
                        width="46px"
                        padding={0}
                        minWidth="unset"
                        borderRadius={23}
                      ></Button>
                    </PopoverTrigger>
                    <PopoverContent className="colorpop"  width="300px">
                      <PopoverArrow />
                      <PopoverCloseButton color="black" />
                      <PopoverBody paddingX={5} paddingY={8} height="450px">
                        <SimpleGrid columns={5} spacing={2}>
                          {colorPallete.mainPallete1.map((color, index) => (
                            <div key={index} className="my-0">
                              {color.map((c, index) => (
                                <Center key={index} className="my-1">
                                  <button
                                    style={{
                                      background: c.color,
                                      borderRadius: "16px",
                                      width: "32px",
                                      height: "32px",
                                    }}
                                    onClick={() => {
                                      const svg = fabricCanvas
                                        .getObjects()
                                        .find(
                                          (fab) =>
                                            fab.height ==
                                            getState().dimension.width
                                        );
                                      const selectedLayer = svg._objects.find(
                                        (obj) => obj.id == data.id
                                      );
                                      selectedLayer.set("fill", c.color);
                                      fabricCanvas.renderAll();
                                      data.fill = c.color;
                                      setState({
                                        evented: true,
                                        changedColor: true,
                                      });
                                    }}
                                  ></button>
                                  {/* <Button
                                                                    className="transition-all"
                                                                    border={c.color == data.fill ? '2px solid rgb(59,130,246)' : ''}
                                                                    aria-label={c.color}
                                                                    background={c.color}
                                                                    height="32px"
                                                                    width="32px"
                                                                    padding={0}
                                                                    minWidth="unset"
                                                                    borderRadius={16}
                                                                    _hover={{ background: c }}
                                                                    onClick={() => {
                                                                        const svg = fabricCanvas.getObjects().find(fab => fab.height == getState().dimension.width)
                                                                        const selectedLayer = svg._objects.find(obj => obj.id == data.id)
                                                                        selectedLayer.set('fill', c.color)
                                                                        fabricCanvas.renderAll();
                                                                        data.fill = c.color
                                                                        setState({
                                                                            evented: true,
                                                                            changedColor: true
                                                                        })
                                                                    }}
                                                                ></Button> */}
                                </Center>
                              ))}
                            </div>
                          ))}
                        </SimpleGrid>
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                  <Text className="capitalize ms-3">
                    {data.id.split("-")[0]} {data.id.split("-")[1]}
                  </Text>
                </div>
              ))}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </div>
      {!isMobile && (
        <div className="p-5 border-y-2 flex items-center">
          <InfoIcon color="gray.400" />
          <Text className="ms-3" color="gray.400">
            The color accuracy may vary
          </Text>
        </div>
      )}
    </>
  );
};

export default ColorContent;
