// import
import Dashboard from "views/Dashboard/Dashboard";
import Design from "views/Dashboard/Design";
import Product from "views/Dashboard/Product/";
import Category from "views/Dashboard/Category";
import Garments from "views/Dashboard/Garments";
import Profile from "views/Profile";

import {
  HomeIcon,
  StatsIcon,
  CreditIcon,
  PersonIcon,
  CartIcon,
  DocumentIcon,
  RocketIcon,
  SupportIcon,
} from "components/Icons/Icons";

var dashRoutes = [
  {
    name: "",
    category: "account",
    state: "pageCollapse",
    views: [
      {
        path: "/category",
        name: "Category",
        icon: <CartIcon color="inherit" />,
        secondaryNavbar: true,
        component: Category,
        layout: "/admin",
      },
      {
        path: "/product",
        name: "Product",
        icon: <PersonIcon color="inherit" />,
        secondaryNavbar: false,
        component: Product,
        layout: "/admin",
      },
      {
        path: "/garment",
        name: "Garments",
        icon: <PersonIcon color="inherit" />,
        secondaryNavbar: false,
        component: Garments,
        layout: "/admin",
      },
      {
        path: "/design",
        name: "Design",
        icon: <HomeIcon color="inherit" />,
        secondaryNavbar: false,
        component: Design,
        layout: "/admin",
      },
      {
        path: "/profile",
        name: "Profile",
        icon: <PersonIcon color="inherit" />,
        secondaryNavbar: true,
        component: Profile,
        layout: "/admin",
      },
    ],
  },
];
export default dashRoutes;
