// Chakra imports
import {
  Box,
  Flex,
  Image,
  Text,
  useColorModeValue,
  Button,
} from "@chakra-ui/react";
import React from "react";
import { useHistory, Link } from "react-router-dom";
import { MdDesignServices } from "react-icons/md";
const ProjectCard = ({
  id,
  catId,
  prodId,
  image,
  name,
  category,
  description,
}) => {
  const history = useHistory();
  const textColor = useColorModeValue("gray.700", "white");
  const data = { prodId: prodId, catId: catId, designId: id };
  return (
    <Flex
      direction="column"
      onClick={() => {
        sessionStorage.setItem("designName", category);
        sessionStorage.setItem(
          "designLink",
          `#/admin/design/${catId}/${prodId}/${id}`
        );
        const data = { prod_id: prodId, cat_id: catId };
        history.push({
          pathname: `/admin/design/${catId}/${prodId}/${id}`,
          state: data,
        });
      }}
    >
      <Box
        className="imgFlex"
        mb="20px"
        position="relative"
        borderRadius="15px"
      >
        <Image src={image} className="imgThumb" borderRadius="15px" />
        <Box
          w="100%"
          h="100%"
          position="absolute"
          top="0"
          borderRadius="15px"
        ></Box>
      </Box>
      <Flex className="imgText" direction="column">
        <Button
          rightIcon={<MdDesignServices />}
          colorScheme="teal"
          variant="link"
        >
          Customise
        </Button>
        {/* <Text fontSize="xl" color={textColor} fontWeight="bold" mb="10px">
          {category}
        </Text> */}
        <Text fontSize="md" color="gray.500" fontWeight="400" mb="20px">
          {description}
        </Text>
      </Flex>
    </Flex>
  );
};

export default ProjectCard;
