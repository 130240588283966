/* eslint-disable react/prop-types */
import {
  Box,
  Button,
  Input,
  Text,
  Img,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Card,
  Tabs,
  TabList,
  TabPanel,
  Tab,
  TabPanels,
  Grid,
  CardBody,
  Image,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  DrawerHeader,
  useDisclosure,
} from "@chakra-ui/react";

import React, { useState } from "react";
import useStore, { setState, getState } from "../../../../hooks/useStore";

import { InfoIcon, DeleteIcon, EditIcon } from "@chakra-ui/icons";

const ArtworkContent = ({ fabricCanvas }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [style, setStyle] = useState({});

  const handleClick = () => {
    setStyle({
      background:
        "linear-gradient(360deg, rgba(49, 56, 96, 0.16) 0%, linen 100%)",
    });
  };
  const [currentSvg, setCurrentSvg] = useState(null);
  var sports = [
    {
      id: "Baseball",
      name: "Baseball",
      mascots: [
        {
          name: "Baseball 1",
          icon: "images/mascots/Baseball/Baseball-1.svg.png",
          svg: "Baseball-1.svg",
        },
        {
          name: "Baseball 2",
          icon: "images/mascots/Baseball/Baseball-2.svg.png",
          svg: "Baseball-2.svg",
        },
        {
          name: "Baseball 3",
          icon: "images/mascots/Baseball/Baseball-3.svg.png",
          svg: "Baseball-3.svg",
        },
        {
          name: "Baseball 4",
          icon: "images/mascots/Baseball/Baseball-4.svg.png",
          svg: "Baseball-4.svg",
        },
        {
          name: "Baseball 5",
          icon: "images/mascots/Baseball/Baseball-5.svg.png",
          svg: "Baseball-5.svg",
        },
        {
          name: "Baseball 6",
          icon: "images/mascots/Baseball/Baseball-6.svg.png",
          svg: "Baseball-6.svg",
        },
        {
          name: "Baseball 7",
          icon: "images/mascots/Baseball/Baseball-7.svg.png",
          svg: "Baseball-7.svg",
        },
        {
          name: "Baseball 8",
          icon: "images/mascots/Baseball/Baseball-8.svg.png",
          svg: "Baseball-8.svg",
        },
        {
          name: "Baseball 9",
          icon: "images/mascots/Baseball/Baseball-9.svg.png",
          svg: "Baseball-9.svg",
        },
        {
          name: "Baseball 10",
          icon: "images/mascots/Baseball/Baseball-10.svg.png",
          svg: "Baseball-10.svg",
        },
        {
          name: "Baseball 11",
          icon: "images/mascots/Baseball/Baseball-11.svg.png",
          svg: "Baseball-11.svg",
        },
        {
          name: "Baseball 12",
          icon: "images/mascots/Baseball/Baseball-12.svg.png",
          svg: "Baseball-12.svg",
        },
        {
          name: "Baseball 13",
          icon: "images/mascots/Baseball/Baseball-13.svg.png",
          svg: "Baseball-13.svg",
        },
        {
          name: "Baseball 14",
          icon: "images/mascots/Baseball/Baseball-14.svg.png",
          svg: "Baseball-14.svg",
        },
        {
          name: "Baseball 15",
          icon: "images/mascots/Baseball/Baseball-15.svg.png",
          svg: "Baseball-15.svg",
        },
        {
          name: "Baseball 16",
          icon: "images/mascots/Baseball/Baseball-16.svg.png",
          svg: "Baseball-16.svg",
        },
        //{
        //	name: 'Baseball 17',
        //	icon: 'images/mascots/Baseball/Baseball-17.svg.png',
        //	svg: 'Baseball-17.svg'
        //},
        {
          name: "Baseball 18",
          icon: "images/mascots/Baseball/Baseball-18.svg.png",
          svg: "Baseball-18.svg",
        },
        //{
        //	name: 'Baseball 19',
        //	icon: 'images/mascots/Baseball/Baseball-19.svg.png',
        //	svg: 'Baseball-19.svg'
        //},
        {
          name: "Baseball 20",
          icon: "images/mascots/Baseball/Baseball-20.svg.png",
          svg: "Baseball-20.svg",
        },
        {
          name: "Baseball 21",
          icon: "images/mascots/Baseball/Baseball-21.svg.png",
          svg: "Baseball-21.svg",
        },
        {
          name: "Baseball 22",
          icon: "images/mascots/Baseball/Baseball-22.svg.png",
          svg: "Baseball-22.svg",
        },
        {
          name: "Baseball 23",
          icon: "images/mascots/Baseball/Baseball-23.svg.png",
          svg: "Baseball-23.svg",
        },
        {
          name: "Baseball 24",
          icon: "images/mascots/Baseball/Baseball-24.svg.png",
          svg: "Baseball-24.svg",
        },
        {
          name: "Baseball 25",
          icon: "images/mascots/Baseball/Baseball-25.svg.png",
          svg: "Baseball-25.svg",
        },
        {
          name: "Baseball 26",
          icon: "images/mascots/Baseball/Baseball-26.svg.png",
          svg: "Baseball-26.svg",
        },
        {
          name: "Baseball 27",
          icon: "images/mascots/Baseball/Baseball-27.svg.png",
          svg: "Baseball-27.svg",
        },
        {
          name: "Baseball 28",
          icon: "images/mascots/Baseball/Baseball-28.svg.png",
          svg: "Baseball-28.svg",
        },
        {
          name: "Baseball 29",
          icon: "images/mascots/Baseball/Baseball-29.svg.png",
          svg: "Baseball-29.svg",
        },
        {
          name: "Baseball 30",
          icon: "images/mascots/Baseball/Baseball-30.svg.png",
          svg: "Baseball-30.svg",
        },
        {
          name: "Baseball 31",
          icon: "images/mascots/Baseball/Baseball-31.svg.png",
          svg: "Baseball-31.svg",
        },
        {
          name: "Baseball 32",
          icon: "images/mascots/Baseball/Baseball-32.svg.png",
          svg: "Baseball-32.svg",
        },
        {
          name: "Baseball 33",
          icon: "images/mascots/Baseball/Baseball-33.svg.png",
          svg: "Baseball-33.svg",
        },
        {
          name: "Baseball 34",
          icon: "images/mascots/Baseball/Baseball-34.svg.png",
          svg: "Baseball-34.svg",
        },
        {
          name: "Baseball 35",
          icon: "images/mascots/Baseball/Baseball-35.svg.png",
          svg: "Baseball-35.svg",
        },
        {
          name: "Baseball 36",
          icon: "images/mascots/Baseball/Baseball-36.svg.png",
          svg: "Baseball-36.svg",
        },
        {
          name: "Baseball 37",
          icon: "images/mascots/Baseball/Baseball-37.svg.png",
          svg: "Baseball-37.svg",
        },
        {
          name: "Baseball 38",
          icon: "images/mascots/Baseball/Baseball-38.svg.png",
          svg: "Baseball-38.svg",
        },
        {
          name: "Baseball 39",
          icon: "images/mascots/Baseball/Baseball-39.svg.png",
          svg: "Baseball-39.svg",
        },
        {
          name: "Baseball 40",
          icon: "images/mascots/Baseball/Baseball-40.svg.png",
          svg: "Baseball-40.svg",
        },
        {
          name: "Baseball 41",
          icon: "images/mascots/Baseball/Baseball-41.svg.png",
          svg: "Baseball-41.svg",
        },
      ],
    },
    {
      id: "Basketball",
      name: "Basketball",
      mascots: [
        {
          name: "Basketball 1",
          icon: "images/mascots/Basketball/Basketball-1.svg.png",
          svg: "Basketball-1.svg",
        },
        {
          name: "Basketball 2",
          icon: "images/mascots/Basketball/Basketball-2.svg.png",
          svg: "Basketball-2.svg",
        },
        {
          name: "Basketball 3",
          icon: "images/mascots/Basketball/Basketball-3.svg.png",
          svg: "Basketball-3.svg",
        },
        {
          name: "Basketball 4",
          icon: "images/mascots/Basketball/Basketball-4.svg.png",
          svg: "Basketball-4.svg",
        },
        {
          name: "Basketball 5",
          icon: "images/mascots/Basketball/Basketball-5.svg.png",
          svg: "Basketball-5.svg",
        },
        {
          name: "Basketball 6",
          icon: "images/mascots/Basketball/Basketball-6.svg.png",
          svg: "Basketball-6.svg",
        },
        {
          name: "Basketball 7",
          icon: "images/mascots/Basketball/Basketball-7.svg.png",
          svg: "Basketball-7.svg",
        },
        {
          name: "Basketball 8",
          icon: "images/mascots/Basketball/Basketball-8.svg.png",
          svg: "Basketball-8.svg",
        },
        {
          name: "Basketball 9",
          icon: "images/mascots/Basketball/Basketball-9.svg.png",
          svg: "Basketball-9.svg",
        },
        {
          name: "Basketball 10",
          icon: "images/mascots/Basketball/Basketball-10.svg.png",
          svg: "Basketball-10.svg",
        },
        {
          name: "Basketball 11",
          icon: "images/mascots/Basketball/Basketball-11.svg.png",
          svg: "Basketball-11.svg",
        },
        //{
        //	name: 'Basketball 12',
        //	icon: 'images/mascots/Basketball/Basketball-12.svg.png',
        //	svg: 'Basketball-12.svg'
        //},
        {
          name: "Basketball 13",
          icon: "images/mascots/Basketball/Basketball-13.svg.png",
          svg: "Basketball-13.svg",
        },
        {
          name: "Basketball 14",
          icon: "images/mascots/Basketball/Basketball-14.svg.png",
          svg: "Basketball-14.svg",
        },
        {
          name: "Basketball 15",
          icon: "images/mascots/Basketball/Basketball-15.svg.png",
          svg: "Basketball-15.svg",
        },
        {
          name: "Basketball 16",
          icon: "images/mascots/Basketball/Basketball-16.svg.png",
          svg: "Basketball-16.svg",
        },
        {
          name: "Basketball 17",
          icon: "images/mascots/Basketball/Basketball-17.svg.png",
          svg: "Basketball-17.svg",
        },
        {
          name: "Basketball 18",
          icon: "images/mascots/Basketball/Basketball-18.svg.png",
          svg: "Basketball-18.svg",
        },
        {
          name: "Basketball 19",
          icon: "images/mascots/Basketball/Basketball-19.svg.png",
          svg: "Basketball-19.svg",
        },
        {
          name: "Basketball 20",
          icon: "images/mascots/Basketball/Basketball-20.svg.png",
          svg: "Basketball-20.svg",
        },
        {
          name: "Basketball 21",
          icon: "images/mascots/Basketball/Basketball-21.svg.png",
          svg: "Basketball-21.svg",
        },
        {
          name: "Basketball 22",
          icon: "images/mascots/Basketball/Basketball-22.svg.png",
          svg: "Basketball-22.svg",
        },
        {
          name: "Basketball 23",
          icon: "images/mascots/Basketball/Basketball-23.svg.png",
          svg: "Basketball-23.svg",
        },
      ],
    },
    {
      id: "Cheerleading",
      name: "Cheerleading",
      mascots: [
        {
          name: "Cheerleading 1",
          icon: "images/mascots/Cheerleading/Cheerleading-1.svg.png",
          svg: "Cheerleading-1.svg",
        },
        {
          name: "Cheerleading 2",
          icon: "images/mascots/Cheerleading/Cheerleading-2.svg.png",
          svg: "Cheerleading-2.svg",
        },
        {
          name: "Cheerleading 3",
          icon: "images/mascots/Cheerleading/Cheerleading-3.svg.png",
          svg: "Cheerleading-3.svg",
        },
        {
          name: "Cheerleading 4",
          icon: "images/mascots/Cheerleading/Cheerleading-4.svg.png",
          svg: "Cheerleading-4.svg",
        },
        {
          name: "Cheerleading 5",
          icon: "images/mascots/Cheerleading/Cheerleading-5.svg.png",
          svg: "Cheerleading-5.svg",
        },
        {
          name: "Cheerleading 6",
          icon: "images/mascots/Cheerleading/Cheerleading-6.svg.png",
          svg: "Cheerleading-6.svg",
        },
        {
          name: "Cheerleading 7",
          icon: "images/mascots/Cheerleading/Cheerleading-7.svg.png",
          svg: "Cheerleading-7.svg",
        },
        {
          name: "Cheerleading 8",
          icon: "images/mascots/Cheerleading/Cheerleading-8.svg.png",
          svg: "Cheerleading-8.svg",
        },
        {
          name: "Cheerleading 9",
          icon: "images/mascots/Cheerleading/Cheerleading-9.svg.png",
          svg: "Cheerleading-9.svg",
        },
      ],
    },
    {
      id: "Football",
      name: "Football",
      mascots: [
        {
          name: "Football 1",
          icon: "images/mascots/Football/Football-1.svg.png",
          svg: "Football-1.svg",
        },
        {
          name: "Football 2",
          icon: "images/mascots/Football/Football-2.svg.png",
          svg: "Football-2.svg",
        },
        {
          name: "Football 3",
          icon: "images/mascots/Football/Football-3.svg.png",
          svg: "Football-3.svg",
        },
        {
          name: "Football 4",
          icon: "images/mascots/Football/Football-4.svg.png",
          svg: "Football-4.svg",
        },
        {
          name: "Football 5",
          icon: "images/mascots/Football/Football-5.svg.png",
          svg: "Football-5.svg",
        },
        {
          name: "Football 6",
          icon: "images/mascots/Football/Football-6.svg.png",
          svg: "Football-6.svg",
        },
        {
          name: "Football 7",
          icon: "images/mascots/Football/Football-7.svg.png",
          svg: "Football-7.svg",
        },
        {
          name: "Football 8",
          icon: "images/mascots/Football/Football-8.svg.png",
          svg: "Football-8.svg",
        },
        {
          name: "Football 9",
          icon: "images/mascots/Football/Football-9.svg.png",
          svg: "Football-9.svg",
        },
        {
          name: "Football 10",
          icon: "images/mascots/Football/Football-10.svg.png",
          svg: "Football-10.svg",
        },
        {
          name: "Football 11",
          icon: "images/mascots/Football/Football-11.svg.png",
          svg: "Football-11.svg",
        },
        {
          name: "Football 12",
          icon: "images/mascots/Football/Football-12.svg.png",
          svg: "Football-12.svg",
        },
        {
          name: "Football 13",
          icon: "images/mascots/Football/Football-13.svg.png",
          svg: "Football-13.svg",
        },
        {
          name: "Football 14",
          icon: "images/mascots/Football/Football-14.svg.png",
          svg: "Football-14.svg",
        },
        //{
        //	name: 'Football 15',
        //	icon: 'images/mascots/Football/Football-15.svg.png',
        //	svg: 'Football-15.svg'
        //},
        {
          name: "Football 16",
          icon: "images/mascots/Football/Football-16.svg.png",
          svg: "Football-16.svg",
        },
        {
          name: "Football 17",
          icon: "images/mascots/Football/Football-17.svg.png",
          svg: "Football-17.svg",
        },
        {
          name: "Football 18",
          icon: "images/mascots/Football/Football-18.svg.png",
          svg: "Football-18.svg",
        },
        {
          name: "Football 19",
          icon: "images/mascots/Football/Football-19.svg.png",
          svg: "Football-19.svg",
        },
        {
          name: "Football 20",
          icon: "images/mascots/Football/Football-20.svg.png",
          svg: "Football-20.svg",
        },
        {
          name: "Football 21",
          icon: "images/mascots/Football/Football-21.svg.png",
          svg: "Football-21.svg",
        },
        {
          name: "Football 22",
          icon: "images/mascots/Football/Football-22.svg.png",
          svg: "Football-22.svg",
        },
        {
          name: "Football 23",
          icon: "images/mascots/Football/Football-23.svg.png",
          svg: "Football-23.svg",
        },
        {
          name: "Football 24",
          icon: "images/mascots/Football/Football-24.svg.png",
          svg: "Football-24.svg",
        },
        {
          name: "Football 25",
          icon: "images/mascots/Football/Football-25.svg.png",
          svg: "Football-25.svg",
        },
        // {
        // 	name: 'Football 26',
        // 	icon: 'images/mascots/Football/Football-26.svg.png',
        // 	svg: 'Football-26.svg'
        // },
      ],
    },
    {
      id: "Golf",
      name: "Golf",
      mascots: [
        {
          name: "Golf 1",
          icon: "images/mascots/Golf/Golf-1.svg.png",
          svg: "Golf-1.svg",
        },
        {
          name: "Golf 2",
          icon: "images/mascots/Golf/Golf-2.svg.png",
          svg: "Golf-2.svg",
        },
        {
          name: "Golf 3",
          icon: "images/mascots/Golf/Golf-3.svg.png",
          svg: "Golf-3.svg",
        },
        {
          name: "Golf 4",
          icon: "images/mascots/Golf/Golf-4.svg.png",
          svg: "Golf-4.svg",
        },
        {
          name: "Golf 5",
          icon: "images/mascots/Golf/Golf-5.svg.png",
          svg: "Golf-5.svg",
        },
        {
          name: "Golf 6",
          icon: "images/mascots/Golf/Golf-6.svg.png",
          svg: "Golf-6.svg",
        },
        {
          name: "Golf 7",
          icon: "images/mascots/Golf/Golf-7.svg.png",
          svg: "Golf-7.svg",
        },
        {
          name: "Golf 8",
          icon: "images/mascots/Golf/Golf-8.svg.png",
          svg: "Golf-8.svg",
        },
      ],
    },
    {
      id: "Hockey",
      name: "Hockey",
      mascots: [
        {
          name: "Hockey 1",
          icon: "images/mascots/Hockey/Hockey-1.svg.png",
          svg: "Hockey-1.svg",
        },
        {
          name: "Hockey 2",
          icon: "images/mascots/Hockey/Hockey-2.svg.png",
          svg: "Hockey-2.svg",
        },
        {
          name: "Hockey 3",
          icon: "images/mascots/Hockey/Hockey-3.svg.png",
          svg: "Hockey-3.svg",
        },
        {
          name: "Hockey 4",
          icon: "images/mascots/Hockey/Hockey-4.svg.png",
          svg: "Hockey-4.svg",
        },
        {
          name: "Hockey 5",
          icon: "images/mascots/Hockey/Hockey-5.svg.png",
          svg: "Hockey-5.svg",
        },
        {
          name: "Hockey 6",
          icon: "images/mascots/Hockey/Hockey-6.svg.png",
          svg: "Hockey-6.svg",
        },
        {
          name: "Hockey 7",
          icon: "images/mascots/Hockey/Hockey-7.svg.png",
          svg: "Hockey-7.svg",
        },
        {
          name: "Hockey 8",
          icon: "images/mascots/Hockey/Hockey-8.svg.png",
          svg: "Hockey-8.svg",
        },
        //{
        //	name: 'Hockey 9',
        //	icon: 'images/mascots/Hockey/Hockey-9.svg.png',
        //	svg: 'Hockey-9.svg'
        //},
      ],
    },
    {
      id: "Lacrosse",
      name: "Lacrosse",
      mascots: [
        {
          name: "Lacrosse 1",
          icon: "images/mascots/Lacrosse/Lacrosse-1.svg.png",
          svg: "Lacrosse-1.svg",
        },
        {
          name: "Lacrosse 2",
          icon: "images/mascots/Lacrosse/Lacrosse-2.svg.png",
          svg: "Lacrosse-2.svg",
        },
        {
          name: "Lacrosse 3",
          icon: "images/mascots/Lacrosse/Lacrosse-3.svg.png",
          svg: "Lacrosse-3.svg",
        },
        {
          name: "Lacrosse 4",
          icon: "images/mascots/Lacrosse/Lacrosse-4.svg.png",
          svg: "Lacrosse-4.svg",
        },
      ],
    },
    {
      id: "Soccer",
      name: "Soccer",
      mascots: [
        {
          name: "Soccer 1",
          icon: "images/mascots/Soccer/Soccer-1.svg.png",
          svg: "Soccer-1.svg",
        },
        {
          name: "Soccer 2",
          icon: "images/mascots/Soccer/Soccer-2.svg.png",
          svg: "Soccer-2.svg",
        },
        {
          name: "Soccer 3",
          icon: "images/mascots/Soccer/Soccer-3.svg.png",
          svg: "Soccer-3.svg",
        },
        {
          name: "Soccer 4",
          icon: "images/mascots/Soccer/Soccer-4.svg.png",
          svg: "Soccer-4.svg",
        },
        //{
        //	name: 'Soccer 5',
        //	icon: 'images/mascots/Soccer/Soccer-5.svg.png',
        //	svg: 'Soccer-5.svg'
        //},
        {
          name: "Soccer 6",
          icon: "images/mascots/Soccer/Soccer-6.svg.png",
          svg: "Soccer-6.svg",
        },
        {
          name: "Soccer 7",
          icon: "images/mascots/Soccer/Soccer-7.svg.png",
          svg: "Soccer-7.svg",
        },
        {
          name: "Soccer 8",
          icon: "images/mascots/Soccer/Soccer-8.svg.png",
          svg: "Soccer-8.svg",
        },
        {
          name: "Soccer 9",
          icon: "images/mascots/Soccer/Soccer-9.svg.png",
          svg: "Soccer-9.svg",
        },
        {
          name: "Soccer 10",
          icon: "images/mascots/Soccer/Soccer-10.svg.png",
          svg: "Soccer-10.svg",
        },
        {
          name: "Soccer 11",
          icon: "images/mascots/Soccer/Soccer-11.svg.png",
          svg: "Soccer-11.svg",
        },
        {
          name: "Soccer 12",
          icon: "images/mascots/Soccer/Soccer-12.svg.png",
          svg: "Soccer-12.svg",
        },
        {
          name: "Soccer 13",
          icon: "images/mascots/Soccer/Soccer-13.svg.png",
          svg: "Soccer-13.svg",
        },
      ],
    },
    {
      id: "Swimming",
      name: "Swimming",
      mascots: [
        {
          name: "Swimming 1",
          icon: "images/mascots/Swimming/Swimming-1.svg.png",
          svg: "Swimming-1.svg",
        },
        {
          name: "Swimming 2",
          icon: "images/mascots/Swimming/Swimming-2.svg.png",
          svg: "Swimming-2.svg",
        },
        {
          name: "Swimming 3",
          icon: "images/mascots/Swimming/Swimming-3.svg.png",
          svg: "Swimming-3.svg",
        },
        {
          name: "Swimming 4",
          icon: "images/mascots/Swimming/Swimming-4.svg.png",
          svg: "Swimming-4.svg",
        },
        {
          name: "Swimming 5",
          icon: "images/mascots/Swimming/Swimming-5.svg.png",
          svg: "Swimming-5.svg",
        },
        {
          name: "Swimming 6",
          icon: "images/mascots/Swimming/Swimming-6.svg.png",
          svg: "Swimming-6.svg",
        },
      ],
    },
    {
      id: "Tennis",
      name: "Tennis",
      mascots: [
        {
          name: "Tennis 1",
          icon: "images/mascots/Tennis/Tennis-1.svg.png",
          svg: "Tennis-1.svg",
        },
        {
          name: "Tennis 2",
          icon: "images/mascots/Tennis/Tennis-2.svg.png",
          svg: "Tennis-2.svg",
        },
        {
          name: "Tennis 3",
          icon: "images/mascots/Tennis/Tennis-3.svg.png",
          svg: "Tennis-3.svg",
        },
        {
          name: "Tennis 4",
          icon: "images/mascots/Tennis/Tennis-4.svg.png",
          svg: "Tennis-4.svg",
        },
      ],
    },
    {
      id: "Track",
      name: "Track",
      mascots: [
        {
          name: "Track 1",
          icon: "images/mascots/Track/Track-1.svg.png",
          svg: "Track-1.svg",
        },
        {
          name: "Track 2",
          icon: "images/mascots/Track/Track-2.svg.png",
          svg: "Track-2.svg",
        },
        {
          name: "Track 3",
          icon: "images/mascots/Track/Track-3.svg.png",
          svg: "Track-3.svg",
        },
        {
          name: "Track 4",
          icon: "images/mascots/Track/Track-4.svg.png",
          svg: "Track-4.svg",
        },
        {
          name: "Track 5",
          icon: "images/mascots/Track/Track-5.svg.png",
          svg: "Track-5.svg",
        },
        // {
        // 	name: 'Track 6',
        // 	icon: 'images/mascots/Track/Track-6.svg.png',
        // 	svg: 'Track-6.svg'
        // },
        {
          name: "Track 7",
          icon: "images/mascots/Track/Track-7.svg.png",
          svg: "Track-7.svg",
        },
        {
          name: "Track 8",
          icon: "images/mascots/Track/Track-8.svg.png",
          svg: "Track-8.svg",
        },
        {
          name: "Track 9",
          icon: "images/mascots/Track/Track-9.svg.png",
          svg: "Track-9.svg",
        },
        {
          name: "Track 10",
          icon: "images/mascots/Track/Track-10.svg.png",
          svg: "Track-10.svg",
        },
        {
          name: "Track 11",
          icon: "images/mascots/Track/Track-11.svg.png",
          svg: "Track-11.svg",
        },
        {
          name: "Track 12",
          icon: "images/mascots/Track/Track-12.svg.png",
          svg: "Track-12.svg",
        },
      ],
    },
    {
      id: "Volleyball",
      name: "Volleyball",
      mascots: [
        {
          name: "Volleyball 1",
          icon: "images/mascots/Volleyball/Volleyball-1.svg.png",
          svg: "Volleyball-1.svg",
        },
        {
          name: "Volleyball 2",
          icon: "images/mascots/Volleyball/Volleyball-2.svg.png",
          svg: "Volleyball-2.svg",
        },
        {
          name: "Volleyball 3",
          icon: "images/mascots/Volleyball/Volleyball-3.svg.png",
          svg: "Volleyball-3.svg",
        },
        //{
        //	name: 'Volleyball 4',
        //	icon: 'images/mascots/Volleyball/Volleyball-4.svg.png',
        //	svg: 'Volleyball-4.svg'
        //},
        {
          name: "Volleyball 5",
          icon: "images/mascots/Volleyball/Volleyball-5.svg.png",
          svg: "Volleyball-5.svg",
        },
        {
          name: "Volleyball 6",
          icon: "images/mascots/Volleyball/Volleyball-6.svg.png",
          svg: "Volleyball-6.svg",
        },
        //{
        //	name: 'Volleyball 7',
        //	icon: 'images/mascots/Volleyball/Volleyball-7.svg.png',
        //	svg: 'Volleyball-7.svg'
        //},
        {
          name: "Volleyball 8",
          icon: "images/mascots/Volleyball/Volleyball-8.svg.png",
          svg: "Volleyball-8.svg",
        },
        {
          name: "Volleyball 9",
          icon: "images/mascots/Volleyball/Volleyball-9.svg.png",
          svg: "Volleyball-9.svg",
        },
        {
          name: "Volleyball 10",
          icon: "images/mascots/Volleyball/Volleyball-10.svg.png",
          svg: "Volleyball-10.svg",
        },
        //{
        //	name: 'Volleyball 11',
        //	icon: 'images/mascots/Volleyball/Volleyball-11.svg.png',
        //	svg: 'Volleyball-11.svg'
        //},
        //{
        //	name: 'Volleyball 12',
        //	icon: 'images/mascots/Volleyball/Volleyball-12.svg.png',
        //	svg: 'Volleyball-12.svg'
        //},
        {
          name: "Volleyball 13",
          icon: "images/mascots/Volleyball/Volleyball-13.svg.png",
          svg: "Volleyball-13.svg",
        },
        {
          name: "Volleyball 14",
          icon: "images/mascots/Volleyball/Volleyball-14.svg.png",
          svg: "Volleyball-14.svg",
        },
        {
          name: "Volleyball 15",
          icon: "images/mascots/Volleyball/Volleyball-15.svg.png",
          svg: "Volleyball-15.svg",
        },
      ],
    },
    {
      id: "Wrestling",
      name: "Wrestling",
      mascots: [
        {
          name: "Wrestling 1",
          icon: "images/mascots/Wrestling/Wrestling-1.svg.png",
          svg: "Wrestling-1.svg",
        },
        {
          name: "Wrestling 2",
          icon: "images/mascots/Wrestling/Wrestling-2.svg.png",
          svg: "Wrestling-2.svg",
        },
        {
          name: "Wrestling 3",
          icon: "images/mascots/Wrestling/Wrestling-3.svg.png",
          svg: "Wrestling-3.svg",
        },
        {
          name: "Wrestling 4",
          icon: "images/mascots/Wrestling/Wrestling-4.svg.png",
          svg: "Wrestling-4.svg",
        },
      ],
    },
  ];

  var mascots = [
    {
      id: "badger",
      name: "Badgers",
      mascots: [
        {
          name: "Badger 1",
          icon: "images/mascots/badger_1.png",
          svg: "Badger_1.svg",
        },
        {
          name: "Badger 2",
          icon: "images/mascots/badger_2.png",
          svg: "Badger_2.svg",
        },
        {
          name: "Badger 3",
          icon: "images/mascots/badger_3.png",
          svg: "Badger_3.svg",
        },
      ],
    },
    {
      id: "bear",
      name: "Bears",
      mascots: [
        {
          name: "Bear 1",
          icon: "images/mascots/bear_1.png",
          svg: "Bear_1.svg",
        },
        {
          name: "Bear 2",
          icon: "images/mascots/bear_2.png",
          svg: "Bear_2.svg",
        },
        {
          name: "Bear 3",
          icon: "images/mascots/bear_3.png",
          svg: "Bear_3.svg",
        },
        {
          name: "Bear 4",
          icon: "images/mascots/bear_4.png",
          svg: "Bear_4.svg",
        },
        {
          name: "Bear 5",
          icon: "images/mascots/bear_5.png",
          svg: "Bear_5.svg",
        },
        {
          name: "Bear 6",
          icon: "images/mascots/bear_6.png",
          svg: "Bear_6.svg",
        },
        {
          name: "Bear 7",
          icon: "images/mascots/bear_7.png",
          svg: "Bear_7.svg",
        },
        {
          name: "Bear 8",
          icon: "images/mascots/bear_8.png",
          svg: "Bear_8.svg",
        },
        {
          name: "Bear 9",
          icon: "images/mascots/bear_9.png",
          svg: "Bear_9.svg",
        },
      ],
    },
    {
      id: "bird",
      name: "Birds",
      mascots: [
        {
          name: "Bird 1",
          icon: "images/mascots/bird_1.png",
          svg: "Bird_1.svg",
        },
        {
          name: "Bird 2",
          icon: "images/mascots/bird_2.png",
          svg: "Bird_2.svg",
        },
        {
          name: "Bird 3",
          icon: "images/mascots/bird_3.png",
          svg: "Bird_3.svg",
        },
        {
          name: "Bird 4",
          icon: "images/mascots/bird_4.png",
          svg: "Bird_4.svg",
        },
        {
          name: "Bird 5",
          icon: "images/mascots/bird_5.png",
          svg: "Bird_5.svg",
        },
        {
          name: "Bird 6",
          icon: "images/mascots/bird_6.png",
          svg: "Bird_6.svg",
        },
        {
          name: "Bird 7",
          icon: "images/mascots/bird_7.png",
          svg: "Bird_7.svg",
        },
        {
          name: "Bird 8",
          icon: "images/mascots/bird_8.png",
          svg: "Bird_8.svg",
        },
        {
          name: "Bird 9",
          icon: "images/mascots/bird_9.png",
          svg: "Bird_9.svg",
        },
        {
          name: "Bird 10",
          icon: "images/mascots/bird_10.png",
          svg: "Bird_10.svg",
        },
        {
          name: "Bird 11",
          icon: "images/mascots/bird_11.png",
          svg: "Bird_11.svg",
        },
        {
          name: "Bird 12",
          icon: "images/mascots/bird_12.png",
          svg: "Bird_12.svg",
        },
        {
          name: "Bird 13",
          icon: "images/mascots/bird_13.png",
          svg: "Bird_13.svg",
        },
        {
          name: "Bird 14",
          icon: "images/mascots/bird_14.png",
          svg: "Bird_14.svg",
        },
        {
          name: "Bird 15",
          icon: "images/mascots/bird_15.png",
          svg: "Bird_15.svg",
        },
        {
          name: "Bird 16",
          icon: "images/mascots/bird_16.png",
          svg: "Bird_16.svg",
        },
        {
          name: "Bird 17",
          icon: "images/mascots/bird_17.png",
          svg: "Bird_17.svg",
        },
        {
          name: "Bird 18",
          icon: "images/mascots/bird_18.png",
          svg: "Bird_18.svg",
        },
        {
          name: "Bird 19",
          icon: "images/mascots/bird_19.png",
          svg: "Bird_19.svg",
        },
        {
          name: "Bird 20",
          icon: "images/mascots/bird_20.png",
          svg: "Bird_20.svg",
        },
        {
          name: "Bird 21",
          icon: "images/mascots/bird_21.png",
          svg: "Bird_21.svg",
        },
        {
          name: "Bird 22",
          icon: "images/mascots/bird_22.png",
          svg: "Bird_22.svg",
        },
        {
          name: "Bird 23",
          icon: "images/mascots/bird_23.png",
          svg: "Bird_23.svg",
        },
        {
          name: "Bird 24",
          icon: "images/mascots/bird_24.png",
          svg: "Bird_24.svg",
        },
        {
          name: "Bird 25",
          icon: "images/mascots/bird_25.png",
          svg: "Bird_25.svg",
        },
        {
          name: "Bird 26",
          icon: "images/mascots/bird_26.png",
          svg: "Bird_26.svg",
        },
        {
          name: "Bird 27",
          icon: "images/mascots/bird_27.png",
          svg: "Bird_27.svg",
        },
        {
          name: "Bird 28",
          icon: "images/mascots/bird_28.png",
          svg: "Bird_28.svg",
        },
        //{
        //	name:'Bird 29',
        //	icon:'images/mascots/bird_29.png',
        //	svg:'Bird_29.svg'
        //},
        {
          name: "Bird 30",
          icon: "images/mascots/bird_30.png",
          svg: "Bird_30.svg",
        },
        {
          name: "Bird 31",
          icon: "images/mascots/bird_31.png",
          svg: "Bird_31.svg",
        },
        {
          name: "Bird 32",
          icon: "images/mascots/bird_32.png",
          svg: "Bird_32.svg",
        },
        {
          name: "Bird 33",
          icon: "images/mascots/bird_33.png",
          svg: "Bird_33.svg",
        },
        {
          name: "Bird 34",
          icon: "images/mascots/bird_34.png",
          svg: "Bird_34.svg",
        },
        {
          name: "Bird 35",
          icon: "images/mascots/bird_35.png",
          svg: "Bird_35.svg",
        },
        {
          name: "Bird 36",
          icon: "images/mascots/bird_36.png",
          svg: "Bird_36.svg",
        },
        {
          name: "Bird 37",
          icon: "images/mascots/bird_37.png",
          svg: "Bird_37.svg",
        },
        {
          name: "Bird 38",
          icon: "images/mascots/bird_38.png",
          svg: "Bird_38.svg",
        },
        {
          name: "Bird 39",
          icon: "images/mascots/bird_39.png",
          svg: "Bird_39.svg",
        },
        {
          name: "Bird 40",
          icon: "images/mascots/bird_40.png",
          svg: "Bird_40.svg",
        },
        {
          name: "Bird 41",
          icon: "images/mascots/bird_41.png",
          svg: "Bird_41.svg",
        },
        {
          name: "Bird 42",
          icon: "images/mascots/bird_42.png",
          svg: "Bird_42.svg",
        },
        {
          name: "Bird 43",
          icon: "images/mascots/bird_43.png",
          svg: "Bird_43.svg",
        },
        {
          name: "Bird 44",
          icon: "images/mascots/bird_44.png",
          svg: "Bird_44.svg",
        },
        {
          name: "Bird 45",
          icon: "images/mascots/bird_45.png",
          svg: "Bird_45.svg",
        },
        {
          name: "Bird 46",
          icon: "images/mascots/bird_46.png",
          svg: "Bird_46.svg",
        },
      ],
    },
    {
      id: "buffalo",
      name: "Buffalo",
      mascots: [
        {
          name: "Buffalo 1",
          icon: "images/mascots/buffalo_1.png",
          svg: "Buffalo_1.svg",
        },
        {
          name: "Buffalo 2",
          icon: "images/mascots/buffalo_2.png",
          svg: "Buffalo_2.svg",
        },
        {
          name: "Buffalo 3",
          icon: "images/mascots/buffalo_3.png",
          svg: "Buffalo_3.svg",
        },
        {
          name: "Buffalo 4",
          icon: "images/mascots/buffalo_4.png",
          svg: "Buffalo_4.svg",
        },
        {
          name: "Buffalo 5",
          icon: "images/mascots/buffalo_5.png",
          svg: "Buffalo_5.svg",
        },
      ],
    },
    {
      id: "bull",
      name: "Bulls",
      mascots: [
        {
          name: "Bull 1",
          icon: "images/mascots/bull_1.png",
          svg: "Bull_1.svg",
        },
        {
          name: "Bull 2",
          icon: "images/mascots/bull_2.png",
          svg: "Bull_2.svg",
        },
        {
          name: "Bull 3",
          icon: "images/mascots/bull_3.png",
          svg: "Bull_3.svg",
        },
        {
          name: "Bull 4",
          icon: "images/mascots/bull_4.png",
          svg: "Bull_4.svg",
        },
        {
          name: "Bull 5",
          icon: "images/mascots/bull_5.png",
          svg: "Bull_5.svg",
        },
        {
          name: "Bull 6",
          icon: "images/mascots/bull_6.png",
          svg: "Bull_6.svg",
        },
        {
          name: "Bull 7",
          icon: "images/mascots/bull_7.png",
          svg: "Bull_7.svg",
        },
        {
          name: "Bull 8",
          icon: "images/mascots/bull_8.png",
          svg: "Bull_8.svg",
        },
        {
          name: "Bull 9",
          icon: "images/mascots/bull_9.png",
          svg: "Bull_9.svg",
        },
      ],
    },
    {
      id: "bulldog",
      name: "Bulldogs",
      mascots: [
        {
          name: "Bulldog 1",
          icon: "images/mascots/bulldog_1.png",
          svg: "BullDog_1.svg",
        },
        {
          name: "Bulldog 2",
          icon: "images/mascots/bulldog_2.png",
          svg: "BullDog_2.svg",
        },
        {
          name: "Bulldog 3",
          icon: "images/mascots/bulldog_3.png",
          svg: "BullDog_3.svg",
        },
        {
          name: "Bulldog 4",
          icon: "images/mascots/bulldog_4.png",
          svg: "BullDog_4.svg",
        },
        {
          name: "Bulldog 5",
          icon: "images/mascots/bulldog_5.png",
          svg: "BullDog_5.svg",
        },
        {
          name: "Bulldog 6",
          icon: "images/mascots/bulldog_6.png",
          svg: "BullDog_6.svg",
        },
        // {
        // 	name: 'Bulldog 7',
        // 	icon:'images/mascots/bulldog_7.png',
        // 	svg:'BullDog_7.svg'
        // },
        {
          name: "Bulldog 8",
          icon: "images/mascots/bulldog_8.png",
          svg: "BullDog_8.svg",
        },
        {
          name: "Bulldog 9",
          icon: "images/mascots/bulldog_9.png",
          svg: "BullDog_9.svg",
        },
        {
          name: "Bulldog 10",
          icon: "images/mascots/bulldog_10.png",
          svg: "BullDog_10.svg",
        },
        // {
        // 	name: 'Bulldog 11',
        // 	icon:'images/mascots/bulldog_11.png',
        // 	svg:'BullDog_11.svg'
        // },
        {
          name: "Bulldog 12",
          icon: "images/mascots/bulldog_12.png",
          svg: "BullDog_12.svg",
        },
        // {
        // 	name: 'Bulldog 13',
        // 	icon:'images/mascots/bulldog_13.png',
        // 	svg:'BullDog_13.svg'
        // },
        {
          name: "Bulldog 14",
          icon: "images/mascots/bulldog_14.png",
          svg: "BullDog_14.svg",
        },
        {
          name: "Bulldog 15",
          icon: "images/mascots/bulldog_15.png",
          svg: "BullDog_15.svg",
        },
        {
          name: "Bulldog 16",
          icon: "images/mascots/bulldog_16.png",
          svg: "BullDog_16.svg",
        },
      ],
    },
    {
      id: "cat",
      name: "Cats",
      mascots: [
        {
          name: "Cat 1",
          icon: "images/mascots/cat_1.png",
          svg: "Cat_1.svg",
        },
        {
          name: "Cat 2",
          icon: "images/mascots/cat_2.png",
          svg: "Cat_2.svg",
        },
        {
          name: "Cat 3",
          icon: "images/mascots/cat_3.png",
          svg: "Cat_3.svg",
        },
        {
          name: "Cat 4",
          icon: "images/mascots/cat_4.png",
          svg: "Cat_4.svg",
        },
        {
          name: "Cat 5",
          icon: "images/mascots/cat_5.png",
          svg: "Cat_5.svg",
        },
        {
          name: "Cat 6",
          icon: "images/mascots/cat_6.png",
          svg: "Cat_6.svg",
        },
        {
          name: "Cat 7",
          icon: "images/mascots/cat_7.png",
          svg: "Cat_7.svg",
        },
        {
          name: "Cat 8",
          icon: "images/mascots/cat_8.png",
          svg: "Cat_8.svg",
        },
        {
          name: "Cat 9",
          icon: "images/mascots/cat_9.png",
          svg: "Cat_9.svg",
        },
        {
          name: "Cat 10",
          icon: "images/mascots/cat_10.png",
          svg: "Cat_10.svg",
        },
        {
          name: "Cat 11",
          icon: "images/mascots/cat_11.png",
          svg: "Cat_11.svg",
        },
        {
          name: "Cat 12",
          icon: "images/mascots/cat_12.png",
          svg: "Cat_12.svg",
        },
        {
          name: "Cat 13",
          icon: "images/mascots/cat_13.png",
          svg: "Cat_13.svg",
        },
        {
          name: "Cat 14",
          icon: "images/mascots/cat_14.png",
          svg: "Cat_14.svg",
        },
        {
          name: "Cat 15",
          icon: "images/mascots/cat_15.png",
          svg: "Cat_15.svg",
        },
        {
          name: "Cat 16",
          icon: "images/mascots/cat_16.png",
          svg: "Cat_16.svg",
        },
        {
          name: "Cat 17",
          icon: "images/mascots/cat_17.png",
          svg: "Cat_17.svg",
        },
        {
          name: "Cat 18",
          icon: "images/mascots/cat_18.png",
          svg: "Cat_18.svg",
        },
        {
          name: "Cat 19",
          icon: "images/mascots/cat_19.png",
          svg: "Cat_19.svg",
        },
        // {
        // name:'Cat 20',
        // icon:'images/mascots/cat_20.png',
        // svg:'Cat_20.svg'
        // },
        {
          name: "Cat 21",
          icon: "images/mascots/cat_21.png",
          svg: "Cat_21.svg",
        },
        {
          name: "Cat 22",
          icon: "images/mascots/cat_22.png",
          svg: "Cat_22.svg",
        },
        {
          name: "Cat 23",
          icon: "images/mascots/cat_23.png",
          svg: "Cat_23.svg",
        },
        {
          name: "Cat 24",
          icon: "images/mascots/cat_24.png",
          svg: "Cat_24.svg",
        },
        {
          name: "Cat 25",
          icon: "images/mascots/cat_25.png",
          svg: "Cat_25.svg",
        },
        {
          name: "Cat 26",
          icon: "images/mascots/cat_26.png",
          svg: "Cat_26.svg",
        },
        {
          name: "Cat 27",
          icon: "images/mascots/cat_27.png",
          svg: "Cat_27.svg",
        },
        {
          name: "Cat 29",
          icon: "images/mascots/cat_29.png",
          svg: "Cat_29.svg",
        },
        {
          name: "Cat 30",
          icon: "images/mascots/cat_30.png",
          svg: "Cat_30.svg",
        },
        {
          name: "Cat 31",
          icon: "images/mascots/cat_31.png",
          svg: "Cat_31.svg",
        },
        {
          name: "Cat 32",
          icon: "images/mascots/cat_32.png",
          svg: "Cat_32.svg",
        },
        {
          name: "Cat 33",
          icon: "images/mascots/cat_33.png",
          svg: "Cat_33.svg",
        },
        {
          name: "Cat 35",
          icon: "images/mascots/cat_35.png",
          svg: "Cat_35.svg",
        },
        {
          name: "Cat 36",
          icon: "images/mascots/cat_36.png",
          svg: "Cat_36.svg",
        },
        {
          name: "Cat 37",
          icon: "images/mascots/cat_37.png",
          svg: "Cat_37.svg",
        },
        {
          name: "Cat 38",
          icon: "images/mascots/cat_38.png",
          svg: "Cat_38.svg",
        },
        {
          name: "Cat 39",
          icon: "images/mascots/cat_39.png",
          svg: "Cat_39.svg",
        },
        {
          name: "Cat 40",
          icon: "images/mascots/cat_40.png",
          svg: "Cat_40.svg",
        },
      ],
    },
    {
      id: "cowboy",
      name: "Cowboy",
      mascots: [
        {
          name: "Cowboy 1",
          icon: "images/mascots/cowboy_1.png",
          svg: "Cowboy_1.svg",
        },
        {
          name: "Cowboy 2",
          icon: "images/mascots/cowboy_2.png",
          svg: "Cowboy_2.svg",
        },
      ],
    },
    {
      id: "cyclone",
      name: "Cyclone",
      mascots: [
        {
          name: "Cyclone 1",
          icon: "images/mascots/cyclone_1.png",
          svg: "Cyclone_1.svg",
        },
        // {
        // 	name:'Cyclone 2',
        // 	icon:'images/mascots/cyclone_2.png',
        // 	svg:'Cyclone_2.svg'
        // },
        {
          name: "Cyclone 3",
          icon: "images/mascots/cyclone_3.png",
          svg: "Cyclone_3.svg",
        },
        {
          name: "Cyclone 4",
          icon: "images/mascots/cyclone_4.png",
          svg: "Cyclone_4.svg",
        },
        {
          name: "Cyclone 5",
          icon: "images/mascots/cyclone_5.png",
          svg: "Cyclone_5.svg",
        },
      ],
    },
    {
      id: "deer",
      name: "Deer",
      mascots: [
        {
          name: "Deer 1",
          icon: "images/mascots/deer_1.png",
          svg: "Deer_1.svg",
        },
        {
          name: "Deer 2",
          icon: "images/mascots/deer_2.png",
          svg: "Deer_2.svg",
        },
        {
          name: "Deer 3",
          icon: "images/mascots/deer_3.png",
          svg: "Deer_3.svg",
        },
        {
          name: "Deer 4",
          icon: "images/mascots/deer_4.png",
          svg: "Deer_4.svg",
        },
        {
          name: "Deer 5",
          icon: "images/mascots/deer_5.png",
          svg: "Deer_5.svg",
        },
        {
          name: "Deer 6",
          icon: "images/mascots/deer_6.png",
          svg: "Deer_6.svg",
        },
        {
          name: "Deer 7",
          icon: "images/mascots/deer_7.png",
          svg: "Deer_7.svg",
        },
        {
          name: "Deer 8",
          icon: "images/mascots/deer_8.png",
          svg: "Deer_8.svg",
        },
        {
          name: "Deer 9",
          icon: "images/mascots/deer_9.png",
          svg: "Deer_9.svg",
        },
        {
          name: "Deer 10",
          icon: "images/mascots/deer_10.png",
          svg: "Deer_10.svg",
        },
      ],
    },
    {
      id: "devil",
      name: "Devils",
      mascots: [
        {
          name: "Devil 1",
          icon: "images/mascots/devil_1.png",
          svg: "Devil_1.svg",
        },
        {
          name: "Devil 2",
          icon: "images/mascots/devil_2.png",
          svg: "Devil_2.svg",
        },
        {
          name: "Devil 3",
          icon: "images/mascots/devil_3.png",
          svg: "Devil_3.svg",
        },
        {
          name: "Devil 4",
          icon: "images/mascots/devil_4.png",
          svg: "Devil_4.svg",
        },
        {
          name: "Devil 5",
          icon: "images/mascots/devil_5.png",
          svg: "Devil_5.svg",
        },
        {
          name: "Devil 6",
          icon: "images/mascots/devil_6.png",
          svg: "Devil_6.svg",
        },
      ],
    },
    {
      id: "gator",
      name: "Gators",
      mascots: [
        {
          name: "Gator 1",
          icon: "images/mascots/gator_1.png",
          svg: "Gator_1.svg",
        },
        {
          name: "Gator 2",
          icon: "images/mascots/gator_2.png",
          svg: "Gator_2.svg",
        },
        {
          name: "Gator 3",
          icon: "images/mascots/gator_3.png",
          svg: "Gator_3.svg",
        },
        {
          name: "Gator 5",
          icon: "images/mascots/gator_5.png",
          svg: "Gator_5.svg",
        },
        {
          name: "Gator 6",
          icon: "images/mascots/gator_6.png",
          svg: "Gator_6.svg",
        },
      ],
    },
    {
      id: "hornet",
      name: "Hornets",
      mascots: [
        {
          name: "Hornet 1",
          icon: "images/mascots/hornet_1.png",
          svg: "Hornet_1.svg",
        },
        {
          name: "Hornet 2",
          icon: "images/mascots/hornet_2.png",
          svg: "Hornet_2.svg",
        },
        {
          name: "Hornet 3",
          icon: "images/mascots/hornet_3.png",
          svg: "Hornet_3.svg",
        },
        {
          name: "Hornet 4",
          icon: "images/mascots/hornet_4.png",
          svg: "Hornet_4.svg",
        },
        {
          name: "Hornet 5",
          icon: "images/mascots/hornet_5.png",
          svg: "Hornet_5.svg",
        },
        {
          name: "Hornet 6",
          icon: "images/mascots/hornet_6.png",
          svg: "Hornet_6.svg",
        },
        {
          name: "Hornet 7",
          icon: "images/mascots/hornet_7.png",
          svg: "Hornet_7.svg",
        },
        {
          name: "Hornet 8",
          icon: "images/mascots/hornet_8.png",
          svg: "Hornet_8.svg",
        },
        {
          name: "Hornet 9",
          icon: "images/mascots/hornet_9.png",
          svg: "Hornet_9.svg",
        },
      ],
    },
    {
      id: "horse",
      name: "Horses",
      mascots: [
        {
          name: "Horse 1",
          icon: "images/mascots/horse_1.png",
          svg: "Horse_1.svg",
        },
        {
          name: "Horse 2",
          icon: "images/mascots/horse_2.png",
          svg: "Horse_2.svg",
        },
        {
          name: "Horse 3",
          icon: "images/mascots/horse_3.png",
          svg: "Horse_3.svg",
        },
        {
          name: "Horse 4",
          icon: "images/mascots/horse_4.png",
          svg: "Horse_4.svg",
        },
        {
          name: "Horse 5",
          icon: "images/mascots/horse_5.png",
          svg: "Horse_5.svg",
        },
        {
          name: "Horse 6",
          icon: "images/mascots/horse_6.png",
          svg: "Horse_6.svg",
        },
        {
          name: "Horse 7",
          icon: "images/mascots/horse_7.png",
          svg: "Horse_7.svg",
        },
        {
          name: "Horse 8",
          icon: "images/mascots/horse_8.png",
          svg: "Horse_8.svg",
        },
        {
          name: "Horse 9",
          icon: "images/mascots/horse_9.png",
          svg: "Horse_9.svg",
        },
        {
          name: "Horse 10",
          icon: "images/mascots/horse_10.png",
          svg: "Horse_10.svg",
        },
        // {
        // name:'Horse 11',
        // icon:'images/mascots/horse_11.png',
        // svg:'Horse_11.svg'
        // }
        {
          name: "Horse 12",
          icon: "images/mascots/horse_12.png",
          svg: "Horse_12.svg",
        },
        {
          name: "Horse 14",
          icon: "images/mascots/horse_14.png",
          svg: "Horse_14.svg",
        },
      ],
    },
    {
      id: "husky",
      name: "Huskies",
      mascots: [
        {
          name: "Husky 1",
          icon: "images/mascots/husky_1.png",
          svg: "Husky_1.svg",
        },
        {
          name: "Husky 2",
          icon: "images/mascots/husky_2.png",
          svg: "Husky_2.svg",
        },
        {
          name: "Husky 3",
          icon: "images/mascots/husky_3.png",
          svg: "Husky_3.svg",
        },
        {
          name: "Husky 4",
          icon: "images/mascots/husky_4.png",
          svg: "Husky_4.svg",
        },
        {
          name: "Husky 5",
          icon: "images/mascots/husky_5.png",
          svg: "Husky_5.svg",
        },
        {
          name: "Husky 6",
          icon: "images/mascots/husky_6.png",
          svg: "Husky_6.svg",
        },
        {
          name: "Husky 7",
          icon: "images/mascots/husky_7.png",
          svg: "Husky_7.svg",
        },
        {
          name: "Husky 8",
          icon: "images/mascots/husky_8.png",
          svg: "Husky_8.svg",
        },
        {
          name: "Husky 9",
          icon: "images/mascots/husky_9.png",
          svg: "Husky_9.svg",
        },
        {
          name: "Husky 10",
          icon: "images/mascots/husky_10.png",
          svg: "Husky_10.svg",
        },
        {
          name: "Husky 11",
          icon: "images/mascots/husky_11.png",
          svg: "Husky_11.svg",
        },
      ],
    },
    {
      id: "knight",
      name: "Knights",
      mascots: [
        {
          name: "Knight 1",
          icon: "images/mascots/knight_1.png",
          svg: "Knight_1.svg",
        },
        {
          name: "Knight 2",
          icon: "images/mascots/knight_2.png",
          svg: "Knight_2.svg",
        },
        {
          name: "Knight 3",
          icon: "images/mascots/knight_3.png",
          svg: "Knight_3.svg",
        },
        {
          name: "Knight 4",
          icon: "images/mascots/knight_4.png",
          svg: "Knight_4.svg",
        },
        {
          name: "Knight 5",
          icon: "images/mascots/knight_5.png",
          svg: "Knight_5.svg",
        },
        {
          name: "Knight 6",
          icon: "images/mascots/knight_6.png",
          svg: "Knight_6.svg",
        },
        {
          name: "Knight 7",
          icon: "images/mascots/knight_7.png",
          svg: "Knight_7.svg",
        },
        {
          name: "Knight 8",
          icon: "images/mascots/knight_8.png",
          svg: "Knight_8.svg",
        },
        {
          name: "Knight 9",
          icon: "images/mascots/knight_9.png",
          svg: "Knight_9.svg",
        },
        {
          name: "Knight 10",
          icon: "images/mascots/knight_10.png",
          svg: "Knight_10.svg",
        },
        {
          name: "Knight 11",
          icon: "images/mascots/knight_11.png",
          svg: "Knight_11.svg",
        },
        {
          name: "Knight 12",
          icon: "images/mascots/knight_12.png",
          svg: "Knight_12.svg",
        },
        {
          name: "Knight 13",
          icon: "images/mascots/knight_13.png",
          svg: "Knight_13.svg",
        },
        {
          name: "Knight 14",
          icon: "images/mascots/knight_14.png",
          svg: "Knight_14.svg",
        },
        {
          name: "Knight 15",
          icon: "images/mascots/knight_15.png",
          svg: "Knight_15.svg",
        },
        {
          name: "Knight 18",
          icon: "images/mascots/knight_18.png",
          svg: "Knight_18.svg",
        },
        {
          name: "Knight 20",
          icon: "images/mascots/knight_20.png",
          svg: "Knight_20.svg",
        },
        {
          name: "Knight 22",
          icon: "images/mascots/knight_22.png",
          svg: "Knight_22.svg",
        },
        {
          name: "Knight 23",
          icon: "images/mascots/knight_23.png",
          svg: "Knight_23.svg",
        },
        {
          name: "Knight 24",
          icon: "images/mascots/knight_24.png",
          svg: "Knight_24.svg",
        },
        {
          name: "Knight 25",
          icon: "images/mascots/knight_25.png",
          svg: "Knight_25.svg",
        },
        {
          name: "Knight 26",
          icon: "images/mascots/knight_26.png",
          svg: "Knight_26.svg",
        },
      ],
    },
    {
      id: "misc",
      name: "Miscellaneous",
      mascots: [
        {
          name: "Misc 1",
          icon: "images/mascots/misc_1.png",
          svg: "Misc_1.svg",
        },
        {
          name: "Misc 2",
          icon: "images/mascots/misc_2.png",
          svg: "Misc_2.svg",
        },
        {
          name: "Misc 3",
          icon: "images/mascots/misc_3.png",
          svg: "Misc_3.svg",
        },
        {
          name: "Misc 4",
          icon: "images/mascots/misc_4.png",
          svg: "Misc_4.svg",
        },
        {
          name: "Misc 5",
          icon: "images/mascots/misc_5.png",
          svg: "Misc_5.svg",
        },
      ],
    },
    {
      id: "native",
      name: "Natives",
      mascots: [
        {
          name: "Native 1",
          icon: "images/mascots/native_1.png",
          svg: "Native_1.svg",
        },
        {
          name: "Native 2",
          icon: "images/mascots/native_2.png",
          svg: "Native_2.svg",
        },
        {
          name: "Native 3",
          icon: "images/mascots/native_3.png",
          svg: "Native_3.svg",
        },
        {
          name: "Native 4",
          icon: "images/mascots/native_4.png",
          svg: "Native_4.svg",
        },
        {
          name: "Native 5",
          icon: "images/mascots/native_5.png",
          svg: "Native_5.svg",
        },
        {
          name: "Native 6",
          icon: "images/mascots/native_6.png",
          svg: "Native_6.svg",
        },
        {
          name: "Native 7",
          icon: "images/mascots/native_7.png",
          svg: "Native_7.svg",
        },
        {
          name: "Native 8",
          icon: "images/mascots/native_8.png",
          svg: "Native_8.svg",
        },
        {
          name: "Native 9",
          icon: "images/mascots/native_9.png",
          svg: "Native_9.svg",
        },
        {
          name: "Native 10",
          icon: "images/mascots/native_10.png",
          svg: "Native_10.svg",
        },
        {
          name: "Native 11",
          icon: "images/mascots/native_11.png",
          svg: "Native_11.svg",
        },
        {
          name: "Native 12",
          icon: "images/mascots/native_12.png",
          svg: "Native_12.svg",
        },
        {
          name: "Native 13",
          icon: "images/mascots/native_13.png",
          svg: "Native_13.svg",
        },
        {
          name: "Native 14",
          icon: "images/mascots/native_14.png",
          svg: "Native_14.svg",
        },
        {
          name: "Native 15",
          icon: "images/mascots/native_15.png",
          svg: "Native_15.svg",
        },
        {
          name: "Native 16",
          icon: "images/mascots/native_16.png",
          svg: "Native_16.svg",
        },
        {
          name: "Native 17",
          icon: "images/mascots/native_17.png",
          svg: "Native_17.svg",
        },
        {
          name: "Native 18",
          icon: "images/mascots/native_18.png",
          svg: "Native_18.svg",
        },
        {
          name: "Native 19",
          icon: "images/mascots/native_19.png",
          svg: "Native_19.svg",
        },
        {
          name: "Native 20",
          icon: "images/mascots/native_20.png",
          svg: "Native_20.svg",
        },
        {
          name: "Native 21",
          icon: "images/mascots/native_21.png",
          svg: "Native_21.svg",
        },
        {
          name: "Native 23",
          icon: "images/mascots/native_23.png",
          svg: "Native_23.svg",
        },
      ],
    },
    {
      id: "patriot",
      name: "Patriots",
      mascots: [
        {
          name: "Patriot 1",
          icon: "images/mascots/patriot_1.png",
          svg: "Patriot_1.svg",
        },
        {
          name: "Patriot 2",
          icon: "images/mascots/patriot_2.png",
          svg: "Patriot_2.svg",
        },
        {
          name: "Patriot 3",
          icon: "images/mascots/patriot_3.png",
          svg: "Patriot_3.svg",
        },
      ],
    },
    {
      id: "paw",
      name: "Paws",
      mascots: [
        {
          name: "Paw 1",
          icon: "images/mascots/paw_1.png",
          svg: "Paw_1.svg",
        },
        {
          name: "Paw 2",
          icon: "images/mascots/paw_2.png",
          svg: "Paw_2.svg",
        },
        {
          name: "Paw 3",
          icon: "images/mascots/paw_3.png",
          svg: "Paw_3.svg",
        },
        {
          name: "Paw 4",
          icon: "images/mascots/paw_4.png",
          svg: "Paw_4.svg",
        },
        {
          name: "Paw 5",
          icon: "images/mascots/paw_5.png",
          svg: "Paw_5.svg",
        },
        {
          name: "Paw 6",
          icon: "images/mascots/paw_6.png",
          svg: "Paw_6.svg",
        },
        {
          name: "Paw 7",
          icon: "images/mascots/paw_7.png",
          svg: "Paw_7.svg",
        },
        {
          name: "Paw 8",
          icon: "images/mascots/paw_8.png",
          svg: "Paw_8.svg",
        },
        {
          name: "Paw 9",
          icon: "images/mascots/paw_9.png",
          svg: "Paw_9.svg",
        },
        {
          name: "Paw 11",
          icon: "images/mascots/paw_11.png",
          svg: "Paw_11.svg",
        },
        {
          name: "Paw 12",
          icon: "images/mascots/paw_12.png",
          svg: "Paw_12.svg",
        },
        {
          name: "Paw 13",
          icon: "images/mascots/paw_13.png",
          svg: "Paw_13.svg",
        },
        {
          name: "Paw 14",
          icon: "images/mascots/paw_14.png",
          svg: "Paw_14.svg",
        },
        {
          name: "Paw 15",
          icon: "images/mascots/paw_15.png",
          svg: "Paw_15.svg",
        },
        {
          name: "Paw 16",
          icon: "images/mascots/paw_16.png",
          svg: "Paw_16.svg",
        },
        {
          name: "Paw 17",
          icon: "images/mascots/paw_17.png",
          svg: "Paw_17.svg",
        },
        {
          name: "Paw 18",
          icon: "images/mascots/paw_18.png",
          svg: "Paw_18.svg",
        },
        {
          name: "Paw 19",
          icon: "images/mascots/paw_19.png",
          svg: "Paw_19.svg",
        },
      ],
    },
    {
      id: "pig",
      name: "Pigs",
      mascots: [
        {
          name: "Pig 1",
          icon: "images/mascots/pig_1.png",
          svg: "Pig_1.svg",
        },
        {
          name: "Pig 2",
          icon: "images/mascots/pig_2.png",
          svg: "Pig_2.svg",
        },
        {
          name: "Pig 3",
          icon: "images/mascots/pig_3.png",
          svg: "Pig_3.svg",
        },
        {
          name: "Pig 4",
          icon: "images/mascots/pig_4.png",
          svg: "Pig_4.svg",
        },
        {
          name: "Pig 5",
          icon: "images/mascots/pig_5.png",
          svg: "Pig_5.svg",
        },
        {
          name: "Pig 6",
          icon: "images/mascots/pig_6.png",
          svg: "Pig_6.svg",
        }, //,
        // {
        // name:'Pig 7',
        // icon:'images/mascots/pig_7.png',
        // svg:'Pig_7.svg'
        // }
      ],
    },
    {
      id: "pirate",
      name: "Pirates",
      mascots: [
        {
          name: "Pirate 1",
          icon: "images/mascots/pirate_1.png",
          svg: "Pirate_1.svg",
        },
        {
          name: "Pirate 2",
          icon: "images/mascots/pirate_2.png",
          svg: "Pirate_2.svg",
        },
        {
          name: "Pirate 3",
          icon: "images/mascots/pirate_3.png",
          svg: "Pirate_3.svg",
        },
        {
          name: "Pirate 4",
          icon: "images/mascots/pirate_4.png",
          svg: "Pirate_4.svg",
        },
        {
          name: "Pirate 5",
          icon: "images/mascots/pirate_5.png",
          svg: "Pirate_5.svg",
        },
        {
          name: "Pirate 6",
          icon: "images/mascots/pirate_6.png",
          svg: "Pirate_6.svg",
        },
        {
          name: "Pirate 7",
          icon: "images/mascots/pirate_7.png",
          svg: "Pirate_7.svg",
        },
        {
          name: "Pirate 8",
          icon: "images/mascots/pirate_8.png",
          svg: "Pirate_8.svg",
        },
        {
          name: "Pirate 9",
          icon: "images/mascots/pirate_9.png",
          svg: "Pirate_9.svg",
        },
        {
          name: "Pirate 10",
          icon: "images/mascots/pirate_10.png",
          svg: "Pirate_10.svg",
        },
        {
          name: "Pirate 11",
          icon: "images/mascots/pirate_11.png",
          svg: "Pirate_11.svg",
        },
        {
          name: "Pirate 12",
          icon: "images/mascots/pirate_12.png",
          svg: "Pirate_12.svg",
        },
        {
          name: "Pirate 13",
          icon: "images/mascots/pirate_13.png",
          svg: "Pirate_13.svg",
        },
      ],
    },
    {
      id: "ram",
      name: "Rams",
      mascots: [
        {
          name: "Ram 1",
          icon: "images/mascots/ram_1.png",
          svg: "Ram_1.svg",
        },
        {
          name: "Ram 2",
          icon: "images/mascots/ram_2.png",
          svg: "Ram_2.svg",
        },
        {
          name: "Ram 3",
          icon: "images/mascots/ram_3.png",
          svg: "Ram_3.svg",
        },
        {
          name: "Ram 4",
          icon: "images/mascots/ram_4.png",
          svg: "Ram_4.svg",
        },
        {
          name: "Ram 5",
          icon: "images/mascots/ram_5.png",
          svg: "Ram_5.svg",
        },
        {
          name: "Ram 6",
          icon: "images/mascots/ram_6.png",
          svg: "Ram_6.svg",
        },
        {
          name: "Ram 7",
          icon: "images/mascots/ram_7.png",
          svg: "Ram_7.svg",
        },
      ],
    },
    {
      id: "razorback",
      name: "Razorbacks",
      mascots: [
        {
          name: "Razorback 1",
          icon: "images/mascots/razorback_1.png",
          svg: "Razorback_1.svg",
        },
        {
          name: "Razorback 2",
          icon: "images/mascots/razorback_2.png",
          svg: "Razorback_2.svg",
        },
      ],
    },
    {
      id: "reptile",
      name: "Reptiles",
      mascots: [
        {
          name: "Reptile 1",
          icon: "images/mascots/reptile_1.png",
          svg: "Reptile_1.svg",
        },
        {
          name: "Reptile 2",
          icon: "images/mascots/reptile_2.png",
          svg: "Reptile_2.svg",
        },
        {
          name: "Reptile 3",
          icon: "images/mascots/reptile_3.png",
          svg: "Reptile_3.svg",
        },
        {
          name: "Reptile 4",
          icon: "images/mascots/reptile_4.png",
          svg: "Reptile_4.svg",
        },
        {
          name: "Reptile 5",
          icon: "images/mascots/reptile_5.png",
          svg: "Reptile_5.svg",
        },
        {
          name: "Reptile 6",
          icon: "images/mascots/reptile_6.png",
          svg: "Reptile_6.svg",
        },
      ],
    },
    {
      id: "rocket",
      name: "Rockets",
      mascots: [
        {
          name: "Rocket 1",
          icon: "images/mascots/rocket_1.png",
          svg: "Rocket_1.svg",
        },
        {
          name: "Rocket 2",
          icon: "images/mascots/rocket_2.png",
          svg: "Rocket_2.svg",
        },
      ],
    },
    {
      id: "shark",
      name: "Sharks",
      mascots: [
        {
          name: "Shark 1",
          icon: "images/mascots/shark_1.png",
          svg: "Shark_1.svg",
        },
        {
          name: "Shark 2",
          icon: "images/mascots/shark_2.png",
          svg: "Shark_2.svg",
        },
        {
          name: "Shark 3",
          icon: "images/mascots/shark_3.png",
          svg: "Shark_3.svg",
        },
        {
          name: "Shark 4",
          icon: "images/mascots/shark_4.png",
          svg: "Shark_4.svg",
        },
        {
          name: "Shark 5",
          icon: "images/mascots/shark_5.png",
          svg: "Shark_5.svg",
        },
      ],
    },
    {
      id: "viking",
      name: "Vikings",
      mascots: [
        {
          name: "Viking 1",
          icon: "images/mascots/viking_1.png",
          svg: "Viking_1.svg",
        },
        {
          name: "Viking 2",
          icon: "images/mascots/viking_2.png",
          svg: "Viking_2.svg",
        },
        {
          name: "Viking 3",
          icon: "images/mascots/viking_3.png",
          svg: "Viking_3.svg",
        },
        {
          name: "Viking 4",
          icon: "images/mascots/viking_4.png",
          svg: "Viking_4.svg",
        },
        {
          name: "Viking 5",
          icon: "images/mascots/viking_5.png",
          svg: "Viking_5.svg",
        },
        {
          name: "Viking 6",
          icon: "images/mascots/viking_6.png",
          svg: "Viking_6.svg",
        },
        {
          name: "Viking 7",
          icon: "images/mascots/viking_7.png",
          svg: "Viking_7.svg",
        },
        {
          name: "Viking 8",
          icon: "images/mascots/viking_8.png",
          svg: "Viking_8.svg",
        },
        {
          name: "Viking 9",
          icon: "images/mascots/viking_9.png",
          svg: "Viking_9.svg",
        },
        {
          name: "Viking 10",
          icon: "images/mascots/viking_10.png",
          svg: "Viking_10.svg",
        },
        {
          name: "Viking 11",
          icon: "images/mascots/viking_11.png",
          svg: "Viking_11.svg",
        },
        {
          name: "Viking 12",
          icon: "images/mascots/viking_12.png",
          svg: "Viking_12.svg",
        },
        {
          name: "Viking 13",
          icon: "images/mascots/viking_13.png",
          svg: "Viking_13.svg",
        },
        {
          name: "Viking 14",
          icon: "images/mascots/viking_14.png",
          svg: "Viking_14.svg",
        },
      ],
    },
    {
      id: "wolverine",
      name: "Wolverines",
      mascots: [
        {
          name: "Wolverine 1",
          icon: "images/mascots/wolverine_1.png",
          svg: "Wolverine_1.svg",
        },
        {
          name: "Wolverine 2",
          icon: "images/mascots/wolverine_2.png",
          svg: "Wolverine_2.svg",
        },
      ],
    },
  ];

  var sponsors = [
    {
      id: "All",
      name: "All",
      mascots: [
        {
          name: "ACADEMY SPORTS  OUTDOORS",
          icon: "images/sponsors/all/academysportsoutdoors.png",
          svg: "academysportsoutdoors.svg",
        },
        {
          name: "ACE HARDWARE",
          icon: "images/sponsors/all/acehardware.png",
          svg: "acehardware.svg",
        },
        {
          name: "ACE HARDWARE #2",
          icon: "images/sponsors/all/acehardware-2.png",
          svg: "acehardware-2.svg",
        },
        {
          name: "ADVANCE AUTO PARTS",
          icon: "images/sponsors/all/advanceautoparts.png",
          svg: "advanceautoparts.svg",
        },
        {
          name: "AFTCO",
          icon: "images/sponsors/all/aftco.png",
          svg: "aftco.svg",
        },
        {
          name: "AFTCO #2",
          icon: "images/sponsors/all/aftco-2.png",
          svg: "aftco-2.svg",
        },
        {
          name: "ALASKAN BREWING COMPANY",
          icon: "images/sponsors/all/alaskanbrewingcompany.png",
          svg: "alaskanbrewingcompany.svg",
        },
        {
          name: "ALASKAN BREWING COMPANY #2",
          icon: "images/sponsors/all/alaskanbrewingcompany-2.png",
          svg: "alaskanbrewingcompany-2.svg",
        },
        {
          name: "ALLEN",
          icon: "images/sponsors/all/allen.png",
          svg: "allen.svg",
        },
        {
          name: "AMEND 2",
          icon: "images/sponsors/all/amend2.png",
          svg: "amend2.svg",
        },
        {
          name: "AMEND 2 #2",
          icon: "images/sponsors/all/amend2-2.png",
          svg: "amend2-2.svg",
        },
        {
          name: "ANHEUSER-BUSCH",
          icon: "images/sponsors/all/anheuser-busch.png",
          svg: "anheuser-busch.svg",
        },
        {
          name: "ANHEUSER-BUSCH #2",
          icon: "images/sponsors/all/anheuser-busch-2.png",
          svg: "anheuser-busch-2.svg",
        },
        {
          name: "ARE",
          icon: "images/sponsors/all/are.png",
          svg: "are.svg",
        },
        {
          name: "ARE #2",
          icon: "images/sponsors/all/are-2.png",
          svg: "are-2.svg",
        },
        {
          name: "B&M",
          icon: "images/sponsors/all/b&m.png",
          svg: "b&m.svg",
        },
        {
          name: "B&M #2",
          icon: "images/sponsors/all/b&m-2.png",
          svg: "b&m-2.svg",
        },
        {
          name: "BLUE BUFFALO",
          icon: "images/sponsors/all/bluebuffalo.png",
          svg: "bluebuffalo.svg",
        },
        {
          name: "BLUE MOON",
          icon: "images/sponsors/all/bluemoon.png",
          svg: "bluemoon.svg",
        },
        {
          name: "BLUE WILDERNESS",
          icon: "images/sponsors/all/bluewilderness.png",
          svg: "bluewilderness.svg",
        },
        {
          name: "BUD LIGHT",
          icon: "images/sponsors/all/budlight.png",
          svg: "budlight.svg",
        },
        {
          name: "BUD LIGHT #2",
          icon: "images/sponsors/all/budlight-2.png",
          svg: "budlight-2.svg",
        },
        {
          name: "BUD LIGHT #3",
          icon: "images/sponsors/all/budlight-3.png",
          svg: "budlight-3.svg",
        },
        {
          name: "BUDWEISER",
          icon: "images/sponsors/all/budweiser.png",
          svg: "budweiser.svg",
        },
        {
          name: "BUDWEISER #2",
          icon: "images/sponsors/all/budweiser-2.png",
          svg: "budweiser-2.svg",
        },
        {
          name: "BURGER KING",
          icon: "images/sponsors/all/burgerking.png",
          svg: "burgerking.svg",
        },
        {
          name: "BURGER KING #2",
          icon: "images/sponsors/all/burgerking-2.png",
          svg: "burgerking-2.svg",
        },
        {
          name: "BUSCH",
          icon: "images/sponsors/all/busch.png",
          svg: "busch.svg",
        },
        {
          name: "BUSCH LIGHT",
          icon: "images/sponsors/all/buschlight.png",
          svg: "buschlight.svg",
        },
        {
          name: "CAN-AM",
          icon: "images/sponsors/all/can-am.png",
          svg: "can-am.svg",
        },
        {
          name: "CAN-AM #2",
          icon: "images/sponsors/all/can-am-2.png",
          svg: "can-am-2.svg",
        },
        {
          name: "CAPTAIN MORGAN",
          icon: "images/sponsors/all/captainmorgan.png",
          svg: "captainmorgan.svg",
        },
        {
          name: "CARHARTT",
          icon: "images/sponsors/all/carhartt.png",
          svg: "carhartt.svg",
        },
        {
          name: "CASTROL",
          icon: "images/sponsors/all/castrol.png",
          svg: "castrol.svg",
        },
        {
          name: "CHAMPION POWER EQUIP.",
          icon: "images/sponsors/all/championpowerequip..png",
          svg: "championpowerequip..svg",
        },
        {
          name: "CHAMPION POWER EQUIP. #2",
          icon: "images/sponsors/all/championpowerequip.-2.png",
          svg: "championpowerequip.-2.svg",
        },
        {
          name: "CHEVROLET",
          icon: "images/sponsors/all/chevrolet.png",
          svg: "chevrolet.svg",
        },
        {
          name: "CHEVROLET #2",
          icon: "images/sponsors/all/chevrolet-2.png",
          svg: "chevrolet-2.svg",
        },
        {
          name: "COCA-COLA",
          icon: "images/sponsors/all/coca-cola.png",
          svg: "coca-cola.svg",
        },
        {
          name: "COLUMBIA",
          icon: "images/sponsors/all/columbia.png",
          svg: "columbia.svg",
        },
        {
          name: "COLUMBIA #2",
          icon: "images/sponsors/all/columbia-2.png",
          svg: "columbia-2.svg",
        },
        {
          name: "COORS",
          icon: "images/sponsors/all/coors.png",
          svg: "coors.svg",
        },
        {
          name: "COORS LIGHT",
          icon: "images/sponsors/all/coorslight.png",
          svg: "coorslight.svg",
        },
        {
          name: "CORDOVA",
          icon: "images/sponsors/all/cordova.png",
          svg: "cordova.svg",
        },
        {
          name: "CORDOVA #2",
          icon: "images/sponsors/all/cordova-2.png",
          svg: "cordova-2.svg",
        },
        {
          name: "CORDOVA #3",
          icon: "images/sponsors/all/cordova-3.png",
          svg: "cordova-3.svg",
        },
        {
          name: "CORONA",
          icon: "images/sponsors/all/corona.png",
          svg: "corona.svg",
        },
        {
          name: "CORONA #2",
          icon: "images/sponsors/all/corona-2.png",
          svg: "corona-2.svg",
        },
        {
          name: "CORONA #3",
          icon: "images/sponsors/all/corona-3.png",
          svg: "corona-3.svg",
        },
        {
          name: "CORONA #4",
          icon: "images/sponsors/all/corona-4.png",
          svg: "corona-4.svg",
        },
        {
          name: "CROWN ROYAL",
          icon: "images/sponsors/all/crownroyal.png",
          svg: "crownroyal.svg",
        },
        {
          name: "CROWN ROYAL #2",
          icon: "images/sponsors/all/crownroyal-2.png",
          svg: "crownroyal-2.svg",
        },
        {
          name: "CULVER'S",
          icon: "images/sponsors/all/culver's.png",
          svg: "culver's.svg",
        },
        {
          name: "DAIRY QUEEN",
          icon: "images/sponsors/all/dairyqueen.png",
          svg: "dairyqueen.svg",
        },
        {
          name: "DAIRY QUEEN #2",
          icon: "images/sponsors/all/dairyqueen-2.png",
          svg: "dairyqueen-2.svg",
        },
        {
          name: "DANNER",
          icon: "images/sponsors/all/danner.png",
          svg: "danner.svg",
        },
        {
          name: "DANNER #2",
          icon: "images/sponsors/all/danner-2.png",
          svg: "danner-2.svg",
        },
        {
          name: "DIAMOND DOG FOOD",
          icon: "images/sponsors/all/diamonddogfood.png",
          svg: "diamonddogfood.svg",
        },
        {
          name: "DIAMOND DOG FOOD #2",
          icon: "images/sponsors/all/diamonddogfood-2.png",
          svg: "diamonddogfood-2.svg",
        },
        {
          name: "DICK CEPEK",
          icon: "images/sponsors/all/dickcepek.png",
          svg: "dickcepek.svg",
        },
        {
          name: "DICK CEPEK #2",
          icon: "images/sponsors/all/dickcepek-2.png",
          svg: "dickcepek-2.svg",
        },
        {
          name: "DISCOVERY CHANNEL",
          icon: "images/sponsors/all/discoverychannel.png",
          svg: "discoverychannel.svg",
        },
        {
          name: "DISCOVERY CHANNEL #2",
          icon: "images/sponsors/all/discoverychannel-2.png",
          svg: "discoverychannel-2.svg",
        },
        {
          name: "DODGE RAM",
          icon: "images/sponsors/all/dodgeram.png",
          svg: "dodgeram.svg",
        },
        {
          name: "DODGE RAM #2",
          icon: "images/sponsors/all/dodgeram-2.png",
          svg: "dodgeram-2.svg",
        },
        {
          name: "DOMINO'S",
          icon: "images/sponsors/all/domino's.png",
          svg: "domino's.svg",
        },
        {
          name: "DOS EQUIS",
          icon: "images/sponsors/all/dosequis.png",
          svg: "dosequis.svg",
        },
        {
          name: "DOS EQUIS #2",
          icon: "images/sponsors/all/dosequis-2.png",
          svg: "dosequis-2.svg",
        },
        {
          name: "DOS EQUIS #3",
          icon: "images/sponsors/all/dosequis-3.png",
          svg: "dosequis-3.svg",
        },
        {
          name: "DOS EQUIS #4",
          icon: "images/sponsors/all/dosequis-4.png",
          svg: "dosequis-4.svg",
        },
        {
          name: "EGO",
          icon: "images/sponsors/all/ego.png",
          svg: "ego.svg",
        },
        {
          name: "EGO S2 SLIDER",
          icon: "images/sponsors/all/egos2slider.png",
          svg: "egos2slider.svg",
        },
        {
          name: "ENGEL COOLERS",
          icon: "images/sponsors/all/engelcoolers.png",
          svg: "engelcoolers.svg",
        },
        {
          name: "ENGEL COOLERS #2",
          icon: "images/sponsors/all/engelcoolers-2.png",
          svg: "engelcoolers-2.svg",
        },
        {
          name: "EUKANUBA",
          icon: "images/sponsors/all/eukanuba.png",
          svg: "eukanuba.svg",
        },
        {
          name: "EUKANUBA #2",
          icon: "images/sponsors/all/eukanuba-2.png",
          svg: "eukanuba-2.svg",
        },
        {
          name: "FAT TIRE",
          icon: "images/sponsors/all/fattire.png",
          svg: "fattire.svg",
        },
        {
          name: "FORD",
          icon: "images/sponsors/all/ford.png",
          svg: "ford.svg",
        },
        {
          name: "GEICO",
          icon: "images/sponsors/all/geico.png",
          svg: "geico.svg",
        },
        {
          name: "GEICO #2",
          icon: "images/sponsors/all/geico-2.png",
          svg: "geico-2.svg",
        },
        {
          name: "GEICO #3",
          icon: "images/sponsors/all/geico-3.png",
          svg: "geico-3.svg",
        },
        {
          name: "GEICO #4",
          icon: "images/sponsors/all/geico-4.png",
          svg: "geico-4.svg",
        },
        {
          name: "GENERAC",
          icon: "images/sponsors/all/generac.png",
          svg: "generac.svg",
        },
        {
          name: "GENERAC #2",
          icon: "images/sponsors/all/generac-2.png",
          svg: "generac-2.svg",
        },
        {
          name: "GENERAL TIRE",
          icon: "images/sponsors/all/generaltire.png",
          svg: "generaltire.svg",
        },
        {
          name: "GMC",
          icon: "images/sponsors/all/gmc.png",
          svg: "gmc.svg",
        },
        {
          name: "GOAL ZERO",
          icon: "images/sponsors/all/goalzero.png",
          svg: "goalzero.svg",
        },
        {
          name: "GOAL ZERO #2",
          icon: "images/sponsors/all/goalzero-2.png",
          svg: "goalzero-2.svg",
        },
        {
          name: "GOOSE ISLAND",
          icon: "images/sponsors/all/gooseisland.png",
          svg: "gooseisland.svg",
        },
        {
          name: "GP8",
          icon: "images/sponsors/all/gp8.png",
          svg: "gp8.svg",
        },
        {
          name: "GP8 #2",
          icon: "images/sponsors/all/gp8-2.png",
          svg: "gp8-2.svg",
        },
        {
          name: "GRIME BOSS",
          icon: "images/sponsors/all/grimeboss.png",
          svg: "grimeboss.svg",
        },
        {
          name: "GRIME BOSS #2",
          icon: "images/sponsors/all/grimeboss-2.png",
          svg: "grimeboss-2.svg",
        },
        {
          name: "GUINNESS",
          icon: "images/sponsors/all/guinness.png",
          svg: "guinness.svg",
        },
        {
          name: "GUINNESS #2",
          icon: "images/sponsors/all/guinness-2.png",
          svg: "guinness-2.svg",
        },
        {
          name: "HAMM'S",
          icon: "images/sponsors/all/hamm's.png",
          svg: "hamm's.svg",
        },
        {
          name: "HEINEKEN",
          icon: "images/sponsors/all/heineken.png",
          svg: "heineken.svg",
        },
        {
          name: "HEINEKEN #2",
          icon: "images/sponsors/all/heineken-2.png",
          svg: "heineken-2.svg",
        },
        {
          name: "HILL'S SCIENCE DIET",
          icon: "images/sponsors/all/hill'ssciencediet.png",
          svg: "hill'ssciencediet.svg",
        },
        {
          name: "HONDA",
          icon: "images/sponsors/all/honda.png",
          svg: "honda.svg",
        },
        {
          name: "HOPPE'S",
          icon: "images/sponsors/all/hoppe's.png",
          svg: "hoppe's.svg",
        },
        {
          name: "HOPPE'S 2",
          icon: "images/sponsors/all/hoppe's2.png",
          svg: "hoppe's2.svg",
        },
        {
          name: "HUNTWORTH 2",
          icon: "images/sponsors/all/huntworth2.png",
          svg: "huntworth2.svg",
        },
        {
          name: "IAMS",
          icon: "images/sponsors/all/iams.png",
          svg: "iams.svg",
        },
        {
          name: "IAMS #2",
          icon: "images/sponsors/all/iams-2.png",
          svg: "iams-2.svg",
        },
        {
          name: "IGLOO COOLERS",
          icon: "images/sponsors/all/igloocoolers.png",
          svg: "igloocoolers.svg",
        },
        {
          name: "IGLOO COOLERS #2",
          icon: "images/sponsors/all/igloocoolers-2.png",
          svg: "igloocoolers-2.svg",
        },
        {
          name: "JACK DANIEL'S",
          icon: "images/sponsors/all/jackdaniel's.png",
          svg: "jackdaniel's.svg",
        },
        {
          name: "JACK DANIEL'S #2",
          icon: "images/sponsors/all/jackdaniel's-2.png",
          svg: "jackdaniel's-2.svg",
        },
        {
          name: "JACK LINKS",
          icon: "images/sponsors/all/jacklinks.png",
          svg: "jacklinks.svg",
        },
        {
          name: "K2 COOLERS",
          icon: "images/sponsors/all/k2coolers.png",
          svg: "k2coolers.svg",
        },
        {
          name: "K2 COOLERS #2",
          icon: "images/sponsors/all/k2coolers-2.png",
          svg: "k2coolers-2.svg",
        },
        {
          name: "KELLOGG'S",
          icon: "images/sponsors/all/kellogg's.png",
          svg: "kellogg's.svg",
        },
        {
          name: "KFC",
          icon: "images/sponsors/all/kfc.png",
          svg: "kfc.svg",
        },
        {
          name: "KFC #2",
          icon: "images/sponsors/all/kfc-2.png",
          svg: "kfc-2.svg",
        },
        {
          name: "LACROSSE",
          icon: "images/sponsors/all/lacrosse.png",
          svg: "lacrosse.svg",
        },
        {
          name: "LANDSHARK",
          icon: "images/sponsors/all/landshark.png",
          svg: "landshark.svg",
        },
        {
          name: "LEINENKUGEL'S",
          icon: "images/sponsors/all/leinenkugel's.png",
          svg: "leinenkugel's.svg",
        },
        {
          name: "LEINENKUGEL'S #2",
          icon: "images/sponsors/all/leinenkugel's-2.png",
          svg: "leinenkugel's-2.svg",
        },
        {
          name: "LEINENKUGEL'S #3",
          icon: "images/sponsors/all/leinenkugel's-3.png",
          svg: "leinenkugel's-3.svg",
        },
        {
          name: "LEINENKUGEL'S #4",
          icon: "images/sponsors/all/leinenkugel's-4.png",
          svg: "leinenkugel's-4.svg",
        },
        {
          name: "LEINENKUGEL'S #5",
          icon: "images/sponsors/all/leinenkugel's-5.png",
          svg: "leinenkugel's-5.svg",
        },
        {
          name: "LEINENKUGEL'S #6",
          icon: "images/sponsors/all/leinenkugel's-6.png",
          svg: "leinenkugel's-6.svg",
        },
        {
          name: "LIT COOLERS",
          icon: "images/sponsors/all/litcoolers.png",
          svg: "litcoolers.svg",
        },
        {
          name: "LONE WOLF 2",
          icon: "images/sponsors/all/lonewolf2.png",
          svg: "lonewolf2.svg",
        },
        {
          name: "MAUI JIM",
          icon: "images/sponsors/all/mauijim.png",
          svg: "mauijim.svg",
        },
        {
          name: "MAUI JIM #2",
          icon: "images/sponsors/all/mauijim-2.png",
          svg: "mauijim-2.svg",
        },
        {
          name: "MCDONALD'S",
          icon: "images/sponsors/all/mcdonald's.png",
          svg: "mcdonald's.svg",
        },
        {
          name: "MICHELOB",
          icon: "images/sponsors/all/michelob.png",
          svg: "michelob.svg",
        },
        {
          name: "MICHELOB #2",
          icon: "images/sponsors/all/michelob-2.png",
          svg: "michelob-2.svg",
        },
        {
          name: "MICHELOB #3",
          icon: "images/sponsors/all/michelob-3.png",
          svg: "michelob-3.svg",
        },
        {
          name: "MILLER GENUINE DRAFT",
          icon: "images/sponsors/all/millergenuinedraft.png",
          svg: "millergenuinedraft.svg",
        },
        {
          name: "MILLER HIGH LIFE",
          icon: "images/sponsors/all/millerhighlife.png",
          svg: "millerhighlife.svg",
        },
        {
          name: "MILLER LITE",
          icon: "images/sponsors/all/millerlite.png",
          svg: "millerlite.svg",
        },
        {
          name: "MOBIL",
          icon: "images/sponsors/all/mobil.png",
          svg: "mobil.svg",
        },
        {
          name: "MR. HEATER",
          icon: "images/sponsors/all/mr.heater.png",
          svg: "mr.heater.svg",
        },
        {
          name: "MR. HEATER #2",
          icon: "images/sponsors/all/mr.heater-2.png",
          svg: "mr.heater-2.svg",
        },
        {
          name: "MTN OPS",
          icon: "images/sponsors/all/mtnops.png",
          svg: "mtnops.svg",
        },
        {
          name: "MTN OPS #2",
          icon: "images/sponsors/all/mtnops-2.png",
          svg: "mtnops-2.svg",
        },
        {
          name: "NATIONWIDE",
          icon: "images/sponsors/all/nationwide.png",
          svg: "nationwide.svg",
        },
        {
          name: "NATIONWIDE #2",
          icon: "images/sponsors/all/nationwide-2.png",
          svg: "nationwide-2.svg",
        },
        {
          name: "NEW BELGIUM",
          icon: "images/sponsors/all/newbelgium.png",
          svg: "newbelgium.svg",
        },
        {
          name: "NEW GLARUS",
          icon: "images/sponsors/all/newglarus.png",
          svg: "newglarus.svg",
        },
        {
          name: "NEW GLARUS #2",
          icon: "images/sponsors/all/newglarus-2.png",
          svg: "newglarus-2.svg",
        },
        {
          name: "NEW GLARUS SPOTTED COW",
          icon: "images/sponsors/all/newglarusspottedcow.png",
          svg: "newglarusspottedcow.svg",
        },
        {
          name: "NEW GLARUS SPOTTED COW #2",
          icon: "images/sponsors/all/newglarusspottedcow-2.png",
          svg: "newglarusspottedcow-2.svg",
        },
        {
          name: "NEWCASTLE",
          icon: "images/sponsors/all/newcastle.png",
          svg: "newcastle.svg",
        },
        {
          name: "NORTHERN BATTERY",
          icon: "images/sponsors/all/northernbattery.png",
          svg: "northernbattery.svg",
        },
        {
          name: "NUTRO",
          icon: "images/sponsors/all/nutro.png",
          svg: "nutro.svg",
        },
        {
          name: "O'REILLY",
          icon: "images/sponsors/all/o'reilly.png",
          svg: "o'reilly.svg",
        },
        {
          name: "O'REILLY #2",
          icon: "images/sponsors/all/o'reilly-2.png",
          svg: "o'reilly-2.svg",
        },
        {
          name: "OAKLEY",
          icon: "images/sponsors/all/oakley.png",
          svg: "oakley.svg",
        },
        {
          name: "OAKLEY #2",
          icon: "images/sponsors/all/oakley-2.png",
          svg: "oakley-2.svg",
        },
        {
          name: "OLD MILWAUKEE",
          icon: "images/sponsors/all/oldmilwaukee.png",
          svg: "oldmilwaukee.svg",
        },
        {
          name: "ORION COOLERS",
          icon: "images/sponsors/all/orioncoolers.png",
          svg: "orioncoolers.svg",
        },
        {
          name: "ORION COOLERS #2",
          icon: "images/sponsors/all/orioncoolers-2.png",
          svg: "orioncoolers-2.svg",
        },
        {
          name: "PABST",
          icon: "images/sponsors/all/pabst.png",
          svg: "pabst.svg",
        },
        {
          name: "PAPA JOHNS",
          icon: "images/sponsors/all/papajohns.png",
          svg: "papajohns.svg",
        },
        {
          name: "PAPA JOHNS #2",
          icon: "images/sponsors/all/papajohns-2.png",
          svg: "papajohns-2.svg",
        },
        {
          name: "PEDIGREE",
          icon: "images/sponsors/all/pedigree.png",
          svg: "pedigree.svg",
        },
        {
          name: "PELICAN COOLERS",
          icon: "images/sponsors/all/pelicancoolers.png",
          svg: "pelicancoolers.svg",
        },
        {
          name: "PELICAN COOLERS #2",
          icon: "images/sponsors/all/pelicancoolers-2.png",
          svg: "pelicancoolers-2.svg",
        },
        {
          name: "PEPSI",
          icon: "images/sponsors/all/pepsi.png",
          svg: "pepsi.svg",
        },
        {
          name: "PEPSI #2",
          icon: "images/sponsors/all/pepsi-2.png",
          svg: "pepsi-2.svg",
        },
        {
          name: "PETCO",
          icon: "images/sponsors/all/petco.png",
          svg: "petco.svg",
        },
        {
          name: "PETSMART",
          icon: "images/sponsors/all/petsmart.png",
          svg: "petsmart.svg",
        },
        {
          name: "PIZZA HUT",
          icon: "images/sponsors/all/pizzahut.png",
          svg: "pizzahut.svg",
        },
        {
          name: "PRINCETON TEC",
          icon: "images/sponsors/all/princetontec.png",
          svg: "princetontec.svg",
        },
        {
          name: "PRINCETON TEC #2",
          icon: "images/sponsors/all/princetontec-2.png",
          svg: "princetontec-2.svg",
        },
        {
          name: "PROGRESSIVE",
          icon: "images/sponsors/all/progressive.png",
          svg: "progressive.svg",
        },
        {
          name: "PROGRESSIVE #2",
          icon: "images/sponsors/all/progressive-2.png",
          svg: "progressive-2.svg",
        },
        {
          name: "PURINA",
          icon: "images/sponsors/all/purina.png",
          svg: "purina.svg",
        },
        {
          name: "PURINA #2",
          icon: "images/sponsors/all/purina-2.png",
          svg: "purina-2.svg",
        },
        {
          name: "RED WING SHOES",
          icon: "images/sponsors/all/redwingshoes.png",
          svg: "redwingshoes.svg",
        },
        {
          name: "RED WING SHOES #2",
          icon: "images/sponsors/all/redwingshoes-2.png",
          svg: "redwingshoes-2.svg",
        },
        {
          name: "RHINO-RACK",
          icon: "images/sponsors/all/rhino-rack.png",
          svg: "rhino-rack.svg",
        },
        {
          name: "RHINO-RACK #2",
          icon: "images/sponsors/all/rhino-rack-2.png",
          svg: "rhino-rack-2.svg",
        },
        {
          name: "ROCKY",
          icon: "images/sponsors/all/rocky.png",
          svg: "rocky.svg",
        },
        {
          name: "ROCKY #2",
          icon: "images/sponsors/all/rocky-2.png",
          svg: "rocky-2.svg",
        },
        {
          name: "ROCKY #3",
          icon: "images/sponsors/all/rocky-3.png",
          svg: "rocky-3.svg",
        },
        {
          name: "ROCKY #4",
          icon: "images/sponsors/all/rocky-4.png",
          svg: "rocky-4.svg",
        },
        {
          name: "ROYAL CANIN",
          icon: "images/sponsors/all/royalcanin.png",
          svg: "royalcanin.svg",
        },
        {
          name: "RTIC COOLERS",
          icon: "images/sponsors/all/rticcoolers.png",
          svg: "rticcoolers.svg",
        },
        {
          name: "RTIC COOLERS #2",
          icon: "images/sponsors/all/rticcoolers-2.png",
          svg: "rticcoolers-2.svg",
        },
        {
          name: "Rust-B-Gone",
          icon: "images/sponsors/all/rust-b-gone.png",
          svg: "rust-b-gone.svg",
        },
        {
          name: "SAMUEL ADAMS",
          icon: "images/sponsors/all/samueladams.png",
          svg: "samueladams.svg",
        },
        {
          name: "SCHEELS",
          icon: "images/sponsors/all/scheels.png",
          svg: "scheels.svg",
        },
        {
          name: "SCHEELS #2",
          icon: "images/sponsors/all/scheels-2.png",
          svg: "scheels-2.svg",
        },
        {
          name: "SCHRADE",
          icon: "images/sponsors/all/schrade.png",
          svg: "schrade.svg",
        },
        {
          name: "SCHRADE #2",
          icon: "images/sponsors/all/schrade-2.png",
          svg: "schrade-2.svg",
        },
        {
          name: "SEA-DOO",
          icon: "images/sponsors/all/sea-doo.png",
          svg: "sea-doo.svg",
        },
        {
          name: "SEA-DOO #2",
          icon: "images/sponsors/all/sea-doo-2.png",
          svg: "sea-doo-2.svg",
        },
        // {
        // name: "SHOCK TOP",
        // icon: "images/sponsors/all/shocktop.png",
        // svg: "shocktop.svg"
        // } ,
        {
          name: "SMIRNOFF",
          icon: "images/sponsors/all/smirnoff.png",
          svg: "smirnoff.svg",
        },
        {
          name: "SOFT SCIENCE FOOTWEAR",
          icon: "images/sponsors/all/softsciencefootwear.png",
          svg: "softsciencefootwear.svg",
        },
        {
          name: "SOG",
          icon: "images/sponsors/all/sog.png",
          svg: "sog.svg",
        },
        {
          name: "SOG #2",
          icon: "images/sponsors/all/sog-2.png",
          svg: "sog-2.svg",
        },
        {
          name: "SOLAR BAT SUNGLASSES",
          icon: "images/sponsors/all/solarbatsunglasses.png",
          svg: "solarbatsunglasses.svg",
        },
        {
          name: "SOLAR BAT SUNGLASSES #2",
          icon: "images/sponsors/all/solarbatsunglasses-2.png",
          svg: "solarbatsunglasses-2.svg",
        },
        {
          name: "SONIC",
          icon: "images/sponsors/all/sonic.png",
          svg: "sonic.svg",
        },
        {
          name: "SONIC #2",
          icon: "images/sponsors/all/sonic-2.png",
          svg: "sonic-2.svg",
        },
        {
          name: "SOUTHERN COMFORT",
          icon: "images/sponsors/all/southerncomfort.png",
          svg: "southerncomfort.svg",
        },
        {
          name: "SQWINCHER",
          icon: "images/sponsors/all/sqwincher.png",
          svg: "sqwincher.svg",
        },
        {
          name: "STAR BRITE",
          icon: "images/sponsors/all/starbrite.png",
          svg: "starbrite.svg",
        },
        {
          name: "STAR BRITE #2",
          icon: "images/sponsors/all/starbrite-2.png",
          svg: "starbrite-2.svg",
        },
        {
          name: "STAR BRITE STAR TRON",
          icon: "images/sponsors/all/starbritestartron.png",
          svg: "starbritestartron.svg",
        },
        {
          name: "STARBUCKS",
          icon: "images/sponsors/all/starbucks.png",
          svg: "starbucks.svg",
        },
        {
          name: "STELLA ARTOIS",
          icon: "images/sponsors/all/stellaartois.png",
          svg: "stellaartois.svg",
        },
        {
          name: "SUZUKI",
          icon: "images/sponsors/all/suzuki.png",
          svg: "suzuki.svg",
        },
        {
          name: "TASTE OF THE WILD",
          icon: "images/sponsors/all/tasteofthewild.png",
          svg: "tasteofthewild.svg",
        },
        {
          name: "TASTE OF THE WILD #2",
          icon: "images/sponsors/all/tasteofthewild-2.png",
          svg: "tasteofthewild-2.svg",
        },
        {
          name: "THE GENERAL INSURANCE",
          icon: "images/sponsors/all/thegeneralinsurance.png",
          svg: "thegeneralinsurance.svg",
        },
        {
          name: "THE GENERAL INSURANCE #2",
          icon: "images/sponsors/all/thegeneralinsurance-2.png",
          svg: "thegeneralinsurance-2.svg",
        },
        {
          name: "THERMACELL",
          icon: "images/sponsors/all/thermacell.png",
          svg: "thermacell.svg",
        },
        {
          name: "THERMACELL #2",
          icon: "images/sponsors/all/thermacell-2.png",
          svg: "thermacell-2.svg",
        },
        {
          name: "TOYOTA",
          icon: "images/sponsors/all/toyota.png",
          svg: "toyota.svg",
        },
        {
          name: "TRAEGER GRILLS",
          icon: "images/sponsors/all/traegergrills.png",
          svg: "traegergrills.svg",
        },
        {
          name: "TRAEGER GRILLS #2",
          icon: "images/sponsors/all/traegergrills-2.png",
          svg: "traegergrills-2.svg",
        },
        {
          name: "TRUE VALUE",
          icon: "images/sponsors/all/truevalue.png",
          svg: "truevalue.svg",
        },
        {
          name: "TRUE VALUE #2",
          icon: "images/sponsors/all/truevalue-2.png",
          svg: "truevalue-2.svg",
        },
        {
          name: "TWISTED TEA",
          icon: "images/sponsors/all/twistedtea.png",
          svg: "twistedtea.svg",
        },
        {
          name: "UNDER ARMOUR",
          icon: "images/sponsors/all/underarmour.png",
          svg: "underarmour.svg",
        },
        {
          name: "UNDER ARMOUR #2",
          icon: "images/sponsors/all/underarmour-2.png",
          svg: "underarmour-2.svg",
        },
        {
          name: "WAL MART",
          icon: "images/sponsors/all/walmart.png",
          svg: "walmart.svg",
        },
        {
          name: "WAL MART #2",
          icon: "images/sponsors/all/walmart-2.png",
          svg: "walmart-2.svg",
        },
        {
          name: "WILD TURKEY BOURBON",
          icon: "images/sponsors/all/wildturkeybourbon.png",
          svg: "wildturkeybourbon.svg",
        },
        {
          name: "WOLVERINE BOOTS",
          icon: "images/sponsors/all/wolverineboots.png",
          svg: "wolverineboots.svg",
        },
        {
          name: "WORTHINGTON",
          icon: "images/sponsors/all/worthington.png",
          svg: "worthington.svg",
        },
        {
          name: "WRANGLER",
          icon: "images/sponsors/all/wrangler.png",
          svg: "wrangler.svg",
        },
        {
          name: "YUENGLING",
          icon: "images/sponsors/all/yuengling.png",
          svg: "yuengling.svg",
        },
        {
          name: "YUENGLING #2",
          icon: "images/sponsors/all/yuengling-2.png",
          svg: "yuengling-2.svg",
        },
        {
          name: "ZIPPO",
          icon: "images/sponsors/all/zippo.png",
          svg: "zippo.svg",
        },
        {
          name: "ZIPPO #2",
          icon: "images/sponsors/all/zippo-2.png",
          svg: "zippo-2.svg",
        },
      ],
    },
    {
      id: "Archery",
      name: "Archery",
      mascots: [
        {
          name: "3 RIVERS ARCHERY",
          icon: "images/sponsors/archery/3riversarchery.png",
          svg: "3riversarchery.svg",
        },
        {
          name: "3 RIVERS ARCHERY #2",
          icon: "images/sponsors/archery/3riversarchery-2.png",
          svg: "3riversarchery-2.svg",
        },
        {
          name: "60X CUSTOM STRINGS",
          icon: "images/sponsors/archery/60xcustomstrings.png",
          svg: "60xcustomstrings.svg",
        },
        {
          name: "60X CUSTOM STRINGS #2",
          icon: "images/sponsors/archery/60xcustomstrings-2.png",
          svg: "60xcustomstrings-2.svg",
        },
        {
          name: "ALPINE ARCHERY",
          icon: "images/sponsors/archery/alpinearchery.png",
          svg: "alpinearchery.svg",
        },
        {
          name: "AMERICAN BROADHEAD",
          icon: "images/sponsors/archery/americanbroadhead.png",
          svg: "americanbroadhead.svg",
        },
        {
          name: "APPLE ARCHERY",
          icon: "images/sponsors/archery/applearchery.png",
          svg: "applearchery.svg",
        },
        {
          name: "APPLE ARCHERY #2",
          icon: "images/sponsors/archery/applearchery-2.png",
          svg: "applearchery-2.svg",
        },
        {
          name: "ARCHER XTREME",
          icon: "images/sponsors/archery/archerxtreme.png",
          svg: "archerxtreme.svg",
        },
        {
          name: "ARROW PRECISION",
          icon: "images/sponsors/archery/arrowprecision.png",
          svg: "arrowprecision.svg",
        },
        {
          name: "ARROW PRECISION #2",
          icon: "images/sponsors/archery/arrowprecision-2.png",
          svg: "arrowprecision-2.svg",
        },
        {
          name: "AURORA",
          icon: "images/sponsors/archery/aurora.png",
          svg: "aurora.svg",
        },
        {
          name: "AXION ARCHERY",
          icon: "images/sponsors/archery/axionarchery.png",
          svg: "axionarchery.svg",
        },
        {
          name: "AXION ARCHERY #2",
          icon: "images/sponsors/archery/axionarchery-2.png",
          svg: "axionarchery-2.svg",
        },
        {
          name: "BARNETT",
          icon: "images/sponsors/archery/barnett.png",
          svg: "barnett.svg",
        },
        {
          name: "BEAR ARCHERY",
          icon: "images/sponsors/archery/beararchery.png",
          svg: "beararchery.svg",
        },
        {
          name: "BEAR ARCHERY #2",
          icon: "images/sponsors/archery/beararchery-2.png",
          svg: "beararchery-2.svg",
        },
        {
          name: "BEMAN",
          icon: "images/sponsors/archery/beman.png",
          svg: "beman.svg",
        },
        {
          name: "BEMAN #2",
          icon: "images/sponsors/archery/beman-2.png",
          svg: "beman-2.svg",
        },
        {
          name: "BLOCK TARGETS",
          icon: "images/sponsors/archery/blocktargets.png",
          svg: "blocktargets.svg",
        },
        {
          name: "BLOCK TARGETS #2",
          icon: "images/sponsors/archery/blocktargets-2.png",
          svg: "blocktargets-2.svg",
        },
        {
          name: "BLOODSPORT",
          icon: "images/sponsors/archery/bloodsport.png",
          svg: "bloodsport.svg",
        },
        {
          name: "BLOODSPORT #2",
          icon: "images/sponsors/archery/bloodsport-2.png",
          svg: "bloodsport-2.svg",
        },
        {
          name: "BOHNING",
          icon: "images/sponsors/archery/bohning.png",
          svg: "bohning.svg",
        },
        {
          name: "BOLT SONIC",
          icon: "images/sponsors/archery/boltsonic.png",
          svg: "boltsonic.svg",
        },
        {
          name: "BOWJAX",
          icon: "images/sponsors/archery/bowjax.png",
          svg: "bowjax.svg",
        },
        {
          name: "CAM X CROSSBOWS",
          icon: "images/sponsors/archery/camxcrossbows.png",
          svg: "camxcrossbows.svg",
        },
        {
          name: "CAM X CROSSBOWS #2",
          icon: "images/sponsors/archery/camxcrossbows-2.png",
          svg: "camxcrossbows-2.svg",
        },
        {
          name: "CARTER ENTERPRISES",
          icon: "images/sponsors/archery/carterenterprises.png",
          svg: "carterenterprises.svg",
        },
        {
          name: "CBE",
          icon: "images/sponsors/archery/cbe.png",
          svg: "cbe.svg",
        },
        {
          name: "CBE #2",
          icon: "images/sponsors/archery/cbe-2.png",
          svg: "cbe-2.svg",
        },
        {
          name: "CBE #3",
          icon: "images/sponsors/archery/cbe-3.png",
          svg: "cbe-3.svg",
        },
        {
          name: "CENTER CIRCLE STRINGS",
          icon: "images/sponsors/archery/centercirclestrings.png",
          svg: "centercirclestrings.svg",
        },
        {
          name: "DARTON ARCHERY",
          icon: "images/sponsors/archery/dartonarchery.png",
          svg: "dartonarchery.svg",
        },
        {
          name: "DIAMOND ARCHERY",
          icon: "images/sponsors/archery/diamondarchery-2.png",
          svg: "diamondarchery-2.svg",
        },
        {
          name: "DELTA MCKENZIE TARGETS",
          icon: "images/sponsors/archery/deltamckenzietargets.png",
          svg: "deltamckenzietargets.svg",
        },
        {
          name: "DELTA MCKENZIE TARGETS #2",
          icon: "images/sponsors/archery/deltamckenzietargets-2.png",
          svg: "deltamckenzietargets-2.svg",
        },
        /*{
			name: "DIAMOND ARCHERY",
			icon: "images/sponsors/archery/diamondarchery.png",
			svg: "diamondarchery.svg" 
			} ,*/
        {
          name: "DIRTNAP",
          icon: "images/sponsors/archery/dirtnap.png",
          svg: "dirtnap.svg",
        },
        {
          name: "DIRTNAP #2",
          icon: "images/sponsors/archery/dirtnap-2.png",
          svg: "dirtnap-2.svg",
        },
        {
          name: "EASTON",
          icon: "images/sponsors/archery/easton.png",
          svg: "easton.svg",
        },
        /*{
			name: "EASTON #2",
			icon: "images/sponsors/archery/easton-2.png",
			svg: "easton-2.svg" 
			} ,*/
        {
          name: "ELITE ARCHERY",
          icon: "images/sponsors/archery/elitearchery.png",
          svg: "elitearchery.svg",
        },
        {
          name: "ELITE ARCHERY #2",
          icon: "images/sponsors/archery/elitearchery-2.png",
          svg: "elitearchery-2.svg",
        },
        {
          name: "EXTREME ARCHERY PRODUCTS",
          icon: "images/sponsors/archery/extremearcheryproducts.png",
          svg: "extremearcheryproducts.svg",
        },
        {
          name: "EXTREME ARCHERY PRODUCTS #2",
          icon: "images/sponsors/archery/extremearcheryproducts-2.png",
          svg: "extremearcheryproducts-2.svg",
        },
        {
          name: "FIELD LOGIC",
          icon: "images/sponsors/archery/fieldlogic.png",
          svg: "fieldlogic.svg",
        },
        {
          name: "FIELD LOGIC #2",
          icon: "images/sponsors/archery/fieldlogic-2.png",
          svg: "fieldlogic-2.svg",
        },
        {
          name: "FIVICS",
          icon: "images/sponsors/archery/fivics.png",
          svg: "fivics.svg",
        },
        {
          name: "FIVICS #2",
          icon: "images/sponsors/archery/fivics-2.png",
          svg: "fivics-2.svg",
        },
        {
          name: "FLYING ARROW ARCHERY",
          icon: "images/sponsors/archery/flyingarrowarchery.png",
          svg: "flyingarrowarchery.svg",
        },
        {
          name: "G5",
          icon: "images/sponsors/archery/g5.png",
          svg: "g5.svg",
        },
        {
          name: "GLENDEL",
          icon: "images/sponsors/archery/glendel.png",
          svg: "glendel.svg",
        },
        {
          name: "GLENDEL #2",
          icon: "images/sponsors/archery/glendel-2.png",
          svg: "glendel-2.svg",
        },
        {
          name: "GOLD TIP",
          icon: "images/sponsors/archery/goldtip.png",
          svg: "goldtip.svg",
        },
        {
          name: "GOLD TIP #2",
          icon: "images/sponsors/archery/goldtip-2.png",
          svg: "goldtip-2.svg",
        },
        {
          name: "GRIM REAPER BROADHEADS",
          icon: "images/sponsors/archery/grimreaperbroadheads.png",
          svg: "grimreaperbroadheads.svg",
        },
        {
          name: "GRIM REAPER BROADHEADS #2",
          icon: "images/sponsors/archery/grimreaperbroadheads-2.png",
          svg: "grimreaperbroadheads-2.svg",
        },
        {
          name: "HAMSKEA",
          icon: "images/sponsors/archery/hamskea.png",
          svg: "hamskea.svg",
        },
        {
          name: "HE DOG ARCHERY",
          icon: "images/sponsors/archery/hedogarchery.png",
          svg: "hedogarchery.svg",
        },
        {
          name: "HHA SPORTS",
          icon: "images/sponsors/archery/hhasports.png",
          svg: "hhasports.svg",
        },
        {
          name: "HHA SPORTS #2",
          icon: "images/sponsors/archery/hhasports-2.png",
          svg: "hhasports-2.svg",
        },
        {
          name: "HIND SIGHT",
          icon: "images/sponsors/archery/hindsight.png",
          svg: "hindsight.svg",
        },
        {
          name: "HINKY STRINGS",
          icon: "images/sponsors/archery/hinkystrings.png",
          svg: "hinkystrings.svg",
        },
        {
          name: "HINKY STRINGS #2",
          icon: "images/sponsors/archery/hinkystrings-2.png",
          svg: "hinkystrings-2.svg",
        },
        {
          name: "HOT SHOT",
          icon: "images/sponsors/archery/hotshot.png",
          svg: "hotshot.svg",
        },
        {
          name: "HOYT",
          icon: "images/sponsors/archery/hoyt.png",
          svg: "hoyt.svg",
        },
        {
          name: "HOYT ARCHERY",
          icon: "images/sponsors/archery/hoytarchery.png",
          svg: "hoytarchery.svg",
        },
        {
          name: "HOYT ARCHERY #2",
          icon: "images/sponsors/archery/hoytarchery-2.png",
          svg: "hoytarchery-2.svg",
        },
        {
          name: "HURRICANE TARGETS",
          icon: "images/sponsors/archery/hurricanetargets.png",
          svg: "hurricanetargets.svg",
        },
        {
          name: "HURRICANE TARGETS #2",
          icon: "images/sponsors/archery/hurricanetargets-2.png",
          svg: "hurricanetargets-2.svg",
        },
        {
          name: "INNERLOC BOWFISHING",
          icon: "images/sponsors/archery/innerlocbowfishing.png",
          svg: "innerlocbowfishing.svg",
        },
        {
          name: "INNERLOC BROADHEADS",
          icon: "images/sponsors/archery/innerlocbroadheads.png",
          svg: "innerlocbroadheads.svg",
        },
        {
          name: "IQ BOWSIGHT",
          icon: "images/sponsors/archery/iqbowsight.png",
          svg: "iqbowsight.svg",
        },
        {
          name: "KWIKEE KWIVER",
          icon: "images/sponsors/archery/kwikeekwiver.png",
          svg: "kwikeekwiver.svg",
        },
        {
          name: "LANCASTER ARCHERY SUPPLY",
          icon: "images/sponsors/archery/lancasterarcherysupply.png",
          svg: "lancasterarcherysupply.svg",
        },
        {
          name: "LANCASTER ARCHERY SUPPLY #2",
          icon: "images/sponsors/archery/lancasterarcherysupply-2.png",
          svg: "lancasterarcherysupply-2.svg",
        },
        {
          name: "LUMENOK",
          icon: "images/sponsors/archery/lumenok.png",
          svg: "lumenok.svg",
        },
        {
          name: "MAGNUS BROADHEADS",
          icon: "images/sponsors/archery/magnusbroadheads.png",
          svg: "magnusbroadheads.svg",
        },
        {
          name: "MAGNUS BROADHEADS #2",
          icon: "images/sponsors/archery/magnusbroadheads-2.png",
          svg: "magnusbroadheads-2.svg",
        },
        /*{
			name: "MARTIN ARCHERY",
			icon: "images/sponsors/archery/martinarchery.png",
			svg: "martinarchery.svg" 
			} ,
			{
			name: "MARTIN ARCHERY #2",
			icon: "images/sponsors/archery/martinarchery-2.png",
			svg: "martinarchery-2.svg" 
			} ,*/
        {
          name: "MATHEWS SOLOCAM",
          icon: "images/sponsors/archery/mathewssolocam.png",
          svg: "mathewssolocam.svg",
        },
        {
          name: "MISSION",
          icon: "images/sponsors/archery/mission.png",
          svg: "mission.svg",
        },
        {
          name: "MISSION #2",
          icon: "images/sponsors/archery/mission-2.png",
          svg: "mission-2.svg",
        },
        {
          name: "MOXIE",
          icon: "images/sponsors/archery/moxie.png",
          svg: "moxie.svg",
        },
        {
          name: "MUZZY BROADHEADS",
          icon: "images/sponsors/archery/muzzybroadheads.png",
          svg: "muzzybroadheads.svg",
        },
        {
          name: "MUZZY BROADHEADS #2",
          icon: "images/sponsors/archery/muzzybroadheads-2.png",
          svg: "muzzybroadheads-2.svg",
        },
        {
          name: "NAP",
          icon: "images/sponsors/archery/nap.png",
          svg: "nap.svg",
        },
        {
          name: "NAP #2",
          icon: "images/sponsors/archery/nap-2.png",
          svg: "nap-2.svg",
        },
        {
          name: "NEW LEVEL ARCHERY",
          icon: "images/sponsors/archery/newlevelarchery.png",
          svg: "newlevelarchery.svg",
        },
        {
          name: "NEW LEVEL ARCHERY #2",
          icon: "images/sponsors/archery/newlevelarchery-2.png",
          svg: "newlevelarchery-2.svg",
        },
        {
          name: "NOCKTURNAL LIGHTED NOCKS",
          icon: "images/sponsors/archery/nockturnallightednocks.png",
          svg: "nockturnallightednocks.svg",
        },
        {
          name: "NOCKTURNAL LIGHTED NOCKS #2",
          icon: "images/sponsors/archery/nockturnallightednocks-2.png",
          svg: "nockturnallightednocks-2.svg",
        },
        {
          name: "NU FLETCH ARCHERY",
          icon: "images/sponsors/archery/nufletcharchery.png",
          svg: "nufletcharchery.svg",
        },
        {
          name: "NU FLETCH ARCHERY #2",
          icon: "images/sponsors/archery/nufletcharchery2.png",
          svg: "nufletcharchery2.svg",
        },
        {
          name: "OCTANE",
          icon: "images/sponsors/archery/octane.png",
          svg: "octane.svg",
        },
        {
          name: "OCTANE #2",
          icon: "images/sponsors/archery/octane-2.png",
          svg: "octane-2.svg",
        },
        {
          name: "ONEIDA BOWS",
          icon: "images/sponsors/archery/oneidabows.png",
          svg: "oneidabows.svg",
        },
        {
          name: "ONEIDA BOWS #2",
          icon: "images/sponsors/archery/oneidabows-2.png",
          svg: "oneidabows-2.svg",
        },
        {
          name: "PINE RIDGE ARCHERY",
          icon: "images/sponsors/archery/pineridgearchery.png",
          svg: "pineridgearchery.svg",
        },
        {
          name: "QAD",
          icon: "images/sponsors/archery/qad.png",
          svg: "qad.svg",
        },
        {
          name: "QAD #2",
          icon: "images/sponsors/archery/qad-2.png",
          svg: "qad-2.svg",
        },
        {
          name: "QUEST",
          icon: "images/sponsors/archery/quest.png",
          svg: "quest.svg",
        },
        {
          name: "RAD",
          icon: "images/sponsors/archery/rad.png",
          svg: "rad.svg",
        },
        {
          name: "RAGE BROADHEADS",
          icon: "images/sponsors/archery/ragebroadheads.png",
          svg: "ragebroadheads.svg",
        },
        {
          name: "RAGE BROADHEADS #2",
          icon: "images/sponsors/archery/ragebroadheads-2.png",
          svg: "ragebroadheads-2.svg",
        },
        {
          name: "RAM CAT BROADHEADS",
          icon: "images/sponsors/archery/ramcatbroadheads.png",
          svg: "ramcatbroadheads.svg",
        },
        {
          name: "RINEHART TARGETS",
          icon: "images/sponsors/archery/rineharttargets.png",
          svg: "rineharttargets.svg",
        },
        {
          name: "RINEHART TARGETS #2",
          icon: "images/sponsors/archery/rineharttargets-2.png",
          svg: "rineharttargets-2.svg",
        },
        {
          name: "RIPCORD ARROW REST",
          icon: "images/sponsors/archery/ripcordarrowrest.png",
          svg: "ripcordarrowrest.svg",
        },
        {
          name: "RIPCORD ARROW REST #2",
          icon: "images/sponsors/archery/ripcordarrowrest-2.png",
          svg: "ripcordarrowrest-2.svg",
        },
        {
          name: "SANFORD INNOVATIONS",
          icon: "images/sponsors/archery/sanfordinnovations.png",
          svg: "sanfordinnovations.svg",
        },
        {
          name: "SCHMIDTY'S SPECIALTY STRINGS",
          icon: "images/sponsors/archery/schmidty'sspecialtystrings.png",
          svg: "schmidty'sspecialtystrings.svg",
        },
        {
          name: "SCHMIDTY'S SPECIALTY STRINGS #2",
          icon: "images/sponsors/archery/schmidty'sspecialtystrings-2.png",
          svg: "schmidty'sspecialtystrings-2.svg",
        },
        {
          name: "SCOTT ARCHERY",
          icon: "images/sponsors/archery/scottarchery.png",
          svg: "scottarchery.svg",
        },
        {
          name: "SERIOUS STRINGS",
          icon: "images/sponsors/archery/seriousstrings.png",
          svg: "seriousstrings.svg",
        },
        {
          name: "SERIOUS STRINGS #2",
          icon: "images/sponsors/archery/seriousstrings-2.png",
          svg: "seriousstrings-2.svg",
        },
        {
          name: "SHOOTER 3D TARGETS",
          icon: "images/sponsors/archery/shooter3dtargets.png",
          svg: "shooter3dtargets.svg",
        },
        {
          name: "SHOOTER 3D TARGETS #2",
          icon: "images/sponsors/archery/shooter3dtargets-2.png",
          svg: "shooter3dtargets-2.svg",
        },
        {
          name: "SLICK TRICK",
          icon: "images/sponsors/archery/slicktrick.png",
          svg: "slicktrick.svg",
        },
        {
          name: "SOLID BROADHEAD COMPANY",
          icon: "images/sponsors/archery/solidbroadheadcompany.png",
          svg: "solidbroadheadcompany.svg",
        },
        {
          name: "SONIC HEADS",
          icon: "images/sponsors/archery/sonicheads.png",
          svg: "sonicheads.svg",
        },
        {
          name: "SONIC PRO",
          icon: "images/sponsors/archery/sonicpro.png",
          svg: "sonicpro.svg",
        },
        {
          name: "SPECIALTY ARCHERY LLC",
          icon: "images/sponsors/archery/specialtyarcheryllc.png",
          svg: "specialtyarcheryllc.svg",
        },
        {
          name: "SPECIALTY ARCHERY LLC #2",
          icon: "images/sponsors/archery/specialtyarcheryllc-2.png",
          svg: "specialtyarcheryllc-2.svg",
        },
        {
          name: "SPIDER ARCHERY PRODUCTS",
          icon: "images/sponsors/archery/spiderarcheryproducts.png",
          svg: "spiderarcheryproducts.svg",
        },
        {
          name: "SPIDER ARCHERY PRODUCTS #2",
          icon: "images/sponsors/archery/spiderarcheryproducts-2.png",
          svg: "spiderarcheryproducts-2.svg",
        },
        {
          name: "SPOT HOGG",
          icon: "images/sponsors/archery/spothogg.png",
          svg: "spothogg.svg",
        },
        {
          name: "SPOT HOGG #2",
          icon: "images/sponsors/archery/spothogg-2.png",
          svg: "spothogg-2.svg",
        },
        {
          name: "STAN",
          icon: "images/sponsors/archery/stan.png",
          svg: "stan.svg",
        },
        {
          name: "STAN #2",
          icon: "images/sponsors/archery/stan-2.png",
          svg: "stan-2.svg",
        },
        {
          name: "STEEL FORCE BROADHEADS",
          icon: "images/sponsors/archery/steelforcebroadheads.png",
          svg: "steelforcebroadheads.svg",
        },
        {
          name: "SURE-LOC",
          icon: "images/sponsors/archery/sure-loc.png",
          svg: "sure-loc.svg",
        },
        {
          name: "SURE-LOC #2",
          icon: "images/sponsors/archery/sure-loc-2.png",
          svg: "sure-loc-2.svg",
        },
        {
          name: "SWAT BROADHEADS",
          icon: "images/sponsors/archery/swatbroadheads.png",
          svg: "swatbroadheads.svg",
        },
        {
          name: "SWHACKER",
          icon: "images/sponsors/archery/swhacker.png",
          svg: "swhacker.svg",
        },
        {
          name: "SWORD SIGHTS",
          icon: "images/sponsors/archery/swordsights.png",
          svg: "swordsights.svg",
        },
        {
          name: "TAS",
          icon: "images/sponsors/archery/tas.png",
          svg: "tas.svg",
        },
        {
          name: "THREADZ",
          icon: "images/sponsors/archery/threadz.png",
          svg: "threadz.svg",
        },
        {
          name: "THREADZ #2",
          icon: "images/sponsors/archery/threadz-2.png",
          svg: "threadz-2.svg",
        },
        {
          name: "TOXONICS",
          icon: "images/sponsors/archery/toxonics.png",
          svg: "toxonics.svg",
        },
        {
          name: "VAPOR TRAIL",
          icon: "images/sponsors/archery/vaportrail.png",
          svg: "vaportrail.svg",
        },
        {
          name: "WAC 'EM BROADHEADS",
          icon: "images/sponsors/archery/wac'embroadheads.png",
          svg: "wac'embroadheads.svg",
        },
        {
          name: "WAC 'EM BROADHEADS #2",
          icon: "images/sponsors/archery/wac'embroadheads-2.png",
          svg: "wac'embroadheads-2.svg",
        },
        {
          name: "WASP ARCHERY",
          icon: "images/sponsors/archery/wasparchery.png",
          svg: "wasparchery.svg",
        },
        {
          name: "WASP ARCHERY #2",
          icon: "images/sponsors/archery/wasparchery-2.png",
          svg: "wasparchery-2.svg",
        },
        {
          name: "WIN&WIN ARCHERY",
          icon: "images/sponsors/archery/win&winarchery.png",
          svg: "win&winarchery.svg",
        },
        {
          name: "60x",
          icon: "images/sponsors/archery/60x-3.png",
          svg: "60x-3.svg",
        },
        {
          name: "60X #2",
          icon: "images/sponsors/archery/60x-4.png",
          svg: "60x-4.svg",
        },
        {
          name: "ACCU NOCK",
          icon: "images/sponsors/archery/accunock.png",
          svg: "accunock.svg",
        },
        {
          name: "ACU ARCHERY 2",
          icon: "images/sponsors/archery/acuarchery2.png",
          svg: "acuarchery2.svg",
        },
        {
          name: "ACU ARCHERY",
          icon: "images/sponsors/archery/acuarchery.png",
          svg: "acuarchery.svg",
        },
        {
          name: "AEP ARCHERY #2",
          icon: "images/sponsors/archery/aeparchery.png",
          svg: "aeparchery.svg",
        },
        {
          name: "AEP ARCHERY",
          icon: "images/sponsors/archery/aep_archery.png",
          svg: "aep_archery.svg",
        },
        {
          name: "ARCUS HUNTING",
          icon: "images/sponsors/archery/arcushunting.png",
          svg: "arcushunting.svg",
        },
        {
          name: "ARIZONA ARCHERY",
          icon: "images/sponsors/archery/arizonaarcheryproduct-2.png",
          svg: "arizonaarcheryproduct-2.svg",
        },
        {
          name: "ARIZONA ARCHERY #2",
          icon: "images/sponsors/archery/arizonaarchery.png",
          svg: "arizonaarchery.svg",
        },
        {
          name: "ASA ARCHERY",
          icon: "images/sponsors/archery/asaarchery.png",
          svg: "asaarchery.svg",
        },
        {
          name: "AXCEL ",
          icon: "images/sponsors/archery/axcel2-white.png",
          svg: "axcel2-white.svg",
        },
        /*{
				name: "AXCEL #2",
				icon: "images/sponsors/archery/axcel2.png",
				svg: "axcel2.svg" 
				} ,*/
        {
          name: "AXCEL #3",
          icon: "images/sponsors/archery/axcel_logo-new_whiteletters.png",
          svg: "axcel_logo-new_whiteletters.svg",
        },
        {
          name: "B STINGER",
          icon: "images/sponsors/archery/bstinger.png",
          svg: "bstinger.svg",
        },
        {
          name: "BCY BOWSTRING",
          icon: "images/sponsors/archery/bcybowstring.png",
          svg: "bcybowstring.svg",
        },
        {
          name: "BLACK GOLD BOWSIGHTS #2",
          icon: "images/sponsors/archery/blackgoldbowsights-2.png",
          svg: "blackgoldbowsights-2.svg",
        },
        {
          name: "BLACK GOLD BOWSIGHTS",
          icon: "images/sponsors/archery/black-goldbowsights2.png",
          svg: "black-goldbowsights2.svg",
        },
        {
          name: "BOHNING",
          icon: "images/sponsors/archery/bohning-copy.png",
          svg: "bohning-copy.svg",
        },
        {
          name: "BOHNING #2",
          icon: "images/sponsors/archery/bohning2.png",
          svg: "bohning2.svg",
        },
        {
          name: "BOHNING #3",
          icon: "images/sponsors/archery/bohning3.png",
          svg: "bohning3.svg",
        },
        {
          name: "BOWTECH",
          icon: "images/sponsors/archery/bowtech.png",
          svg: "bowtech.svg",
        },
        {
          name: "BOWTECH #2",
          icon: "images/sponsors/archery/bowtech2.png",
          svg: "bowtech2.svg",
        },
        {
          name: "BOWTECH #3",
          icon: "images/sponsors/archery/bowtech3.png",
          svg: "bowtech3.svg",
        },
        {
          name: "BRITE STRINGS",
          icon:
            "images/sponsors/archery/britestringscustombowstrings-01-blacklogo.png",
          svg: "britestringscustombowstrings-01-blacklogo.svg",
        },
        {
          name: "BRITE STRINGS #2",
          icon:
            "images/sponsors/archery/britestringscustombowstrings-01-whitelogo.png",
          svg: "britestringscustombowstrings-01-whitelogo.svg",
        },
        {
          name: "CARBON EXPRESS",
          icon: "images/sponsors/archery/carbonexpress-2.png",
          svg: "carbonexpress-2.svg",
        },
        {
          name: "CARBON EXPRESS #2",
          icon:
            "images/sponsors/archery/carbonexpress_logo_version2-blacklogo.png",
          svg: "carbonexpress_logo_version2-blacklogo.svg",
        },
        {
          name: "CLEAR TARGETS",
          icon: "images/sponsors/archery/cleartargets.png",
          svg: "cleartargets.svg",
        },
        {
          name: "DEAD-CENTER",
          icon: "images/sponsors/archery/dead-center-1.png",
          svg: "dead-center-1.svg",
        },
        {
          name: "DEAD CENTER #2",
          icon: "images/sponsors/archery/deadcenter2.png",
          svg: "deadcenter2.svg",
        },
        {
          name: "DEAD CENTER ARCHERY PRODUCTS #3",
          icon: "images/sponsors/archery/deadcenterarcheryproducts3.png",
          svg: "deadcenterarcheryproducts3.svg",
        },
        {
          name: "DOINKER ARCHERY",
          icon: "images/sponsors/archery/doinkerarchery.png",
          svg: "doinkerarchery.svg",
        },
        {
          name: "DONIKER STABILIZERS",
          icon: "images/sponsors/archery/donikerstabilizers.png",
          svg: "donikerstabilizers.svg",
        },
        {
          name: "EASTON #2",
          icon: "images/sponsors/archery/easton2.png",
          svg: "easton2.svg",
        },
        {
          name: "EASTON",
          icon: "images/sponsors/archery/easton.png",
          svg: "easton.svg",
        },
        {
          name: "ELI VANES",
          icon: "images/sponsors/archery/elivanes.png",
          svg: "elivanes.svg",
        },
        {
          name: "ELITE ARCHERY #3",
          icon: "images/sponsors/archery/elitearchery2-black.png",
          svg: "elitearchery2-black.svg",
        },
        {
          name: "ELITE ARCHERY #2",
          icon: "images/sponsors/archery/elitearchery2.png",
          svg: "elitearchery2.svg",
        },
        {
          name: "ETHICS ARCHERY",
          icon: "images/sponsors/archery/ethicsarchery-01.png",
          svg: "ethicsarchery-01.svg",
        },
        /*{
				name: "EXCALIBUR CROSSBOW",
				icon: "images/sponsors/archery/excaliburcrossbow.png",
				svg: "excaliburcrossbow.svg" 
				} ,*/
        {
          name: "FULCRUM ",
          icon: "images/sponsors/archery/fulcrum-white-bgr.png",
          svg: "fulcrum-white-bgr.svg",
        },
        {
          name: "FULCRUM #2",
          icon: "images/sponsors/archery/fulcrum.png",
          svg: "fulcrum.svg",
        },
        {
          name: "FUSE #3",
          icon: "images/sponsors/archery/fuse-2.png",
          svg: "fuse-2.svg",
        },
        /*{
				name: "FUSE #2",
				icon: "images/sponsors/archery/fuse2.png",
				svg: "fuse2.svg" 
				} ,*/
        {
          name: "FUSE",
          icon: "images/sponsors/archery/fuse.png",
          svg: "fuse.svg",
        },
        {
          name: "HOYT ARCHERY #2",
          icon: "images/sponsors/archery/hoytarchery2.png",
          svg: "hoytarchery2.svg",
        },
        {
          name: "HOYT ARCHERY #3",
          icon: "images/sponsors/archery/hoytarchery3.png",
          svg: "hoytarchery3.svg",
        },
        {
          name: "HOYT ARCHERY",
          icon: "images/sponsors/archery/hoytarchery.png",
          svg: "hoytarchery.svg",
        },
        {
          name: "HUSHPUK",
          icon: "images/sponsors/archery/hushpuk.png",
          svg: "hushpuk.svg",
        },
        {
          name: "HUSHPUK #2",
          icon: "images/sponsors/archery/hushpuk2.png",
          svg: "hushpuk2.svg",
        },
        {
          name: "INNERLOC BROADHEADS",
          icon: "images/sponsors/archery/innerlocbroadheads.png",
          svg: "innerlocbroadheads.svg",
        },
        {
          name: "JAMES RIVER ARCHERY",
          icon: "images/sponsors/archery/jamesriverarchery.png",
          svg: "jamesriverarchery.svg",
        },
        {
          name: "NANOPTICS",
          icon: "images/sponsors/archery/nanoptics.png",
          svg: "nanoptics.svg",
        },
        {
          name: "NANOPTICS #2",
          icon: "images/sponsors/archery/nanoptics2.png",
          svg: "nanoptics2.svg",
        },
        {
          name: "NEW BREED",
          icon: "images/sponsors/archery/newbreed.png",
          svg: "newbreed.svg",
        },
        {
          name: "NEW BREED #2",
          icon: "images/sponsors/archery/new_breed_archery.png",
          svg: "new_breed_archery.svg",
        },
        {
          name: "NEW BREED #3",
          icon: "images/sponsors/archery/new_breed_archery_2.png",
          svg: "new_breed_archery_2.svg",
        },
        {
          name: "NOCK OUT LIGHTED NOCKS",
          icon: "images/sponsors/archery/nockoutlightednocks.png",
          svg: "nockoutlightednocks.svg",
        },
        {
          name: "NOCK OUT LIGHTED NOCKS #2",
          icon: "images/sponsors/archery/nockoutlightednocks-2.png",
          svg: "nockoutlightednocks-2.svg",
        },
        {
          name: "NOCK OUT LIGHTED NOCKS #3",
          icon: "images/sponsors/archery/nockoutlightednocks2bg.png",
          svg: "nockoutlightednocks2bg.svg",
        },
        {
          name: "OBSESSION BOWS",
          icon: "images/sponsors/archery/obsessionbows2.png",
          svg: "obsessionbows2.svg",
        },
        {
          name: "OBSESSION BOWS #2",
          icon: "images/sponsors/archery/obsessionbows2(1).png",
          svg: "obsessionbows2(1).svg",
        },
        {
          name: "PODIUM PLUS",
          icon:
            "images/sponsors/archery/podiumpluscompetitionbowstringsregistered.png",
          svg: "podiumpluscompetitionbowstringsregistered.svg",
        },
        {
          name: "PRECISION",
          icon: "images/sponsors/archery/precisionarcheryreticles-2color.png",
          svg: "precisionarcheryreticles-2color.svg",
        },
        {
          name: "RAGE",
          icon: "images/sponsors/archery/rage.png",
          svg: "rage.svg",
        },
        {
          name: "SHIBUYA",
          icon: "images/sponsors/archery/shibuya.png",
          svg: "shibuya.svg",
        },
        {
          name: "SHIBUYA #2",
          icon: "images/sponsors/archery/shibuya2.png",
          svg: "shibuya2.svg",
        },
        {
          name: "SILVER THREAD #2",
          icon: "images/sponsors/archery/silverthread2.png",
          svg: "silverthread2.svg",
        },
        {
          name: "SILVER THREAD",
          icon: "images/sponsors/archery/silverthread.png",
          svg: "silverthread.svg",
        },
        {
          name: "TIGHT SPOT",
          icon: "images/sponsors/archery/tightspot.png",
          svg: "tightspot.svg",
        },
        {
          name: "TROPHYTAKER",
          icon: "images/sponsors/archery/trophytaker.png",
          svg: "trophytaker.svg",
        },
        {
          name: "TRU BALL",
          icon:
            "images/sponsors/archery/truball-fullcolor_whtletters[converted].png",
          svg: "truball-fullcolor_whtletters[converted].svg",
        },
        {
          name: "TRU FIRE #2",
          icon: "images/sponsors/archery/trufire2.png",
          svg: "trufire2.svg",
        },
        {
          name: "TRU FIRE",
          icon: "images/sponsors/archery/trufire.png",
          svg: "trufire.svg",
        },
        {
          name: "VIPER #1",
          icon: "images/sponsors/archery/viperarcheryproducts.png",
          svg: "viperarcheryproducts.svg",
        },
        {
          name: "VIPER #2",
          icon: "images/sponsors/archery/viperarcheryproducts-2.png",
          svg: "viperarcheryproducts-2.svg",
        },
        {
          name: "ZBROS",
          icon: "images/sponsors/archery/zbrosarchery-01.png",
          svg: "zbrosarchery-01.svg",
        },
        {
          name: "Accubow",
          icon: "images/sponsors/archery/accubow.png",
          svg: "accubow.svg",
        },
        {
          name: "America's Best Bowstrings",
          icon: "images/sponsors/archery/america'sbestbowstrings.png",
          svg: "america'sbestbowstrings.svg",
        },
        {
          name: "Apa Archery",
          icon: "images/sponsors/archery/apaarchery2.png",
          svg: "apaarchery2.svg",
        },
        {
          name: "Arrowtech Archery",
          icon: "images/sponsors/archery/arrowtecharchery.png",
          svg: "arrowtecharchery.svg",
        },
        {
          name: "Big Shot Targets",
          icon: "images/sponsors/archery/bigshottargets.png",
          svg: "bigshottargets.svg",
        },
        {
          name: "Black Hole Targets",
          icon: "images/sponsors/archery/blackholetargets.png",
          svg: "blackholetargets.svg",
        },
        {
          name: "Blazer",
          icon: "images/sponsors/archery/blazer.png",
          svg: "blazer.svg",
        },
        {
          name: "Boss Stabilizers",
          icon: "images/sponsors/archery/bossstabilizers.png",
          svg: "bossstabilizers.svg",
        },
        {
          name: "BOW ADX",
          icon: "images/sponsors/archery/bowadx.png",
          svg: "bowadx.svg",
        },
        {
          name: "Carbon Craft Stabilizers",
          icon: "images/sponsors/archery/carboncraftstabilizers.png",
          svg: "carboncraftstabilizers.svg",
        },
        {
          name: "Carbon Express",
          icon: "images/sponsors/archery/carbonexpress.png",
          svg: "carbonexpress.svg",
        },
        {
          name: "Carter BW",
          icon: "images/sponsors/archery/carterbw.png",
          svg: "carterbw.svg",
        },
        {
          name: "Carter BW #2",
          icon: "images/sponsors/archery/carterbw-2.png",
          svg: "carterbw-2.svg",
        },
        {
          name: "Clean Shot Archery",
          icon: "images/sponsors/archery/cleanshotarchery.png",
          svg: "cleanshotarchery.svg",
        },
        {
          name: "Cobra-Archery",
          icon: "images/sponsors/archery/cobra-archery.png",
          svg: "cobra-archery.svg",
        },
        {
          name: "Extreme Bowstrings",
          icon: "images/sponsors/archery/extremebowstrings.png",
          svg: "extremebowstrings.svg",
        },
        {
          name: "Feather Vision #2",
          icon: "images/sponsors/archery/feathervision2.png",
          svg: "feathervision2.svg",
        },
        {
          name: "Feather Vision #3",
          icon: "images/sponsors/archery/feathervision3.png",
          svg: "feathervision3.svg",
        },
        {
          name: "Feather Vision #4",
          icon: "images/sponsors/archery/feathervision4.png",
          svg: "feathervision4.svg",
        },
        {
          name: "Feather Vision #5",
          icon: "images/sponsors/archery/feathervision5.png",
          svg: "feathervision5.svg",
        },
        {
          name: "Feather Vision",
          icon: "images/sponsors/archery/feathervision.png",
          svg: "feathervision.svg",
        },
        {
          name: "First String",
          icon: "images/sponsors/archery/firststring.png",
          svg: "firststring.svg",
        },
        {
          name: "Hail Archery Products",
          icon: "images/sponsors/archery/hailarcheryproducts.png",
          svg: "hailarcheryproducts.svg",
        },
        {
          name: "Hi-Tek Archery",
          icon: "images/sponsors/archery/hi-tekarchery.png",
          svg: "hi-tekarchery.svg",
        },
        {
          name: "Hot Shot Archery",
          icon: "images/sponsors/archery/hotshotarchery.png",
          svg: "hotshotarchery.svg",
        },
        {
          name: "KTECH Designs",
          icon: "images/sponsors/archery/ktechdesigns.png",
          svg: "ktechdesigns.svg",
        },
        {
          name: "Last Chance Archery",
          icon: "images/sponsors/archery/lastchancearchery.png",
          svg: "lastchancearchery.svg",
        },
        {
          name: "Live Oak Archery #2",
          icon: "images/sponsors/archery/liveoakarchery2.png",
          svg: "liveoakarchery2.svg",
        },
        {
          name: "Live Oak Archery",
          icon: "images/sponsors/archery/liveoakarchery.png",
          svg: "liveoakarchery.svg",
        },
        {
          name: "Lumenok",
          icon: "images/sponsors/archery/lumenok.png",
          svg: "lumenok.svg",
        },
        {
          name: "Morrel Targets",
          icon: "images/sponsors/archery/morrelltargets-logo-glow.png",
          svg: "morrelltargets-logo-glow.svg",
        },
        {
          name: "NASP",
          icon: "images/sponsors/archery/nasp.png",
          svg: "nasp.svg",
        },
        {
          name: "Nock On Archery 2",
          icon: "images/sponsors/archery/nockonarchery2.png",
          svg: "nockonarchery2.svg",
        },
        {
          name: "Nock On Archery",
          icon: "images/sponsors/archery/nockonarchery.png",
          svg: "nockonarchery.svg",
        },
        {
          name: "Pro Points Pins",
          icon: "images/sponsors/archery/propointspins.png",
          svg: "propointspins.svg",
        },
        {
          name: "Proline Bowstrings",
          icon: "images/sponsors/archery/prolinebowstrings.png",
          svg: "prolinebowstrings.svg",
        },
        {
          name: "S3DA-HR",
          icon: "images/sponsors/archery/s3da-hr.png",
          svg: "s3da-hr.svg",
        },
        {
          name: "Shrewd Archery",
          icon: "images/sponsors/archery/shrewdarchery.png",
          svg: "shrewdarchery.svg",
        },
        {
          name: "Shrewd RED ARROWS",
          icon: "images/sponsors/archery/shrewdredarrows.png",
          svg: "shrewdredarrows.svg",
        },
        {
          name: "Spider Archery",
          icon: "images/sponsors/archery/spider-archery-300x88.png",
          svg: "spider-archery-300x88.svg",
        },
        {
          name: "Stanislaski",
          icon: "images/sponsors/archery/stanislaskilogo.png",
          svg: "stanislaskilogo.svg",
        },
        {
          name: "Stanislaski #2",
          icon: "images/sponsors/archery/stanislaskilogo-2.png",
          svg: "stanislaskilogo-2.svg",
        },
        {
          name: "SWORD #2",
          icon: "images/sponsors/archery/sword-2.png",
          svg: "sword-2.svg",
        },
        {
          name: "SWORD",
          icon: "images/sponsors/archery/sword.png",
          svg: "sword.svg",
        },
        {
          name: "TOAD Terrybuilt Original Archery Designs",
          icon:
            "images/sponsors/archery/toadterrybuiltoriginalarcherydesigns.png",
          svg: "toadterrybuiltoriginalarcherydesigns.svg",
        },
        {
          name: "Tribe Archery",
          icon: "images/sponsors/archery/tribearchery.png",
          svg: "tribearchery.svg",
        },
        {
          name: "Truball Axcel 2",
          icon: "images/sponsors/archery/truballaxcel2.png",
          svg: "truballaxcel2.svg",
        },
        {
          name: "Truball Axcel",
          icon: "images/sponsors/archery/truballaxcel.png",
          svg: "truballaxcel.svg",
        },
        {
          name: "Twisted X Bowstrings",
          icon: "images/sponsors/archery/twistedxbowstrings.png",
          svg: "twistedxbowstrings.svg",
        },
        {
          name: "Twister X-01",
          icon: "images/sponsors/archery/twisterx-01.png",
          svg: "twisterx-01.svg",
        },
        {
          name: "ULTRAVIEW Archery",
          icon: "images/sponsors/archery/ultraviewarchery.png",
          svg: "ultraviewarchery.svg",
        },
        {
          name: "Victory",
          icon: "images/sponsors/archery/victory.png",
          svg: "victory.svg",
        },
        {
          name: "Vista Targets",
          icon: "images/sponsors/archery/vistatargets.png",
          svg: "vistatargets.svg",
        },
        {
          name: "Zebra Bowstrings",
          icon: "images/sponsors/archery/zebrabowstrings.png",
          svg: "zebrabowstrings.svg",
        },
        {
          name: "Mybo",
          icon: "images/sponsors/archery/mybo.png",
          svg: "mybo.svg",
        },
        {
          name: "Mybo #2",
          icon: "images/sponsors/archery/mybobb.png",
          svg: "mybobb.svg",
        },
        {
          name: "GAS Bowstrings",
          icon: "images/sponsors/archery/gasbowstrings.png",
          svg: "gasbowstrings.svg",
        },
        {
          name: "Moonshine Camo",
          icon: "images/sponsors/archery/moonshine_camo_logo.png",
          svg: "moonshine_camo_logo.svg",
        },
        {
          name: "Pro Archery",
          icon: "images/sponsors/archery/proarchery-01.png",
          svg: "proarchery-01.svg",
        },
      ],
    },
    {
      id: "Fishing",
      name: "Fishing",
      mascots: [
        {
          name: "13 FISHING",
          icon: "images/sponsors/fishing/13fishing.png",
          svg: "13fishing.svg",
        },
        {
          name: "13 FISHING #2",
          icon: "images/sponsors/fishing/13fishing-2.png",
          svg: "13fishing-2.svg",
        },
        {
          name: "13 FISHING #3",
          icon: "images/sponsors/fishing/13fishing-3.png",
          svg: "13fishing-3.svg",
        },
        {
          name: "13 FISHING #4",
          icon: "images/sponsors/fishing/13fishing-4.png",
          svg: "13fishing-4.svg",
        },
        {
          name: "5X3",
          icon: "images/sponsors/fishing/5x3.png",
          svg: "5x3.svg",
        },
        {
          name: "5X3 #2",
          icon: "images/sponsors/fishing/5x3-2.png",
          svg: "5x3-2.svg",
        },
        {
          name: "6TH SENSE",
          icon: "images/sponsors/fishing/6thsense.png",
          svg: "6thsense.svg",
        },
        {
          name: "6TH SENSE #2",
          icon: "images/sponsors/fishing/6thsense-2.png",
          svg: "6thsense-2.svg",
        },
        {
          name: "AA'S",
          icon: "images/sponsors/fishing/aa's.png",
          svg: "aa's.svg",
        },
        {
          name: "AA'S #2",
          icon: "images/sponsors/fishing/aa's-2.png",
          svg: "aa's-2.svg",
        },
        {
          name: "AB FISHING TACKLE",
          icon: "images/sponsors/fishing/abfishingtackle.png",
          svg: "abfishingtackle.svg",
        },
        {
          name: "AB FISHING TACKLE #2",
          icon: "images/sponsors/fishing/abfishingtackle-2.png",
          svg: "abfishingtackle-2.svg",
        },
        {
          name: "ABU GARCIA",
          icon: "images/sponsors/fishing/abugarcia.png",
          svg: "abugarcia.svg",
        },
        {
          name: "ABU GARCIA #2",
          icon: "images/sponsors/fishing/abugarcia-2.png",
          svg: "abugarcia-2.svg",
        },
        {
          name: "AFW AMERICAN FISHING WIRE",
          icon: "images/sponsors/fishing/afwamericanfishingwire.png",
          svg: "afwamericanfishingwire.svg",
        },
        {
          name: "AFW AMERICAN FISHING WIRE #2",
          icon: "images/sponsors/fishing/afwamericanfishingwire-2.png",
          svg: "afwamericanfishingwire-2.svg",
        },
        {
          name: "ALL-TERRAIN TACKLE",
          icon: "images/sponsors/fishing/all-terraintackle.png",
          svg: "all-terraintackle.svg",
        },
        {
          name: "ALLISON BOATS",
          icon: "images/sponsors/fishing/allisonboats.png",
          svg: "allisonboats.svg",
        },
        {
          name: "ALLISON BOATS #2",
          icon: "images/sponsors/fishing/allisonboats-2.png",
          svg: "allisonboats-2.svg",
        },
        {
          name: "ALUMA CRAFT",
          icon: "images/sponsors/fishing/alumacraft.png",
          svg: "alumacraft.svg",
        },
        {
          name: "ALUMA CRAFT #2",
          icon: "images/sponsors/fishing/alumacraft-2.png",
          svg: "alumacraft-2.svg",
        },
        {
          name: "ALUMA-BRITE",
          icon: "images/sponsors/fishing/aluma-brite.png",
          svg: "aluma-brite.svg",
        },
        {
          name: "ALWELD BOATS",
          icon: "images/sponsors/fishing/alweldboats.png",
          svg: "alweldboats.svg",
        },
        {
          name: "ALWELD BOATS #2",
          icon: "images/sponsors/fishing/alweldboats-2.png",
          svg: "alweldboats-2.svg",
        },
        {
          name: "ALX RODS",
          icon: "images/sponsors/fishing/alxrods.png",
          svg: "alxrods.svg",
        },
        {
          name: "AMERICAN BASS ANGLERS",
          icon: "images/sponsors/fishing/americanbassanglers.png",
          svg: "americanbassanglers.svg",
        },
        {
          name: "AMS BOWFISHING",
          icon: "images/sponsors/fishing/amsbowfishing.png",
          svg: "amsbowfishing.svg",
        },
        {
          name: "AMS BOWFISHING #2",
          icon: "images/sponsors/fishing/amsbowfishing-2.png",
          svg: "amsbowfishing-2.svg",
        },
        {
          name: "AMS BOWFISHING #3",
          icon: "images/sponsors/fishing/amsbowfishing-3.png",
          svg: "amsbowfishing-3.svg",
        },
        {
          name: "AMS BOWFISHING #4",
          icon: "images/sponsors/fishing/amsbowfishing-4.png",
          svg: "amsbowfishing-4.svg",
        },
        {
          name: "ANDE MONOFILAMENT",
          icon: "images/sponsors/fishing/andemonofilament.png",
          svg: "andemonofilament.svg",
        },
        {
          name: "ANDE MONOFILAMENT #2",
          icon: "images/sponsors/fishing/andemonofilament-2.png",
          svg: "andemonofilament-2.svg",
        },
        {
          name: "AQUA-VU",
          icon: "images/sponsors/fishing/aqua-vu.png",
          svg: "aqua-vu.svg",
        },
        {
          name: "ARDENT",
          icon: "images/sponsors/fishing/ardent.png",
          svg: "ardent.svg",
        },
        {
          name: "ARDENT #2",
          icon: "images/sponsors/fishing/ardent-2.png",
          svg: "ardent-2.svg",
        },
        {
          name: "BAGLEY",
          icon: "images/sponsors/fishing/bagley.png",
          svg: "bagley.svg",
        },
        {
          name: "BAGLEY #2",
          icon: "images/sponsors/fishing/bagley-2.png",
          svg: "bagley-2.svg",
        },
        {
          name: "BAIT CLOUD",
          icon: "images/sponsors/fishing/baitcloud.png",
          svg: "baitcloud.svg",
        },
        {
          name: "BAIT CLOUD #2",
          icon: "images/sponsors/fishing/baitcloud-2.png",
          svg: "baitcloud-2.svg",
        },
        {
          name: "BAIT WERKS",
          icon: "images/sponsors/fishing/baitwerks.png",
          svg: "baitwerks.svg",
        },
        {
          name: "BAIT WERKS #2",
          icon: "images/sponsors/fishing/baitwerks-2.png",
          svg: "baitwerks-2.svg",
        },
        {
          name: "BAITMATE",
          icon: "images/sponsors/fishing/baitmate.png",
          svg: "baitmate.svg",
        },
        {
          name: "BAITMATE #2",
          icon: "images/sponsors/fishing/baitmate-2.png",
          svg: "baitmate-2.svg",
        },
        {
          name: "BANAX",
          icon: "images/sponsors/fishing/banax.png",
          svg: "banax.svg",
        },
        {
          name: "BANAX #2",
          icon: "images/sponsors/fishing/banax-2.png",
          svg: "banax-2.svg",
        },
        {
          name: "BANG FISH ATTRACTANT",
          icon: "images/sponsors/fishing/bangfishattractant.png",
          svg: "bangfishattractant.svg",
        },
        {
          name: "BANG FISH ATTRACTANT #2",
          icon: "images/sponsors/fishing/bangfishattractant-2.png",
          svg: "bangfishattractant-2.svg",
        },
        {
          name: "BANG LURES",
          icon: "images/sponsors/fishing/banglures.png",
          svg: "banglures.svg",
        },
        {
          name: "BANG LURES #2",
          icon: "images/sponsors/fishing/banglures-2.png",
          svg: "banglures-2.svg",
        },
        {
          name: "BASS",
          icon: "images/sponsors/fishing/bass.png",
          svg: "bass.svg",
        },
        {
          name: "BASS ASSASSIN FISHING LURES",
          icon: "images/sponsors/fishing/bassassassinfishinglures.png",
          svg: "bassassassinfishinglures.svg",
        },
        {
          name: "BASS ASSASSIN FISHING LURES #2",
          icon: "images/sponsors/fishing/bassassassinfishinglures-2.png",
          svg: "bassassassinfishinglures-2.svg",
        },
        {
          name: "BASS ASSASSINS",
          icon: "images/sponsors/fishing/bassassassins.png",
          svg: "bassassassins.svg",
        },
        {
          name: "BASS FEDERATION",
          icon: "images/sponsors/fishing/bassfederation.png",
          svg: "bassfederation.svg",
        },
        {
          name: "BASS FEDERATION WISCONSIN",
          icon: "images/sponsors/fishing/bassfederationwisconsin.png",
          svg: "bassfederationwisconsin.svg",
        },
        {
          name: "BASS MAFIA",
          icon: "images/sponsors/fishing/bassmafia.png",
          svg: "bassmafia.svg",
        },
        {
          name: "BASS MAFIA #2",
          icon: "images/sponsors/fishing/bassmafia-2.png",
          svg: "bassmafia-2.svg",
        },
        {
          name: "BASS MAGNET",
          icon: "images/sponsors/fishing/bassmagnet.png",
          svg: "bassmagnet.svg",
        },
        {
          name: "BASS MAGNET 2",
          icon: "images/sponsors/fishing/bassmagnet2.png",
          svg: "bassmagnet2.svg",
        },
        {
          name: "BASS MEMBER",
          icon: "images/sponsors/fishing/bassmember.png",
          svg: "bassmember.svg",
        },
        {
          name: "BASS NATION IOWA",
          icon: "images/sponsors/fishing/bassnationiowa.png",
          svg: "bassnationiowa.svg",
        },
        {
          name: "BASS NATION IOWA #2",
          icon: "images/sponsors/fishing/bassnationiowa-2.png",
          svg: "bassnationiowa-2.svg",
        },
        {
          name: "BASS NATION WISCONSIN",
          icon: "images/sponsors/fishing/bassnationwisconsin.png",
          svg: "bassnationwisconsin.svg",
        },
        {
          name: "BASS NATION WISCONSIN #2",
          icon: "images/sponsors/fishing/bassnationwisconsin-2.png",
          svg: "bassnationwisconsin-2.svg",
        },
        {
          name: "BASS PRO SHOPS",
          icon: "images/sponsors/fishing/bassproshops.png",
          svg: "bassproshops.svg",
        },
        {
          name: "BASSMASTER ELITE",
          icon: "images/sponsors/fishing/bassmasterelite.png",
          svg: "bassmasterelite.svg",
        },
        {
          name: "BASSMASTER TUNDRA",
          icon: "images/sponsors/fishing/bassmastertundra.png",
          svg: "bassmastertundra.svg",
        },
        {
          name: "BASSTAR",
          icon: "images/sponsors/fishing/basstar.png",
          svg: "basstar.svg",
        },
        {
          name: "BAY RAT LURES",
          icon: "images/sponsors/fishing/bayratlures.png",
          svg: "bayratlures.svg",
        },
        {
          name: "BAY RAT LURES #2",
          icon: "images/sponsors/fishing/bayratlures-2.png",
          svg: "bayratlures-2.svg",
        },
        {
          name: "BEAU MAC JIGS",
          icon: "images/sponsors/fishing/beaumacjigs.png",
          svg: "beaumacjigs.svg",
        },
        {
          name: "BEAU MAC JIGS #2",
          icon: "images/sponsors/fishing/beaumacjigs-2.png",
          svg: "beaumacjigs-2.svg",
        },
        {
          name: "BEAVER DAM",
          icon: "images/sponsors/fishing/beaverdam.png",
          svg: "beaverdam.svg",
        },
        {
          name: "BEAVER DAM #2",
          icon: "images/sponsors/fishing/beaverdam-2.png",
          svg: "beaverdam-2.svg",
        },
        {
          name: "BERKLEY",
          icon: "images/sponsors/fishing/berkley.png",
          svg: "berkley.svg",
        },
        {
          name: "BERKLEY #2",
          icon: "images/sponsors/fishing/berkley-2.png",
          svg: "berkley-2.svg",
        },
        {
          name: "BERKLEY FIRELINE",
          icon: "images/sponsors/fishing/berkleyfireline.png",
          svg: "berkleyfireline.svg",
        },
        {
          name: "BERKLEY GULP",
          icon: "images/sponsors/fishing/berkleygulp.png",
          svg: "berkleygulp.svg",
        },
        {
          name: "BERKLEY HAVOC",
          icon: "images/sponsors/fishing/berkleyhavoc.png",
          svg: "berkleyhavoc.svg",
        },
        {
          name: "BERKLEY NANOFIL",
          icon: "images/sponsors/fishing/berkleynanofil.png",
          svg: "berkleynanofil.svg",
        },
        {
          name: "BERKLEY POWERBAIT",
          icon: "images/sponsors/fishing/berkleypowerbait.png",
          svg: "berkleypowerbait.svg",
        },
        {
          name: "BERKLEY TRILENE",
          icon: "images/sponsors/fishing/berkleytrilene.png",
          svg: "berkleytrilene.svg",
        },
        {
          name: "BERKLEY TRILENE #2",
          icon: "images/sponsors/fishing/berkleytrilene-2.png",
          svg: "berkleytrilene-2.svg",
        },
        {
          name: "BIG BEAR",
          icon: "images/sponsors/fishing/bigbear.png",
          svg: "bigbear.svg",
        },
        {
          name: "BIG BITE BAITS",
          icon: "images/sponsors/fishing/bigbitebaits.png",
          svg: "bigbitebaits.svg",
        },
        {
          name: "BIG BITE BAITS #2",
          icon: "images/sponsors/fishing/bigbitebaits-2.png",
          svg: "bigbitebaits-2.svg",
        },
        {
          name: "BIO EDGE",
          icon: "images/sponsors/fishing/bioedge.png",
          svg: "bioedge.svg",
        },
        {
          name: "BIO EDGE #2",
          icon: "images/sponsors/fishing/bioedge-2.png",
          svg: "bioedge-2.svg",
        },
        {
          name: "BIO SPAWN",
          icon: "images/sponsors/fishing/biospawn.png",
          svg: "biospawn.svg",
        },
        {
          name: "BIO SPAWN #2",
          icon: "images/sponsors/fishing/biospawn-2.png",
          svg: "biospawn-2.svg",
        },
        {
          name: "BLACK BART",
          icon: "images/sponsors/fishing/blackbart.png",
          svg: "blackbart.svg",
        },
        {
          name: "BLACK BART #2",
          icon: "images/sponsors/fishing/blackbart-2.png",
          svg: "blackbart-2.svg",
        },
        {
          name: "BLAZER BOATS",
          icon: "images/sponsors/fishing/blazerboats.png",
          svg: "blazerboats.svg",
        },
        {
          name: "BOGA GRIP",
          icon: "images/sponsors/fishing/bogagrip.png",
          svg: "bogagrip.svg",
        },
        {
          name: "BOGA GRIP #2",
          icon: "images/sponsors/fishing/bogagrip-2.png",
          svg: "bogagrip-2.svg",
        },
        {
          name: "BOMBER LURES",
          icon: "images/sponsors/fishing/bomberlures.png",
          svg: "bomberlures.svg",
        },
        {
          name: "BOMBER LURES #2",
          icon: "images/sponsors/fishing/bomberlures-2.png",
          svg: "bomberlures-2.svg",
        },
        {
          name: "BOOMERANG TOOL",
          icon: "images/sponsors/fishing/boomerangtool.png",
          svg: "boomerangtool.svg",
        },
        {
          name: "BOSTON WHALER",
          icon: "images/sponsors/fishing/bostonwhaler.png",
          svg: "bostonwhaler.svg",
        },
        {
          name: "BOSTON WHALER #2",
          icon: "images/sponsors/fishing/bostonwhaler-2.png",
          svg: "bostonwhaler-2.svg",
        },
        {
          name: "BRUISER BAITS",
          icon: "images/sponsors/fishing/bruiserbaits.png",
          svg: "bruiserbaits.svg",
        },
        {
          name: "BRUISER BAITS #2",
          icon: "images/sponsors/fishing/bruiserbaits-2.png",
          svg: "bruiserbaits-2.svg",
        },
        {
          name: "BUBBA BLADE",
          icon: "images/sponsors/fishing/bubbablade.png",
          svg: "bubbablade.svg",
        },
        {
          name: "BUCKEYE LURES",
          icon: "images/sponsors/fishing/buckeyelures.png",
          svg: "buckeyelures.svg",
        },
        {
          name: "BULLET WEIGHTS",
          icon: "images/sponsors/fishing/bulletweights.png",
          svg: "bulletweights.svg",
        },
        {
          name: "CAJUN BOWFISHING",
          icon: "images/sponsors/fishing/cajunbowfishing.png",
          svg: "cajunbowfishing.svg",
        },
        {
          name: "CAPTAIN MACK'S",
          icon: "images/sponsors/fishing/captainmack's.png",
          svg: "captainmack's.svg",
        },
        {
          name: "CARROT STIX",
          icon: "images/sponsors/fishing/carrotstix.png",
          svg: "carrotstix.svg",
        },
        {
          name: "CARROT STIX #2",
          icon: "images/sponsors/fishing/carrotstix-2.png",
          svg: "carrotstix-2.svg",
        },
        {
          name: "CAST AWAY RODS",
          icon: "images/sponsors/fishing/castawayrods.png",
          svg: "castawayrods.svg",
        },
        {
          name: "CAST AWAY RODS #2",
          icon: "images/sponsors/fishing/castawayrods-2.png",
          svg: "castawayrods-2.svg",
        },
        {
          name: "CATFISH CHARLIE",
          icon: "images/sponsors/fishing/catfishcharlie.png",
          svg: "catfishcharlie.svg",
        },
        {
          name: "CATFISH CHARLIE #2",
          icon: "images/sponsors/fishing/catfishcharlie-2.png",
          svg: "catfishcharlie-2.svg",
        },
        {
          name: "CHAMPION BOATS",
          icon: "images/sponsors/fishing/championboats.png",
          svg: "championboats.svg",
        },
        {
          name: "CHARMER BAITS",
          icon: "images/sponsors/fishing/charmerbaits.png",
          svg: "charmerbaits.svg",
        },
        {
          name: "CHATTER BAIT",
          icon: "images/sponsors/fishing/chatterbait.png",
          svg: "chatterbait.svg",
        },
        {
          name: "CLAM",
          icon: "images/sponsors/fishing/clam.png",
          svg: "clam.svg",
        },
        {
          name: "CLAM #2",
          icon: "images/sponsors/fishing/clam-2.png",
          svg: "clam-2.svg",
        },
        {
          name: "COSTA",
          icon: "images/sponsors/fishing/costa.png",
          svg: "costa.svg",
        },
        {
          name: "COTTON CORDELL",
          icon: "images/sponsors/fishing/cottoncordell.png",
          svg: "cottoncordell.svg",
        },
        {
          name: "COTTON CORDELL #2",
          icon: "images/sponsors/fishing/cottoncordell-2.png",
          svg: "cottoncordell-2.svg",
        },
        {
          name: "CREME BAITS",
          icon: "images/sponsors/fishing/cremebaits.png",
          svg: "cremebaits.svg",
        },
        {
          name: "CREME BAITS #2",
          icon: "images/sponsors/fishing/cremebaits-2.png",
          svg: "cremebaits-2.svg",
        },
        {
          name: "CS COATINGS",
          icon: "images/sponsors/fishing/cscoatings.png",
          svg: "cscoatings.svg",
        },
        {
          name: "CUDA",
          icon: "images/sponsors/fishing/cuda.png",
          svg: "cuda.svg",
        },
        {
          name: "CULL KEEPER",
          icon: "images/sponsors/fishing/cullkeeper.png",
          svg: "cullkeeper.svg",
        },
        {
          name: "CULL KEEPER #2",
          icon: "images/sponsors/fishing/cullkeeper-2.png",
          svg: "cullkeeper-2.svg",
        },
        {
          name: "CUMBERLAND LURES",
          icon: "images/sponsors/fishing/cumberlandlures.png",
          svg: "cumberlandlures.svg",
        },
        {
          name: "CUSTOM LURES UNLIMITED",
          icon: "images/sponsors/fishing/customluresunlimited.png",
          svg: "customluresunlimited.svg",
        },
        {
          name: "D&L TACKLE",
          icon: "images/sponsors/fishing/d&ltackle.png",
          svg: "d&ltackle.svg",
        },
        {
          name: "DAIWA",
          icon: "images/sponsors/fishing/daiwa.png",
          svg: "daiwa.svg",
        },
        {
          name: "DAIWA #2",
          icon: "images/sponsors/fishing/daiwa-2.png",
          svg: "daiwa-2.svg",
        },
        {
          name: "DEEPER FISHFINDER",
          icon: "images/sponsors/fishing/deeperfishfinder.png",
          svg: "deeperfishfinder.svg",
        },
        {
          name: "DEEPER FISHFINDER #2",
          icon: "images/sponsors/fishing/deeperfishfinder-2.png",
          svg: "deeperfishfinder-2.svg",
        },
        {
          name: "DENALI",
          icon: "images/sponsors/fishing/denali.png",
          svg: "denali.svg",
        },
        {
          name: "DENALI #2",
          icon: "images/sponsors/fishing/denali-2.png",
          svg: "denali-2.svg",
        },
        // {
        // name: "DIRTY JIGS TACKLE",
        // icon: "images/sponsors/fishing/dirtyjigstackle.png",
        // svg: "dirtyjigstackle.svg"
        // } ,
        {
          name: "DOBYNS",
          icon: "images/sponsors/fishing/dobyns.png",
          svg: "dobyns.svg",
        },
        {
          name: "DOBYNS #2",
          icon: "images/sponsors/fishing/dobyns-2.png",
          svg: "dobyns-2.svg",
        },
        {
          name: "DOBYNS #3",
          icon: "images/sponsors/fishing/dobyns-3.png",
          svg: "dobyns-3.svg",
        },
        {
          name: "DRIFTER TACKLE",
          icon: "images/sponsors/fishing/driftertackle.png",
          svg: "driftertackle.svg",
        },
        {
          name: "DRIFTER TACKLE #2",
          icon: "images/sponsors/fishing/driftertackle-2.png",
          svg: "driftertackle-2.svg",
        },
        {
          name: "DUCKETT",
          icon: "images/sponsors/fishing/duckett.png",
          svg: "duckett.svg",
        },
        {
          name: "DYNAMIC LURES",
          icon: "images/sponsors/fishing/dynamiclures.png",
          svg: "dynamiclures.svg",
        },
        {
          name: "DYNAMIC LURES #2",
          icon: "images/sponsors/fishing/dynamiclures-2.png",
          svg: "dynamiclures-2.svg",
        },
        {
          name: "DYNAMITE BAITS",
          icon: "images/sponsors/fishing/dynamitebaits.png",
          svg: "dynamitebaits.svg",
        },
        {
          name: "EAGLE CLAW",
          icon: "images/sponsors/fishing/eagleclaw.png",
          svg: "eagleclaw.svg",
        },
        {
          name: "EAGLE CLAW #2",
          icon: "images/sponsors/fishing/eagleclaw-2.png",
          svg: "eagleclaw-2.svg",
        },
        {
          name: "EAGLE CLAW TROKAR",
          icon: "images/sponsors/fishing/eagleclawtrokar.png",
          svg: "eagleclawtrokar.svg",
        },
        {
          name: "ECO PRO TUNGSTEN",
          icon: "images/sponsors/fishing/ecoprotungsten.png",
          svg: "ecoprotungsten.svg",
        },
        {
          name: "ELITE TUNGSTEN",
          icon: "images/sponsors/fishing/elitetungsten.png",
          svg: "elitetungsten.svg",
        },
        {
          name: "ERIE DEARIE",
          icon: "images/sponsors/fishing/eriedearie.png",
          svg: "eriedearie.svg",
        },
        {
          name: "ERIE DEARIE #2",
          icon: "images/sponsors/fishing/eriedearie-2.png",
          svg: "eriedearie-2.svg",
        },
        {
          name: "ESKIMO",
          icon: "images/sponsors/fishing/eskimo.png",
          svg: "eskimo.svg",
        },
        {
          name: "ESKIMO #2",
          icon: "images/sponsors/fishing/eskimo-2.png",
          svg: "eskimo-2.svg",
        },
        {
          name: "EVINRUDE",
          icon: "images/sponsors/fishing/evinrude.png",
          svg: "evinrude.svg",
        },
        {
          name: "EVINRUDE #2",
          icon: "images/sponsors/fishing/evinrude-2.png",
          svg: "evinrude-2.svg",
        },
        {
          name: "FALCON RODS",
          icon: "images/sponsors/fishing/falconrods.png",
          svg: "falconrods.svg",
        },
        {
          name: "FALCON RODS #2",
          icon: "images/sponsors/fishing/falconrods-2.png",
          svg: "falconrods-2.svg",
        },
        {
          name: "FENWICK",
          icon: "images/sponsors/fishing/fenwick.png",
          svg: "fenwick.svg",
        },
        {
          name: "FIN-FINDER",
          icon: "images/sponsors/fishing/fin-finder.png",
          svg: "fin-finder.svg",
        },
        {
          name: "FIN-FINDER #2",
          icon: "images/sponsors/fishing/fin-finder-2.png",
          svg: "fin-finder-2.svg",
        },
        {
          name: "FIN-TECH",
          icon: "images/sponsors/fishing/fin-tech.png",
          svg: "fin-tech.svg",
        },
        {
          name: "FISH HEAD",
          icon: "images/sponsors/fishing/fishhead.png",
          svg: "fishhead.svg",
        },
        {
          name: "FISH HEAD #2",
          icon: "images/sponsors/fishing/fishhead-2.png",
          svg: "fishhead-2.svg",
        },
        {
          name: "FISH HUNTER",
          icon: "images/sponsors/fishing/fishhunter.png",
          svg: "fishhunter.svg",
        },
        {
          name: "FISH HUNTER #2",
          icon: "images/sponsors/fishing/fishhunter-2.png",
          svg: "fishhunter-2.svg",
        },
        {
          name: "FISHBITES",
          icon: "images/sponsors/fishing/fishbites.png",
          svg: "fishbites.svg",
        },
        {
          name: "FISHING HOT SPOTS",
          icon: "images/sponsors/fishing/fishinghotspots.png",
          svg: "fishinghotspots.svg",
        },
        {
          name: "FISHING HOT SPOTS #2",
          icon: "images/sponsors/fishing/fishinghotspots-2.png",
          svg: "fishinghotspots-2.svg",
        },
        {
          name: "FISHPOND",
          icon: "images/sponsors/fishing/fishpond.png",
          svg: "fishpond.svg",
        },
        {
          name: "FISHPOND #2",
          icon: "images/sponsors/fishing/fishpond-2.png",
          svg: "fishpond-2.svg",
        },
        {
          name: "FLIR",
          icon: "images/sponsors/fishing/flir.png",
          svg: "flir.svg",
        },
        {
          name: "FLIR #2",
          icon: "images/sponsors/fishing/flir-2.png",
          svg: "flir-2.svg",
        },
        {
          name: "FLW",
          icon: "images/sponsors/fishing/flw.png",
          svg: "flw.svg",
        },
        {
          name: "FLW #2",
          icon: "images/sponsors/fishing/flw-2.png",
          svg: "flw-2.svg",
        },
        {
          name: "FLW #3",
          icon: "images/sponsors/fishing/flw-3.png",
          svg: "flw-3.svg",
        },
        // {
        // name: "FLW #4",
        // icon: "images/sponsors/fishing/flw-4.png",
        // svg: "flw-4.svg"
        // } ,
        {
          name: "FRABIL",
          icon: "images/sponsors/fishing/frabil.png",
          svg: "frabil.svg",
        },
        {
          name: "FREEDOM",
          icon: "images/sponsors/fishing/freedom.png",
          svg: "freedom.svg",
        },
        {
          name: "FREEDOM #2",
          icon: "images/sponsors/fishing/freedom-2.png",
          svg: "freedom-2.svg",
        },
        {
          name: "FROGG TOGGS",
          icon: "images/sponsors/fishing/froggtoggs.png",
          svg: "froggtoggs.svg",
        },
        {
          name: "FROGG TOGGS #2",
          icon: "images/sponsors/fishing/froggtoggs-2.png",
          svg: "froggtoggs-2.svg",
        },
        {
          name: "G LOOMIS",
          icon: "images/sponsors/fishing/gloomis.png",
          svg: "gloomis.svg",
        },
        {
          name: "G LOOMIS #2",
          icon: "images/sponsors/fishing/gloomis-2.png",
          svg: "gloomis-2.svg",
        },
        {
          name: "G-LOOMIS",
          icon: "images/sponsors/fishing/g-loomis.png",
          svg: "g-loomis.svg",
        },
        {
          name: "G-LOOMIS #2",
          icon: "images/sponsors/fishing/g-loomis-2.png",
          svg: "g-loomis-2.svg",
        },
        {
          name: "G3 BOATS",
          icon: "images/sponsors/fishing/g3boats.png",
          svg: "g3boats.svg",
        },
        {
          name: "G3 BOATS #2",
          icon: "images/sponsors/fishing/g3boats-2.png",
          svg: "g3boats-2.svg",
        },
        {
          name: "GAMAKATSU",
          icon: "images/sponsors/fishing/gamakatsu.png",
          svg: "gamakatsu.svg",
        },
        {
          name: "GAMAKATSU #2",
          icon: "images/sponsors/fishing/gamakatsu-2.png",
          svg: "gamakatsu-2.svg",
        },
        {
          name: "GAMBLER LURES",
          icon: "images/sponsors/fishing/gamblerlures.png",
          svg: "gamblerlures.svg",
        },
        {
          name: "GAMMA",
          icon: "images/sponsors/fishing/gamma.png",
          svg: "gamma.svg",
        },
        {
          name: "GARMIN",
          icon: "images/sponsors/fishing/garmin.png",
          svg: "garmin.svg",
        },
        {
          name: "GARMIN #2",
          icon: "images/sponsors/fishing/garmin-2.png",
          svg: "garmin-2.svg",
        },
        {
          name: "GARY YAMAMOTO",
          icon: "images/sponsors/fishing/garyyamamoto.png",
          svg: "garyyamamoto.svg",
        },
        {
          name: "GARY YAMAMOTO #2",
          icon: "images/sponsors/fishing/garyyamamoto-2.png",
          svg: "garyyamamoto-2.svg",
        },
        {
          name: "GENE LAREW",
          icon: "images/sponsors/fishing/genelarew.png",
          svg: "genelarew.svg",
        },
        {
          name: "GITEM BAITS",
          icon: "images/sponsors/fishing/gitembaits.png",
          svg: "gitembaits.svg",
        },
        {
          name: "GITEM BAITS #2",
          icon: "images/sponsors/fishing/gitembaits-2.png",
          svg: "gitembaits-2.svg",
        },
        {
          name: "GREENFISH TACKLE",
          icon: "images/sponsors/fishing/greenfishtackle.png",
          svg: "greenfishtackle.svg",
        },
        {
          name: "H&H LURES",
          icon: "images/sponsors/fishing/h&hlures.png",
          svg: "h&hlures.svg",
        },
        {
          name: "HANK'S LIVE BAIT",
          icon: "images/sponsors/fishing/hank'slivebait.png",
          svg: "hank'slivebait.svg",
        },
        {
          name: "HANK'S LIVE BAIT #2",
          icon: "images/sponsors/fishing/hank'slivebait-2.png",
          svg: "hank'slivebait-2.svg",
        },
        {
          name: "HEDDON",
          icon: "images/sponsors/fishing/heddon.png",
          svg: "heddon.svg",
        },
        {
          name: "HI SEAS",
          icon: "images/sponsors/fishing/hiseas.png",
          svg: "hiseas.svg",
        },
        {
          name: "HI SEAS #2",
          icon: "images/sponsors/fishing/hiseas-2.png",
          svg: "hiseas-2.svg",
        },
        {
          name: "HOBIE",
          icon: "images/sponsors/fishing/hobie.png",
          svg: "hobie.svg",
        },
        {
          name: "HOGY",
          icon: "images/sponsors/fishing/hogy.png",
          svg: "hogy.svg",
        },
        {
          name: "HOKIE TACKLE",
          icon: "images/sponsors/fishing/hokietackle.png",
          svg: "hokietackle.svg",
        },
        {
          name: "HOKIE TACKLE #2",
          icon: "images/sponsors/fishing/hokietackle-2.png",
          svg: "hokietackle-2.svg",
        },
        {
          name: "HONDA MARINE",
          icon: "images/sponsors/fishing/hondamarine.png",
          svg: "hondamarine.svg",
        },
        {
          name: "HONDA MARINE #2",
          icon: "images/sponsors/fishing/hondamarine-2.png",
          svg: "hondamarine-2.svg",
        },
        {
          name: "HT ENTERPRISES",
          icon: "images/sponsors/fishing/htenterprises.png",
          svg: "htenterprises.svg",
        },
        {
          name: "HT ENTERPRISES #2",
          icon: "images/sponsors/fishing/htenterprises-2.png",
          svg: "htenterprises-2.svg",
        },
        {
          name: "HUMMINBIRD",
          icon: "images/sponsors/fishing/humminbird.png",
          svg: "humminbird.svg",
        },
        {
          name: "HUMMINBIRD #2",
          icon: "images/sponsors/fishing/humminbird-2.png",
          svg: "humminbird-2.svg",
        },
        {
          name: "HUMMINBIRD #3",
          icon: "images/sponsors/fishing/humminbird-3.png",
          svg: "humminbird-3.svg",
        },
        {
          name: "HUMMINBIRD #4",
          icon: "images/sponsors/fishing/humminbird-4.png",
          svg: "humminbird-4.svg",
        },
        {
          name: "HYDRO GLOW",
          icon: "images/sponsors/fishing/hydroglow.png",
          svg: "hydroglow.svg",
        },
        // {
        // name: "HYDROWAVE",
        // icon: "images/sponsors/fishing/hydrowave.png",
        // svg: "hydrowave.svg"
        // } ,
        // {
        // name: "HYDROWAVE #2",
        // icon: "images/sponsors/fishing/hydrowave-2.png",
        // svg: "hydrowave-2.svg"
        // } ,
        {
          name: "ICE FORCE",
          icon: "images/sponsors/fishing/iceforce.png",
          svg: "iceforce.svg",
        },
        {
          name: "ICE FORCE #2",
          icon: "images/sponsors/fishing/iceforce-2.png",
          svg: "iceforce-2.svg",
        },
        {
          name: "IKE-CON",
          icon: "images/sponsors/fishing/ike-con.png",
          svg: "ike-con.svg",
        },
        {
          name: "INTERSTATE BATTERIES",
          icon: "images/sponsors/fishing/interstatebatteries.png",
          svg: "interstatebatteries.svg",
        },
        {
          name: "IROD",
          icon: "images/sponsors/fishing/irod.png",
          svg: "irod.svg",
        },
        {
          name: "JACKALL",
          icon: "images/sponsors/fishing/jackall.png",
          svg: "jackall.svg",
        },
        {
          name: "JACKALL #2",
          icon: "images/sponsors/fishing/jackall-2.png",
          svg: "jackall-2.svg",
        },
        {
          name: "JACKS JUICE",
          icon: "images/sponsors/fishing/jacksjuice.png",
          svg: "jacksjuice.svg",
        },
        {
          name: "JACKS JUICE #2",
          icon: "images/sponsors/fishing/jacksjuice-2.png",
          svg: "jacksjuice-2.svg",
        },
        {
          name: "JAKKED",
          icon: "images/sponsors/fishing/jakked.png",
          svg: "jakked.svg",
        },
        {
          name: "JAKKED #2",
          icon: "images/sponsors/fishing/jakked-2.png",
          svg: "jakked-2.svg",
        },
        {
          name: "JESUS FISH",
          icon: "images/sponsors/fishing/jesusfish.png",
          svg: "jesusfish.svg",
        },
        {
          name: "JESUS FISH #2",
          icon: "images/sponsors/fishing/jesusfish-2.png",
          svg: "jesusfish-2.svg",
        },
        {
          name: "JESUS FISH #3",
          icon: "images/sponsors/fishing/jesusfish-3.png",
          svg: "jesusfish-3.svg",
        },
        {
          name: "JESUS FISH #4",
          icon: "images/sponsors/fishing/jesusfish-4.png",
          svg: "jesusfish-4.svg",
        },
        {
          name: "JIFFY ICE DRILLS",
          icon: "images/sponsors/fishing/jiffyicedrills.png",
          svg: "jiffyicedrills.svg",
        },
        {
          name: "JIFFY ICE DRILLS #2",
          icon: "images/sponsors/fishing/jiffyicedrills-2.png",
          svg: "jiffyicedrills-2.svg",
        },
        {
          name: "JOE BUCHER OUTDOORS",
          icon: "images/sponsors/fishing/joebucheroutdoors.png",
          svg: "joebucheroutdoors.svg",
        },
        {
          name: "JOE BUCHER OUTDOORS #2",
          icon: "images/sponsors/fishing/joebucheroutdoors-2.png",
          svg: "joebucheroutdoors-2.svg",
        },
        {
          name: "JOHNSON",
          icon: "images/sponsors/fishing/johnson.png",
          svg: "johnson.svg",
        },
        {
          name: "KALINS",
          icon: "images/sponsors/fishing/kalins.png",
          svg: "kalins.svg",
        },
        {
          name: "KEEP ALIVE",
          icon: "images/sponsors/fishing/keepalive.png",
          svg: "keepalive.svg",
        },
        {
          name: "KEITECH",
          icon: "images/sponsors/fishing/keitech.png",
          svg: "keitech.svg",
        },
        {
          name: "KEITECH #2",
          icon: "images/sponsors/fishing/keitech-2.png",
          svg: "keitech-2.svg",
        },
        {
          name: "KICKER FISH",
          icon: "images/sponsors/fishing/kickerfish.png",
          svg: "kickerfish.svg",
        },
        {
          name: "KJ'S LURES",
          icon: "images/sponsors/fishing/kj'slures.png",
          svg: "kj'slures.svg",
        },
        {
          name: "KJ'S LURES #2",
          icon: "images/sponsors/fishing/kj'slures-2.png",
          svg: "kj'slures-2.svg",
        },
        {
          name: "LAKE FORK LURES",
          icon: "images/sponsors/fishing/lakeforklures.png",
          svg: "lakeforklures.svg",
        },
        {
          name: "LAKE FORK LURES #2",
          icon: "images/sponsors/fishing/lakeforklures-2.png",
          svg: "lakeforklures-2.svg",
        },
        {
          name: "LAKEMASTER",
          icon: "images/sponsors/fishing/lakemaster.png",
          svg: "lakemaster.svg",
        },
        {
          name: "LAKEMASTER #2",
          icon: "images/sponsors/fishing/lakemaster-2.png",
          svg: "lakemaster-2.svg",
        },
        {
          name: "LAMIGLAS",
          icon: "images/sponsors/fishing/lamiglas.png",
          svg: "lamiglas.svg",
        },
        {
          name: "LAMIGLAS #2",
          icon: "images/sponsors/fishing/lamiglas-2.png",
          svg: "lamiglas-2.svg",
        },
        {
          name: "LBAA",
          icon: "images/sponsors/fishing/lbaa.png",
          svg: "lbaa.svg",
        },
        {
          name: "LBAA #2",
          icon: "images/sponsors/fishing/lbaa-2.png",
          svg: "lbaa-2.svg",
        },
        {
          name: "LEATHERMAN",
          icon: "images/sponsors/fishing/leatherman.png",
          svg: "leatherman.svg",
        },
        {
          name: "Lethal Weapon",
          icon: "images/sponsors/fishing/lethalweapon.png",
          svg: "lethalweapon.svg",
        },
        {
          name: "Lethal Weapon #2",
          icon: "images/sponsors/fishing/lethalweapon-2.png",
          svg: "lethalweapon-2.svg",
        },
        {
          name: "Lethal Weapon #3",
          icon: "images/sponsors/fishing/lethalweapon-3.png",
          svg: "lethalweapon-3.svg",
        },
        {
          name: "LEW'S",
          icon: "images/sponsors/fishing/lew's.png",
          svg: "lew's.svg",
        },
        {
          name: "LIL HUSTLER",
          icon: "images/sponsors/fishing/lilhustler.png",
          svg: "lilhustler.svg",
        },
        {
          name: "LINDY",
          icon: "images/sponsors/fishing/lindy.png",
          svg: "lindy.svg",
        },
        {
          name: "LINDY #2",
          icon: "images/sponsors/fishing/lindy-2.png",
          svg: "lindy-2.svg",
        },
        {
          name: "LIQUID MAYHEM",
          icon: "images/sponsors/fishing/liquidmayhem.png",
          svg: "liquidmayhem.svg",
        },
        {
          name: "LIQUID MAYHEM #2",
          icon: "images/sponsors/fishing/liquidmayhem-2.png",
          svg: "liquidmayhem-2.svg",
        },
        {
          name: "LIVE TARGET",
          icon: "images/sponsors/fishing/livetarget.png",
          svg: "livetarget.svg",
        },
        {
          name: "LIVE TARGET #2",
          icon: "images/sponsors/fishing/livetarget-2.png",
          svg: "livetarget-2.svg",
        },
        {
          name: "LIVE TARGET #3",
          icon: "images/sponsors/fishing/livetarget-3.png",
          svg: "livetarget-3.svg",
        },
        {
          name: "LIVE TARGET #4",
          icon: "images/sponsors/fishing/livetarget-4.png",
          svg: "livetarget-4.svg",
        },
        {
          name: "LIVINGSTON LURES",
          icon: "images/sponsors/fishing/livingstonlures.png",
          svg: "livingstonlures.svg",
        },
        {
          name: "LIVINGSTON LURES #2",
          icon: "images/sponsors/fishing/livingstonlures-2.png",
          svg: "livingstonlures-2.svg",
        },
        {
          name: "LOKE LURES",
          icon: "images/sponsors/fishing/lokelures.png",
          svg: "lokelures.svg",
        },
        {
          name: "LOWRANCE",
          icon: "images/sponsors/fishing/lowrance.png",
          svg: "lowrance.svg",
        },
        {
          name: "LOWRANCE #2",
          icon: "images/sponsors/fishing/lowrance-2.png",
          svg: "lowrance-2.svg",
        },
        {
          name: "LUCK E STRIKE",
          icon: "images/sponsors/fishing/luckestrike.png",
          svg: "luckestrike.svg",
        },
        {
          name: "LUCKY CRAFT",
          icon: "images/sponsors/fishing/luckycraft.png",
          svg: "luckycraft.svg",
        },
        {
          name: "LUND",
          icon: "images/sponsors/fishing/lund.png",
          svg: "lund.svg",
        },
        {
          name: "LUND #2",
          icon: "images/sponsors/fishing/lund-2.png",
          svg: "lund-2.svg",
        },
        {
          name: "LUND #3",
          icon: "images/sponsors/fishing/lund-3.png",
          svg: "lund-3.svg",
        },
        {
          name: "LUNKER CITY",
          icon: "images/sponsors/fishing/lunkercity.png",
          svg: "lunkercity.svg",
        },
        {
          name: "LUNKER LURE",
          icon: "images/sponsors/fishing/lunkerlure.png",
          svg: "lunkerlure.svg",
        },
        {
          name: "LUNKER LURE #2",
          icon: "images/sponsors/fishing/lunkerlure-2.png",
          svg: "lunkerlure-2.svg",
        },
        {
          name: "LUNKERHUNT",
          icon: "images/sponsors/fishing/lunkerhunt.png",
          svg: "lunkerhunt.svg",
        },
        {
          name: "LURE PARTS ONLINE",
          icon: "images/sponsors/fishing/lurepartsonline.png",
          svg: "lurepartsonline.svg",
        },
        {
          name: "LURE PARTS ONLINE #2",
          icon: "images/sponsors/fishing/lurepartsonline-2.png",
          svg: "lurepartsonline-2.svg",
        },
        {
          name: "M-PACK LURES",
          icon: "images/sponsors/fishing/m-packlures.png",
          svg: "m-packlures.svg",
        },
        {
          name: "M-PACK LURES #2",
          icon: "images/sponsors/fishing/m-packlures-2.png",
          svg: "m-packlures-2.svg",
        },
        {
          name: "MACKS LURE",
          icon: "images/sponsors/fishing/mackslure.png",
          svg: "mackslure.svg",
        },
        {
          name: "MACKS LURE #2",
          icon: "images/sponsors/fishing/mackslure-2.png",
          svg: "mackslure-2.svg",
        },
        {
          name: "MAGIC BAIT",
          icon: "images/sponsors/fishing/magicbait.png",
          svg: "magicbait.svg",
        },
        {
          name: "MAGIC BAIT #2",
          icon: "images/sponsors/fishing/magicbait-2.png",
          svg: "magicbait-2.svg",
        },
        {
          name: "MANN'S BAIT COMPANY",
          icon: "images/sponsors/fishing/mann'sbaitcompany.png",
          svg: "mann'sbaitcompany.svg",
        },
        {
          name: "MARCUM",
          icon: "images/sponsors/fishing/marcum.png",
          svg: "marcum.svg",
        },
        {
          name: "MARCUM #2",
          icon: "images/sponsors/fishing/marcum-2.png",
          svg: "marcum-2.svg",
        },
        {
          name: "MARINE METAL PRODUCTS",
          icon: "images/sponsors/fishing/marinemetalproducts.png",
          svg: "marinemetalproducts.svg",
        },
        {
          name: "MATZUO",
          icon: "images/sponsors/fishing/matzuo.png",
          svg: "matzuo.svg",
        },
        {
          name: "MATZUO #2",
          icon: "images/sponsors/fishing/matzuo-2.png",
          svg: "matzuo-2.svg",
        },
        {
          name: "MAXIMA",
          icon: "images/sponsors/fishing/maxima.png",
          svg: "maxima.svg",
        },
        {
          name: "MAXIMA #2",
          icon: "images/sponsors/fishing/maxima-2.png",
          svg: "maxima-2.svg",
        },
        {
          name: "MEGA STRIKE",
          icon: "images/sponsors/fishing/megastrike.png",
          svg: "megastrike.svg",
        },
        {
          name: "MEGABASS",
          icon: "images/sponsors/fishing/megabass.png",
          svg: "megabass.svg",
        },
        {
          name: "MEPPS",
          icon: "images/sponsors/fishing/mepps.png",
          svg: "mepps.svg",
        },
        {
          name: "MERCURY",
          icon: "images/sponsors/fishing/mercury.png",
          svg: "mercury.svg",
        },
        {
          name: "MERCURY #2",
          icon: "images/sponsors/fishing/mercury-2.png",
          svg: "mercury-2.svg",
        },
        {
          name: "MINN KOTA",
          icon: "images/sponsors/fishing/minnkota.png",
          svg: "minnkota.svg",
        },
        {
          name: "MISSILE BAITS",
          icon: "images/sponsors/fishing/missilebaits.png",
          svg: "missilebaits.svg",
        },
        {
          name: "MISSILE BAITS #2",
          icon: "images/sponsors/fishing/missilebaits-2.png",
          svg: "missilebaits-2.svg",
        },
        {
          name: "MISTER TWISTER",
          icon: "images/sponsors/fishing/mistertwister.png",
          svg: "mistertwister.svg",
        },
        {
          name: "MISTER TWISTER #2",
          icon: "images/sponsors/fishing/mistertwister-2.png",
          svg: "mistertwister-2.svg",
        },
        {
          name: "MITCHELL",
          icon: "images/sponsors/fishing/mitchell.png",
          svg: "mitchell.svg",
        },
        {
          name: "MOMOI",
          icon: "images/sponsors/fishing/momoi.png",
          svg: "momoi.svg",
        },
        {
          name: "MOMOI #2",
          icon: "images/sponsors/fishing/momoi-2.png",
          svg: "momoi-2.svg",
        },
        {
          name: "MONSTER FISHING TACKLE",
          icon: "images/sponsors/fishing/monsterfishingtackle.png",
          svg: "monsterfishingtackle.svg",
        },
        {
          name: "MONSTER FISHING TACKLE #2",
          icon: "images/sponsors/fishing/monsterfishingtackle-2.png",
          svg: "monsterfishingtackle-2.svg",
        },
        {
          name: "MOTOR GUIDE",
          icon: "images/sponsors/fishing/motorguide.png",
          svg: "motorguide.svg",
        },
        {
          name: "MOTOR GUIDE #2",
          icon: "images/sponsors/fishing/motorguide-2.png",
          svg: "motorguide-2.svg",
        },
        {
          name: "MOTOR MATE",
          icon: "images/sponsors/fishing/motormate.png",
          svg: "motormate.svg",
        },
        {
          name: "MOTOR MATE #2",
          icon: "images/sponsors/fishing/motormate-2.png",
          svg: "motormate-2.svg",
        },
        {
          name: "MR. CRAPPIE",
          icon: "images/sponsors/fishing/mr.crappie.png",
          svg: "mr.crappie.svg",
        },
        {
          name: "MR. CRAPPIE #2",
          icon: "images/sponsors/fishing/mr.crappie-2.png",
          svg: "mr.crappie-2.svg",
        },
        {
          name: "RPM BOWFISHING",
          icon: "images/sponsors/fishing/rpmbowfishing.png",
          svg: "rpmbowfishing.svg",
        },
        {
          name: "RPM BOWFISHING #2",
          icon: "images/sponsors/fishing/rpmbowfishing-2.png",
          svg: "rpmbowfishing-2.svg",
        },
        {
          name: "MSBC",
          icon: "images/sponsors/fishing/msbc.png",
          svg: "msbc.svg",
        },
        {
          name: "MUSKY MANIA",
          icon: "images/sponsors/fishing/muskymania.png",
          svg: "muskymania.svg",
        },
        {
          name: "MUSKY MANIA #2",
          icon: "images/sponsors/fishing/muskymania-2.png",
          svg: "muskymania-2.svg",
        },
        {
          name: "MUSTAD",
          icon: "images/sponsors/fishing/mustad.png",
          svg: "mustad.svg",
        },
        {
          name: "MUSTAD #2",
          icon: "images/sponsors/fishing/mustad-2.png",
          svg: "mustad-2.svg",
        },
        {
          name: "NATIONAL GUARD",
          icon: "images/sponsors/fishing/nationalguard.png",
          svg: "nationalguard.svg",
        },
        {
          name: "NAVIONICS",
          icon: "images/sponsors/fishing/navionics.png",
          svg: "navionics.svg",
        },
        {
          name: "NAVIONICS #2",
          icon: "images/sponsors/fishing/navionics-2.png",
          svg: "navionics-2.svg",
        },
        {
          name: "NET BAIT",
          icon: "images/sponsors/fishing/netbait.png",
          svg: "netbait.svg",
        },
        {
          name: "NET BAIT #2",
          icon: "images/sponsors/fishing/netbait-2.png",
          svg: "netbait-2.svg",
        },
        {
          name: "NET BAIT #3",
          icon: "images/sponsors/fishing/netbait-3.png",
          svg: "netbait-3.svg",
        },
        {
          name: "NICHOLS LURES",
          icon: "images/sponsors/fishing/nicholslures.png",
          svg: "nicholslures.svg",
        },
        {
          name: "NICHOLS LURES #2",
          icon: "images/sponsors/fishing/nicholslures-2.png",
          svg: "nicholslures-2.svg",
        },
        {
          name: "NITRO",
          icon: "images/sponsors/fishing/nitro.png",
          svg: "nitro.svg",
        },
        {
          name: "NITRO #2",
          icon: "images/sponsors/fishing/nitro-2.png",
          svg: "nitro-2.svg",
        },
        {
          name: "NITRO #3",
          icon: "images/sponsors/fishing/nitro-3.png",
          svg: "nitro-3.svg",
        },
        {
          name: "NORMAN LURES",
          icon: "images/sponsors/fishing/normanlures.png",
          svg: "normanlures.svg",
        },
        {
          name: "NORMAN LURES #2",
          icon: "images/sponsors/fishing/normanlures-2.png",
          svg: "normanlures-2.svg",
        },
        {
          name: "OKUMA",
          icon: "images/sponsors/fishing/okuma.png",
          svg: "okuma.svg",
        },
        {
          name: "OLD RIVER LURE",
          icon: "images/sponsors/fishing/oldriverlure.png",
          svg: "oldriverlure.svg",
        },
        // {
        // name: "OMEGA CUSTOM TACKLE",
        // icon: "images/sponsors/fishing/omegacustomtackle.png",
        // svg: "omegacustomtackle.svg"
        // } ,
        {
          name: "OPTIMA BATTERIES",
          icon: "images/sponsors/fishing/optimabatteries.png",
          svg: "optimabatteries.svg",
        },
        {
          name: "OPTIMUM BAITS",
          icon: "images/sponsors/fishing/optimumbaits.png",
          svg: "optimumbaits.svg",
        },
        {
          name: "OPTIMUM BAITS #2",
          icon: "images/sponsors/fishing/optimumbaits-2.png",
          svg: "optimumbaits-2.svg",
        },
        {
          name: "ORCA COOLERS",
          icon: "images/sponsors/fishing/orcacoolers.png",
          svg: "orcacoolers.svg",
        },
        {
          name: "ORCA COOLERS #2",
          icon: "images/sponsors/fishing/orcacoolers-2.png",
          svg: "orcacoolers-2.svg",
        },
        {
          name: "OTTER OUTDOORS",
          icon: "images/sponsors/fishing/otteroutdoors.png",
          svg: "otteroutdoors.svg",
        },
        {
          name: "OUTKAST TACKLE",
          icon: "images/sponsors/fishing/outkasttackle.png",
          svg: "outkasttackle.svg",
        },
        {
          name: "OUTKAST TACKLE #2",
          icon: "images/sponsors/fishing/outkasttackle-2.png",
          svg: "outkasttackle-2.svg",
        },
        {
          name: "OWNER HOOKS",
          icon: "images/sponsors/fishing/ownerhooks.png",
          svg: "ownerhooks.svg",
        },
        {
          name: "OWNER HOOKS #2",
          icon: "images/sponsors/fishing/ownerhooks-2.png",
          svg: "ownerhooks-2.svg",
        },
        {
          name: "OXYGENATOR",
          icon: "images/sponsors/fishing/oxygenator.png",
          svg: "oxygenator.svg",
        },
        {
          name: "P-LINE",
          icon: "images/sponsors/fishing/p-line.png",
          svg: "p-line.svg",
        },
        {
          name: "PAA",
          icon: "images/sponsors/fishing/paa.png",
          svg: "paa.svg",
        },
        {
          name: "PENN",
          icon: "images/sponsors/fishing/penn.png",
          svg: "penn.svg",
        },
        {
          name: "PENN #2",
          icon: "images/sponsors/fishing/penn-2.png",
          svg: "penn-2.svg",
        },
        {
          name: "PEPPER CUSTOM BAITS",
          icon: "images/sponsors/fishing/peppercustombaits.png",
          svg: "peppercustombaits.svg",
        },
        {
          name: "PFLUEGER",
          icon: "images/sponsors/fishing/pflueger.png",
          svg: "pflueger.svg",
        },
        {
          name: "PFLUEGER #2",
          icon: "images/sponsors/fishing/pflueger-2.png",
          svg: "pflueger-2.svg",
        },
        {
          name: "PFLUEGER #3",
          icon: "images/sponsors/fishing/pflueger-3.png",
          svg: "pflueger-3.svg",
        },
        {
          name: "PFLUEGER #4",
          icon: "images/sponsors/fishing/pflueger-4.png",
          svg: "pflueger-4.svg",
        },
        {
          name: "PHANTOM",
          icon: "images/sponsors/fishing/phantom.png",
          svg: "phantom.svg",
        },
        {
          name: "PINNACLE FISHING",
          icon: "images/sponsors/fishing/pinnaclefishing.png",
          svg: "pinnaclefishing.svg",
        },
        {
          name: "PINNACLE FISHING #2",
          icon: "images/sponsors/fishing/pinnaclefishing-2.png",
          svg: "pinnaclefishing-2.svg",
        },
        {
          name: "PIRANA FISHING LURES",
          icon: "images/sponsors/fishing/piranafishinglures.png",
          svg: "piranafishinglures.svg",
        },
        {
          name: "PLANO",
          icon: "images/sponsors/fishing/plano.png",
          svg: "plano.svg",
        },
        {
          name: "PLANO #2",
          icon: "images/sponsors/fishing/plano-2.png",
          svg: "plano-2.svg",
        },
        {
          name: "POWER POLE",
          icon: "images/sponsors/fishing/powerpole.png",
          svg: "powerpole.svg",
        },
        {
          name: "POWER POLE #2",
          icon: "images/sponsors/fishing/powerpole-2.png",
          svg: "powerpole-2.svg",
        },
        {
          name: "POWER POLE #3",
          icon: "images/sponsors/fishing/powerpole-3.png",
          svg: "powerpole-3.svg",
        },
        {
          name: "POWER POLE #4",
          icon: "images/sponsors/fishing/powerpole-4.png",
          svg: "powerpole-4.svg",
        },
        {
          name: "POWER PRO",
          icon: "images/sponsors/fishing/powerpro.png",
          svg: "powerpro.svg",
        },
        {
          name: "POWER PRO #2",
          icon: "images/sponsors/fishing/powerpro-2.png",
          svg: "powerpro-2.svg",
        },
        {
          name: "POWER TEAM LURES",
          icon: "images/sponsors/fishing/powerteamlures.png",
          svg: "powerteamlures.svg",
        },
        {
          name: "PRO-CURE",
          icon: "images/sponsors/fishing/pro-cure.png",
          svg: "pro-cure.svg",
        },
        {
          name: "PRO-CURE #2",
          icon: "images/sponsors/fishing/pro-cure-2.png",
          svg: "pro-cure-2.svg",
        },
        {
          name: "PROMAR",
          icon: "images/sponsors/fishing/promar.png",
          svg: "promar.svg",
        },
        {
          name: "PROMAR #2",
          icon: "images/sponsors/fishing/promar-2.png",
          svg: "promar-2.svg",
        },
        {
          name: "PULSAR",
          icon: "images/sponsors/fishing/pulsar.png",
          svg: "pulsar.svg",
        },
        {
          name: "PULSAR #2",
          icon: "images/sponsors/fishing/pulsar-2.png",
          svg: "pulsar-2.svg",
        },
        {
          name: "PURE FISHING",
          icon: "images/sponsors/fishing/purefishing.png",
          svg: "purefishing.svg",
        },
        {
          name: "QUANTUM",
          icon: "images/sponsors/fishing/quantum.png",
          svg: "quantum.svg",
        },
        {
          name: "QUANTUM #2",
          icon: "images/sponsors/fishing/quantum-2.png",
          svg: "quantum-2.svg",
        },
        {
          name: "RAGE TAIL",
          icon: "images/sponsors/fishing/ragetail.png",
          svg: "ragetail.svg",
        },
        {
          name: "RANGER BOATS",
          icon: "images/sponsors/fishing/rangerboats.png",
          svg: "rangerboats.svg",
        },
        {
          name: "RANGER CUP",
          icon: "images/sponsors/fishing/rangercup.png",
          svg: "rangercup.svg",
        },
        {
          name: "RAPALA",
          icon: "images/sponsors/fishing/rapala.png",
          svg: "rapala.svg",
        },
        {
          name: "RAPALA #2",
          icon: "images/sponsors/fishing/rapala-2.png",
          svg: "rapala-2.svg",
        },
        {
          name: "RAT-L-TRAP",
          icon: "images/sponsors/fishing/rat-l-trap.png",
          svg: "rat-l-trap.svg",
        },
        {
          name: "RAT-L-TRAP #2",
          icon: "images/sponsors/fishing/rat-l-trap-2.png",
          svg: "rat-l-trap-2.svg",
        },
        {
          name: "RAYMARINE",
          icon: "images/sponsors/fishing/raymarine.png",
          svg: "raymarine.svg",
        },
        {
          name: "RAYMARINE #2",
          icon: "images/sponsors/fishing/raymarine-2.png",
          svg: "raymarine-2.svg",
        },
        {
          name: "REACTION INNOVATIONS",
          icon: "images/sponsors/fishing/reactioninnovations.png",
          svg: "reactioninnovations.svg",
        },
        {
          name: "Readi-Lures",
          icon: "images/sponsors/fishing/readi-lures.png",
          svg: "readi-lures.svg",
        },
        {
          name: "Realis",
          icon: "images/sponsors/fishing/realis.png",
          svg: "realis.svg",
        },
        {
          name: "REALIS #2",
          icon: "images/sponsors/fishing/realis-2.png",
          svg: "realis-2.svg",
        },
        {
          name: "REBEL",
          icon: "images/sponsors/fishing/rebel.png",
          svg: "rebel.svg",
        },
        {
          name: "REBEL #2",
          icon: "images/sponsors/fishing/rebel-2.png",
          svg: "rebel-2.svg",
        },
        {
          name: "REDINGTON",
          icon: "images/sponsors/fishing/redington.png",
          svg: "redington.svg",
        },
        {
          name: "REDINGTON #2",
          icon: "images/sponsors/fishing/redington-2.png",
          svg: "redington-2.svg",
        },
        {
          name: "REEF RUNNER",
          icon: "images/sponsors/fishing/reefrunner.png",
          svg: "reefrunner.svg",
        },
        {
          name: "REEF RUNNER #2",
          icon: "images/sponsors/fishing/reefrunner-2.png",
          svg: "reefrunner-2.svg",
        },
        {
          name: "REEL BAIT",
          icon: "images/sponsors/fishing/reelbait.png",
          svg: "reelbait.svg",
        },
        {
          name: "RELIABLE FISHING PRODUCTS",
          icon: "images/sponsors/fishing/reliablefishingproducts.png",
          svg: "reliablefishingproducts.svg",
        },
        {
          name: "RIVER 2 SEA",
          icon: "images/sponsors/fishing/river2sea.png",
          svg: "river2sea.svg",
        },
        {
          name: "RIVER 2 SEA #2",
          icon: "images/sponsors/fishing/river2sea-2.png",
          svg: "river2sea-2.svg",
        },
        {
          name: "ROBOWORM",
          icon: "images/sponsors/fishing/roboworm.png",
          svg: "roboworm.svg",
        },
        {
          name: "ROD GLOVE",
          icon: "images/sponsors/fishing/rodglove.png",
          svg: "rodglove.svg",
        },
        {
          name: "ROD GLOVE #2",
          icon: "images/sponsors/fishing/rodglove-2.png",
          svg: "rodglove-2.svg",
        },
        {
          name: "ROD SOX",
          icon: "images/sponsors/fishing/rodsox.png",
          svg: "rodsox.svg",
        },
        {
          name: "ROD SOX #2",
          icon: "images/sponsors/fishing/rodsox-2.png",
          svg: "rodsox-2.svg",
        },
        {
          name: "ROD WRAP",
          icon: "images/sponsors/fishing/rodwrap.png",
          svg: "rodwrap.svg",
        },
        {
          name: "SALMO",
          icon: "images/sponsors/fishing/salmo.png",
          svg: "salmo.svg",
        },
        {
          name: "SCUM FROG",
          icon: "images/sponsors/fishing/scumfrog.png",
          svg: "scumfrog.svg",
        },
        {
          name: "SEA ARK BOATS",
          icon: "images/sponsors/fishing/seaarkboats.png",
          svg: "seaarkboats.svg",
        },
        {
          name: "SEA ARK BOATS #2",
          icon: "images/sponsors/fishing/seaarkboats-2.png",
          svg: "seaarkboats-2.svg",
        },
        {
          name: "SEA STRIKER",
          icon: "images/sponsors/fishing/seastriker.png",
          svg: "seastriker.svg",
        },
        {
          name: "SEA STRIKER #2",
          icon: "images/sponsors/fishing/seastriker-2.png",
          svg: "seastriker-2.svg",
        },
        {
          name: "SEA TO SUMMIT",
          icon: "images/sponsors/fishing/seatosummit.png",
          svg: "seatosummit.svg",
        },
        {
          name: "SEA TO SUMMIT #2",
          icon: "images/sponsors/fishing/seatosummit-2.png",
          svg: "seatosummit-2.svg",
        },
        {
          name: "SEAGUAR",
          icon: "images/sponsors/fishing/seaguar.png",
          svg: "seaguar.svg",
        },
        {
          name: "SEAGUAR #2",
          icon: "images/sponsors/fishing/seaguar-2.png",
          svg: "seaguar-2.svg",
        },
        {
          name: "SEBILE",
          icon: "images/sponsors/fishing/sebile.png",
          svg: "sebile.svg",
        },
        {
          name: "SEBILE #2",
          icon: "images/sponsors/fishing/sebile-2.png",
          svg: "sebile-2.svg",
        },
        {
          name: "SHAKESPEAR",
          icon: "images/sponsors/fishing/shakespear.png",
          svg: "shakespear.svg",
        },
        {
          name: "SHAKESPEAR #2",
          icon: "images/sponsors/fishing/shakespear-2.png",
          svg: "shakespear-2.svg",
        },
        {
          name: "SHIMANO",
          icon: "images/sponsors/fishing/shimano.png",
          svg: "shimano.svg",
        },
        {
          name: "SHIMANO #2",
          icon: "images/sponsors/fishing/shimano-2.png",
          svg: "shimano-2.svg",
        },
        {
          name: "SILVER HORDE",
          icon: "images/sponsors/fishing/silverhorde.png",
          svg: "silverhorde.svg",
        },
        {
          name: "SIMMS",
          icon: "images/sponsors/fishing/simms.png",
          svg: "simms.svg",
        },
        {
          name: "SIMMS #2",
          icon: "images/sponsors/fishing/simms-2.png",
          svg: "simms-2.svg",
        },
        {
          name: "SIMRAD",
          icon: "images/sponsors/fishing/simrad.png",
          svg: "simrad.svg",
        },
        {
          name: "SIMRAD #2",
          icon: "images/sponsors/fishing/simrad-2.png",
          svg: "simrad-2.svg",
        },
        {
          name: "SKEET REESE",
          icon: "images/sponsors/fishing/skeetreese.png",
          svg: "skeetreese.svg",
        },
        {
          name: "SKEETER",
          icon: "images/sponsors/fishing/skeeter.png",
          svg: "skeeter.svg",
        },
        {
          name: "SKEETER #2",
          icon: "images/sponsors/fishing/skeeter-2.png",
          svg: "skeeter-2.svg",
        },
        {
          name: "SKEETER #3",
          icon: "images/sponsors/fishing/skeeter-3.png",
          svg: "skeeter-3.svg",
        },
        {
          name: "SKEETER #4",
          icon: "images/sponsors/fishing/skeeter-4.png",
          svg: "skeeter-4.svg",
        },
        {
          name: "SKEETER #5",
          icon: "images/sponsors/fishing/skeeter-5.png",
          svg: "skeeter-5.svg",
        },
        {
          name: "SKEETER #6",
          icon: "images/sponsors/fishing/skeeter-6.png",
          svg: "skeeter-6.svg",
        },
        {
          name: "SKEETER #7",
          icon: "images/sponsors/fishing/skeeter-7.png",
          svg: "skeeter-7.svg",
        },
        {
          name: "SLIDER FISHING",
          icon: "images/sponsors/fishing/sliderfishing.png",
          svg: "sliderfishing.svg",
        },
        {
          name: "SMELLY JELLY",
          icon: "images/sponsors/fishing/smellyjelly.png",
          svg: "smellyjelly.svg",
        },
        {
          name: "SMELLY JELLY #2",
          icon: "images/sponsors/fishing/smellyjelly-2.png",
          svg: "smellyjelly-2.svg",
        },
        {
          name: "SNAG PROOF",
          icon: "images/sponsors/fishing/snagproof.png",
          svg: "snagproof.svg",
        },
        {
          name: "SPIDER WIRE",
          icon: "images/sponsors/fishing/spiderwire.png",
          svg: "spiderwire.svg",
        },
        {
          name: "SPIDER WIRE #2",
          icon: "images/sponsors/fishing/spiderwire-2.png",
          svg: "spiderwire-2.svg",
        },
        {
          name: "SPIKE-IT",
          icon: "images/sponsors/fishing/spike-it.png",
          svg: "spike-it.svg",
        },
        {
          name: "SPINTECH HOOKS",
          icon: "images/sponsors/fishing/spintechhooks.png",
          svg: "spintechhooks.svg",
        },
        {
          name: "SPRO",
          icon: "images/sponsors/fishing/spro.png",
          svg: "spro.svg",
        },
        {
          name: "SPRO #2",
          icon: "images/sponsors/fishing/spro-2.png",
          svg: "spro-2.svg",
        },
        {
          name: "SPRO #3",
          icon: "images/sponsors/fishing/spro-3.png",
          svg: "spro-3.svg",
        },
        {
          name: "ST. CROIX",
          icon: "images/sponsors/fishing/st.croix.png",
          svg: "st.croix.svg",
        },
        {
          name: "STANLEY",
          icon: "images/sponsors/fishing/stanley.png",
          svg: "stanley.svg",
        },
        {
          name: "STANLEY #2",
          icon: "images/sponsors/fishing/stanley-2.png",
          svg: "stanley-2.svg",
        },
        {
          name: "STIHL",
          icon: "images/sponsors/fishing/stihl.png",
          svg: "stihl.svg",
        },
        {
          name: "STIHL #2",
          icon: "images/sponsors/fishing/stihl-2.png",
          svg: "stihl-2.svg",
        },
        {
          name: "STORM",
          icon: "images/sponsors/fishing/storm.png",
          svg: "storm.svg",
        },
        {
          name: "STORM #2",
          icon: "images/sponsors/fishing/storm-2.png",
          svg: "storm-2.svg",
        },
        // {
        // name: "STRATOS BOATS",
        // icon: "images/sponsors/fishing/stratosboats.png",
        // svg: "stratosboats.svg"
        // } ,
        {
          name: "STREN",
          icon: "images/sponsors/fishing/stren.png",
          svg: "stren.svg",
        },
        {
          name: "STRIKE KING",
          icon: "images/sponsors/fishing/strikeking.png",
          svg: "strikeking.svg",
        },
        {
          name: "STRIKE MASTER",
          icon: "images/sponsors/fishing/strikemaster.png",
          svg: "strikemaster.svg",
        },
        {
          name: "STUMP JUMPER",
          icon: "images/sponsors/fishing/stumpjumper.png",
          svg: "stumpjumper.svg",
        },
        {
          name: "SUFIX",
          icon: "images/sponsors/fishing/sufix.png",
          svg: "sufix.svg",
        },
        {
          name: "SUFIX #2",
          icon: "images/sponsors/fishing/sufix-2.png",
          svg: "sufix-2.svg",
        },
        {
          name: "SUFIX #3",
          icon: "images/sponsors/fishing/sufix-3.png",
          svg: "sufix-3.svg",
        },
        {
          name: "SUNLINE",
          icon: "images/sponsors/fishing/sunline.png",
          svg: "sunline.svg",
        },
        {
          name: "SUNLINE #2",
          icon: "images/sponsors/fishing/sunline-2.png",
          svg: "sunline-2.svg",
        },
        {
          name: "SUNLINE #3",
          icon: "images/sponsors/fishing/sunline-3.png",
          svg: "sunline-3.svg",
        },
        {
          name: "SUNLINE #4",
          icon: "images/sponsors/fishing/sunline-4.png",
          svg: "sunline-4.svg",
        },
        {
          name: "SUZUKI MARINE",
          icon: "images/sponsors/fishing/suzukimarine.png",
          svg: "suzukimarine.svg",
        },
        {
          name: "SUZUKI MARINE #2",
          icon: "images/sponsors/fishing/suzukimarine-2.png",
          svg: "suzukimarine-2.svg",
        },
        {
          name: "TACKLE ADDICT",
          icon: "images/sponsors/fishing/tackleaddict.png",
          svg: "tackleaddict.svg",
        },
        {
          name: "TACKLE CENTER",
          icon: "images/sponsors/fishing/tacklecenter.png",
          svg: "tacklecenter.svg",
        },
        {
          name: "TACKLE WAREHOUSE",
          icon: "images/sponsors/fishing/tacklewarehouse.png",
          svg: "tacklewarehouse.svg",
        },
        {
          name: "TACKLE WAREHOUSE #2",
          icon: "images/sponsors/fishing/tacklewarehouse-2.png",
          svg: "tacklewarehouse-2.svg",
        },
        {
          name: "TALON SHALLOW WATER ANCHOR",
          icon: "images/sponsors/fishing/talonshallowwateranchor.png",
          svg: "talonshallowwateranchor.svg",
        },
        {
          name: "TALON SHALLOW WATER ANCHOR #2",
          icon: "images/sponsors/fishing/talonshallowwateranchor-2.png",
          svg: "talonshallowwateranchor-2.svg",
        },
        {
          name: "TEAM CATFISH",
          icon: "images/sponsors/fishing/teamcatfish.png",
          svg: "teamcatfish.svg",
        },
        {
          name: "TEAM KISTLER",
          icon: "images/sponsors/fishing/teamkistler.png",
          svg: "teamkistler.svg",
        },
        {
          name: "TERMINATOR LURES",
          icon: "images/sponsors/fishing/terminatorlures.png",
          svg: "terminatorlures.svg",
        },
        {
          name: "TFO RODS",
          icon: "images/sponsors/fishing/tforods.png",
          svg: "tforods.svg",
        },
        {
          name: "TFO RODS #2",
          icon: "images/sponsors/fishing/tforods-2.png",
          svg: "tforods-2.svg",
        },
        {
          name: "TH MARINE",
          icon: "images/sponsors/fishing/thmarine.png",
          svg: "thmarine.svg",
        },
        {
          name: "TH MARINE #2",
          icon: "images/sponsors/fishing/thmarine-2.png",
          svg: "thmarine-2.svg",
        },
        {
          name: "TIGHTLINES UV",
          icon: "images/sponsors/fishing/tightlinesuv.png",
          svg: "tightlinesuv.svg",
        },
        {
          name: "TIGHTLINES UV #2",
          icon: "images/sponsors/fishing/tightlinesuv-2.png",
          svg: "tightlinesuv-2.svg",
        },
        {
          name: "TITE LOK",
          icon: "images/sponsors/fishing/titelok.png",
          svg: "titelok.svg",
        },
        {
          name: "TITE LOK #2",
          icon: "images/sponsors/fishing/titelok-2.png",
          svg: "titelok-2.svg",
        },
        {
          name: "TOP BRASS",
          icon: "images/sponsors/fishing/topbrass.png",
          svg: "topbrass.svg",
        },
        {
          name: "TOYOTA BONUS BUCKS FISHING",
          icon: "images/sponsors/fishing/toyotabonusbucksfishing.png",
          svg: "toyotabonusbucksfishing.svg",
        },
        {
          name: "TOYOTA BONUS BUCKS FISHING #2",
          icon: "images/sponsors/fishing/toyotabonusbucksfishing-2.png",
          svg: "toyotabonusbucksfishing-2.svg",
        },
        {
          name: "TRACKER BOATS",
          icon: "images/sponsors/fishing/trackerboats.png",
          svg: "trackerboats.svg",
        },
        {
          name: "TRACKER BOATS #2",
          icon: "images/sponsors/fishing/trackerboats-2.png",
          svg: "trackerboats-2.svg",
        },
        {
          name: "TRITON BOATS",
          icon: "images/sponsors/fishing/tritonboats.png",
          svg: "tritonboats.svg",
        },
        {
          name: "TRITON BOATS #2",
          icon: "images/sponsors/fishing/tritonboats-2.png",
          svg: "tritonboats-2.svg",
        },
        {
          name: "TRITON BOATS #3",
          icon: "images/sponsors/fishing/tritonboats-3.png",
          svg: "tritonboats-3.svg",
        },
        {
          name: "TRU TURN",
          icon: "images/sponsors/fishing/truturn.png",
          svg: "truturn.svg",
        },
        {
          name: "TRU TURN #2",
          icon: "images/sponsors/fishing/truturn-2.png",
          svg: "truturn-2.svg",
        },
        {
          name: "TRU-TUNGSTEN",
          icon: "images/sponsors/fishing/tru-tungsten.png",
          svg: "tru-tungsten.svg",
        },
        {
          name: "TRU-TUNGSTEN #2",
          icon: "images/sponsors/fishing/tru-tungsten-2.png",
          svg: "tru-tungsten-2.svg",
        },
        {
          name: "TTI BLAKEMORE",
          icon: "images/sponsors/fishing/ttiblakemore.png",
          svg: "ttiblakemore.svg",
        },
        {
          name: "TUF-LINE",
          icon: "images/sponsors/fishing/tuf-line.png",
          svg: "tuf-line.svg",
        },
        {
          name: "TUF-LINE #2",
          icon: "images/sponsors/fishing/tuf-line-2.png",
          svg: "tuf-line-2.svg",
        },
        {
          name: "TWF",
          icon: "images/sponsors/fishing/twf.png",
          svg: "twf.svg",
        },
        {
          name: "TWF #2",
          icon: "images/sponsors/fishing/twf-2.png",
          svg: "twf-2.svg",
        },
        {
          name: "TYRANT",
          icon: "images/sponsors/fishing/tyrant.png",
          svg: "tyrant.svg",
        },
        {
          name: "U2 LIVEWELL TREATMENT",
          icon: "images/sponsors/fishing/u2livewelltreatment.png",
          svg: "u2livewelltreatment.svg",
        },
        {
          name: "U2 LIVEWELL TREATMENT #2",
          icon: "images/sponsors/fishing/u2livewelltreatment-2.png",
          svg: "u2livewelltreatment-2.svg",
        },
        {
          name: "UGLY STIK",
          icon: "images/sponsors/fishing/uglystik.png",
          svg: "uglystik.svg",
        },
        {
          name: "UGLY STIK #2",
          icon: "images/sponsors/fishing/uglystik-2.png",
          svg: "uglystik-2.svg",
        },
        {
          name: "UGLY STIK #3",
          icon: "images/sponsors/fishing/uglystik-3.png",
          svg: "uglystik-3.svg",
        },
        {
          name: "UGLY STIK #4",
          icon: "images/sponsors/fishing/uglystik-4.png",
          svg: "uglystik-4.svg",
        },
        {
          name: "UNCLE JOSH",
          icon: "images/sponsors/fishing/unclejosh.png",
          svg: "unclejosh.svg",
        },
        {
          name: "V&M BAIT",
          icon: "images/sponsors/fishing/v&mbait.png",
          svg: "v&mbait.svg",
        },
        {
          name: "VENOM LURES",
          icon: "images/sponsors/fishing/venomlures.png",
          svg: "venomlures.svg",
        },
        {
          name: "VENOM LURES #2",
          icon: "images/sponsors/fishing/venomlures-2.png",
          svg: "venomlures-2.svg",
        },
        {
          name: "VENOM LURES #3",
          icon: "images/sponsors/fishing/venomlures-3.png",
          svg: "venomlures-3.svg",
        },
        {
          name: "VEXILAR",
          icon: "images/sponsors/fishing/vexilar.png",
          svg: "vexilar.svg",
        },
        {
          name: "VEXILAR #2",
          icon: "images/sponsors/fishing/vexilar-2.png",
          svg: "vexilar-2.svg",
        },
        {
          name: "VICIOUS FISHING",
          icon: "images/sponsors/fishing/viciousfishing.png",
          svg: "viciousfishing.svg",
        },
        {
          name: "VMC",
          icon: "images/sponsors/fishing/vmc.png",
          svg: "vmc.svg",
        },
        {
          name: "WALMART BASS FISHING LEAGUE",
          icon: "images/sponsors/fishing/walmartbassfishingleague.png",
          svg: "walmartbassfishingleague.svg",
        },
        {
          name: "WALMART BASS FISHING LEAGUE #2",
          icon: "images/sponsors/fishing/walmartbassfishingleague-2.png",
          svg: "walmartbassfishingleague-2.svg",
        },
        {
          name: "WALMART BASS FISHING LEAGUE #3",
          icon: "images/sponsors/fishing/walmartbassfishingleague-3.png",
          svg: "walmartbassfishingleague-3.svg",
        },
        {
          name: "WALMART BASS FISHING LEAGUE #4",
          icon: "images/sponsors/fishing/walmartbassfishingleague-4.png",
          svg: "walmartbassfishingleague-4.svg",
        },
        {
          name: "WAR EAGLE LURES",
          icon: "images/sponsors/fishing/wareaglelures.png",
          svg: "wareaglelures.svg",
        },
        {
          name: "WAR EAGLE LURES #2",
          icon: "images/sponsors/fishing/wareaglelures-2.png",
          svg: "wareaglelures-2.svg",
        },
        {
          name: "WAVE FISHING",
          icon: "images/sponsors/fishing/wavefishing.png",
          svg: "wavefishing.svg",
        },
        {
          name: "WILD RIVER",
          icon: "images/sponsors/fishing/wildriver.png",
          svg: "wildriver.svg",
        },
        {
          name: "WILD RIVER #2",
          icon: "images/sponsors/fishing/wildriver-2.png",
          svg: "wildriver-2.svg",
        },
        {
          name: "WILEY X EYEWEAR",
          icon: "images/sponsors/fishing/wileyxeyewear.png",
          svg: "wileyxeyewear.svg",
        },
        {
          name: "WILLIAMSON LURES",
          icon: "images/sponsors/fishing/williamsonlures.png",
          svg: "williamsonlures.svg",
        },
        {
          name: "WING-IT",
          icon: "images/sponsors/fishing/wing-it.png",
          svg: "wing-it.svg",
        },
        {
          name: "WOLVERINE TACKLE",
          icon: "images/sponsors/fishing/wolverinetackle.png",
          svg: "wolverinetackle.svg",
        },
        {
          name: "WORDEN'S LURES",
          icon: "images/sponsors/fishing/worden'slures.png",
          svg: "worden'slures.svg",
        },
        {
          name: "WRIGHT & MCGILL",
          icon: "images/sponsors/fishing/wright&mcgill.png",
          svg: "wright&mcgill.svg",
        },
        {
          name: "WWA WORLD WALLEYE",
          icon: "images/sponsors/fishing/wwaworldwalleye.png",
          svg: "wwaworldwalleye.svg",
        },
        // {
        // name: "XCITE BAITS",
        // icon: "images/sponsors/fishing/xcitebaits.png",
        // svg: "xcitebaits.svg"
        // } ,
        {
          name: "YAMAHA",
          icon: "images/sponsors/fishing/yamaha.png",
          svg: "yamaha.svg",
        },
        {
          name: "YAMAHA #2",
          icon: "images/sponsors/fishing/yamaha-2.png",
          svg: "yamaha-2.svg",
        },
        {
          name: "YAMAHA PRO FISHING",
          icon: "images/sponsors/fishing/yamahaprofishing.png",
          svg: "yamahaprofishing.svg",
        },
        {
          name: "YAMAHA PRO FISHING #2",
          icon: "images/sponsors/fishing/yamahaprofishing-2.png",
          svg: "yamahaprofishing-2.svg",
        },
        {
          name: "YAMALUBE",
          icon: "images/sponsors/fishing/yamalube.png",
          svg: "yamalube.svg",
        },
        {
          name: "YAMALUBE #2",
          icon: "images/sponsors/fishing/yamalube-2.png",
          svg: "yamalube-2.svg",
        },
        {
          name: "YETI",
          icon: "images/sponsors/fishing/yeti.png",
          svg: "yeti.svg",
        },
        {
          name: "YETI #2",
          icon: "images/sponsors/fishing/yeti-2.png",
          svg: "yeti-2.svg",
        },
        {
          name: "YO-ZURI",
          icon: "images/sponsors/fishing/yo-zuri.png",
          svg: "yo-zuri.svg",
        },
        {
          name: "YUM",
          icon: "images/sponsors/fishing/yum.png",
          svg: "yum.svg",
        },
        {
          name: "Z MAN",
          icon: "images/sponsors/fishing/zman.png",
          svg: "zman.svg",
        },
        {
          name: "ZEBCO",
          icon: "images/sponsors/fishing/zebco.png",
          svg: "zebco.svg",
        },
        {
          name: "ZEBCO #2",
          icon: "images/sponsors/fishing/zebco-2.png",
          svg: "zebco-2.svg",
        },
        {
          name: "ZEBCO #3",
          icon: "images/sponsors/fishing/zebco-3.png",
          svg: "zebco-3.svg",
        },
        {
          name: "ZOOM BAIT",
          icon: "images/sponsors/fishing/zoombait.png",
          svg: "zoombait.svg",
        },
        {
          name: "ZOOM BAIT #2",
          icon: "images/sponsors/fishing/zoombait-2.png",
          svg: "zoombait-2.svg",
        },
        {
          name: "AIRRUS RODS",
          icon: "images/sponsors/fishing/airrusrods2.png",
          svg: "airrusrods.svg",
        },
        {
          name: "AIRRUS RODS #2",
          icon: "images/sponsors/fishing/airrusrods.png",
          svg: "airrusrods.svg",
        },
        {
          name: "BASSCATBOATS",
          icon: "images/sponsors/fishing/basscatboats.png",
          svg: "basscatboats.svg",
        },
        {
          name: "BASSCATBOATS #2",
          icon: "images/sponsors/fishing/basscatboats2.png",
          svg: "basscatboats2.svg",
        },
        {
          name: "BIZZBAITS",
          icon: "images/sponsors/fishing/bizzbaits.png",
          svg: "bizzbaits.svg",
        },
        {
          name: "BRAD'S",
          icon: "images/sponsors/fishing/brad's.png",
          svg: "brad's.svg",
        },
        {
          name: "DIRTYJIGSTACKLE",
          icon: "images/sponsors/fishing/dirtyjigstackle.png",
          svg: "dirtyjigstackle.svg",
        },
        {
          name: "FISHERSOFMEN",
          icon: "images/sponsors/fishing/fishersofmen.png",
          svg: "fishersofmen.svg",
        },
        {
          name: "HYDROWAVE",
          icon: "images/sponsors/fishing/hydrowave.png",
          svg: "hydrowave.svg",
        },
        {
          name: "INSEINEFISHING",
          icon: "images/sponsors/fishing/inseinefishing.png",
          svg: "inseinefishing.svg",
        },
        {
          name: "INSEINEFISHING #2",
          icon: "images/sponsors/fishing/inseinefishing2.png",
          svg: "inseinefishing2.svg",
        },
        {
          name: "MCCAINRODS",
          icon: "images/sponsors/fishing/mccainrods.png",
          svg: "mccainrods.svg",
        },
        {
          name: "OKUMA #2",
          icon: "images/sponsors/fishing/okuma-2.png",
          svg: "okuma-2.svg",
        },
        {
          name: "OMEGA CUSTOM TACKLE",
          icon: "images/sponsors/fishing/omegacustomtackle.png",
          svg: "omegacustomtackle.svg",
        },
        {
          name: "OMEGA CUSTOM TACKLE #2",
          icon: "images/sponsors/fishing/omegacustomtackle4.png",
          svg: "omegacustomtackle4.svg",
        },
        {
          name: "OMEGA CUSTOM TACKLE #3",
          icon: "images/sponsors/fishing/omegacustomtackle2.png",
          svg: "omegacustomtackle2.svg",
        },
        {
          name: "2k Jigs #2",
          icon: "images/sponsors/fishing/2kjigs#2.png",
          svg: "2kjigs#2.svg",
        },
        {
          name: "2k Jigs",
          icon: "images/sponsors/fishing/2kjigs.png",
          svg: "2kjigs.svg",
        },
        {
          name: "4x4 Bass Jigs",
          icon: "images/sponsors/fishing/4x4bassjigs.png",
          svg: "4x4bassjigs.svg",
        },
        {
          name: "Advanced Angler",
          icon: "images/sponsors/fishing/advancedangler.png",
          svg: "advancedangler.svg",
        },
        {
          name: "Anglr",
          icon: "images/sponsors/fishing/anglr.png",
          svg: "anglr.svg",
        },
        {
          name: "Army Bass Anglers",
          icon: "images/sponsors/fishing/armybassanglers.png",
          svg: "armybassanglers.svg",
        },
        {
          name: "B n M",
          icon: "images/sponsors/fishing/bnm.png",
          svg: "bnm.svg",
        },
        {
          name: "Bag 5 Baits",
          icon: "images/sponsors/fishing/bag5baits.png",
          svg: "bag5baits.svg",
        },
        {
          name: "Bait Rigs",
          icon: "images/sponsors/fishing/baitrigs.png",
          svg: "baitrigs.svg",
        },
        {
          name: "Balsax",
          icon: "images/sponsors/fishing/balsax.png",
          svg: "balsax.svg",
        },
        {
          name: "Bandit Lures",
          icon: "images/sponsors/fishing/banditlures.png",
          svg: "banditlures.svg",
        },
        {
          name: "Bass Snax",
          icon: "images/sponsors/fishing/basssnax.png",
          svg: "basssnax.svg",
        },
        {
          name: "Bassman Spinnerbaits",
          icon: "images/sponsors/fishing/bassmanspinnerbaits.png",
          svg: "bassmanspinnerbaits.svg",
        },
        {
          name: "Bassmaster high school fishing",
          icon: "images/sponsors/fishing/bassmasterhighschoolfishing.png",
          svg: "bassmasterhighschoolfishing.svg",
        },
        {
          name: "Bayou Rattler Products",
          icon: "images/sponsors/fishing/bayourattlerproducts.png",
          svg: "bayourattlerproducts.svg",
        },
        {
          name: "Beckman",
          icon: "images/sponsors/fishing/beckman.png",
          svg: "beckman.svg",
        },
        {
          name: "Berkley Fusion 19",
          icon: "images/sponsors/fishing/berkleyfusion19.png",
          svg: "berkleyfusion19.svg",
        },
        {
          name: "Berkley Gulp Saltwater",
          icon: "images/sponsors/fishing/berkleygulpsaltwater.png",
          svg: "berkleygulpsaltwater.svg",
        },
        {
          name: "Bill Lewis",
          icon: "images/sponsors/fishing/billlewis.png",
          svg: "billlewis.svg",
        },
        {
          name: "Biwaa",
          icon: "images/sponsors/fishing/biwaa.png",
          svg: "biwaa.svg",
        },
        {
          name: "BlueWaterLED",
          icon: "images/sponsors/fishing/bluewaterled.png",
          svg: "bluewaterled.svg",
        },
        {
          name: "Blue Fox",
          icon: "images/sponsors/fishing/blue_fox.png",
          svg: "blue_fox.svg",
        },
        {
          name: "B N M",
          icon: "images/sponsors/fishing/b_n_m.png",
          svg: "b_n_m.svg",
        },
        {
          name: "Boatlogix",
          icon: "images/sponsors/fishing/boatlogix.png",
          svg: "boatlogix.svg",
        },
        {
          name: "Bob's Machine Shop",
          icon: "images/sponsors/fishing/bob'smachineshop.png",
          svg: "bob'smachineshop.svg",
        },
        {
          name: "Bobby Garland Crappie Baits",
          icon: "images/sponsors/fishing/bobbygarlandcrappiebaits.png",
          svg: "bobbygarlandcrappiebaits.svg",
        },
        {
          name: "Bolt Lock",
          icon: "images/sponsors/fishing/boltlock.png",
          svg: "boltlock.svg",
        },
        {
          name: "Boomer's Tackle",
          icon: "images/sponsors/fishing/boomer'stackle.png",
          svg: "boomer'stackle.svg",
        },
        {
          name: "Booyah Bait Company",
          icon: "images/sponsors/fishing/booyahbaitcompany.png",
          svg: "booyahbaitcompany.svg",
        },
        {
          name: "Bugsy's Baits",
          icon: "images/sponsors/fishing/bugsy'sbaits.png",
          svg: "bugsy'sbaits.svg",
        },
        {
          name: "Buzz-Bomb",
          icon: "images/sponsors/fishing/buzz-bomb.png",
          svg: "buzz-bomb.svg",
        },
        {
          name: "bwsta red",
          icon: "images/sponsors/fishing/bwstared.png",
          svg: "bwstared.svg",
        },
        {
          name: "Cal Coast Fishing #2",
          icon: "images/sponsors/fishing/calcoastfishing-2.png",
          svg: "calcoastfishing-2.svg",
        },
        {
          name: "Cal Coast Fishing",
          icon: "images/sponsors/fishing/calcoastfishing.png",
          svg: "calcoastfishing.svg",
        },
        {
          name: "Calcutta",
          icon: "images/sponsors/fishing/calcutta.png",
          svg: "calcutta.svg",
        },
        {
          name: "caliber",
          icon: "images/sponsors/fishing/caliber.png",
          svg: "caliber.svg",
        },
        {
          name: "Cannon",
          icon: "images/sponsors/fishing/cannon.png",
          svg: "cannon.svg",
        },
        {
          name: "Cashion Fishing Rods",
          icon: "images/sponsors/fishing/cashionfishingrods.png",
          svg: "cashionfishingrods.svg",
        },
        {
          name: "Castaic Swimbaits",
          icon: "images/sponsors/fishing/castaicswimbaits.png",
          svg: "castaicswimbaits.svg",
        },
        {
          name: "Catch fishing",
          icon: "images/sponsors/fishing/catchfishing.png",
          svg: "catchfishing.svg",
        },
        {
          name: "Charge",
          icon: "images/sponsors/fishing/charge.png",
          svg: "charge.svg",
        },
        {
          name: "Charger Boats",
          icon: "images/sponsors/fishing/chargerboats.png",
          svg: "chargerboats.svg",
        },
        {
          name: "ChooChoo lures",
          icon: "images/sponsors/fishing/choochoolures.png",
          svg: "choochoolures.svg",
        },
        {
          name: "Cocoons",
          icon: "images/sponsors/fishing/cocoons.png",
          svg: "cocoons.svg",
        },
        {
          name: "Connect Scale",
          icon: "images/sponsors/fishing/connectscale.png",
          svg: "connectscale.svg",
        },
        {
          name: "costa",
          icon: "images/sponsors/fishing/costa.png",
          svg: "costa.svg",
        },
        {
          name: "COX JUICE",
          icon: "images/sponsors/fishing/coxjuice.png",
          svg: "coxjuice.svg",
        },
        {
          name: "Crossed Industries",
          icon: "images/sponsors/fishing/crossedindustries.png",
          svg: "crossedindustries.svg",
        },
        {
          name: "Cull-Em",
          icon: "images/sponsors/fishing/cull-em.png",
          svg: "cull-em.svg",
        },
        {
          name: "Culprit Lures",
          icon: "images/sponsors/fishing/culpritlures.png",
          svg: "culpritlures.svg",
        },
        {
          name: "Damiki Fishing Tackle 2",
          icon: "images/sponsors/fishing/damikifishingtackle2.png",
          svg: "damikifishingtackle2.svg",
        },
        {
          name: "Damiki Fishing Tackle",
          icon: "images/sponsors/fishing/damikifishingtackle.png",
          svg: "damikifishingtackle.svg",
        },
        {
          name: "Deka Batteries",
          icon: "images/sponsors/fishing/dekabatteries.png",
          svg: "dekabatteries.svg",
        },
        {
          name: "Delta Lures",
          icon: "images/sponsors/fishing/deltalures.png",
          svg: "deltalures.svg",
        },
        {
          name: "Deps",
          icon: "images/sponsors/fishing/deps.png",
          svg: "deps.svg",
        },
        {
          name: "Do-It Molds",
          icon: "images/sponsors/fishing/do-itmolds.png",
          svg: "do-itmolds.svg",
        },
        {
          name: "dockmaster",
          icon: "images/sponsors/fishing/dockmaster.png",
          svg: "dockmaster.svg",
        },
        {
          name: "Doomsday Tackle Co",
          icon: "images/sponsors/fishing/doomsdaytackleco.png",
          svg: "doomsdaytackleco.svg",
        },
        {
          name: "Driftmaster",
          icon: "images/sponsors/fishing/driftmaster.png",
          svg: "driftmaster.svg",
        },
        {
          name: "duckett pro-driven",
          icon: "images/sponsors/fishing/duckettpro-driven.png",
          svg: "duckettpro-driven.svg",
        },
        {
          name: "Ducote Lures",
          icon: "images/sponsors/fishing/ducotelures.png",
          svg: "ducotelures.svg",
        },
        {
          name: "duel",
          icon: "images/sponsors/fishing/duel.png",
          svg: "duel.svg",
        },
        {
          name: "Dyesafe",
          icon: "images/sponsors/fishing/dyesafe.png",
          svg: "dyesafe.svg",
        },
        {
          name: "EB Ethanol Buster",
          icon: "images/sponsors/fishing/ebethanolbuster.png",
          svg: "ebethanolbuster.svg",
        },
        {
          name: "eco pro tungsten",
          icon: "images/sponsors/fishing/ecoprotungsten.png",
          svg: "ecoprotungsten.svg",
        },
        {
          name: "ECSTAR",
          icon: "images/sponsors/fishing/ecstar.png",
          svg: "ecstar.svg",
        },
        {
          name: "enigma #2",
          icon: "images/sponsors/fishing/enigma-2.png",
          svg: "enigma-2.svg",
        },
        {
          name: "Enigma",
          icon: "images/sponsors/fishing/enigma.png",
          svg: "enigma.svg",
        },
        {
          name: "Eupro",
          icon: "images/sponsors/fishing/eupro.png",
          svg: "eupro.svg",
        },
        {
          name: "Exori Fishing",
          icon: "images/sponsors/fishing/exorifishing.png",
          svg: "exorifishing.svg",
        },
        {
          name: "EXP",
          icon: "images/sponsors/fishing/exp.png",
          svg: "exp.svg",
        },
        {
          name: "EZ Loader Trailers",
          icon: "images/sponsors/fishing/ezloadertrailers.png",
          svg: "ezloadertrailers.svg",
        },
        {
          name: "FINS SUPERLINES",
          icon: "images/sponsors/fishing/finssuperlines.png",
          svg: "finssuperlines.svg",
        },
        {
          name: "Fishbrain",
          icon: "images/sponsors/fishing/fishbrain.png",
          svg: "fishbrain.svg",
        },
        {
          name: "FishSens Technology",
          icon: "images/sponsors/fishing/fishsenstechnology.png",
          svg: "fishsenstechnology.svg",
        },
        {
          name: "Fiskas",
          icon: "images/sponsors/fishing/fiskas.png",
          svg: "fiskas.svg",
        },
        {
          name: "Fitzgerald Fishing",
          icon: "images/sponsors/fishing/fitzgeraldfishing.png",
          svg: "fitzgeraldfishing.svg",
        },
        {
          name: "flat out tungsten",
          icon: "images/sponsors/fishing/flatouttungsten.png",
          svg: "flatouttungsten.svg",
        },
        {
          name: "FLW #2",
          icon: "images/sponsors/fishing/flw-2.png",
          svg: "flw-2.svg",
        },
        {
          name: "flw Tour",
          icon: "images/sponsors/fishing/flwtour.png",
          svg: "flwtour.svg",
        },
        {
          name: "FLW Walmart",
          icon: "images/sponsors/fishing/flwwalmart.png",
          svg: "flwwalmart.svg",
        },
        {
          name: "FLW",
          icon: "images/sponsors/fishing/flw.png",
          svg: "flw.svg",
        },
        {
          name: "Freedom Tackle",
          icon: "images/sponsors/fishing/freedomtackle.png",
          svg: "freedomtackle.svg",
        },
        {
          name: "fuji",
          icon: "images/sponsors/fishing/fuji.png",
          svg: "fuji.svg",
        },
        {
          name: "Fulton",
          icon: "images/sponsors/fishing/fulton.png",
          svg: "fulton.svg",
        },
        {
          name: "Fusion",
          icon: "images/sponsors/fishing/fusion.png",
          svg: "fusion.svg",
        },
        {
          name: "FX treme custom rods",
          icon: "images/sponsors/fishing/fxtremecustomrods.png",
          svg: "fxtremecustomrods.svg",
        },
        {
          name: "Gajo Baits",
          icon: "images/sponsors/fishing/gajobaits.png",
          svg: "gajobaits.svg",
        },
        {
          name: "Gator Guards",
          icon: "images/sponsors/fishing/gatorguards.png",
          svg: "gatorguards.svg",
        },
        {
          name: "Gatr Coolers",
          icon: "images/sponsors/fishing/gatrcoolers.png",
          svg: "gatrcoolers.svg",
        },
        {
          name: "Gill Fishing Gear",
          icon: "images/sponsors/fishing/gillfishinggear.png",
          svg: "gillfishinggear.svg",
        },
        {
          name: "Googan Baits",
          icon: "images/sponsors/fishing/googanbaits.png",
          svg: "googanbaits.svg",
        },
        {
          name: "GrandeBass Bait Co",
          icon: "images/sponsors/fishing/grandebassbaitco.png",
          svg: "grandebassbaitco.svg",
        },
        {
          name: "Gruv Fishing",
          icon: "images/sponsors/fishing/gruvfishing.png",
          svg: "gruvfishing.svg",
        },
        {
          name: "H&H Fishing Rods",
          icon: "images/sponsors/fishing/h&hfishingrods.png",
          svg: "h&hfishingrods.svg",
        },
        {
          name: "Hale Lure Company",
          icon: "images/sponsors/fishing/halelurecompany.png",
          svg: "halelurecompany.svg",
        },
        {
          name: "Hayabusa",
          icon: "images/sponsors/fishing/hayabusa.png",
          svg: "hayabusa.svg",
        },
        {
          name: "Hildebrandt",
          icon: "images/sponsors/fishing/hildebrandt.png",
          svg: "hildebrandt.svg",
        },
        {
          name: "Hooksetter Supply Co",
          icon: "images/sponsors/fishing/hooksettersupplyco.png",
          svg: "hooksettersupplyco.svg",
        },
        {
          name: "i1baits",
          icon: "images/sponsors/fishing/i1baits.png",
          svg: "i1baits.svg",
        },
        {
          name: "icom",
          icon: "images/sponsors/fishing/icom.png",
          svg: "icom.svg",
        },
        {
          name: "Icon Coolers",
          icon: "images/sponsors/fishing/iconcoolers.png",
          svg: "iconcoolers.svg",
        },
        {
          name: "IMA",
          icon: "images/sponsors/fishing/ima.png",
          svg: "ima.svg",
        },
        {
          name: "Izorline",
          icon: "images/sponsors/fishing/izorline.png",
          svg: "izorline.svg",
        },
        {
          name: "J Braid",
          icon: "images/sponsors/fishing/jbraid.png",
          svg: "jbraid.svg",
        },
        {
          name: "J&S Custom Jigs",
          icon: "images/sponsors/fishing/j&scustomjigs.png",
          svg: "j&scustomjigs.svg",
        },
        {
          name: "Jenko Fishing",
          icon: "images/sponsors/fishing/jenkofishing.png",
          svg: "jenkofishing.svg",
        },
        {
          name: "Jewel Bait Company",
          icon: "images/sponsors/fishing/jewelbaitcompany.png",
          svg: "jewelbaitcompany.svg",
        },
        {
          name: "KastKing",
          icon: "images/sponsors/fishing/kastking.png",
          svg: "kastking.svg",
        },
        {
          name: "Kicker Fish Bait",
          icon: "images/sponsors/fishing/kickerfishbait.png",
          svg: "kickerfishbait.svg",
        },
        {
          name: "Kistler",
          icon: "images/sponsors/fishing/kistler.png",
          svg: "kistler.svg",
        },
        {
          name: "Kysek",
          icon: "images/sponsors/fishing/kysek.png",
          svg: "kysek.svg",
        },
        {
          name: "LBAA",
          icon: "images/sponsors/fishing/lbaa.png",
          svg: "lbaa.svg",
        },
        {
          name: "Legend Boats",
          icon: "images/sponsors/fishing/legendboats.png",
          svg: "legendboats.svg",
        },
        {
          name: "Lithium Pros",
          icon: "images/sponsors/fishing/lithiumpros.png",
          svg: "lithiumpros.svg",
        },
        {
          name: "Live Line Baits",
          icon: "images/sponsors/fishing/livelinebaits.png",
          svg: "livelinebaits.svg",
        },
        {
          name: "Lox Fishing Rods",
          icon: "images/sponsors/fishing/loxfishingrods.png",
          svg: "loxfishingrods.svg",
        },
        {
          name: "Lucky 7 Baits",
          icon: "images/sponsors/fishing/lucky7baits.png",
          svg: "lucky7baits.svg",
        },
        {
          name: "Lucky Tackle Box",
          icon: "images/sponsors/fishing/luckytacklebox.png",
          svg: "luckytacklebox.svg",
        },
        {
          name: "luhr-jensen",
          icon: "images/sponsors/fishing/luhr-jensen.png",
          svg: "luhr-jensen.svg",
        },
        {
          name: "Mad River Mfg",
          icon: "images/sponsors/fishing/madrivermfg.png",
          svg: "madrivermfg.svg",
        },
        {
          name: "Mako Boats",
          icon: "images/sponsors/fishing/makoboats.png",
          svg: "makoboats.svg",
        },
        {
          name: "Mako Polarized",
          icon: "images/sponsors/fishing/makopolarized.png",
          svg: "makopolarized.svg",
        },
        {
          name: "mate series",
          icon: "images/sponsors/fishing/mateseries.png",
          svg: "mateseries.svg",
        },
        {
          name: "Mercury",
          icon: "images/sponsors/fishing/mercury.png",
          svg: "mercury.svg",
        },
        {
          name: "MHX Rod Blanks 2",
          icon: "images/sponsors/fishing/mhxrodblanks2.png",
          svg: "mhxrodblanks2.svg",
        },
        {
          name: "MHX Rod Blanks",
          icon: "images/sponsors/fishing/mhxrodblanks.png",
          svg: "mhxrodblanks.svg",
        },
        {
          name: "Millerods",
          icon: "images/sponsors/fishing/millerods.png",
          svg: "millerods.svg",
        },
        {
          name: "MirrOLure",
          icon: "images/sponsors/fishing/mirrolure.png",
          svg: "mirrolure.svg",
        },
        {
          name: "Moaner Hooks",
          icon: "images/sponsors/fishing/moanerhooks.png",
          svg: "moanerhooks.svg",
        },
        {
          name: "Molix",
          icon: "images/sponsors/fishing/molix.png",
          svg: "molix.svg",
        },
        {
          name: "Mossy Oak Fishing",
          icon: "images/sponsors/fishing/mossyoakfishing.png",
          svg: "mossyoakfishing.svg",
        },
        {
          name: "Mustang Survival",
          icon: "images/sponsors/fishing/mustangsurvival.png",
          svg: "mustangsurvival.svg",
        },
        {
          name: "Native Eyewear",
          icon: "images/sponsors/fishing/nativeeyewear.png",
          svg: "nativeeyewear.svg",
        },
        /*{
				name: "Nock On Archery 2",
				icon: "images/sponsors/fishing/nockonarchery2.png",
				svg: "nockonarchery2.svg" 
				} ,
				{
				name: "Nock On Archery",
				icon: "images/sponsors/fishing/nockonarchery.png",
				svg: "nockonarchery.svg" 
				} ,*/
        {
          name: "Ocean Tackle International",
          icon: "images/sponsors/fishing/oceantackleinternational.png",
          svg: "oceantackleinternational.svg",
        },
        {
          name: "OKUMA",
          icon: "images/sponsors/fishing/okuma-3.png",
          svg: "okuma-3.svg",
        },
        {
          name: "Old Town",
          icon: "images/sponsors/fishing/oldtown.png",
          svg: "oldtown.svg",
        },
        {
          name: "Opass",
          icon: "images/sponsors/fishing/opass.png",
          svg: "opass.svg",
        },
        {
          name: "Panther Martin",
          icon: "images/sponsors/fishing/panthermartin.png",
          svg: "panthermartin.svg",
        },
        {
          name: "Peregrine 250 Eagle Eye",
          icon: "images/sponsors/fishing/peregrine250eagleeye.png",
          svg: "peregrine250eagleeye.svg",
        },
        {
          name: "Peregrine 250",
          icon: "images/sponsors/fishing/peregrine250.png",
          svg: "peregrine250.svg",
        },
        {
          name: "PH Custom Lures 2",
          icon: "images/sponsors/fishing/phcustomlures2.png",
          svg: "phcustomlures2.svg",
        },
        {
          name: "PH Custom Lures",
          icon: "images/sponsors/fishing/phcustomlures.png",
          svg: "phcustomlures.svg",
        },
        {
          name: "Phenix Custom Rods",
          icon: "images/sponsors/fishing/phenixcustomrods.png",
          svg: "phenixcustomrods.svg",
        },
        {
          name: "Phoenix Boats",
          icon: "images/sponsors/fishing/phoenixboats.png",
          svg: "phoenixboats.svg",
        },
        {
          name: "Pioneer Tackle",
          icon: "images/sponsors/fishing/pioneertackle.png",
          svg: "pioneertackle.svg",
        },
        {
          name: "Pointers Fishing Tackle",
          icon: "images/sponsors/fishing/pointersfishingtackle.png",
          svg: "pointersfishingtackle.svg",
        },
        {
          name: "Power Pole Captains Cash",
          icon: "images/sponsors/fishing/powerpolecaptainscash.png",
          svg: "powerpolecaptainscash.svg",
        },
        {
          name: "Pro Craft Boats",
          icon: "images/sponsors/fishing/procraftboats.png",
          svg: "procraftboats.svg",
        },
        {
          name: "Pro Mariner",
          icon: "images/sponsors/fishing/promariner.png",
          svg: "promariner.svg",
        },
        {
          name: "Pro-Guide Batteries",
          icon: "images/sponsors/fishing/pro-guidebatteries.png",
          svg: "pro-guidebatteries.svg",
        },
        {
          name: "Pro-Troll",
          icon: "images/sponsors/fishing/pro-troll.png",
          svg: "pro-troll.svg",
        },
        {
          name: "Proline Bowstrings",
          icon: "images/sponsors/fishing/prolinebowstrings.png",
          svg: "prolinebowstrings.svg",
        },
        {
          name: "Quarrow",
          icon: "images/sponsors/fishing/quarrow.png",
          svg: "quarrow.svg",
        },
        {
          name: "Quicksilver",
          icon: "images/sponsors/fishing/quicksilver.png",
          svg: "quicksilver.svg",
        },
        {
          name: "ranger boats",
          icon: "images/sponsors/fishing/rangerboats.png",
          svg: "rangerboats.svg",
        },
        {
          name: "Rapid Fishing Solutions",
          icon: "images/sponsors/fishing/rapidfishingsolutions.png",
          svg: "rapidfishingsolutions.svg",
        },
        {
          name: "RaZr Rods",
          icon: "images/sponsors/fishing/razrrods.png",
          svg: "razrrods.svg",
        },
        {
          name: "Realtree-Fishing",
          icon: "images/sponsors/fishing/realtree-fishing.png",
          svg: "realtree-fishing.svg",
        },
        {
          name: "Recon Fishing Boats",
          icon: "images/sponsors/fishing/reconfishingboats.png",
          svg: "reconfishingboats.svg",
        },
        {
          name: "Regency Boats",
          icon: "images/sponsors/fishing/regencyboats.png",
          svg: "regencyboats.svg",
        },
        {
          name: "Reins Fishing",
          icon: "images/sponsors/fishing/reinsfishing.png",
          svg: "reinsfishing.svg",
        },
        {
          name: "Riot Baits",
          icon: "images/sponsors/fishing/riotbaits.png",
          svg: "riotbaits.svg",
        },
        {
          name: "Road Runner",
          icon: "images/sponsors/fishing/roadrunner.png",
          svg: "roadrunner.svg",
        },
        {
          name: "Rocky Brook Sinkers",
          icon: "images/sponsors/fishing/rockybrooksinkers.png",
          svg: "rockybrooksinkers.svg",
        },
        {
          name: "Rosco",
          icon: "images/sponsors/fishing/rosco.png",
          svg: "rosco.svg",
        },
        {
          name: "S4-Gear",
          icon: "images/sponsors/fishing/s4-gear.png",
          svg: "s4-gear.svg",
        },
        {
          name: "sage",
          icon: "images/sponsors/fishing/sage.png",
          svg: "sage.svg",
        },
        {
          name: "Sampo Swivels",
          icon: "images/sponsors/fishing/samposwivels.png",
          svg: "samposwivels.svg",
        },
        {
          name: "Savage Gear",
          icon: "images/sponsors/fishing/savagegear.png",
          svg: "savagegear.svg",
        },
        {
          name: "Scales Gear",
          icon: "images/sponsors/fishing/scalesgear.png",
          svg: "scalesgear.svg",
        },
        {
          name: "Scotty 2",
          icon: "images/sponsors/fishing/scotty2.png",
          svg: "scotty2.svg",
        },
        {
          name: "SixGill Fishing",
          icon: "images/sponsors/fishing/sixgillfishing.png",
          svg: "sixgillfishing.svg",
        },
        {
          name: "Slix Rod Cover",
          icon: "images/sponsors/fishing/slixrodcover.png",
          svg: "slixrodcover.svg",
        },
        {
          name: "Slurpies Soft Plastics",
          icon: "images/sponsors/fishing/slurpiessoftplastics.png",
          svg: "slurpiessoftplastics.svg",
        },
        {
          name: "Smartbaits",
          icon: "images/sponsors/fishing/smartbaits.png",
          svg: "smartbaits.svg",
        },
        {
          name: "Spider-archery",
          icon: "images/sponsors/fishing/spider-archery.png",
          svg: "spider-archery.svg",
        },
        {
          name: "Spiralite Custom Rod",
          icon: "images/sponsors/fishing/spiralitecustomrod.png",
          svg: "spiralitecustomrod.svg",
        },
        {
          name: "stanislawski",
          icon: "images/sponsors/fishing/stanislawski.png",
          svg: "stanislawski.svg",
        },
        {
          name: "Steelshad",
          icon: "images/sponsors/fishing/steelshad.png",
          svg: "steelshad.svg",
        },
        {
          name: "stormr",
          icon: "images/sponsors/fishing/stormr.png",
          svg: "stormr.svg",
        },
        {
          name: "Strike_King",
          icon: "images/sponsors/fishing/strike_king.png",
          svg: "strike_king.svg",
        },
        {
          name: "Sun Tracker Boats",
          icon: "images/sponsors/fishing/sun_tracker_boats.png",
          svg: "sun_tracker_boats.svg",
        },
        {
          name: "Sure-Life",
          icon: "images/sponsors/fishing/sure-life.png",
          svg: "sure-life.svg",
        },
        {
          name: "Tackle Bandit",
          icon: "images/sponsors/fishing/tacklebandit.png",
          svg: "tacklebandit.svg",
        },
        {
          name: "Tackle Direct",
          icon: "images/sponsors/fishing/tackledirect.png",
          svg: "tackledirect.svg",
        },
        {
          name: "Tackle Supply Depot",
          icon: "images/sponsors/fishing/tacklesupplydepot.png",
          svg: "tacklesupplydepot.svg",
        },
        {
          name: "Tailwalker Lures",
          icon: "images/sponsors/fishing/tailwalkerlures.png",
          svg: "tailwalkerlures.svg",
        },
        {
          name: "TBF",
          icon: "images/sponsors/fishing/tbf.png",
          svg: "tbf.svg",
        },
        {
          name: "TFO",
          icon: "images/sponsors/fishing/tfo.png",
          svg: "tfo.svg",
        },
        {
          name: "The FISH BOMB",
          icon: "images/sponsors/fishing/thefishbomb.png",
          svg: "thefishbomb.svg",
        },
        {
          name: "Toray",
          icon: "images/sponsors/fishing/toray.png",
          svg: "toray.svg",
        },
        {
          name: "Toyota Bassmaster",
          icon: "images/sponsors/fishing/toyotabassmaster.png",
          svg: "toyotabassmaster.svg",
        },
        {
          name: "Trailmaster Trailers",
          icon: "images/sponsors/fishing/trailmastertrailers.png",
          svg: "trailmastertrailers.svg",
        },
        {
          name: "Trapper Tackle",
          icon: "images/sponsors/fishing/trappertackle.png",
          svg: "trappertackle.svg",
        },
        {
          name: "Treeshaker Tackle Company",
          icon: "images/sponsors/fishing/treeshakertacklecompany.png",
          svg: "treeshakertacklecompany.svg",
        },
        /*{
				name: "Tribe Archery",
				icon: "images/sponsors/fishing/tribearchery.png",
				svg: "tribearchery.svg" 
				} ,*/
        {
          name: "Tricked Out Marine",
          icon: "images/sponsors/fishing/trickedoutmarine.png",
          svg: "trickedoutmarine.svg",
        },
        {
          name: "Trik Fish",
          icon: "images/sponsors/fishing/trikfish.png",
          svg: "trikfish.svg",
        },
        {
          name: "True South Custom Lures",
          icon: "images/sponsors/fishing/truesouthcustomlures.png",
          svg: "truesouthcustomlures.svg",
        },
        {
          name: "TT Lures",
          icon: "images/sponsors/fishing/ttlures.png",
          svg: "ttlures.svg",
        },
        {
          name: "TTI-Blakemore",
          icon: "images/sponsors/fishing/tti-blakemore.png",
          svg: "tti-blakemore.svg",
        },
        {
          name: "ULTRAVIEW Archery",
          icon: "images/sponsors/fishing/ultraviewarchery.png",
          svg: "ultraviewarchery.svg",
        },
        {
          name: "V-T2 Livewell Ventilation",
          icon: "images/sponsors/fishing/v-t2livewellventilation.png",
          svg: "v-t2livewellventilation.svg",
        },
        {
          name: "Vista Targets",
          icon: "images/sponsors/fishing/vistatargets.png",
          svg: "vistatargets.svg",
        },
        {
          name: "WaterWolf Lures",
          icon: "images/sponsors/fishing/waterwolflures.png",
          svg: "waterwolflures.svg",
        },
        {
          name: "Wilson Fishing",
          icon: "images/sponsors/fishing/wilsonfishing.png",
          svg: "wilsonfishing.svg",
        },
        {
          name: "winn",
          icon: "images/sponsors/fishing/winn.png",
          svg: "winn.svg",
        },
        {
          name: "Zebra Bowstrings",
          icon: "images/sponsors/fishing/zebrabowstrings.png",
          svg: "zebrabowstrings.svg",
        },
        {
          name: "Cabin Creek Bait Co",
          icon: "images/sponsors/fishing/cabincreekbaitco.png",
          svg: "cabincreekbaitco.svg",
        },
        {
          name: "Berkley Gulp Alive",
          icon: "images/sponsors/fishing/berkleygulpalive.png",
          svg: "berkleygulpalive.svg",
        },
        {
          name: "Enigma #3",
          icon: "images/sponsors/fishing/enigma-3.png",
          svg: "enigma-3.svg",
        },
        {
          name: "Gibbs Lures",
          icon: "images/sponsors/fishing/gibbslures.png",
          svg: "gibbslures.svg",
        },
        {
          name: "Lure Lock",
          icon: "images/sponsors/fishing/lurelock.png",
          svg: "lurelock.svg",
        },
        {
          name: "Millerods #2",
          icon: "images/sponsors/fishing/millerods_blackbackground.png",
          svg: "millerods_blackbackground.svg",
        },
        {
          name: "Point Wilson Brite Bite",
          icon: "images/sponsors/fishing/pointwilsonbritebite.png",
          svg: "pointwilsonbritebite.svg",
        },
      ],
    },
    {
      id: "Hunting",
      name: "Hunting",
      mascots: [
        {
          name: "10X",
          icon: "images/sponsors/hunting/10x.png",
          svg: "10x.svg",
        },
        {
          name: "10X #2",
          icon: "images/sponsors/hunting/10x-2.png",
          svg: "10x-2.svg",
        },
        {
          name: "30-06 OUTDOORS",
          icon: "images/sponsors/hunting/30-06outdoors.png",
          svg: "30-06outdoors.svg",
        },
        {
          name: "30-06 OUTDOORS #2",
          icon: "images/sponsors/hunting/30-06outdoors-2.png",
          svg: "30-06outdoors-2.svg",
        },
        {
          name: "5.11",
          icon: "images/sponsors/hunting/5.11.png",
          svg: "5.11.svg",
        },
        {
          name: "ACCURACY INTERNATIONAL",
          icon: "images/sponsors/hunting/accuracyinternational.png",
          svg: "accuracyinternational.svg",
        },
        {
          name: "ACCURACY INTERNATIONAL #2",
          icon: "images/sponsors/hunting/accuracyinternational-2.png",
          svg: "accuracyinternational-2.svg",
        },
        {
          name: "ADAMS ARMS",
          icon: "images/sponsors/hunting/adamsarms.png",
          svg: "adamsarms.svg",
        },
        {
          name: "ADAMS ARMS #2",
          icon: "images/sponsors/hunting/adamsarms-2.png",
          svg: "adamsarms-2.svg",
        },
        {
          name: "AIMPOINT",
          icon: "images/sponsors/hunting/aimpoint.png",
          svg: "aimpoint.svg",
        },
        {
          name: "AIMPOINT #2",
          icon: "images/sponsors/hunting/aimpoint-2.png",
          svg: "aimpoint-2.svg",
        },
        {
          name: "ALPEN OPTICS",
          icon: "images/sponsors/hunting/alpenoptics.png",
          svg: "alpenoptics.svg",
        },
        {
          name: "ALPS OUTDOORZ",
          icon: "images/sponsors/hunting/alpsoutdoorz.png",
          svg: "alpsoutdoorz.svg",
        },
        {
          name: "AMERISTEP",
          icon: "images/sponsors/hunting/ameristep.png",
          svg: "ameristep.svg",
        },
        {
          name: "AMERISTEP #2",
          icon: "images/sponsors/hunting/ameristep-2.png",
          svg: "ameristep-2.svg",
        },
        {
          name: "ANI LOGICS",
          icon: "images/sponsors/hunting/anilogics.png",
          svg: "anilogics.svg",
        },
        {
          name: "APEX GEAR",
          icon: "images/sponsors/hunting/apexgear.png",
          svg: "apexgear.svg",
        },
        {
          name: "APEX GEAR #2",
          icon: "images/sponsors/hunting/apexgear-2.png",
          svg: "apexgear-2.svg",
        },
        {
          name: "ARMA LITE",
          icon: "images/sponsors/hunting/armalite.png",
          svg: "armalite.svg",
        },
        {
          name: "ARMA LITE #2",
          icon: "images/sponsors/hunting/armalite-2.png",
          svg: "armalite-2.svg",
        },
        {
          name: "ARMASIGHT",
          icon: "images/sponsors/hunting/armasight.png",
          svg: "armasight.svg",
        },
        {
          name: "ARMASIGHT #2",
          icon: "images/sponsors/hunting/armasight-2.png",
          svg: "armasight-2.svg",
        },
        {
          name: "ARMSCOR",
          icon: "images/sponsors/hunting/armscor.png",
          svg: "armscor.svg",
        },
        {
          name: "ARMSCOR #2",
          icon: "images/sponsors/hunting/armscor-2.png",
          svg: "armscor-2.svg",
        },
        {
          name: "ATN",
          icon: "images/sponsors/hunting/atn.png",
          svg: "atn.svg",
        },
        {
          name: "ATN #2",
          icon: "images/sponsors/hunting/atn-2.png",
          svg: "atn-2.svg",
        },
        {
          name: "AVERY",
          icon: "images/sponsors/hunting/avery.png",
          svg: "avery.svg",
        },
        {
          name: "AVERY #2",
          icon: "images/sponsors/hunting/avery-2.png",
          svg: "avery-2.svg",
        },
        // {
        // name: "AVIAN",
        // icon: "images/sponsors/hunting/avian.png",
        // svg: "avian.svg"
        // } ,
        {
          name: "B&P BASCHIERI&PELLAGRI",
          icon: "images/sponsors/hunting/b&pbaschieri&pellagri.png",
          svg: "b&pbaschieri&pellagri.svg",
        },
        {
          name: "B&P BASCHIERI&PELLAGRI #2",
          icon: "images/sponsors/hunting/b&pbaschieri&pellagri-2.png",
          svg: "b&pbaschieri&pellagri-2.svg",
        },
        {
          name: "BAD BOY BUGGIES",
          icon: "images/sponsors/hunting/badboybuggies.png",
          svg: "badboybuggies.svg",
        },
        {
          name: "BADLANDS",
          icon: "images/sponsors/hunting/badlands.png",
          svg: "badlands.svg",
        },
        {
          name: "BADLANDS #2",
          icon: "images/sponsors/hunting/badlands-2.png",
          svg: "badlands-2.svg",
        },
        {
          name: "BANDED",
          icon: "images/sponsors/hunting/banded.png",
          svg: "banded.svg",
        },
        {
          name: "BANDED #2",
          icon: "images/sponsors/hunting/banded-2.png",
          svg: "banded-2.svg",
        },
        {
          name: "BANKS OUTDOORS",
          icon: "images/sponsors/hunting/banksoutdoors.png",
          svg: "banksoutdoors.svg",
        },
        {
          name: "BANKS OUTDOORS #2",
          icon: "images/sponsors/hunting/banksoutdoors-2.png",
          svg: "banksoutdoors-2.svg",
        },
        {
          name: "BARNES",
          icon: "images/sponsors/hunting/barnes.png",
          svg: "barnes.svg",
        },
        {
          name: "BARNES #2",
          icon: "images/sponsors/hunting/barnes-2.png",
          svg: "barnes-2.svg",
        },
        {
          name: "BARNETT OUTDOORS",
          icon: "images/sponsors/hunting/barnettoutdoors.png",
          svg: "barnettoutdoors.svg",
        },
        {
          name: "BARRETT FIREARMS",
          icon: "images/sponsors/hunting/barrettfirearms.png",
          svg: "barrettfirearms.svg",
        },
        {
          name: "BARSKA",
          icon: "images/sponsors/hunting/barska.png",
          svg: "barska.svg",
        },
        {
          name: "BARSKA #2",
          icon: "images/sponsors/hunting/barska-2.png",
          svg: "barska-2.svg",
        },
        {
          name: "BEAVERTAIL",
          icon: "images/sponsors/hunting/beavertail.png",
          svg: "beavertail.svg",
        },
        {
          name: "BENCHMADE",
          icon: "images/sponsors/hunting/benchmade.png",
          svg: "benchmade.svg",
        },
        {
          name: "BENCHMADE #2",
          icon: "images/sponsors/hunting/benchmade-2.png",
          svg: "benchmade-2.svg",
        },
        {
          name: "BENELLI",
          icon: "images/sponsors/hunting/benelli.png",
          svg: "benelli.svg",
        },
        {
          name: "BENELLI #2",
          icon: "images/sponsors/hunting/benelli-2.png",
          svg: "benelli-2.svg",
        },
        {
          name: "BERETTA ARMS",
          icon: "images/sponsors/hunting/berettaarms.png",
          svg: "berettaarms.svg",
        },
        {
          name: "BERETTA ARMS #2",
          icon: "images/sponsors/hunting/berettaarms-2.png",
          svg: "berettaarms-2.svg",
        },
        {
          name: "BERETTA ARMS #3",
          icon: "images/sponsors/hunting/berettaarms-3.png",
          svg: "berettaarms-3.svg",
        },
        {
          name: "BERETTA ARMS #4",
          icon: "images/sponsors/hunting/berettaarms-4.png",
          svg: "berettaarms-4.svg",
        },
        {
          name: "BERGARA",
          icon: "images/sponsors/hunting/bergara.png",
          svg: "bergara.svg",
        },
        {
          name: "BERSA",
          icon: "images/sponsors/hunting/bersa.png",
          svg: "bersa.svg",
        },
        {
          name: "BERSA #2",
          icon: "images/sponsors/hunting/bersa-2.png",
          svg: "bersa-2.svg",
        },
        {
          name: "BIG DOG TREESTANDS",
          icon: "images/sponsors/hunting/bigdogtreestands.png",
          svg: "bigdogtreestands.svg",
        },
        {
          name: "BIG GAME TREE STANDS",
          icon: "images/sponsors/hunting/biggametreestands.png",
          svg: "biggametreestands.svg",
        },
        {
          name: "BIG RIVER CALLS",
          icon: "images/sponsors/hunting/bigrivercalls.png",
          svg: "bigrivercalls.svg",
        },
        {
          name: "BIG RIVER CALLS #2",
          icon: "images/sponsors/hunting/bigrivercalls-2.png",
          svg: "bigrivercalls-2.svg",
        },
        {
          name: "BIG TINE",
          icon: "images/sponsors/hunting/bigtine.png",
          svg: "bigtine.svg",
        },
        {
          name: "BIG TINE 2",
          icon: "images/sponsors/hunting/bigtine2.png",
          svg: "bigtine2.svg",
        },
        {
          name: "BIG&J",
          icon: "images/sponsors/hunting/big&j.png",
          svg: "big&j.svg",
        },
        {
          name: "BIGFOOT DECOYS",
          icon: "images/sponsors/hunting/bigfootdecoys.png",
          svg: "bigfootdecoys.svg",
        },
        {
          name: "BILL SAUNDERS CALLS & GEAR",
          icon: "images/sponsors/hunting/billsaunderscalls&gear.png",
          svg: "billsaunderscalls&gear.svg",
        },
        {
          name: "BILL SAUNDERS CALLS & GEAR #2",
          icon: "images/sponsors/hunting/billsaunderscalls&gear-2.png",
          svg: "billsaunderscalls&gear-2.svg",
        },
        {
          name: "BLACK CLOUD",
          icon: "images/sponsors/hunting/blackcloud.png",
          svg: "blackcloud.svg",
        },
        {
          name: "BLACK CLOUD #2",
          icon: "images/sponsors/hunting/blackcloud-2.png",
          svg: "blackcloud-2.svg",
        },
        {
          name: "BLACK HILLS AMMO",
          icon: "images/sponsors/hunting/blackhillsammo.png",
          svg: "blackhillsammo.svg",
        },
        {
          name: "BLACK HILLS AMMO #2",
          icon: "images/sponsors/hunting/blackhillsammo-2.png",
          svg: "blackhillsammo-2.svg",
        },
        {
          name: "BLACK WIDOW",
          icon: "images/sponsors/hunting/blackwidow.png",
          svg: "blackwidow.svg",
        },
        {
          name: "BLACK WIDOW #2",
          icon: "images/sponsors/hunting/blackwidow-2.png",
          svg: "blackwidow-2.svg",
        },
        {
          name: "BLACKHAWK",
          icon: "images/sponsors/hunting/blackhawk.png",
          svg: "blackhawk.svg",
        },
        {
          name: "BLACKHAWK 2",
          icon: "images/sponsors/hunting/blackhawk2.png",
          svg: "blackhawk2.svg",
        },
        {
          name: "BOYDS",
          icon: "images/sponsors/hunting/boyds.png",
          svg: "boyds.svg",
        },
        {
          name: "BOYDS 2",
          icon: "images/sponsors/hunting/boyds2.png",
          svg: "boyds2.svg",
        },
        {
          name: "BRENNEKE",
          icon: "images/sponsors/hunting/brenneke.png",
          svg: "brenneke.svg",
        },
        {
          name: "BRENNEKE #2",
          icon: "images/sponsors/hunting/brenneke-2.png",
          svg: "brenneke-2.svg",
        },
        {
          name: "BROWNING",
          icon: "images/sponsors/hunting/browning.png",
          svg: "browning.svg",
        },
        {
          name: "BROWNING #2",
          icon: "images/sponsors/hunting/browning-2.png",
          svg: "browning-2.svg",
        },
        {
          name: "BROWNING #3",
          icon: "images/sponsors/hunting/browning-3.png",
          svg: "browning-3.svg",
        },
        {
          name: "BSA OPTICS",
          icon: "images/sponsors/hunting/bsaoptics.png",
          svg: "bsaoptics.svg",
        },
        {
          name: "BSA OPTICS #2",
          icon: "images/sponsors/hunting/bsaoptics-2.png",
          svg: "bsaoptics-2.svg",
        },
        {
          name: "BUCK GARDNER CALLS",
          icon: "images/sponsors/hunting/buckgardnercalls.png",
          svg: "buckgardnercalls.svg",
        },
        {
          name: "BUCK KNIVES",
          icon: "images/sponsors/hunting/buckknives.png",
          svg: "buckknives.svg",
        },
        {
          name: "BUFFALO BORE AMMUNITION",
          icon: "images/sponsors/hunting/buffaloboreammunition.png",
          svg: "buffaloboreammunition.svg",
        },
        {
          name: "BURRIS",
          icon: "images/sponsors/hunting/burris.png",
          svg: "burris.svg",
        },
        {
          name: "BURRIS #2",
          icon: "images/sponsors/hunting/burris-2.png",
          svg: "burris-2.svg",
        },
        {
          name: "BUSHNELL",
          icon: "images/sponsors/hunting/bushnell.png",
          svg: "bushnell.svg",
        },
        {
          name: "BUSHNELL #2",
          icon: "images/sponsors/hunting/bushnell-2.png",
          svg: "bushnell-2.svg",
        },
        {
          name: "CABELA'S",
          icon: "images/sponsors/hunting/cabela's.png",
          svg: "cabela's.svg",
        },
        {
          name: "CABELA'S #2",
          icon: "images/sponsors/hunting/cabela's-2.png",
          svg: "cabela's-2.svg",
        },
        {
          name: "CABELA'S #3",
          icon: "images/sponsors/hunting/cabela's-3.png",
          svg: "cabela's-3.svg",
        },
        {
          name: "CABELA'S #4",
          icon: "images/sponsors/hunting/cabela's-4.png",
          svg: "cabela's-4.svg",
        },
        {
          name: "CALDWELL",
          icon: "images/sponsors/hunting/caldwell.png",
          svg: "caldwell.svg",
        },
        {
          name: "CALICO",
          icon: "images/sponsors/hunting/calico.png",
          svg: "calico.svg",
        },
        {
          name: "CALICO #2",
          icon: "images/sponsors/hunting/calico-2.png",
          svg: "calico-2.svg",
        },
        {
          name: "CARLSON'S",
          icon: "images/sponsors/hunting/carlson's.png",
          svg: "carlson's.svg",
        },
        {
          name: "CARLSON'S #2",
          icon: "images/sponsors/hunting/carlson's-2.png",
          svg: "carlson's-2.svg",
        },
        {
          name: "CASS CREEK",
          icon: "images/sponsors/hunting/casscreek.png",
          svg: "casscreek.svg",
        },
        {
          name: "CCI",
          icon: "images/sponsors/hunting/cci.png",
          svg: "cci.svg",
        },
        {
          name: "CENTURY INTERNATIONAL ARMS",
          icon: "images/sponsors/hunting/centuryinternationalarms.png",
          svg: "centuryinternationalarms.svg",
        },
        {
          name: "CLAYBUSTER WADS",
          icon: "images/sponsors/hunting/claybusterwads.png",
          svg: "claybusterwads.svg",
        },
        {
          name: "CLAYBUSTER WADS #2",
          icon: "images/sponsors/hunting/claybusterwads-2.png",
          svg: "claybusterwads-2.svg",
        },
        {
          name: "CODE BLUE",
          icon: "images/sponsors/hunting/codeblue.png",
          svg: "codeblue.svg",
        },
        {
          name: "CODE BLUE #2",
          icon: "images/sponsors/hunting/codeblue-2.png",
          svg: "codeblue-2.svg",
        },
        {
          name: "CODY TURKEY CALLS",
          icon: "images/sponsors/hunting/codyturkeycalls.png",
          svg: "codyturkeycalls.svg",
        },
        {
          name: "COLD STEEL",
          icon: "images/sponsors/hunting/coldsteel.png",
          svg: "coldsteel.svg",
        },
        {
          name: "COLEMAN",
          icon: "images/sponsors/hunting/coleman.png",
          svg: "coleman.svg",
        },
        {
          name: "COLEMAN #2",
          icon: "images/sponsors/hunting/coleman-2.png",
          svg: "coleman-2.svg",
        },
        {
          name: "COLT",
          icon: "images/sponsors/hunting/colt.png",
          svg: "colt.svg",
        },
        {
          name: "COLT #2",
          icon: "images/sponsors/hunting/colt-2.png",
          svg: "colt-2.svg",
        },
        {
          name: "COLUMBIA",
          icon: "images/sponsors/hunting/columbia.png",
          svg: "columbia.svg",
        },
        {
          name: "COLUMBIA #2",
          icon: "images/sponsors/hunting/columbia-2.png",
          svg: "columbia-2.svg",
        },
        {
          name: "COMPASS 360",
          icon: "images/sponsors/hunting/compass360.png",
          svg: "compass360.svg",
        },
        {
          name: "COMPASS 360 #2",
          icon: "images/sponsors/hunting/compass360-2.png",
          svg: "compass360-2.svg",
        },
        {
          name: "CONQUEST SCENTS",
          icon: "images/sponsors/hunting/conquestscents.png",
          svg: "conquestscents.svg",
        },
        {
          name: "CONQUEST SCENTS #2",
          icon: "images/sponsors/hunting/conquestscents-2.png",
          svg: "conquestscents-2.svg",
        },
        {
          name: "CORBON AMMO",
          icon: "images/sponsors/hunting/corbonammo.png",
          svg: "corbonammo.svg",
        },
        {
          name: "COVERT",
          icon: "images/sponsors/hunting/covert.png",
          svg: "covert.svg",
        },
        {
          name: "CRIMSON TRACE",
          icon: "images/sponsors/hunting/crimsontrace.png",
          svg: "crimsontrace.svg",
        },
        {
          name: "CUDA #2",
          icon: "images/sponsors/hunting/cuda-2.png",
          svg: "cuda-2.svg",
        },
        {
          name: "CUDDEBACK",
          icon: "images/sponsors/hunting/cuddeback.png",
          svg: "cuddeback.svg",
        },
        {
          name: "CUDDEBACK #2",
          icon: "images/sponsors/hunting/cuddeback-2.png",
          svg: "cuddeback-2.svg",
        },
        {
          name: "CVA",
          icon: "images/sponsors/hunting/cva.png",
          svg: "cva.svg",
        },
        {
          name: "CVA #2",
          icon: "images/sponsors/hunting/cva-2.png",
          svg: "cva-2.svg",
        },
        {
          name: "CYCLOPS",
          icon: "images/sponsors/hunting/cyclops.png",
          svg: "cyclops.svg",
        },
        {
          name: "CYCLOPS #2",
          icon: "images/sponsors/hunting/cyclops-2.png",
          svg: "cyclops-2.svg",
        },
        {
          name: "CZ-USA",
          icon: "images/sponsors/hunting/cz-usa.png",
          svg: "cz-usa.svg",
        },
        {
          name: "CZ-USA #2",
          icon: "images/sponsors/hunting/cz-usa-2.png",
          svg: "cz-usa-2.svg",
        },
        {
          name: "D.T. SYSTEMS",
          icon: "images/sponsors/hunting/d.t.systems.png",
          svg: "d.t.systems.svg",
        },
        {
          name: "D.T. SYSTEMS #2",
          icon: "images/sponsors/hunting/d.t.systems-2.png",
          svg: "d.t.systems-2.svg",
        },
        {
          name: "DAKOTA DECOY COMPANY",
          icon: "images/sponsors/hunting/dakotadecoycompany.png",
          svg: "dakotadecoycompany.svg",
        },
        {
          name: "DAVE SMITH DECOYS",
          icon: "images/sponsors/hunting/davesmithdecoys.png",
          svg: "davesmithdecoys.svg",
        },
        {
          name: "DEAD DOWN WIND",
          icon: "images/sponsors/hunting/deaddownwind.png",
          svg: "deaddownwind.svg",
        },
        {
          name: "DEAD DOWN WIND #2",
          icon: "images/sponsors/hunting/deaddownwind-2.png",
          svg: "deaddownwind-2.svg",
        },
        {
          name: "DEAD RINGER",
          icon: "images/sponsors/hunting/deadringer.png",
          svg: "deadringer.svg",
        },
        {
          name: "DEAD RINGER #2",
          icon: "images/sponsors/hunting/deadringer-2.png",
          svg: "deadringer-2.svg",
        },
        {
          name: "DELTA WATERFOWL",
          icon: "images/sponsors/hunting/deltawaterfowl.png",
          svg: "deltawaterfowl.svg",
        },
        {
          name: "DOA DECOY COMPANY",
          icon: "images/sponsors/hunting/doadecoycompany.png",
          svg: "doadecoycompany.svg",
        },
        {
          name: "DOGTRA",
          icon: "images/sponsors/hunting/dogtra.png",
          svg: "dogtra.svg",
        },
        {
          name: "DOGTRA #2",
          icon: "images/sponsors/hunting/dogtra-2.png",
          svg: "dogtra-2.svg",
        },
        {
          name: "DOUBLE BULL BLINDS",
          icon: "images/sponsors/hunting/doublebullblinds.png",
          svg: "doublebullblinds.svg",
        },
        {
          name: "DOUBLE BULL BLINDS #2",
          icon: "images/sponsors/hunting/doublebullblinds-2.png",
          svg: "doublebullblinds-2.svg",
        },
        {
          name: "DRAKE WATERFOWL SYSTEMS",
          icon: "images/sponsors/hunting/drakewaterfowlsystems.png",
          svg: "drakewaterfowlsystems.svg",
        },
        {
          name: "DUCKS UNLIMITED",
          icon: "images/sponsors/hunting/ducksunlimited.png",
          svg: "ducksunlimited.svg",
        },
        {
          name: "DUCKS UNLIMITED #2",
          icon: "images/sponsors/hunting/ducksunlimited-2.png",
          svg: "ducksunlimited-2.svg",
        },
        {
          name: "EBERLESTOCK",
          icon: "images/sponsors/hunting/eberlestock.png",
          svg: "eberlestock.svg",
        },
        {
          name: "EBERLESTOCK #2",
          icon: "images/sponsors/hunting/eberlestock-2.png",
          svg: "eberlestock-2.svg",
        },
        {
          name: "ECHO CALLS",
          icon: "images/sponsors/hunting/echocalls.png",
          svg: "echocalls.svg",
        },
        {
          name: "ELIMITRAX",
          icon: "images/sponsors/hunting/elimitrax.png",
          svg: "elimitrax.svg",
        },
        {
          name: "ELIMITRAX #2",
          icon: "images/sponsors/hunting/elimitrax-2.png",
          svg: "elimitrax-2.svg",
        },
        {
          name: "EOTECH",
          icon: "images/sponsors/hunting/eotech.png",
          svg: "eotech.svg",
        },
        {
          name: "EOTECH #2",
          icon: "images/sponsors/hunting/eotech-2.png",
          svg: "eotech-2.svg",
        },
        {
          name: "EOTECH #3",
          icon: "images/sponsors/hunting/eotech-3.png",
          svg: "eotech-3.svg",
        },
        {
          name: "ESTATE CARTRIDGE",
          icon: "images/sponsors/hunting/estatecartridge.png",
          svg: "estatecartridge.svg",
        },
        {
          name: "EVOLVED HABITATS",
          icon: "images/sponsors/hunting/evolvedhabitats.png",
          svg: "evolvedhabitats.svg",
        },
        {
          name: "EVOLVED HARVEST",
          icon: "images/sponsors/hunting/evolvedharvest.png",
          svg: "evolvedharvest.svg",
        },
        {
          name: "EXCALIBUR CROSSBOWS",
          icon: "images/sponsors/hunting/excaliburcrossbows.png",
          svg: "excaliburcrossbows.svg",
        },
        {
          name: "FEDERAL PREMIUM",
          icon: "images/sponsors/hunting/federalpremium.png",
          svg: "federalpremium.svg",
        },
        {
          name: "FEDERAL PREMIUM #2",
          icon: "images/sponsors/hunting/federalpremium-2.png",
          svg: "federalpremium-2.svg",
        },
        {
          name: "FIELDLINE",
          icon: "images/sponsors/hunting/fieldline.png",
          svg: "fieldline.svg",
        },
        {
          name: "FINAL APPROACH",
          icon: "images/sponsors/hunting/finalapproach.png",
          svg: "finalapproach.svg",
        },
        {
          name: "FIOCCHI AMMUNITION",
          icon: "images/sponsors/hunting/fiocchiammunition.png",
          svg: "fiocchiammunition.svg",
        },
        {
          name: "FIOCCHI AMMUNITION #2",
          icon: "images/sponsors/hunting/fiocchiammunition-2.png",
          svg: "fiocchiammunition-2.svg",
        },
        {
          name: "FIRST LIGHT",
          icon: "images/sponsors/hunting/firstlight.png",
          svg: "firstlight.svg",
        },
        {
          name: "FLAMBEAU OUTDOORS",
          icon: "images/sponsors/hunting/flambeauoutdoors.png",
          svg: "flambeauoutdoors.svg",
        },
        {
          name: "FLAMBEAU OUTDOORS #2",
          icon: "images/sponsors/hunting/flambeauoutdoors-2.png",
          svg: "flambeauoutdoors-2.svg",
        },
        {
          name: "FN FIREARMS",
          icon: "images/sponsors/hunting/fnfirearms.png",
          svg: "fnfirearms.svg",
        },
        {
          name: "FN FIREARMS #2",
          icon: "images/sponsors/hunting/fnfirearms-2.png",
          svg: "fnfirearms-2.svg",
        },
        {
          name: "FOILES",
          icon: "images/sponsors/hunting/foiles.png",
          svg: "foiles.svg",
        },
        {
          name: "FOXPRO",
          icon: "images/sponsors/hunting/foxpro.png",
          svg: "foxpro.svg",
        },
        {
          name: "FOXPRO #2",
          icon: "images/sponsors/hunting/foxpro-2.png",
          svg: "foxpro-2.svg",
        },
        {
          name: "FRANCHI",
          icon: "images/sponsors/hunting/franchi.png",
          svg: "franchi.svg",
        },
        {
          name: "FRANCHI #2",
          icon: "images/sponsors/hunting/franchi-2.png",
          svg: "franchi-2.svg",
        },
        {
          name: "FREEDOM MUNITIONS",
          icon: "images/sponsors/hunting/freedommunitions.png",
          svg: "freedommunitions.svg",
        },
        {
          name: "GALCO",
          icon: "images/sponsors/hunting/galco.png",
          svg: "galco.svg",
        },
        {
          name: "GALCO #2",
          icon: "images/sponsors/hunting/galco-2.png",
          svg: "galco-2.svg",
        },
        {
          name: "GAMEHIDE",
          icon: "images/sponsors/hunting/gamehide.png",
          svg: "gamehide.svg",
        },
        {
          name: "GATOR TRAX",
          icon: "images/sponsors/hunting/gatortrax.png",
          svg: "gatortrax.svg",
        },
        {
          name: "GATOR TRAX #2",
          icon: "images/sponsors/hunting/gatortrax-2.png",
          svg: "gatortrax-2.svg",
        },
        {
          name: "GERBER KNIVES",
          icon: "images/sponsors/hunting/gerberknives.png",
          svg: "gerberknives.svg",
        },
        {
          name: "GERBER KNIVES #2",
          icon: "images/sponsors/hunting/gerberknives-2.png",
          svg: "gerberknives-2.svg",
        },
        {
          name: "GHG GREENHEAD GEAR",
          icon: "images/sponsors/hunting/ghggreenheadgear.png",
          svg: "ghggreenheadgear.svg",
        },
        {
          name: "GHG GREENHEAD GEAR #2",
          icon: "images/sponsors/hunting/ghggreenheadgear-2.png",
          svg: "ghggreenheadgear-2.svg",
        },
        {
          name: "GLOCK",
          icon: "images/sponsors/hunting/glock.png",
          svg: "glock.svg",
        },
        {
          name: "GLOCK #2",
          icon: "images/sponsors/hunting/glock-2.png",
          svg: "glock-2.svg",
        },
        {
          name: "GO PRO",
          icon: "images/sponsors/hunting/gopro.png",
          svg: "gopro.svg",
        },
        {
          name: "GO PRO #2",
          icon: "images/sponsors/hunting/gopro-2.png",
          svg: "gopro-2.svg",
        },
        {
          name: "GOEY",
          icon: "images/sponsors/hunting/goey.png",
          svg: "goey.svg",
        },
        {
          name: "GUN VAULT",
          icon: "images/sponsors/hunting/gunvault.png",
          svg: "gunvault.svg",
        },
        {
          name: "GUN VAULT #2",
          icon: "images/sponsors/hunting/gunvault-2.png",
          svg: "gunvault-2.svg",
        },
        {
          name: "GUNWERKS",
          icon: "images/sponsors/hunting/gunwerks.png",
          svg: "gunwerks.svg",
        },
        {
          name: "GUNWERKS #2",
          icon: "images/sponsors/hunting/gunwerks-2.png",
          svg: "gunwerks-2.svg",
        },
        {
          name: "GUNWERKS #3",
          icon: "images/sponsors/hunting/gunwerks-3.png",
          svg: "gunwerks-3.svg",
        },
        {
          name: "GUNWERKS #4",
          icon: "images/sponsors/hunting/gunwerks-4.png",
          svg: "gunwerks-4.svg",
        },
        {
          name: "H&R",
          icon: "images/sponsors/hunting/h&r.png",
          svg: "h&r.svg",
        },
        {
          name: "H&R #2",
          icon: "images/sponsors/hunting/h&r-2.png",
          svg: "h&r-2.svg",
        },
        // {
        // name: "HAPPY HERD NUTRITION",
        // icon: "images/sponsors/hunting/happyherdnutrition.png",
        // svg: "happyherdnutrition.svg"
        // } ,
        {
          name: "HARD CORE",
          icon: "images/sponsors/hunting/hardcore.png",
          svg: "hardcore.svg",
        },
        {
          name: "HARD CORE #2",
          icon: "images/sponsors/hunting/hardcore-2.png",
          svg: "hardcore-2.svg",
        },
        {
          name: "HAVALON KNIVES",
          icon: "images/sponsors/hunting/havalonknives.png",
          svg: "havalonknives.svg",
        },
        {
          name: "HAVALON KNIVES #2",
          icon: "images/sponsors/hunting/havalonknives-2.png",
          svg: "havalonknives-2.svg",
        },
        {
          name: "HAWK",
          icon: "images/sponsors/hunting/hawk.png",
          svg: "hawk.svg",
        },
        {
          name: "HAWK #2",
          icon: "images/sponsors/hunting/hawk-2.png",
          svg: "hawk-2.svg",
        },
        {
          name: "HAYDEL'S GAME CALLS",
          icon: "images/sponsors/hunting/haydel'sgamecalls.png",
          svg: "haydel'sgamecalls.svg",
        },
        {
          name: "HAYES CALLS",
          icon: "images/sponsors/hunting/hayescalls.png",
          svg: "hayescalls.svg",
        },
        {
          name: "HEATER BODY SUIT",
          icon: "images/sponsors/hunting/heaterbodysuit.png",
          svg: "heaterbodysuit.svg",
        },
        {
          name: "HEAVY HAULER",
          icon: "images/sponsors/hunting/heavyhauler.png",
          svg: "heavyhauler.svg",
        },
        {
          name: "HENRY",
          icon: "images/sponsors/hunting/henry.png",
          svg: "henry.svg",
        },
        {
          name: "HENRY #2",
          icon: "images/sponsors/hunting/henry-2.png",
          svg: "henry-2.svg",
        },
        // {
        // name: "HEVI SHOT",
        // icon: "images/sponsors/hunting/hevishot.png",
        // svg: "hevishot.svg"
        // } ,
        {
          name: "HI-POINT FIREARMS",
          icon: "images/sponsors/hunting/hi-pointfirearms.png",
          svg: "hi-pointfirearms.svg",
        },
        {
          name: "HI-POINT FIREARMS #2",
          icon: "images/sponsors/hunting/hi-pointfirearms-2.png",
          svg: "hi-pointfirearms-2.svg",
        },
        {
          name: "HIGDON OUTDOORS",
          icon: "images/sponsors/hunting/higdonoutdoors.png",
          svg: "higdonoutdoors.svg",
        },
        {
          name: "HME PRODUCTS",
          icon: "images/sponsors/hunting/hmeproducts.png",
          svg: "hmeproducts.svg",
        },
        {
          name: "HOOYMAN",
          icon: "images/sponsors/hunting/hooyman.png",
          svg: "hooyman.svg",
        },
        {
          name: "HOOYMAN #2",
          icon: "images/sponsors/hunting/hooyman-2.png",
          svg: "hooyman-2.svg",
        },
        {
          name: "HORNADY",
          icon: "images/sponsors/hunting/hornady.png",
          svg: "hornady.svg",
        },
        {
          name: "HORNADY #2",
          icon: "images/sponsors/hunting/hornady-2.png",
          svg: "hornady-2.svg",
        },
        {
          name: "HORNADY #3",
          icon: "images/sponsors/hunting/hornady-3.png",
          svg: "hornady-3.svg",
        },
        {
          name: "HORNADY #4",
          icon: "images/sponsors/hunting/hornady-4.png",
          svg: "hornady-4.svg",
        },
        {
          name: "HORTON CROSSBOW",
          icon: "images/sponsors/hunting/hortoncrossbow.png",
          svg: "hortoncrossbow.svg",
        },
        {
          name: "HOWA",
          icon: "images/sponsors/hunting/howa.png",
          svg: "howa.svg",
        },
        {
          name: "HOWA #2",
          icon: "images/sponsors/hunting/howa-2.png",
          svg: "howa-2.svg",
        },
        {
          name: "HPR",
          icon: "images/sponsors/hunting/hpr.png",
          svg: "hpr.svg",
        },
        {
          name: "HUNTERS SPECIALTIES",
          icon: "images/sponsors/hunting/huntersspecialties.png",
          svg: "huntersspecialties.svg",
        },
        {
          name: "HUNTERS SPECIALTIES #2",
          icon: "images/sponsors/hunting/huntersspecialties-2.png",
          svg: "huntersspecialties-2.svg",
        },
        {
          name: "HUNTWORTH",
          icon: "images/sponsors/hunting/huntworth.png",
          svg: "huntworth.svg",
        },
        {
          name: "ITHACA",
          icon: "images/sponsors/hunting/ithaca.png",
          svg: "ithaca.svg",
        },
        {
          name: "ITHACA 2",
          icon: "images/sponsors/hunting/ithaca2.png",
          svg: "ithaca2.svg",
        },
        {
          name: "ITHACA #3",
          icon: "images/sponsors/hunting/ithaca-3.png",
          svg: "ithaca-3.svg",
        },
        {
          name: "ITHACA #4",
          icon: "images/sponsors/hunting/ithaca-4.png",
          svg: "ithaca-4.svg",
        },
        {
          name: "JOHNNY STEWART",
          icon: "images/sponsors/hunting/johnnystewart.png",
          svg: "johnnystewart.svg",
        },
        {
          name: "JOHNNY STEWART #2",
          icon: "images/sponsors/hunting/johnnystewart-2.png",
          svg: "johnnystewart-2.svg",
        },
        {
          name: "KEL TEC",
          icon: "images/sponsors/hunting/keltec.png",
          svg: "keltec.svg",
        },
        {
          name: "KERSHAW KNIVES",
          icon: "images/sponsors/hunting/kershawknives.png",
          svg: "kershawknives.svg",
        },
        {
          name: "KERSHAW KNIVES #2",
          icon: "images/sponsors/hunting/kershawknives-2.png",
          svg: "kershawknives-2.svg",
        },
        {
          name: "KIMBER",
          icon: "images/sponsors/hunting/kimber.png",
          svg: "kimber.svg",
        },
        {
          name: "KNIGHT",
          icon: "images/sponsors/hunting/knight.png",
          svg: "knight.svg",
        },
        {
          name: "KNIGHT 2",
          icon: "images/sponsors/hunting/knight2.png",
          svg: "knight2.svg",
        },
        {
          name: "KNIGHT & HALE",
          icon: "images/sponsors/hunting/knight&hale.png",
          svg: "knight&hale.svg",
        },
        {
          name: "KNIGHT & HALE #2",
          icon: "images/sponsors/hunting/knight&hale-2.png",
          svg: "knight&hale-2.svg",
        },
        {
          name: "LASERLYTE",
          icon: "images/sponsors/hunting/laserlyte.png",
          svg: "laserlyte.svg",
        },
        {
          name: "LASERLYTE 2",
          icon: "images/sponsors/hunting/laserlyte2.png",
          svg: "laserlyte2.svg",
        },
        {
          name: "LEICA",
          icon: "images/sponsors/hunting/leica.png",
          svg: "leica.svg",
        },
        {
          name: "LELLIER & BELLOT",
          icon: "images/sponsors/hunting/lellier&bellot.png",
          svg: "lellier&bellot.svg",
        },
        {
          name: "LETHAL",
          icon: "images/sponsors/hunting/lethal.png",
          svg: "lethal.svg",
        },
        {
          name: "LETHAL #2",
          icon: "images/sponsors/hunting/lethal-2.png",
          svg: "lethal-2.svg",
        },
        {
          name: "LETHAL #3",
          icon: "images/sponsors/hunting/lethal-3.png",
          svg: "lethal-3.svg",
        },
        {
          name: "LEUPOLD",
          icon: "images/sponsors/hunting/leupold.png",
          svg: "leupold.svg",
        },
        {
          name: "LIMBSAVER",
          icon: "images/sponsors/hunting/limbsaver.png",
          svg: "limbsaver.svg",
        },
        {
          name: "LLAMA",
          icon: "images/sponsors/hunting/llama.png",
          svg: "llama.svg",
        },
        {
          name: "LONE WOLF TREESTANDS",
          icon: "images/sponsors/hunting/lonewolftreestands.png",
          svg: "lonewolftreestands.svg",
        },
        {
          name: "LONE WOLF TREESTANDS #2",
          icon: "images/sponsors/hunting/lonewolftreestands-2.png",
          svg: "lonewolftreestands-2.svg",
        },
        {
          name: "LUCKY BUCK MINERAL",
          icon: "images/sponsors/hunting/luckybuckmineral.png",
          svg: "luckybuckmineral.svg",
        },
        {
          name: "LUCKY DUCK DECOYS",
          icon: "images/sponsors/hunting/luckyduckdecoys.png",
          svg: "luckyduckdecoys.svg",
        },
        {
          name: "LUCKY DUCK DECOYS",
          icon: "images/sponsors/hunting/luckyduckdecoys-2.png",
          svg: "luckyduckdecoys-2.svg",
        },
        {
          name: "LYNCH",
          icon: "images/sponsors/hunting/lynch.png",
          svg: "lynch.svg",
        },
        {
          name: "LYNCH #2",
          icon: "images/sponsors/hunting/lynch-2.png",
          svg: "lynch-2.svg",
        },
        {
          name: "MAGNUM RESEARCH",
          icon: "images/sponsors/hunting/magnumresearch.png",
          svg: "magnumresearch.svg",
        },
        {
          name: "MAGNUM RESEARCH #2",
          icon: "images/sponsors/hunting/magnumresearch-2.png",
          svg: "magnumresearch-2.svg",
        },
        {
          name: "MALLARDTONE",
          icon: "images/sponsors/hunting/mallardtone.png",
          svg: "mallardtone.svg",
        },
        {
          name: "MALLARDTONE #2",
          icon: "images/sponsors/hunting/mallardtone-2.png",
          svg: "mallardtone-2.svg",
        },
        {
          name: "MARLIN",
          icon: "images/sponsors/hunting/marlin.png",
          svg: "marlin.svg",
        },
        {
          name: "MARLIN #2",
          icon: "images/sponsors/hunting/marlin-2.png",
          svg: "marlin-2.svg",
        },
        {
          name: "MARSH RAT BOATS",
          icon: "images/sponsors/hunting/marshratboats.png",
          svg: "marshratboats.svg",
        },
        {
          name: "MARSH RAT BOATS #2",
          icon: "images/sponsors/hunting/marshratboats-2.png",
          svg: "marshratboats-2.svg",
        },
        {
          name: "MEC",
          icon: "images/sponsors/hunting/mec.png",
          svg: "mec.svg",
        },
        {
          name: "MEC #2",
          icon: "images/sponsors/hunting/mec-2.png",
          svg: "mec-2.svg",
        },
        {
          name: "MEOPTA",
          icon: "images/sponsors/hunting/meopta.png",
          svg: "meopta.svg",
        },
        {
          name: "MILLENNIUM TREESTANDS",
          icon: "images/sponsors/hunting/millenniumtreestands.png",
          svg: "millenniumtreestands.svg",
        },
        {
          name: "MILLENNIUM TREESTANDS #2",
          icon: "images/sponsors/hunting/millenniumtreestands-2.png",
          svg: "millenniumtreestands-2.svg",
        },
        {
          name: "MOJO OUTDOORS",
          icon: "images/sponsors/hunting/mojooutdoors.png",
          svg: "mojooutdoors.svg",
        },
        {
          name: "MOJO OUTDOORS #2",
          icon: "images/sponsors/hunting/mojooutdoors-2.png",
          svg: "mojooutdoors-2.svg",
        },
        {
          name: "MOJO OUTDOORS #3",
          icon: "images/sponsors/hunting/mojooutdoors-3.png",
          svg: "mojooutdoors-3.svg",
        },
        {
          name: "MOJO OUTDOORS #4",
          icon: "images/sponsors/hunting/mojooutdoors-4.png",
          svg: "mojooutdoors-4.svg",
        },
        {
          name: "MOJO OUTDOORS #5",
          icon: "images/sponsors/hunting/mojooutdoors-5.png",
          svg: "mojooutdoors-5.svg",
        },
        {
          name: "MOMARSH",
          icon: "images/sponsors/hunting/momarsh.png",
          svg: "momarsh.svg",
        },
        {
          name: "MOSIN NAGANT",
          icon: "images/sponsors/hunting/mosinnagant.png",
          svg: "mosinnagant.svg",
        },
        {
          name: "MOSSBERG",
          icon: "images/sponsors/hunting/mossberg.png",
          svg: "mossberg.svg",
        },
        {
          name: "MOULTRIE",
          icon: "images/sponsors/hunting/moultrie.png",
          svg: "moultrie.svg",
        },
        {
          name: "MUCK BOOTS",
          icon: "images/sponsors/hunting/muckboots.png",
          svg: "muckboots.svg",
        },
        {
          name: "MUD BUDDY",
          icon: "images/sponsors/hunting/mudbuddy.png",
          svg: "mudbuddy.svg",
        },
        {
          name: "MUD BUDDY #2",
          icon: "images/sponsors/hunting/mudbuddy-2.png",
          svg: "mudbuddy-2.svg",
        },
        {
          name: "MUDDY OUTDOORS",
          icon: "images/sponsors/hunting/muddyoutdoors.png",
          svg: "muddyoutdoors.svg",
        },
        {
          name: "MUDDY OUTDOORS #2",
          icon: "images/sponsors/hunting/muddyoutdoors-2.png",
          svg: "muddyoutdoors-2.svg",
        },
        {
          name: "NIGHT OWL OPTICS",
          icon: "images/sponsors/hunting/nightowloptics.png",
          svg: "nightowloptics.svg",
        },
        {
          name: "NIGHTFORCE",
          icon: "images/sponsors/hunting/nightforce.png",
          svg: "nightforce.svg",
        },
        {
          name: "NIGHTFORCE #2",
          icon: "images/sponsors/hunting/nightforce-2.png",
          svg: "nightforce-2.svg",
        },
        {
          name: "NIKON",
          icon: "images/sponsors/hunting/nikon.png",
          svg: "nikon.svg",
        },
        {
          name: "NITE SITE",
          icon: "images/sponsors/hunting/nitesite.png",
          svg: "nitesite.svg",
        },
        {
          name: "NITE SITE #2",
          icon: "images/sponsors/hunting/nitesite-2.png",
          svg: "nitesite-2.svg",
        },
        {
          name: "NORINCO",
          icon: "images/sponsors/hunting/norinco.png",
          svg: "norinco.svg",
        },
        {
          name: "NORINCO #2",
          icon: "images/sponsors/hunting/norinco-2.png",
          svg: "norinco-2.svg",
        },
        {
          name: "NOSE JAMMER",
          icon: "images/sponsors/hunting/nosejammer.png",
          svg: "nosejammer.svg",
        },
        {
          name: "NOSLER BULLETS",
          icon: "images/sponsors/hunting/noslerbullets.png",
          svg: "noslerbullets.svg",
        },
        {
          name: "NOSLER BULLETS #2",
          icon: "images/sponsors/hunting/noslerbullets-2.png",
          svg: "noslerbullets-2.svg",
        },
        {
          name: "NOSLER BULLETS #3",
          icon: "images/sponsors/hunting/noslerbullets-3.png",
          svg: "noslerbullets-3.svg",
        },
        {
          name: "NOSLER BULLETS #4",
          icon: "images/sponsors/hunting/noslerbullets-4.png",
          svg: "noslerbullets-4.svg",
        },
        {
          name: "OL MAN",
          icon: "images/sponsors/hunting/olman.png",
          svg: "olman.svg",
        },
        {
          name: "OPMOD",
          icon: "images/sponsors/hunting/opmod.png",
          svg: "opmod.svg",
        },
        {
          name: "OPMOD #2",
          icon: "images/sponsors/hunting/opmod-2.png",
          svg: "opmod-2.svg",
        },
        // {
        // name: "OUTDOOR EDGE",
        // icon: "images/sponsors/hunting/outdooredge.png",
        // svg: "outdooredge.svg"
        // } ,
        {
          name: "OZONICS",
          icon: "images/sponsors/hunting/ozonics.png",
          svg: "ozonics.svg",
        },
        {
          name: "OZONICS #2",
          icon: "images/sponsors/hunting/ozonics-2.png",
          svg: "ozonics-2.svg",
        },
        {
          name: "PETZL",
          icon: "images/sponsors/hunting/petzl.png",
          svg: "petzl.svg",
        },
        {
          name: "PETZL #2",
          icon: "images/sponsors/hunting/petzl-2.png",
          svg: "petzl-2.svg",
        },
        {
          name: "PITTMAN GAME CALLS",
          icon: "images/sponsors/hunting/pittmangamecalls.png",
          svg: "pittmangamecalls.svg",
        },
        {
          name: "PMC AMMUNITION",
          icon: "images/sponsors/hunting/pmcammunition.png",
          svg: "pmcammunition.svg",
        },
        {
          name: "PMC AMMUNITION #2",
          icon: "images/sponsors/hunting/pmcammunition-2.png",
          svg: "pmcammunition-2.svg",
        },
        {
          name: "PRIMOS HUNTING CALLS",
          icon: "images/sponsors/hunting/primoshuntingcalls.png",
          svg: "primoshuntingcalls.svg",
        },
        {
          name: "PRIMOS HUNTING CALLS #2",
          icon: "images/sponsors/hunting/primoshuntingcalls-2.png",
          svg: "primoshuntingcalls-2.svg",
        },
        {
          name: "QUAKER BOY",
          icon: "images/sponsors/hunting/quakerboy.png",
          svg: "quakerboy.svg",
        },
        {
          name: "RACK STACKER",
          icon: "images/sponsors/hunting/rackstacker.png",
          svg: "rackstacker.svg",
        },
        {
          name: "RACK STACKER #2",
          icon: "images/sponsors/hunting/rackstacker-2.png",
          svg: "rackstacker-2.svg",
        },
        {
          name: "RECONYX",
          icon: "images/sponsors/hunting/reconyx.png",
          svg: "reconyx.svg",
        },
        {
          name: "REDFIELD",
          icon: "images/sponsors/hunting/redfield.png",
          svg: "redfield.svg",
        },
        {
          name: "REDFIELD #2",
          icon: "images/sponsors/hunting/redfield-2.png",
          svg: "redfield-2.svg",
        },
        {
          name: "REDNECK BLINDS",
          icon: "images/sponsors/hunting/redneckblinds.png",
          svg: "redneckblinds.svg",
        },
        {
          name: "REDNECK BLINDS #2",
          icon: "images/sponsors/hunting/redneckblinds-2.png",
          svg: "redneckblinds-2.svg",
        },
        {
          name: "REMINGTON",
          icon: "images/sponsors/hunting/remington.png",
          svg: "remington.svg",
        },
        {
          name: "REMINGTON #2",
          icon: "images/sponsors/hunting/remington-2.png",
          svg: "remington-2.svg",
        },
        {
          name: "RHINO BLINDS",
          icon: "images/sponsors/hunting/rhinoblinds.png",
          svg: "rhinoblinds.svg",
        },
        {
          name: "RHINO BLINDS #2",
          icon: "images/sponsors/hunting/rhinoblinds-2.png",
          svg: "rhinoblinds-2.svg",
        },
        {
          name: "RIG'EM RIGHT",
          icon: "images/sponsors/hunting/rig'emright.png",
          svg: "rig'emright.svg",
        },
        {
          name: "RIG'EM RIGHT #2",
          icon: "images/sponsors/hunting/rig'emright-2.png",
          svg: "rig'emright-2.svg",
        },
        {
          name: "RIO",
          icon: "images/sponsors/hunting/rio.png",
          svg: "rio.svg",
        },
        {
          name: "RIO #2",
          icon: "images/sponsors/hunting/rio-2.png",
          svg: "rio-2.svg",
        },
        {
          name: "RIO #3",
          icon: "images/sponsors/hunting/rio-3.png",
          svg: "rio-3.svg",
        },
        {
          name: "RIO #4",
          icon: "images/sponsors/hunting/rio-4.png",
          svg: "rio-4.svg",
        },
        {
          name: "RNT CALLS",
          icon: "images/sponsors/hunting/rntcalls.png",
          svg: "rntcalls.svg",
        },
        {
          name: "RNT CALLS #2",
          icon: "images/sponsors/hunting/rntcalls-2.png",
          svg: "rntcalls-2.svg",
        },
        {
          name: "RUDOLPH OPTICS",
          icon: "images/sponsors/hunting/rudolphoptics.png",
          svg: "rudolphoptics.svg",
        },
        {
          name: "RUDOLPH OPTICS #2",
          icon: "images/sponsors/hunting/rudolphoptics-2.png",
          svg: "rudolphoptics-2.svg",
        },
        {
          name: "RUGER",
          icon: "images/sponsors/hunting/ruger.png",
          svg: "ruger.svg",
        },
        {
          name: "RUGER #2",
          icon: "images/sponsors/hunting/ruger-2.png",
          svg: "ruger-2.svg",
        },
        // {
        // name: "S_EAGLE_2018_1_OZ-01-popup swe",
        // icon: "images/sponsors/hunting/s_eagle_2018_1_oz-01-popupswe.png",
        // svg: "s_eagle_2018_1_oz-01-popupswe.svg"
        // } ,
        {
          name: "SAKO",
          icon: "images/sponsors/hunting/sako.png",
          svg: "sako.svg",
        },
        {
          name: "SAKO #2",
          icon: "images/sponsors/hunting/sako-2.png",
          svg: "sako-2.svg",
        },
        {
          name: "SCENT BLOCKER",
          icon: "images/sponsors/hunting/scentblocker.png",
          svg: "scentblocker.svg",
        },
        {
          name: "SCENT BLOCKER #2",
          icon: "images/sponsors/hunting/scentblocker-2.png",
          svg: "scentblocker-2.svg",
        },
        {
          name: "SCENT CRUSHER",
          icon: "images/sponsors/hunting/scentcrusher.png",
          svg: "scentcrusher.svg",
        },
        {
          name: "SCENT KILLER",
          icon: "images/sponsors/hunting/scentkiller.png",
          svg: "scentkiller.svg",
        },
        {
          name: "SCENT KILLER #2",
          icon: "images/sponsors/hunting/scentkiller-2.png",
          svg: "scentkiller-2.svg",
        },
        {
          name: "SCENT-LOK",
          icon: "images/sponsors/hunting/scent-lok.png",
          svg: "scent-lok.svg",
        },
        {
          name: "SCI",
          icon: "images/sponsors/hunting/sci.png",
          svg: "sci.svg",
        },
        {
          name: "SCI #2",
          icon: "images/sponsors/hunting/sci-2.png",
          svg: "sci-2.svg",
        },
        {
          name: "SEAN MANN OUTDOORS",
          icon: "images/sponsors/hunting/seanmannoutdoors.png",
          svg: "seanmannoutdoors.svg",
        },
        {
          name: "SIG SAUER",
          icon: "images/sponsors/hunting/sigsauer.png",
          svg: "sigsauer.svg",
        },
        {
          name: "SIG SAUER #2",
          icon: "images/sponsors/hunting/sigsauer-2.png",
          svg: "sigsauer-2.svg",
        },
        {
          name: "SIGHT MARK",
          icon: "images/sponsors/hunting/sightmark.png",
          svg: "sightmark.svg",
        },
        {
          name: "SIGHT MARK #2",
          icon: "images/sponsors/hunting/sightmark-2.png",
          svg: "sightmark-2.svg",
        },
        {
          name: "SILENCER CO",
          icon: "images/sponsors/hunting/silencerco.png",
          svg: "silencerco.svg",
        },
        {
          name: "SILENCER CO #2",
          icon: "images/sponsors/hunting/silencerco-2.png",
          svg: "silencerco-2.svg",
        },
        {
          name: "SIMMONS",
          icon: "images/sponsors/hunting/simmons.png",
          svg: "simmons.svg",
        },
        {
          name: "SIMMONS #2",
          icon: "images/sponsors/hunting/simmons-2.png",
          svg: "simmons-2.svg",
        },
        {
          name: "SITKA",
          icon: "images/sponsors/hunting/sitka.png",
          svg: "sitka.svg",
        },
        {
          name: "SITKA #2",
          icon: "images/sponsors/hunting/sitka-2.png",
          svg: "sitka-2.svg",
        },
        {
          name: "SKB",
          icon: "images/sponsors/hunting/skb.png",
          svg: "skb.svg",
        },
        {
          name: "SKB #2",
          icon: "images/sponsors/hunting/skb-2.png",
          svg: "skb-2.svg",
        },
        {
          name: "SMITH & WESSON",
          icon: "images/sponsors/hunting/smith&wesson.png",
          svg: "smith&wesson.svg",
        },
        {
          name: "SMITH & WESSON #2",
          icon: "images/sponsors/hunting/smith&wesson-2.png",
          svg: "smith&wesson-2.svg",
        },
        {
          name: "SMITH & WESSON #3",
          icon: "images/sponsors/hunting/smith&wesson-3.png",
          svg: "smith&wesson-3.svg",
        },
        {
          name: "SMITH & WESSON #4",
          icon: "images/sponsors/hunting/smith&wesson-4.png",
          svg: "smith&wesson-4.svg",
        },
        {
          name: "SPARTAN CAMERA",
          icon: "images/sponsors/hunting/spartancamera.png",
          svg: "spartancamera.svg",
        },
        {
          name: "SPARTAN CAMERA #2",
          icon: "images/sponsors/hunting/spartancamera-2.png",
          svg: "spartancamera-2.svg",
        },
        {
          name: "SPORTDOG",
          icon: "images/sponsors/hunting/sportdog.png",
          svg: "sportdog.svg",
        },
        {
          name: "SPORTDOG #2",
          icon: "images/sponsors/hunting/sportdog-2.png",
          svg: "sportdog-2.svg",
        },
        {
          name: "SPOT",
          icon: "images/sponsors/hunting/spot.png",
          svg: "spot.svg",
        },
        {
          name: "SPOT #2",
          icon: "images/sponsors/hunting/spot-2.png",
          svg: "spot-2.svg",
        },
        {
          name: "SPYPOINT",
          icon: "images/sponsors/hunting/spypoint.png",
          svg: "spypoint.svg",
        },
        {
          name: "STEALTH CAM",
          icon: "images/sponsors/hunting/stealthcam.png",
          svg: "stealthcam.svg",
        },
        {
          name: "STEALTH CAM #2",
          icon: "images/sponsors/hunting/stealthcam-2.png",
          svg: "stealthcam-2.svg",
        },
        {
          name: "STEINER",
          icon: "images/sponsors/hunting/steiner.png",
          svg: "steiner.svg",
        },
        {
          name: "STOEGER",
          icon: "images/sponsors/hunting/stoeger.png",
          svg: "stoeger.svg",
        },
        {
          name: "STOEGER #2",
          icon: "images/sponsors/hunting/stoeger-2.png",
          svg: "stoeger-2.svg",
        },
        {
          name: "STREAMLIGHT",
          icon: "images/sponsors/hunting/streamlight.png",
          svg: "streamlight.svg",
        },
        {
          name: "STREAMLIGHT #2",
          icon: "images/sponsors/hunting/streamlight-2.png",
          svg: "streamlight-2.svg",
        },
        {
          name: "SUMMIT TREESTANDS",
          icon: "images/sponsors/hunting/summittreestands.png",
          svg: "summittreestands.svg",
        },
        {
          name: "SUMMIT TREESTANDS #2",
          icon: "images/sponsors/hunting/summittreestands-2.png",
          svg: "summittreestands-2.svg",
        },
        {
          name: "SWAROVSKI",
          icon: "images/sponsors/hunting/swarovski.png",
          svg: "swarovski.svg",
        },
        {
          name: "SWAROVSKI #2",
          icon: "images/sponsors/hunting/swarovski-2.png",
          svg: "swarovski-2.svg",
        },
        {
          name: "TACTACAM",
          icon: "images/sponsors/hunting/tactacam.png",
          svg: "tactacam.svg",
        },
        {
          name: "TACTACAM #2",
          icon: "images/sponsors/hunting/tactacam-2.png",
          svg: "tactacam-2.svg",
        },
        {
          name: "TANGLEFREE",
          icon: "images/sponsors/hunting/tanglefree.png",
          svg: "tanglefree.svg",
        },
        {
          name: "TANGLEFREE #2",
          icon: "images/sponsors/hunting/tanglefree-2.png",
          svg: "tanglefree-2.svg",
        },
        {
          name: "TASCO",
          icon: "images/sponsors/hunting/tasco.png",
          svg: "tasco.svg",
        },
        {
          name: "TASCO #2",
          icon: "images/sponsors/hunting/tasco-2.png",
          svg: "tasco-2.svg",
        },
        {
          name: "TAURUS",
          icon: "images/sponsors/hunting/taurus.png",
          svg: "taurus.svg",
        },
        {
          name: "TAURUS #2",
          icon: "images/sponsors/hunting/taurus-2.png",
          svg: "taurus-2.svg",
        },
        {
          name: "TAURUS #3",
          icon: "images/sponsors/hunting/taurus-3.png",
          svg: "taurus-3.svg",
        },
        {
          name: "TAURUS #4",
          icon: "images/sponsors/hunting/taurus-4.png",
          svg: "taurus-4.svg",
        },
        {
          name: "TENPOINT CROSSBOWS",
          icon: "images/sponsors/hunting/tenpointcrossbows.png",
          svg: "tenpointcrossbows.svg",
        },
        {
          name: "TENPOINT CROSSBOWS #2",
          icon: "images/sponsors/hunting/tenpointcrossbows-2.png",
          svg: "tenpointcrossbows-2.svg",
        },
        {
          name: "TENZING",
          icon: "images/sponsors/hunting/tenzing.png",
          svg: "tenzing.svg",
        },
        {
          name: "THOMPSON CENTER",
          icon: "images/sponsors/hunting/thompsoncenter.png",
          svg: "thompsoncenter.svg",
        },
        {
          name: "THOMPSON CENTER #2",
          icon: "images/sponsors/hunting/thompsoncenter-2.png",
          svg: "thompsoncenter-2.svg",
        },
        {
          name: "TIKKA",
          icon: "images/sponsors/hunting/tikka.png",
          svg: "tikka.svg",
        },
        {
          name: "TIKKA #2",
          icon: "images/sponsors/hunting/tikka-2.png",
          svg: "tikka-2.svg",
        },
        {
          name: "TIM GROUNDS CALLS",
          icon: "images/sponsors/hunting/timgroundscalls.png",
          svg: "timgroundscalls.svg",
        },
        {
          name: "TIM GROUNDS CALLS #2",
          icon: "images/sponsors/hunting/timgroundscalls-2.png",
          svg: "timgroundscalls-2.svg",
        },
        {
          name: "TINKS",
          icon: "images/sponsors/hunting/tinks.png",
          svg: "tinks.svg",
        },
        {
          name: "TINKS #2",
          icon: "images/sponsors/hunting/tinks-2.png",
          svg: "tinks-2.svg",
        },
        {
          name: "TOM TEASERS",
          icon: "images/sponsors/hunting/tomteasers.png",
          svg: "tomteasers.svg",
        },
        {
          name: "TOM TEASERS #2",
          icon: "images/sponsors/hunting/tomteasers-2.png",
          svg: "tomteasers-2.svg",
        },
        {
          name: "TOP SECRET DEER SCENTS",
          icon: "images/sponsors/hunting/topsecretdeerscents.png",
          svg: "topsecretdeerscents.svg",
        },
        {
          name: "TRACT OPTICS",
          icon: "images/sponsors/hunting/tractoptics.png",
          svg: "tractoptics.svg",
        },
        {
          name: "TRACT OPTICS #2",
          icon: "images/sponsors/hunting/tractoptics-2.png",
          svg: "tractoptics-2.svg",
        },
        {
          name: "TREE SPIDER",
          icon: "images/sponsors/hunting/treespider.png",
          svg: "treespider.svg",
        },
        {
          name: "TREE SPIDER #2",
          icon: "images/sponsors/hunting/treespider-2.png",
          svg: "treespider-2.svg",
        },
        {
          name: "TRIGGER X",
          icon: "images/sponsors/hunting/triggerx.png",
          svg: "triggerx.svg",
        },
        {
          name: "TRIGGER X #2",
          icon: "images/sponsors/hunting/triggerx-2.png",
          svg: "triggerx-2.svg",
        },
        {
          name: "TRIJICON",
          icon: "images/sponsors/hunting/trijicon.png",
          svg: "trijicon.svg",
        },
        {
          name: "TRIJICON #2",
          icon: "images/sponsors/hunting/trijicon-2.png",
          svg: "trijicon-2.svg",
        },
        {
          name: "TROPHY RIDGE",
          icon: "images/sponsors/hunting/trophyridge.png",
          svg: "trophyridge.svg",
        },
        {
          name: "TROPHY RIDGE #2",
          icon: "images/sponsors/hunting/trophyridge-2.png",
          svg: "trophyridge-2.svg",
        },
        {
          name: "TROPHY ROCK",
          icon: "images/sponsors/hunting/trophyrock.png",
          svg: "trophyrock.svg",
        },
        {
          name: "TRUGLO",
          icon: "images/sponsors/hunting/truglo.png",
          svg: "truglo.svg",
        },
        {
          name: "TRUGLO #2",
          icon: "images/sponsors/hunting/truglo-2.png",
          svg: "truglo-2.svg",
        },
        {
          name: "TRULOCK CHOKE TUBES",
          icon: "images/sponsors/hunting/trulockchoketubes.png",
          svg: "trulockchoketubes.svg",
        },
        {
          name: "TRULOCK CHOKE TUBES #2",
          icon: "images/sponsors/hunting/trulockchoketubes-2.png",
          svg: "trulockchoketubes-2.svg",
        },
        {
          name: "ULTRAMAX",
          icon: "images/sponsors/hunting/ultramax.png",
          svg: "ultramax.svg",
        },
        {
          name: "ULTRAMAX #2",
          icon: "images/sponsors/hunting/ultramax-2.png",
          svg: "ultramax-2.svg",
        },
        {
          name: "VAN STAAL",
          icon: "images/sponsors/hunting/vanstaal.png",
          svg: "vanstaal.svg",
        },
        {
          name: "VORTEX OPTICS",
          icon: "images/sponsors/hunting/vortexoptics.png",
          svg: "vortexoptics.svg",
        },
        {
          name: "VORTEX OPTICS #2",
          icon: "images/sponsors/hunting/vortexoptics-2.png",
          svg: "vortexoptics-2.svg",
        },
        {
          name: "VORTEX OPTICS #3",
          icon: "images/sponsors/hunting/vortexoptics-3.png",
          svg: "vortexoptics-3.svg",
        },
        {
          name: "WALKER'S GAME EAR",
          icon: "images/sponsors/hunting/walker'sgameear.png",
          svg: "walker'sgameear.svg",
        },
        {
          name: "WALKER'S GAME EAR #2",
          icon: "images/sponsors/hunting/walker'sgameear-2.png",
          svg: "walker'sgameear-2.svg",
        },
        {
          name: "WALTHER",
          icon: "images/sponsors/hunting/walther.png",
          svg: "walther.svg",
        },
        {
          name: "WALTHER #2",
          icon: "images/sponsors/hunting/walther-2.png",
          svg: "walther-2.svg",
        },
        {
          name: "WAR EAGLE BOATS",
          icon: "images/sponsors/hunting/wareagleboats.png",
          svg: "wareagleboats.svg",
        },
        {
          name: "WEATHERBY",
          icon: "images/sponsors/hunting/weatherby.png",
          svg: "weatherby.svg",
        },
        {
          name: "WEATHERBY #2",
          icon: "images/sponsors/hunting/weatherby-2.png",
          svg: "weatherby-2.svg",
        },
        {
          name: "WEAVER",
          icon: "images/sponsors/hunting/weaver.png",
          svg: "weaver.svg",
        },
        {
          name: "WHITETAIL INSTITUTE",
          icon: "images/sponsors/hunting/whitetailinstitute.png",
          svg: "whitetailinstitute.svg",
        },
        {
          name: "WHITETAIL WORLD",
          icon: "images/sponsors/hunting/whitetailworld.png",
          svg: "whitetailworld.svg",
        },
        {
          name: "WICKED LIGHTS",
          icon: "images/sponsors/hunting/wickedlights.png",
          svg: "wickedlights.svg",
        },
        {
          name: "WICKED RIDGE CROSSBOWS",
          icon: "images/sponsors/hunting/wickedridgecrossbows.png",
          svg: "wickedridgecrossbows.svg",
        },
        {
          name: "WILDGAME INNOVATIONS",
          icon: "images/sponsors/hunting/wildgameinnovations.png",
          svg: "wildgameinnovations.svg",
        },
        {
          name: "WILDLIFE RESEARCH CENTER",
          icon: "images/sponsors/hunting/wildliferesearchcenter.png",
          svg: "wildliferesearchcenter.svg",
        },
        {
          name: "WINCHESTER",
          icon: "images/sponsors/hunting/winchester.png",
          svg: "winchester.svg",
        },
        {
          name: "WINCHESTER #2",
          icon: "images/sponsors/hunting/winchester-2.png",
          svg: "winchester-2.svg",
        },
        {
          name: "WINCHESTER AA SHELLS",
          icon: "images/sponsors/hunting/winchesteraashells.png",
          svg: "winchesteraashells.svg",
        },
        {
          name: "WING NUTZ CALLS",
          icon: "images/sponsors/hunting/wingnutzcalls.png",
          svg: "wingnutzcalls.svg",
        },
        {
          name: "WOODHAVEN CUSTOM CALLS",
          icon: "images/sponsors/hunting/woodhavencustomcalls.png",
          svg: "woodhavencustomcalls.svg",
        },
        {
          name: "WOODHAVEN CUSTOM CALLS #2",
          icon: "images/sponsors/hunting/woodhavencustomcalls-2.png",
          svg: "woodhavencustomcalls-2.svg",
        },
        {
          name: "WOODS WISE",
          icon: "images/sponsors/hunting/woodswise.png",
          svg: "woodswise.svg",
        },
        {
          name: "X-STAND",
          icon: "images/sponsors/hunting/x-stand.png",
          svg: "x-stand.svg",
        },
        {
          name: "X-STAND #2",
          icon: "images/sponsors/hunting/x-stand-2.png",
          svg: "x-stand-2.svg",
        },
        {
          name: "XCITER CALLS",
          icon: "images/sponsors/hunting/xcitercalls.png",
          svg: "xcitercalls.svg",
        },
        {
          name: "XCITER CALLS #2",
          icon: "images/sponsors/hunting/xcitercalls-2.png",
          svg: "xcitercalls-2.svg",
        },
        {
          name: "ZEISS",
          icon: "images/sponsors/hunting/zeiss.png",
          svg: "zeiss.svg",
        },
        {
          name: "ZINK CALLS",
          icon: "images/sponsors/hunting/zinkcalls.png",
          svg: "zinkcalls.svg",
        },
        {
          name: "ZINK CALLS #2",
          icon: "images/sponsors/hunting/zinkcalls-2.png",
          svg: "zinkcalls-2.svg",
        },
        {
          name: "ZODI",
          icon: "images/sponsors/hunting/zodi.png",
          svg: "zodi.svg",
        },
        {
          name: "ZODI #2",
          icon: "images/sponsors/hunting/zodi-2.png",
          svg: "zodi-2.svg",
        },

        {
          name: "ADCORDEFENSE",
          icon: "images/sponsors/hunting/adcordefense.png",
          svg: "adcordefense.svg",
        },
        {
          name: "ADCORDEFENSE #2",
          icon: "images/sponsors/hunting/adcordefense2.png",
          svg: "adcordefense2.svg",
        },
        {
          name: "AMERISTEP",
          icon: "images/sponsors/hunting/ameristep.png",
          svg: "ameristep.svg",
        },
        {
          name: "BUSHNELL #3",
          icon: "images/sponsors/hunting/bushnell-logo.png",
          svg: "bushnell-logo.svg",
        },
        {
          name: "DIAMONDBACK FIREARMS",
          icon: "images/sponsors/hunting/diamondbackfirearms-black.png",
          svg: "diamondbackfirearms-black.svg",
        },
        {
          name: "DIAMONDBACK FIREARMS #2",
          icon: "images/sponsors/hunting/diamondbackfirearms.png",
          svg: "diamondbackfirearms.svg",
        },
        {
          name: "FLEXTONE GAME CALLS",
          icon: "images/sponsors/hunting/flextonegamecalls(black).png",
          svg: "flextonegamecalls(black).svg",
        },
        {
          name: "FLEXTONE GAME CALLS #2",
          icon: "images/sponsors/hunting/flextonegamecalls(white).png",
          svg: "flextonegamecalls(white).svg",
        },
        {
          name: "HEAVY METAL",
          icon: "images/sponsors/hunting/heavymetal.png",
          svg: "heavymetal.svg",
        },
        /*{
				name: "HEAVYMETAL #2",
				icon: "images/sponsors/hunting/heavymetal.png",
				svg: "heavymetal.svg" 
				} ,*/
        {
          name: "HEVI-SHOT",
          icon: "images/sponsors/hunting/hevi-shot.png",
          svg: "hevi-shot.svg",
        },
        {
          name: "OUTDOOR EDGE",
          icon: "images/sponsors/hunting/outdooredge.png",
          svg: "outdooredge.svg",
        },
        /*{
				name: "OUTDOOR EDGE #2",
				icon: "images/sponsors/hunting/outdooredge.png",
				svg: "outdooredge.svg" 
				} ,*/
        {
          name: "STEINER OPTICS",
          icon: "images/sponsors/hunting/steineroptics.png",
          svg: "steineroptics.svg",
        },
        /*{
				name: "STEINER OPTICS #2",
				icon: "images/sponsors/hunting/steineroptics.png",
				svg: "steineroptics.svg" 
				} ,*/
        {
          name: "STEYR-ARMS",
          icon: "images/sponsors/hunting/steyr-arms.png",
          svg: "steyr-arms.svg",
        },
        {
          name: "STEYR-ARMS #2",
          icon: "images/sponsors/hunting/steyr-arms-2.png",
          svg: "steyr-arms-2.svg",
        },
        {
          name: "XS SIGHT SYSTEMS",
          icon: "images/sponsors/hunting/xssightsystems2.png",
          svg: "xssightsystems2.svg",
        },
        {
          name: "XS SIGHT SYSTEMS #2",
          icon: "images/sponsors/hunting/xssightsystems.png",
          svg: "xssightsystems.svg",
        },
        {
          name: "Alliant Powder",
          icon: "images/sponsors/hunting/alliantpowder.png",
          svg: "alliantpowder.svg",
        },
        {
          name: "American Eagle Ammo",
          icon: "images/sponsors/hunting/americaneagleammo.png",
          svg: "americaneagleammo.svg",
        },
        {
          name: "benelli",
          icon: "images/sponsors/hunting/benelli.png",
          svg: "benelli.svg",
        },
        {
          name: "CCI Ammunition",
          icon: "images/sponsors/hunting/cciammunition.png",
          svg: "cciammunition.svg",
        },
        {
          name: "Federal 2",
          icon: "images/sponsors/hunting/federal2.png",
          svg: "federal2.svg",
        },
        {
          name: "Federal 3",
          icon: "images/sponsors/hunting/federal3.png",
          svg: "federal3.svg",
        },
        {
          name: "Federal 4",
          icon: "images/sponsors/hunting/federal4.png",
          svg: "federal4.svg",
        },
        {
          name: "Federal 5",
          icon: "images/sponsors/hunting/federal5.png",
          svg: "federal5.svg",
        },
        {
          name: "Federal",
          icon: "images/sponsors/hunting/federal.png",
          svg: "federal.svg",
        },
        {
          name: "GunSlick",
          icon: "images/sponsors/hunting/gunslick.png",
          svg: "gunslick.svg",
        },
        {
          name: "Hoppes",
          icon: "images/sponsors/hunting/hoppes.png",
          svg: "hoppes.svg",
        },
        {
          name: "outers",
          icon: "images/sponsors/hunting/outers.png",
          svg: "outers.svg",
        },
        {
          name: "rcbs",
          icon: "images/sponsors/hunting/rcbs.png",
          svg: "rcbs.svg",
        },
        {
          name: "simmons",
          icon: "images/sponsors/hunting/simmons.png",
          svg: "simmons.svg",
        },
        {
          name: "Stevens",
          icon: "images/sponsors/hunting/stevens_logo.png",
          svg: "stevens_logo.svg",
        },
      ],
    },
  ];
  setState({
    mascots: mascots,
    sports: sports,
    sponsors: sponsors,
  });

  return (
    <>
      <Button
        colorScheme="teal"
        borderColor="teal.300"
        color="teal.300"
        variant="outline"
        onClick={onOpen}
      >
        <span className="text-sm me-5">Select Artwork</span>
      </Button>
      <div className="proppanel ">
        <Grid
          templateColumns={{
            sm: "repeat(4, 1fr)",
            md: "repeat(4, 1fr)",
            xl: "repeat(4, 1fr)",
          }}
          gap="2px"
        >
          {getState().allArtworks.length > 0 &&
            getState().allArtworks.map((imageData, index) => {
              const url = imageData.url;
              return (
                <>
                  <Box
                    key={index}
                    style={{ width: "125px" }}
                    className="relative hover:border-sky-700 cursor-pointer border m-1 p-1 w-1/3 border-1 rounded flex flex-col justify-center items-center"
                  >
                    {getState().allArtworks.length > 0 && (
                      <Popover
                        closeOnBlur={true}
                        closeOnEsc={true}
                        gutter={25}
                        placement="left"
                        variant="picker"
                      >
                        <PopoverTrigger>
                          <Button
                            onClick={(e) => {
                              let activeObject = null;
                              if (
                                fabricCanvas.getActiveObject() &&
                                fabricCanvas
                                  .getActiveObject()
                                  .id.includes("image")
                              ) {
                                activeObject = fabricCanvas.getActiveObject();
                              } else {
                                activeObject = fabricCanvas
                                  .getObjects()
                                  .find((obj) => obj.id == imageData.id);
                              }
                              setCurrentSvg(activeObject._objects);
                            }}
                            className="absolute left-0 top-0 z-100"
                            variant="ghost"
                            position={"absolute"}
                          >
                            <EditIcon color="teal.300" />
                          </Button>
                        </PopoverTrigger>
                        <PopoverContent>
                          <PopoverArrow />
                          <PopoverHeader>Change Artwork Colors</PopoverHeader>
                          <PopoverCloseButton />
                          <PopoverBody>
                            {currentSvg &&
                              currentSvg.map((obj, inx) => (
                                <Box
                                  key={inx}
                                  className="flex justify-between mb-3"
                                >
                                  <Box className="w-1/2 ms-1">
                                    <Text className="mb-1 text-slate-400">
                                      Color {inx + 1}
                                    </Text>
                                    <Input
                                      key={inx}
                                      type="color"
                                      name=""
                                      value={obj.fill}
                                      onChange={(e) => {
                                        obj.set("fill", e.target.value);
                                        fabricCanvas.renderAll();
                                      }}
                                    />
                                  </Box>
                                  <Box className="w-1/2 ms-1">
                                    <Text className="mb-1 text-slate-400">
                                      Stroke {inx + 1}
                                    </Text>
                                    <Input
                                      key={inx}
                                      type="color"
                                      name=""
                                      value={obj.stroke}
                                      onChange={(e) => {
                                        obj.set("stroke", e.target.value);
                                        fabricCanvas.renderAll();
                                      }}
                                    />
                                  </Box>
                                </Box>
                              ))}
                          </PopoverBody>
                        </PopoverContent>
                      </Popover>
                    )}
                    <Button
                      variant="ghost"
                      className="absolute right-0 top-0 z-100"
                      position={"absolute"}
                      onClick={(e) => {
                        const currentImgObj = fabricCanvas
                          .getObjects()
                          .find((data) => data.id === imageData.id);
                        fabricCanvas.remove(currentImgObj);
                        setState((prevState) => ({
                          allArtworks: prevState.allArtworks.filter(
                            (obj) => obj.id !== imageData.id
                          ),
                        }));
                      }}
                    >
                      <DeleteIcon color="red.500" />
                    </Button>
                    <Box
                      onClick={() => {
                        setState({
                          tempImage: {
                            url,
                            ext: imageData.ext,
                          },
                          addImageEnabled: true,
                          evented: true,
                        });
                      }}
                    >
                      <Img className="w-full" src={url} />
                      <Text className="text-xs mt-1 text-center">
                        {imageData.filename}
                      </Text>
                    </Box>
                  </Box>
                </>
              );
            })}
        </Grid>
      </div>
      <br />
      <div className="p-5 border-y-2 flex items-center">
        <InfoIcon color="gray.400" />
        <Text className="ms-3" color="gray.400">
          Note: Drag and drop the image to place it on the 3d model
        </Text>
      </div>

      <Drawer onClose={onClose} isOpen={isOpen} size={"full"}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>{`Select an Artwork`}</DrawerHeader>
          <DrawerBody>
            <Tabs>
              <TabList>
                <Tab>Sports</Tab>
                <Tab>Mascots</Tab>
                <Tab>Sponsors</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <Tabs>
                    <TabList>
                      {getState().sports.map((data, index) => (
                        <Tab key={index}>{data.id}</Tab>
                      ))}
                    </TabList>

                    <TabPanels>
                      {getState().sports.map((data, index) => (
                        <TabPanel key={index}>
                          <Grid
                            templateColumns={{
                              sm: "repeat(3, 1fr)",
                              md: "repeat(4, 1fr)",
                              xl: "repeat(10, 1fr)",
                            }}
                            templateRows={{
                              sm: "1fr 1fr 1fr auto",
                              md: "1fr 1fr",
                              xl: "1fr",
                            }}
                            gap="24px"
                          >
                            {data.mascots.map((mascot, index) => (
                              <Card
                                className="imgArt"
                                key={index}
                                direction={{ base: "column" }}
                                overflow="hidden"
                                variant="outline"
                                role="group"
                              >
                                <CardBody
                                  className="relative hover:border-sky-700 cursor-pointer"
                                  _groupHover={{
                                    borderColor: "rgb(3 105 161)",
                                    background:
                                      "linear-gradient(360deg, rgba(49, 56, 96, 0.16) 0%, linen 100%)",
                                  }}
                                  onClick={handleClick}
                                >
                                  <Image
                                    objectFit="cover"
                                    loading="lazy"
                                    src={
                                      "https://staging.tolloapp.com/fileserver/Dyesport/artworks/svg/mascots/" +
                                      data.id +
                                      "/" +
                                      mascot.svg
                                    }
                                    alt={data.id}
                                    onClick={() => {
                                      const image = {
                                        id: `image-${
                                          getState().allArtworks.length + 1
                                        }`,
                                        url:
                                          "https://staging.tolloapp.com/fileserver/Dyesport/artworks/svg/mascots/" +
                                          data.id +
                                          "/" +
                                          mascot.svg,
                                        filename: mascot.name,
                                        ext: "svg",
                                      };
                                      setState((prevState) => ({
                                        allArtworks: [
                                          ...prevState.allArtworks,
                                          image,
                                        ],
                                      }));
                                    }}
                                  />
                                </CardBody>
                              </Card>
                            ))}
                          </Grid>
                        </TabPanel>
                      ))}
                    </TabPanels>
                  </Tabs>
                </TabPanel>
                <TabPanel>
                  <Tabs>
                    <TabList>
                      {getState().mascots.map((data, index) => (
                        <Tab key={index}>{data.id}</Tab>
                      ))}
                    </TabList>

                    <TabPanels>
                      {getState().mascots.map((data, index) => (
                        <TabPanel key={index}>
                          <Grid
                            templateColumns={{
                              sm: "repeat(3, 1fr)",
                              md: "repeat(4, 1fr)",
                              xl: "repeat(10, 1fr)",
                            }}
                            templateRows={{
                              sm: "1fr 1fr 1fr auto",
                              md: "1fr 1fr",
                              xl: "1fr",
                            }}
                            gap="24px"
                          >
                            {data.mascots.map((mascot, index) => (
                              <Card
                                className="imgArt"
                                key={index}
                                direction={{ base: "column" }}
                                overflow="hidden"
                                variant="outline"
                                role="group"
                              >
                                <CardBody
                                  _groupHover={{
                                    background:
                                      "linear-gradient(360deg, rgba(49, 56, 96, 0.16) 0%, linen 100%)",
                                  }}
                                  onClick={handleClick}
                                >
                                  <Image
                                    objectFit="cover"
                                    loading="lazy"
                                    src={
                                      "https://staging.tolloapp.com/fileserver/Dyesport/artworks/svg/mascots/" +
                                      data.id +
                                      "/" +
                                      mascot.svg
                                    }
                                    alt={data.id}
                                    onClick={() => {
                                      const image = {
                                        id: `image-${
                                          getState().allArtworks.length + 1
                                        }`,
                                        url:
                                          "https://staging.tolloapp.com/fileserver/Dyesport/artworks/svg/mascots/" +
                                          data.id +
                                          "/" +
                                          mascot.svg,
                                        filename: mascot.name,
                                        ext: "svg",
                                      };
                                      setState((prevState) => ({
                                        allArtworks: [
                                          ...prevState.allArtworks,
                                          image,
                                        ],
                                      }));
                                    }}
                                  />
                                </CardBody>
                              </Card>
                            ))}
                          </Grid>
                        </TabPanel>
                      ))}
                    </TabPanels>
                  </Tabs>
                </TabPanel>
                <TabPanel>
                  <Tabs>
                    <TabList>
                      {getState().sponsors.map((data, index) => (
                        <Tab key={index}>{data.id}</Tab>
                      ))}
                    </TabList>

                    <TabPanels>
                      {getState().sponsors.map((data, index) => (
                        <TabPanel key={index}>
                          <Grid
                            templateColumns={{
                              sm: "repeat(3, 1fr)",
                              md: "repeat(4, 1fr)",
                              xl: "repeat(10, 1fr)",
                            }}
                            templateRows={{
                              sm: "1fr 1fr 1fr auto",
                              md: "1fr 1fr",
                              xl: "1fr",
                            }}
                            gap="24px"
                          >
                            {data.mascots.map((mascot, index) => (
                              <Card
                                className="imgArt"
                                key={index}
                                direction={{ base: "column" }}
                                overflow="hidden"
                                variant="outline"
                                role="group"
                              >
                                <CardBody
                                  _groupHover={{
                                    background:
                                      "linear-gradient(360deg, rgba(49, 56, 96, 0.16) 0%, linen 100%)",
                                  }}
                                  className="cardBody"
                                  bg="linear-gradient(360deg, rgba(49, 56, 96, 0.16) 0%, rgba(21, 25, 40, 0.88) 100%)"
                                  onClick={handleClick}
                                >
                                  <Image
                                    objectFit="cover"
                                    loading="lazy"
                                    src={
                                      "https://staging.tolloapp.com/fileserver/Dyesport/artworks/svg/mascots/" + //"http://localhost/fileserver/Dyesport/artworks/svg/mascots/" +
                                      data.id +
                                      "/" +
                                      mascot.svg
                                    }
                                    alt={data.id}
                                    onClick={() => {
                                      const image = {
                                        id: `image-${
                                          getState().allArtworks.length + 1
                                        }`,
                                        url:
                                          "https://staging.tolloapp.com/fileserver/Dyesport/artworks/svg/mascots/" +
                                          data.id +
                                          "/" +
                                          mascot.svg,
                                        filename: mascot.name,
                                        ext: "svg",
                                      };
                                      setState((prevState) => ({
                                        allArtworks: [
                                          ...prevState.allArtworks,
                                          image,
                                        ],
                                      }));
                                    }}
                                  />
                                </CardBody>
                              </Card>
                            ))}
                          </Grid>
                        </TabPanel>
                      ))}
                    </TabPanels>
                  </Tabs>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default ArtworkContent;
