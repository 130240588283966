// Chakra imports
import { Box, Flex, Image, Text, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import { useHistory } from "react-router-dom";
const ProjectCard = ({ id, catId, image, name, category, description }) => {
  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");
  const history = useHistory();
  return (
    <Flex
      direction="column"
      onClick={() => {
        sessionStorage.setItem("productName", category);
        sessionStorage.setItem("productLink", `#/admin/garment/${catId}/${id}`);
        var prdbrd = document.getElementById("prdbrd");
        if (prdbrd != null) prdbrd.style.display = "block";
        const data = { prod_id: id, cat_id: catId };
        history.push({
          pathname: `/admin/garment/${catId}/${id}`,
          state: data,
        });
      }}
    >
      <Box
        className="imgFlex"
        mb="20px"
        position="relative"
        borderRadius="15px"
      >
        <Image src={image} className="imgThumb" borderRadius="15px" />
        <Box
          w="100%"
          h="100%"
          position="absolute"
          top="0"
          borderRadius="15px"
          // bg="linear-gradient(360deg, rgba(49, 56, 96, 0.16) 0%, rgba(21, 25, 40, 0.88) 100%)"
        ></Box>
      </Box>
      <Flex className="imgText" direction="column">
        <Text fontSize="md" color="gray.500" fontWeight="600" mb="10px">
          {name}
        </Text>
        <Text fontSize="xl" color={textColor} fontWeight="bold" mb="10px">
          {category}
        </Text>
        <Text fontSize="md" color="gray.500" fontWeight="400" mb="20px">
          {description}
        </Text>
      </Flex>
    </Flex>
  );
};

export default ProjectCard;
