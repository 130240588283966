// Chakra imports
import { Flex } from "@chakra-ui/react";
import Projects from "./components/Projects";
import React from "react";
function Product(props) {
  sessionStorage.removeItem("productLink");
  sessionStorage.removeItem("productName");
  var prdbrd = document.getElementById("prdbrd");
  if (prdbrd != null) prdbrd.style.display = "none";
  const data = props.location.state;
  let catId;
  if (data == undefined) {
    if (window.location.hash.split("/").length > 0) {
      catId = window.location.hash.split("/")[3];
    }
  } else catId = data.category_id == null ? 0 : data.category_id;
  return (
    <div className="vermiddle scrollcontainer">
      <Projects
        catId={catId}
        title={"Products"}
        description={"List of products"}
      />
    </div>
  );
}

export default Product;
