import { BellIcon, SearchIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  Box,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  FormControl,
  FormLabel,
  Text,
  useColorModeValue,
  MenuList,
} from "@chakra-ui/react";
import { FaSignOutAlt } from "react-icons/fa";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import { ProfileIcon, SettingsIcon } from "components/Icons/Icons";
import { getState, setState } from "hooks/useStore";

import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import routes from "routes.js";

export default function HeaderLinks(props) {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const { variant, children, fixed, secondary, ...rest } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [cust, setCust] = useState({});
  // Chakra Color Mode
  let mainTeal = useColorModeValue("teal.300", "teal.300");
  let inputBg = useColorModeValue("white", "gray.800");
  let mainText = useColorModeValue("gray.700", "gray.200");
  let navbarIcon = useColorModeValue("gray.500", "gray.200");
  let searchIcon = useColorModeValue("gray.700", "gray.200");

  if (secondary) {
    navbarIcon = "gray.400";
    mainText = "gray.400";
  }
  function closeModal() {
    const closeButton = document.querySelector(".chakra-modal__close-btn");
    if (closeButton) {
      closeButton.click();
    }
  }

  // get from session (if the value expired it is destroyed)
  function sessionGet(key) {
    let stringValue = sessionStorage.getItem(key);
    if (stringValue !== null) {
      let value = JSON.parse(stringValue);
      let expirationDate = new Date(value.expirationDate);
      if (expirationDate > new Date()) {
        return value.value;
      } else {
        sessionStorage.removeItem(key);
      }
    }
    return null;
  }
  // add into session
  function sessionSet(key, value, expirationInMin = 60) {
    let expirationDate = new Date(
      new Date().getTime() + 60000 * expirationInMin
    );
    let newValue = {
      value: value,
      expirationDate: expirationDate.toISOString(),
    };
    sessionStorage.setItem(key, JSON.stringify(newValue));
  }
  sessionGet("company");
  var myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Content-Type", "application/json");

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  const fetchQuotesData = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(
        "https://staging.tolloapp.com/designapi/api/Garments/GetQuotebyEmail?email=" +
          document.getElementById("loginemail").value, //"https://localhost:44392/api", //
        requestOptions
      );
      const result = await response.json();
      sessionSet("quotes", result);
      setState({ quotes: result });
      setState({ customer: result._customer });
      setCust(result._customer);
      setIsLoading(false);
      closeModal();
      history.push({
        pathname: `/admin/profile`,
      });
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (sessionStorage.getItem("quotes") != undefined) {
      setState({ quotes: sessionGet("quotes") });
      setState({ customer: sessionGet("quotes")._customer });
      setCust(sessionGet("quotes")._customer);
    }
  }, [sessionStorage.getItem("quotes")]);
  return (
    <Flex
      pe={{ sm: "0px", md: "16px" }}
      w={{ sm: "100%", md: "auto" }}
      alignItems="center"
      flexDirection="row"
    >
      {cust.name && (
        <div>
          <Menu>
            <MenuButton>
              <Text display={{ sm: "none", md: "flex" }}>{cust.name}</Text>
            </MenuButton>
            <MenuList p="16px 8px">
              <Flex flexDirection="column">
                <MenuItem borderRadius="8px" mb="10px">
                  <Box
                    ms="0px"
                    px="0px"
                    me={{ sm: "2px", md: "16px" }}
                    color={navbarIcon}
                    variant="transparent-with-icon"
                    onClick={() => {
                      history.push({
                        pathname: `/admin/profile`,
                      });
                    }}
                  >
                    <Text display={{ sm: "none", md: "flex" }}>Profile</Text>
                  </Box>
                </MenuItem>
                <MenuItem borderRadius="8px" mb="10px">
                  <Box
                    ms="0px"
                    px="0px"
                    me={{ sm: "2px", md: "16px" }}
                    color={navbarIcon}
                    variant="transparent-with-icon"
                    onClick={() => {
                      sessionStorage.removeItem("quotes");
                      setState({ quotes: {} });
                      setState({ customer: {} });
                      setCust({});
                      history.push({
                        pathname: `/admin/category`,
                      });
                    }}
                  >
                    <Text display={{ sm: "none", md: "flex" }}>Sign Out</Text>
                  </Box>
                </MenuItem>
              </Flex>
            </MenuList>
          </Menu>
        </div>
      )}
      {!cust.name && (
        <Button
          ms="0px"
          px="0px"
          me={{ sm: "2px", md: "16px" }}
          color={navbarIcon}
          variant="transparent-with-icon"
          onClick={onOpen}
        >
          <Text display={{ sm: "none", md: "flex" }}>
            Load Saved Designs/Sign In
          </Text>
        </Button>
      )}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader></ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>Email</FormLabel>
              <Input id="loginemail" placeholder="Enter your Email Address" />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="teal"
              mr={3}
              onClick={fetchQuotesData}
              isLoading={isLoading} 
              loadingText="Loading"
              variant="outline"
              spinnerPlacement="end"
            >
              Login
            </Button>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
}

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
};
