// Chakra imports
import {
  Button,
  Flex,
  Grid,
  Icon,
  Text,
  useColorModeValue,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from "@chakra-ui/react";
import useStore, { setState, getState } from "../../../hooks/useStore";
// Custom components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import React, { useState, useEffect } from "react";
import ProjectCard from "./ProjectCard";
const Projects = ({ title, description }) => {
  const textColor = useColorModeValue("gray.700", "white");
  const [quotes, setQuotes] = useState([]);
  const [sdesigns, setSdesigns] = useState([]);
  const [cust, setCust] = useState([]);
  function sessionGet(key) {
    let stringValue = sessionStorage.getItem(key);
    if (stringValue !== null) {
      let value = JSON.parse(stringValue);
      let expirationDate = new Date(value.expirationDate);
      if (expirationDate > new Date()) {
        return value.value;
      } else {
        sessionStorage.removeItem(key);
      }
    }
    return null;
  }
  useEffect(() => {
    const data = sessionGet("quotes");
    if (data._customer != undefined && data._customer.contact != undefined) {
      data._customer.email = data._customer.contact.email;
      data._customer.phone = data._customer.contact.phone;
    }
    setState({ quotes: data });
    setQuotes(data._garments.filter((x) => x.status == 2));
    setSdesigns(data._garments.filter((x) => x.status == 1));
  }, []);
  return (
    <Card p="16px" my="24px">
      <CardHeader p="12px 5px" mb="12px">
        <Flex direction="column">
          <Text fontSize="lg" color={textColor} fontWeight="bold">
            {title}
          </Text>
          <Text fontSize="sm" color="gray.500" fontWeight="400">
            {description}
          </Text>
        </Flex>
      </CardHeader>
      <CardBody px="5px">
        <div className="profilediv">
          <Tabs isFitted variant="enclosed">
            <TabList>
              <Tab _selected={{ color: "white", bg: "teal.300" }}>
                Saved Designs
              </Tab>
              <Tab _selected={{ color: "white", bg: "teal.300" }}>
                Submitted Quotes
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel style={{ width: "900px" }}>
                {sdesigns.length == 0 && <p>No saved designs!</p>}
                <Grid
                  templateColumns={{
                    sm: "1fr",
                    md: "1fr 1fr",
                    xl: "repeat(4, 1fr)",
                  }}
                  templateRows={{
                    sm: "1fr 1fr 1fr auto",
                    md: "1fr 1fr",
                    xl: "1fr",
                  }}
                  gap="24px"
                >
                  {sdesigns.map((data, index) => (
                    <ProjectCard
                      key={index}
                      image={data.front}
                      name={data.sport.name}
                      category={data.product.name}
                      description={data.design.name}
                      status={data.status}
                      link={
                        "/admin/design/" +
                        data.sport.id +
                        "/" +
                        data.product.id +
                        "/" +
                        data.design.id +
                        "/" +
                        data.id
                      }
                    />
                  ))}
                </Grid>
              </TabPanel>
              <TabPanel style={{ width: "900px" }}>
                {quotes.length == 0 && <p>No submitted quotes!</p>}
                <Grid
                  templateColumns={{
                    sm: "1fr",
                    md: "1fr 1fr",
                    xl: "repeat(4, 1fr)",
                  }}
                  templateRows={{
                    sm: "1fr 1fr 1fr auto",
                    md: "1fr 1fr",
                    xl: "1fr",
                  }}
                  gap="24px"
                >
                  {quotes.map((data, index) => (
                    <ProjectCard
                      key={index}
                      image={data.front}
                      name={data.sport.name}
                      category={data.product.name}
                      description={data.design.name}
                      status={data.status}
                      link={
                        "/admin/design/" +
                        data.sport.id +
                        "/" +
                        data.product.id +
                        "/" +
                        data.design.id +
                        "/" +
                        data.id
                      }
                    />
                  ))}
                </Grid>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </div>
      </CardBody>
    </Card>
  );
};

export default Projects;
