// Chakra imports
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Flex,
  Image,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import { useHistory } from "react-router-dom";
const ProjectCard = ({ key, id, image, name, category, description }) => {
  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");
  const history = useHistory();
  return (
    <Flex
      direction="column"
      key={key}
      onClick={() => {
        sessionStorage.setItem("catName", category);
        sessionStorage.setItem("catLink", `#/admin/product/${id}`);
        const data = { category_id: id };
        history.push({ pathname: `/admin/product/${id}`, state: data });
      }}
    >
      <Box
        className="imgFlex"
        mb="20px"
        position="relative"
        borderRadius="15px"
      >
        <Image src={image} className="imgThumb" borderRadius="15px" />
        <Box
          w="100%"
          h="100%"
          position="absolute"
          top="0"
          borderRadius="15px"
        ></Box>
      </Box>
      <Flex className="imgText" direction="column">
        <Text fontSize="md" color="gray.500" fontWeight="600" mb="10px">
          {name}
        </Text>
        <Text fontSize="xl" color={textColor} fontWeight="bold" mb="10px">
          {category}
        </Text>
        <Text fontSize="md" color="gray.500" fontWeight="400" mb="20px">
          {description}
        </Text>
      </Flex>
    </Flex>
  );
};

export default ProjectCard;
