/* eslint-disable react/prop-types */
import React from 'react';
import useStore, { setState, getState } from '../../../../hooks/useStore'
import { Box, Button, Input, Text, Select } from "@chakra-ui/react";

import { ChevronLeftIcon, DeleteIcon } from '@chakra-ui/icons'
const TextEditor = ({ fabricCanvas, textData, fontFamilies }) => {
    const updateTextProperty = (property, value) => {
        const currentTextObj = fabricCanvas.getObjects().find(data => data.id === textData.id);
        const currentText = getState().allTexts.find(data => data.id === textData.id);
        currentTextObj.set(property, value);
        currentText[property] = value;
        setState(prevState => ({
            allTexts: prevState.allTexts.map(item =>
                item.id === textData.id ? { ...item, [property]: value } : item
            )
        }));
    };

    const handleTextChange = (e) => {
        updateTextProperty('text', e.target.value);
    };

    const handleFontFamilyChange = (e) => {
        updateTextProperty('fontFamily', e.target.value);
    };

    const handleColorChange = (e) => {
        updateTextProperty('fill', e.target.value);
    };

    const handleStrokeWidthChange = (e) => {
        updateTextProperty('strokeWidth', e.target.value);
    };

    const handleStrokeColorChange = (e) => {
        updateTextProperty('stroke', e.target.value);
    };

    const handleDeleteButtonClick = () => {
        const currentTextObj = fabricCanvas.getObjects().find(data => data.id === textData.id);
        fabricCanvas.remove(currentTextObj);
        setState(prevState => ({
            allTexts: prevState.allTexts.filter(text => text.id !== textData.id)
        }));
    };

    return (
        <Box className="flex flex-col">
            <Box className="mb-3">
                <Box className="mb-2 flex justify-between items-center">
                    <Text className="text-slate-400">Change Text</Text>
                    <Box className='flex justify-end'>
                        <Button variant='ghost' onClick={handleDeleteButtonClick}>
                            <DeleteIcon color="red.500" />
                        </Button>
                    </Box>
                </Box>
                <Input type="text" value={textData.text} placeholder="insert text" onChange={handleTextChange} />
            </Box>
            <Box className="flex">
                <Box className="w-1/2 me-1">
                    <Text className="mb-1 text-slate-400">Font Family</Text>
                    <Select  onChange={handleFontFamilyChange}>
                        {fontFamilies.map((font, index) => (
                            <option key={index} value={font}>
                                {font}
                            </option>
                        ))}
                    </Select>
                </Box>
                <Box className="w-1/2 ms-1">
                    <Text className="mb-1 text-slate-400">Color</Text>
                    <Input type="color" value={textData.fill} onChange={handleColorChange} />
                </Box>
            </Box>
            <Box className="flex">
                <Box className="w-1/2 me-1">
                    <Text className="mb-1 text-slate-400">Stroke Width</Text>
                    <Input type="number" value={textData.strokeWidth} onChange={handleStrokeWidthChange} />
                </Box>
                <Box className="w-1/2 ms-1">
                    <Text className="mb-1 text-slate-400">Stroke Color</Text>
                    <Input type="color" value={textData.strokeFill} onChange={handleStrokeColorChange} />
                </Box>
            </Box>
        </Box>
    );
};

export default TextEditor;
