// Chakra imports
import { ChakraProvider, Portal, useDisclosure } from "@chakra-ui/react";
import Footer from "components/Footer/Footer.js";
// Layout components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar";
import React, { useState, useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import routes from "routes.js";
import useStore, { setState, getState } from "../hooks/useStore";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
// Custom Chakra theme
import theme from "theme/theme.js";
import FixedPlugin from "../components/FixedPlugin/FixedPlugin";
// Custom components
import MainPanel from "../components/Layout/MainPanel";
import PanelContainer from "../components/Layout/PanelContainer";
import PanelContent from "../components/Layout/PanelContent";
export default function Dashboard(props) {
  const { ...rest } = props;
  const [fixed, setFixed] = useState(false);
  const getRoute = () => {
    return window.location.pathname !== "/admin/full-screen-maps";
  };
  const getActiveRoute = (routes) => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else if (routes[i].category) {
        let categoryActiveRoute = getActiveRoute(routes[i].views);
        if (categoryActiveRoute !== activeRoute) {
          return categoryActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  // This changes navbar state(fixed or not)
  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].category) {
        let categoryActiveNavbar = getActiveNavbar(routes[i].views);
        if (categoryActiveNavbar !== activeNavbar) {
          return categoryActiveNavbar;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          if (routes[i].secondaryNavbar) {
            return routes[i].secondaryNavbar;
          }
        }
      }
    }
    return activeNavbar;
  };
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.category === "account") {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const { isOpen, onOpen, onClose } = useDisclosure();
  document.documentElement.dir = "ltr";
  const [cust, setCust] = useState({});
  function sessionGet(key) {
    let stringValue = sessionStorage.getItem(key);
    if (stringValue !== null) {
      let value = JSON.parse(stringValue);
      let expirationDate = new Date(value.expirationDate);
      if (expirationDate > new Date()) {
        return value.value;
      } else {
        sessionStorage.removeItem(key);
      }
    }
    return null;
  }
  // add into session
  function sessionSet(key, value, expirationInMin = 60) {
    let expirationDate = new Date(
      new Date().getTime() + 60000 * expirationInMin
    );
    let newValue = {
      value: value,
      expirationDate: expirationDate.toISOString(),
    };
    sessionStorage.setItem(key, JSON.stringify(newValue));
  }
  useEffect(() => {
    // get from session (if the value expired it is destroyed)

    sessionGet("company");
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    const fetchCompData = async () => {
      try {
        const response = await fetch(
          "https://staging.tolloapp.com/designapi/api/Company/1/no",
          requestOptions
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        sessionSet("company", data);
        setState({ company: data });
      } catch (error) {
        console.log(error);
      }
    };
    if (sessionStorage.getItem("company") == undefined) {
      fetchCompData();
    } else {
      setState({
        company: JSON.parse(sessionStorage.getItem("company")).value,
      });
    }

    const fetchFabricsData = async () => {
      try {
        const response = await fetch(
          "https://staging.tolloapp.com/designapi/api/Fabrics",
          requestOptions
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        sessionSet("fabrics", data);
        setState({ allFabrics: data });
      } catch (error) {
        console.log(error);
      }
    };

    if (sessionStorage.getItem("fabrics") == undefined) {
      fetchFabricsData();
    } else {
      setState({
        allFabrics: JSON.parse(sessionStorage.getItem("fabrics")).value,
      });
    }

    if (sessionStorage.getItem("quotes") != undefined) {
      setState({ quotes: sessionGet("quotes") });
      setState({ customer: sessionGet("quotes")._customer });
      setCust(sessionGet("quotes")._customer);
    }
  }, []);

  useEffect(() => {
    if (sessionStorage.getItem("quotes") != undefined) {
      setState({ quotes: sessionGet("quotes") });
      setState({ customer: sessionGet("quotes")._customer });
      setCust(sessionGet("quotes")._customer);
    }
  }, [sessionStorage.getItem("quotes")]);

  return (
    <ChakraProvider theme={theme} resetCss={false} toastOptions={{ defaultOptions: { position: 'bottom' } }}>
      <MainPanel
        w={{
          base: "100%",
          xl: "calc(100% )",
        }}
      >
        <Portal>
          <AdminNavbar
            onOpen={onOpen}
            brandText={getActiveRoute(routes)}
            secondary={getActiveNavbar(routes)}
            fixed={fixed}
            {...rest}
          />
        </Portal>
        {getRoute() ? (
          <PanelContent>
            <PanelContainer>
              <Switch>
                {getRoutes(routes)}
                <Redirect from="/admin" to="/admin/category" />
              </Switch>
            </PanelContainer>
          </PanelContent>
        ) : null}
        <Footer />        
      </MainPanel>
    </ChakraProvider>
  );
}
