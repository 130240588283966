export const jerseyStyles = [
    {
        text: 'Champion',
        image: '/thumbnails/Tshirt-01.png',
        width: 2048,
        height: 2048,
    },
    {
        text: 'Simple',
        image: '/thumbnails/Tshirt-02.png',
        width: 2048,
        height: 2048,
    },
    {
        text: 'Climber',
        image: '/thumbnails/Tshirt-03.png',
        width: 2048,
        height: 2048,
    }
]

export const stepContent = {
    stepOne: {
        step: 'Step 1',
        title: 'choose your style',
    },
    stepTwo: {
        step: 'Step 2',
        title: 'choose your colours',
    },
    stepThree: {
        step: 'Step 3',
        title: 'add text',
    },
    stepFour: {
        step: 'Step 4',
        title: 'add logo',
    },
};


export const colorPallete = {
    basePallete: [
        {
            name: 'White 1',
            color: '#ffffff',
        },
        {
            name: 'Black 5',
            color: '#000000',
        },
    ],
    mainPallete1: [
        [
            { name: 'Yellow 1', color: '#fffa84' },
            { name: 'Yellow 2', color: '#fee837' },
            { name: 'Yellow 3', color: '#fcd60f' },
            { name: 'Yellow 4', color: '#ffc80b' },
            { name: 'Yellow 5', color: '#dfad07' },
        ],
        [
            { name: 'Orange 1', color: '#ffcd55' },
            { name: 'Orange 2', color: '#ffb637' },
            { name: 'Orange 3', color: '#ff8326' },
            { name: 'Orange 4', color: '#fc622b' },
            { name: 'Orange 5', color: '#cc4a2b' },
        ],
        [
            { name: 'Orange Red 1', color: '#ffb99c' },
            { name: 'Orange Red 2', color: '#ff7c65' },
            { name: 'Orange Red 3', color: '#f84434' },
            { name: 'Orange Red 4', color: '#b73025' },
            { name: 'Orange Red 5', color: '#7b2725' },
        ],
        [
            { name: 'Red 1', color: '#f7a493' },
            { name: 'Red 2', color: '#f95552' },
            { name: 'Red 3', color: '#e81233' },
            { name: 'Red 4', color: '#b92b32' },
            { name: 'Red 5', color: '#6d2a31' },
        ],
        [
            { name: 'Berry 1', color: '#ffa6a9' },
            { name: 'Berry 2', color: '#ff5e75' },
            { name: 'Berry 3', color: '#e91c56' },
            { name: 'Berry 4', color: '#a5154a' },
            { name: 'Berry 5', color: '#7d1845' },
        ],
        [
            { name: 'Magenta 1', color: '#ff9dc9' },
            { name: 'Magenta 2', color: '#fa72a9' },
            { name: 'Magenta 3', color: '#da3772' },
            { name: 'Magenta 4', color: '#bc3266' },
            { name: 'Magenta 5', color: '#7e2a56' },
        ],
        [
            { name: 'Purple 1', color: '#dab5d6' },
            { name: 'Purple 2', color: '#ce89bb' },
            { name: 'Purple 3', color: '#bc5997' },
            { name: 'Purple 4', color: '#833f6e' },
            { name: 'Purple 5', color: '#6e375c' },
        ],
        [
            { name: 'Violet 1', color: '#c0afd0' },
            { name: 'Violet 2', color: '#a081ba' },
            { name: 'Violet 3', color: '#684c94' },
            { name: 'Violet 4', color: '#5c3975' },
            { name: 'Violet 5', color: '#4c2c5a' },
        ],
        [
            { name: 'Steel Blue 1', color: '#bfcacc' },
            { name: 'Steel Blue 2', color: '#9bb3bd' },
            { name: 'Steel Blue 3', color: '#5b7f96' },
            { name: 'Steel Blue 4', color: '#3b5769' },
            { name: 'Steel Blue 5', color: '#262c39' },
        ],
        [
            { name: 'Royal 1', color: '#a7c3d4' },
            { name: 'Royal 2', color: '#628ec0' },
            { name: 'Royal 3', color: '#345188' },
            { name: 'Royal 4', color: '#2d3f6d' },
            { name: 'Royal 5', color: '#2a3148' },
        ],
    ],
    mainPallete2: [
        [
            { name: 'Blue 1', color: '#96c8da' },
            { name: 'Blue 2', color: '#4aaad6' },
            { name: 'Blue 3', color: '#017abb' },
            { name: 'Blue 4', color: '#1f5a91' },
            { name: 'Blue 5', color: '#2e455d' },
        ],
        [
            { name: 'Cyan 1', color: '#95d8e1' },
            { name: 'Cyan 2', color: '#66c0cb' },
            { name: 'Cyan 3', color: '#1287a9' },
            { name: 'Cyan 4', color: '#116f85' },
            { name: 'Cyan 5', color: '#0b4a5e' },
        ],
        [
            { name: 'Lagoon 1', color: '#8fdad5' },
            { name: 'Lagoon 2', color: '#60c8c2' },
            { name: 'Lagoon 3', color: '#299c9d' },
            { name: 'Lagoon 4', color: '#12797c' },
            { name: 'Lagoon 5', color: '#0a5860' },
        ],
        [
            { name: 'Ice 1', color: '#c5dfd8' },
            { name: 'Ice 2', color: '#89bfbf' },
            { name: 'Ice 3', color: '#6fa2aa' },
            { name: 'Ice 4', color: '#49727a' },
            { name: 'Ice 5', color: '#10525f' },
        ],
        [
            { name: 'Mint 1', color: '#c0f9c8' },
            { name: 'Mint 2', color: '#8fd8b7' },
            { name: 'Mint 3', color: '#1c9869' },
            { name: 'Mint 4', color: '#15876e' },
            { name: 'Mint 5', color: '#0d645b' },
        ],
        [
            { name: 'Jade 1', color: '#d5fdb6' },
            { name: 'Jade 2', color: '#8adb8b' },
            { name: 'Jade 3', color: '#37a465' },
            { name: 'Jade 4', color: '#138b5f' },
            { name: 'Jade 5', color: '#166d4d' },
        ],
        [
            { name: 'Fog Green 1', color: '#c4eabe' },
            { name: 'Fog Green 2', color: '#a1ce95' },
            { name: 'Fog Green 3', color: '#7ab27b' },
            { name: 'Fog Green 4', color: '#538861' },
            { name: 'Fog Green 5', color: '#2a4f3f' },
        ],
        [
            { name: 'Green 1', color: '#a0e184' },
            { name: 'Green 2', color: '#64b544' },
            { name: 'Green 3', color: '#2e9731' },
            { name: 'Green 4', color: '#20733d' },
            { name: 'Green 5', color: '#214c2e' },
        ],
        [
            { name: 'Moss 1', color: '#cae76e' },
            { name: 'Moss 2', color: '#9bc94a' },
            { name: 'Moss 3', color: '#729d3c' },
            { name: 'Moss 4', color: '#5b7b3a' },
            { name: 'Moss 5', color: '#39552d' },
        ],
        [
            { name: 'Olive 1', color: '#cfd958' },
            { name: 'Olive 2', color: '#c1b50e' },
            { name: 'Olive 3', color: '#949029' },
            { name: 'Olive 4', color: '#5b5d20' },
            { name: 'Olive 5', color: '#464a27' },
        ],
        [
            { name: 'Mustard 1', color: '#ded45b' },
            { name: 'Mustard 2', color: '#dcbc32' },
            { name: 'Mustard 3', color: '#caa02e' },
            { name: 'Mustard 4', color: '#a18528' },
            { name: 'Mustard 5', color: '#5a4f16' },
        ],
        [
            { name: 'Sand 1', color: '#e0d3b3' },
            { name: 'Sand 2', color: '#cbb791' },
            { name: 'Sand 3', color: '#ae976d' },
            { name: 'Sand 4', color: '#997f4b' },
            { name: 'Sand 5', color: '#6e5b35' },
        ],
        [
            { name: 'Brown 1', color: '#f3c569' },
            { name: 'Brown 2', color: '#b58444' },
            { name: 'Brown 3', color: '#906133' },
            { name: 'Brown 4', color: '#674d28' },
            { name: 'Brown 5', color: '#4d3f27' },
        ],
    ],
    mainPallete3: [
        [
            { name: 'Red Brown 1', color: '#f7c280' },
            { name: 'Red Brown 2', color: '#c98346' },
            { name: 'Red Brown 3', color: '#a85a2c' },
            { name: 'Red Brown 4', color: '#6d3a1b' },
            { name: 'Red Brown 5', color: '#4f3826' },
        ],
        [
            { name: 'Nude 1', color: '#f2d7b8' },
            { name: 'Nude 2', color: '#e8be8e' },
            { name: 'Nude 3', color: '#c67f58' },
            { name: 'Nude 4', color: '#a05440' },
            { name: 'Nude 5', color: '#603a36' },
        ],
        [
            { name: 'Blush 1', color: '#f2d2b9' },
            { name: 'Blush 2', color: '#e6b59d' },
            { name: 'Blush 3', color: '#c27d72' },
            { name: 'Blush 4', color: '#9e4b51' },
            { name: 'Blush 5', color: '#80363e' },
        ],
        [
            { name: 'Cool Grey 1', color: '#ced2cd' },
            { name: 'Cool Grey 2', color: '#aeb3b0' },
            { name: 'Cool Grey 3', color: '#939794' },
            { name: 'Cool Grey 4', color: '#6c706c' },
            { name: 'Cool Grey 5', color: '#3a403e' },
        ],
        [
            { name: 'Warm Grey 1', color: '#d5cfbe' },
            { name: 'Warm Grey 2', color: '#bcb1a0' },
            { name: 'Warm Grey 3', color: '#9c9182' },
            { name: 'Warm Grey 4', color: '#877b68' },
            { name: 'Warm Grey 5', color: '#605549' },
        ],
        [
            { name: 'Light 1', color: '#e0eae9' },
            { name: 'Light 2', color: '#e1fade' },
            { name: 'Light 3', color: '#ffd9cf' },
            { name: 'Light 4', color: '#ffe6f4' },
            { name: 'Light 5', color: '#fdfebd' },
        ],
        [
            { name: 'Bright 1', color: '#1eb0e0' },
            { name: 'Bright 2', color: '#b3e754' },
            { name: 'Bright 3', color: '#ff366d' },
            { name: 'Bright 4', color: '#fd3832' },
            { name: 'Bright 5', color: '#f9f944' },
        ],
    ],
    matchBasePallete: [
        {
            name: 'White',
            color: '#ffffff',
        },
        {
            name: 'Black',
            color: '#000000',
        },
        {
            name: 'C810 Night Grey',
            color: '#565c5f',
        },
        {
            name: 'C815 Half Grey',
            color: '#7c8587',
        },
    ],
    matchAdditionalPallete: [
        {
            name: 'C022 Sunny Yellow',
            color: '#f2db32',
        },
        {
            name: 'C113 Dark Fire',
            color: '#c30924',
        },
        {
            name: 'C216 Dark Sky Blue',
            color: '#115ab6',
        },
        {
            name: 'C217 Bright Bavaria Blue',
            color: '#1c348b',
        },
    ],
}