// Chakra imports
import { Flex, Grid, Text, useColorModeValue } from "@chakra-ui/react";

// Custom components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import React from "react";
import ProjectCard from "./ProjectCard";
import { useState, useEffect } from "react";
import useStore, { setState, getState } from "../../../../hooks/useStore";
const Projects = ({ title, description }) => {
  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");
  const [category, setCategory] = useState([]);
  async function getData() {
    var myHeaders = new Headers();
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    return await fetch(
      "https://staging.tolloapp.com/designapi/api/Company/1/no", //"https://localhost:44392/api/Company/1/no", //
      requestOptions
    )
      .then((response) => response.json())
      .catch((error) => console.log(error));
  }
  useEffect(() => {
    sessionStorage.removeItem("catName");
    sessionStorage.removeItem("catLink");
    sessionStorage.removeItem("productLink");
    sessionStorage.removeItem("productName");
    sessionStorage.removeItem("designLink");
    sessionStorage.removeItem("designName");
    // get from session (if the value expired it is destroyed)
    function sessionGet(key) {
      let stringValue = sessionStorage.getItem(key);
      if (stringValue !== null) {
        let value = JSON.parse(stringValue);
        let expirationDate = new Date(value.expirationDate);
        if (expirationDate > new Date()) {
          return value.value;
        } else {
          sessionStorage.removeItem(key);
        }
      }
      return null;
    }

    // add into seossion
    function sessionSet(key, value, expirationInMin = 60) {
      let expirationDate = new Date(
        new Date().getTime() + 60000 * expirationInMin
      );
      let newValue = {
        value: value,
        expirationDate: expirationDate.toISOString(),
      };
      sessionStorage.setItem(key, JSON.stringify(newValue));
    }
    sessionGet("company");
    if (sessionStorage.getItem("company") == undefined) {
      getData().then((data) => {
        sessionSet("company", data); // sessionStorage.setItem("company", JSON.stringify(data));
        setState({ company: data });
        setCategory(data.company_sport);
      });
    } else {
      setState({
        company: JSON.parse(sessionStorage.getItem("company")).value,
      });
      setCategory(
        JSON.parse(sessionStorage.getItem("company")).value.company_sport
      );
    }
  }, []);
  return (
    <Card p="16px" my="24px">
      <CardBody px="5px">
        <div className="divcentre">
          <Grid
            templateColumns={{
              sm: "1fr",
              md: "1fr 1fr 1fr auto",
              xl: "repeat(5, 1fr)",
            }}
            templateRows={{
              sm: "1fr 1fr 1fr auto",
              md: "1fr 1fr 1fr",
              xl: "1fr",
            }}
            gap="30px"
          >
            {category.map((data, index) => (
              <div key={index}>
                <ProjectCard
                  id={data.id}
                  image={data.imagepath}
                  //name={data.sport.name}
                  category={data.sport.name}
                  description={data.sport.notes}
                />
              </div>
            ))}
          </Grid>
        </div>
      </CardBody>
    </Card>
  );
};

export default Projects;
