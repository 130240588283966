/* eslint-disable react/prop-types */
import { Box, Button, Input, Text, Select } from "@chakra-ui/react";
import cn from "clsx";
import useStore, { setState, getState } from "../../../../hooks/useStore";
import { loadSvg } from "../../../../util/fabric";
import TextEditor from "./TextEditor";
import { addText } from "../../../../util/fabric";
import { ChevronLeftIcon, DeleteIcon } from "@chakra-ui/icons";
const TextContent = ({ fabricCanvas }) => {
  const [
    inputText,
    allTexts,
    tempText,
    texturePath,
    isModelLoaded,
  ] = useStore((state) => [
    state.texturePath,
    state.isModelLoaded,
    state.inputText,
    state.tempText,
    state.allTexts,
  ]);
  const fontFamilies = [
    "Arial",
    "Verdana",
    "Times New Roman",
    "Courier New",
    "Georgia",
    "Palatino",
    "Garamond",
    "Bookman",
    "Comic Sans MS",
    "Trebuchet MS",
    "Gill Sans",
    "Baskerville",
    "Calibri",
    "Century Gothic",
    "Didot",
    // Add more font families as needed
  ];

  const getUv = { x: 0, y: 0 };
  const addTextToCanvas = () => {
    addText(fabricCanvas, getUv);
  };
  return (
    <Box className="flex flex-col">
      <Box className="flex mb-3 pt-2">
        <Input
          type="text"
          value={tempText}
          placeholder="insert text"
          onInput={(e) => {
            setState({
              inputText: e.target.value,
              tempText: e.target.value,
            });
          }}
        />
        <Button
          colorScheme="teal"
          borderColor="teal.300"
          color="teal.300"
          variant="outline"
          className="py-4 px-5 rounded-md bg-sky-700 text-white flex items-center ms-2"
          onClick={() => {
            if (inputText == "" || tempText == "") return;
            addTextToCanvas();
            // setState({
            //   addTextEnabled: true,
            //   evented: true,
            //   tempText: "",
            // });
          }}
        >
          <span className="text-sm me-5">Add Text</span>
        </Button>
      </Box>

      {getState().allTexts.map((textData, index) => (
        <TextEditor
          key={index}
          fabricCanvas={fabricCanvas}
          textData={textData}
          fontFamilies={fontFamilies}
        />
      ))}
    </Box>
  );
};

export default TextContent;
