// Chakra imports
import { Flex, Grid, Text, useColorModeValue } from "@chakra-ui/react";

// Custom components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import React from "react";
import ProjectCard from "./ProjectCard";
import { useState, useEffect } from "react";
import { getState, setState } from "../../../../hooks/useStore";
const Projects = ({ catId, title, description }) => {
  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");  
  const [products, setProducts] = useState([]);
  useEffect(() => {
    setState({
      company: JSON.parse(sessionStorage.getItem("company")).value,
    });
    if (Object.keys(getState().company).length === 0) {
      setState({
        company: JSON.parse(sessionStorage.getItem("company")).value,
      });
    }
    if (
      getState().company != {} &&
      getState().company.company_sport != undefined
    ) {
      var company_sport = getState().company.company_sport.filter(
        (x) => x.id == catId
      );
      if (company_sport != null) {
        sessionStorage.setItem("catName", company_sport[0].sport.name);
        setProducts(company_sport[0].products);
      }
    }
  }, []);
  return (
    <Card p="16px" my="24px">
      <CardBody px="5px">
        <div className="divcentre">
          <Grid
            templateColumns={{
              sm: "1fr 1fr",
              md: "repeat(5, 1fr)",
              xl: "repeat(5, 1fr)",
            }}
            templateRows={{ sm: "1fr 1fr 1fr auto", md: "1fr 1fr", xl: "1fr" }}
            gap="30px"
          >
            {products.map((data, index) => (
              <div key={index}>
                <ProjectCard
                  id={data.id}
                  catId={catId}
                  image={data.imagepath}
                  // name={data.name}
                  category={data.name}
                  description={data.notes}
                />
              </div>
            ))}
          </Grid>
        </div>
      </CardBody>
    </Card>
  );
};

export default Projects;
