// Chakra imports
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Flex,
  Image,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import { FaOpencart } from "react-icons/fa";
import { useHistory } from "react-router-dom";
const ProjectCard = ({ image, name, category, link, status, description }) => {
  const history = useHistory();
  const textColor = useColorModeValue("gray.700", "white");

  return (
    <Flex
      direction="column"
      onClick={() => {
        history.push({
          pathname: link,
        });
      }}
    >
      <Box className="imgArt" mb="20px" position="relative" borderRadius="15px">
        <Image width={"250px"} src={image} borderRadius="15px" />
        <Box
          w="100%"
          h="100%"
          position="absolute"
          top="0"
          borderRadius="15px"
          bg="linear-gradient(360deg, rgba(49, 56, 96, 0.16) 0%, rgba(21, 25, 40, 0.88) 100%)"
        ></Box>
      </Box>
      <Flex className="imgText" direction="column">
        {status == 1 && <FaOpencart size={35} fill="teal" />}
        <Text fontSize="md" color="gray.500" fontWeight="600" mb="10px">
          {name}
        </Text>
        <Text fontSize="xl" color={textColor} fontWeight="bold" mb="10px">
          {category}
        </Text>
        <Text fontSize="md" color="gray.500" fontWeight="400" mb="20px">
          {description}
        </Text>
      </Flex>
    </Flex>
  );
};

export default ProjectCard;
