/* eslint-disable react/prop-types */
import {
  Box,
  Button,
  Accordion,
  Input,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  useToast,
} from "@chakra-ui/react";
import TextContent from "./TextContent";
import ColorContent from "./ColorContent";
import ImageContent from "./ImageContent";
import FabricContent from "./FabricContent";
import ArtworkContent from "./ArtworkContent";
import RosterContent from "./RosterContent";
import { ChevronRightIcon } from "@chakra-ui/icons";
import useStore, { setState, getState } from "../../../../hooks/useStore";
import React, { useEffect, useState } from "react";

function sessionSet(key, value, expirationInMin = 60) {
  let expirationDate = new Date(new Date().getTime() + 60000 * expirationInMin);
  let newValue = {
    value: value,
    expirationDate: expirationDate.toISOString(),
  };
  sessionStorage.setItem(key, JSON.stringify(newValue));
}

const PropertyEditor = ({ fabricCanvas, isMobile, threeObject }) => {
  const toast = useToast();
  const [isCustReadOnly, setIsCustReadOnly] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [cust, setCust] = useState({
    name: "",
    id: 0,
    contact_id: 0,
    contact: {
      id: 0,
      email: "",
      phone: "",
      city: "",
      state: "",
      zipcode: "",
      address: "",
    },
  });
  var getmyHeaders = new Headers();
  getmyHeaders.append("Accept", "application/json");
  getmyHeaders.append("Content-Type", "application/json");
  var getrequestOptions = {
    method: "GET",
    headers: getmyHeaders,
    redirect: "follow",
  };
  const fetchQuotesData = async (email) => {
    try {
      const response = await fetch(
        "https://staging.tolloapp.com/designapi/api/Garments/GetQuotebyEmail?email=" +
          email, //"https://localhost:44392/api", //
        getrequestOptions
      );
      const result = await response.json();
      sessionSet("quotes", result);
      setState({ quotes: result });
      setState({ customer: result._customer });
      setCust(result._customer);
    } catch (error) {
      console.log(error);
    }
  };
  const showSuccessToast = () => {
    toast({
      title: "",
      description: "Saved Successfully!",
      status: "success",
      duration: 3000,
      isClosable: true,
      position: "top",
    });
  };
  const showErrorToast = () => {
    toast({
      title: "",
      description: "Some issue happened. Please contact Administrator.",
      status: "error",
      duration: 3000,
      isClosable: true,
      position: "top",
    });
  };
  function sessionGet(key) {
    let stringValue = sessionStorage.getItem(key);
    if (stringValue !== null) {
      let value = JSON.parse(stringValue);
      let expirationDate = new Date(value.expirationDate);
      if (expirationDate > new Date()) {
        return value.value;
      } else {
        sessionStorage.removeItem(key);
      }
    }
    return null;
  }

  useEffect(() => {
    if (sessionStorage.getItem("quotes") != undefined) {
      setState({ quotes: sessionGet("quotes") });
      setState({ customer: sessionGet("quotes")._customer });
      setCust(sessionGet("quotes")._customer);
      setIsCustReadOnly(true);
    }
  }, [sessionStorage.getItem("quotes")]);
  return (
    <div
      style={{
        // flex: "1",
        width: "700px",
        //width: "30%",
        maxWidth: "40%",
        minHeight: "700px",
        background: "white",
        height: "100%",
      }}
      className="h-fit top-10 "
    >
      <div style={{ height: "25px", width: "50px" }}></div>
      <Tabs isFitted variant="enclosed">
        <TabList>
          <Tab
            bg={"teal.200"}
            color={"white"}
            _selected={{ color: "white", bg: "teal.600" }}
          >
            Customisation
          </Tab>
          <Tab
            bg={"teal.200"}
            color={"white"}
            _selected={{ color: "white", bg: "teal.600" }}
          >
            Artworks/Logo
          </Tab>
          <Tab
            bg={"teal.200"}
            color={"white"}
            _selected={{ color: "white", bg: "teal.600" }}
          >
            Save/Order
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <Accordion allowToggle>
              {/* Temporary to hide fabric style */}
              {/* <AccordionItem>
                <h2 className="border-b border-slate-200">
                  <AccordionButton>
                    <Box className="flex flex-col w-full">
                      <Box
                        className="p-1 pb-0 ps-3.5"
                        as="span"
                        flex="1"
                        textAlign="left"
                      >
                        Customize Fabrics
                      </Box>
                      <Text
                        textAlign="left"
                        className="text-sm text-slate-400 ps-3.5 pb-1"
                      >
                        Select your fabric style
                      </Text>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel
                  height="auto"
                  p={0}
                  className="proppanel flex flex-col justify-between items-between"
                >
                  <FabricContent />
                </AccordionPanel>
              </AccordionItem> */}
              <AccordionItem>
                <h2 className="border-b border-slate-200">
                  <AccordionButton>
                    <Box className="flex flex-col w-full">
                      <Box
                        className="p-1 pb-0 ps-3.5"
                        as="span"
                        flex="1"
                        textAlign="left"
                      >
                        Customize Colors
                      </Box>
                      <Text
                        textAlign="left"
                        className="text-sm text-slate-400 ps-3.5 pb-1"
                      >
                        Make your color combination
                      </Text>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel
                  height="auto"
                  p={0}
                  className="colorproppanel flex flex-col justify-between items-between"
                >
                  <ColorContent fabricCanvas={fabricCanvas} />
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem>
                <h2 className="border-b border-slate-200">
                  <AccordionButton>
                    <Box className="flex flex-col w-full">
                      <Box
                        className="p-1 pb-0 ps-3.5"
                        as="span"
                        flex="1"
                        textAlign="left"
                      >
                        Customize Texts
                      </Box>
                      <Text
                        textAlign="left"
                        className="text-sm text-slate-400 ps-3.5 pb-1"
                      >
                        Add names, lettering, numbers, etc.
                      </Text>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel className="proppanel" height="auto">
                  <TextContent fabricCanvas={fabricCanvas} />
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </TabPanel>
          <TabPanel>
            <Accordion allowToggle height="full">
              <AccordionItem>
                <h2 className="border-b border-slate-200">
                  <AccordionButton>
                    <Box className="flex flex-col w-full">
                      <Box
                        className="p-1 pb-0 ps-3.5"
                        as="span"
                        flex="1"
                        textAlign="left"
                      >
                        Customize Images
                      </Box>
                      <Text
                        textAlign="left"
                        className="text-sm text-slate-400 ps-3.5 pb-1"
                      >
                        Add logos, graphics, etc.
                      </Text>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel height="auto">
                  <ImageContent fabricCanvas={fabricCanvas} />
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem>
                <h2 className="border-b border-slate-200">
                  <AccordionButton>
                    <Box className="flex flex-col w-full">
                      <Box
                        className="p-1 pb-0 ps-3.5"
                        as="span"
                        flex="1"
                        textAlign="left"
                      >
                        Add Artworks
                      </Box>
                      <Text
                        textAlign="left"
                        className="text-sm text-slate-400 ps-3.5 pb-1"
                      ></Text>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel height="auto">
                  <ArtworkContent fabricCanvas={fabricCanvas} />
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </TabPanel>
          <TabPanel>
            <Accordion allowToggle height="full">
              <AccordionItem>
                <h2 className="border-b border-slate-200">
                  <AccordionButton>
                    <Box className="flex flex-col w-full">
                      <Box
                        className="p-1 pb-0 ps-3.5"
                        as="span"
                        flex="1"
                        textAlign="left"
                      >
                        Add Roster
                      </Box>
                      <Text
                        textAlign="left"
                        className="text-sm text-slate-400 ps-3.5 pb-1"
                      >
                        Add your Team Roster
                      </Text>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel className="proppanel " height="auto">
                  <RosterContent />
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem>
                <h2 className="border-b border-slate-200">
                  <AccordionButton>
                    <Box className="flex flex-col w-full">
                      <Box
                        className="p-1 pb-0 ps-3.5"
                        as="span"
                        flex="1"
                        textAlign="left"
                      >
                        Finish Designing
                      </Box>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel className="quotepanel " height="auto">
                  <div className="p-4 flex flex-col">
                    <div className="flex justify-between">
                      <Input
                        id="customerid"
                        type="hidden"
                        defaultValue={cust.id}
                      />
                      <Input
                        size="sm"
                        id="name"
                        type="text"
                        placeholder="Enter First Name"
                        defaultValue={cust.name}
                        readOnly={isCustReadOnly}
                      />
                    </div>
                  </div>
                  <div className="p-4 flex flex-col">
                    <div className="flex justify-between">
                      <Input
                        id="contactid"
                        type="hidden"
                        defaultValue={cust.contact.id}
                      />
                      <Input
                        size="sm"
                        id="email"
                        type="text"
                        placeholder="Enter Email Id"
                        defaultValue={cust.contact.email}
                        readOnly={isCustReadOnly}
                      />
                    </div>
                  </div>
                  <div className="p-4 flex flex-col">
                    <div className="flex justify-between">
                      <Input
                        size="sm"
                        id="phone"
                        type="text"
                        placeholder="Enter Phone Number"
                        defaultValue={cust.contact.phone}
                        readOnly={isCustReadOnly}
                      />
                    </div>
                  </div>
                  <div className="p-4 flex flex-col">
                    <div className="flex justify-between">
                      <Input
                        size="sm"
                        id="website"
                        type="text"
                        placeholder="Enter Website"
                        defaultValue={cust.contact.address}
                        readOnly={isCustReadOnly}
                      />
                    </div>
                  </div>
                  <div className="p-4 flex flex-col">
                    <div className="flex justify-between">
                      <Input
                        size="sm"
                        id="city"
                        type="text"
                        placeholder="Enter City"
                        defaultValue={cust.contact.city}
                        readOnly={isCustReadOnly}
                      />
                      <span style={{ width: "15px" }}> </span>
                      <Input
                        size="sm"
                        id="state"
                        type="text"
                        placeholder="Enter State"
                        defaultValue={cust.contact.state}
                        readOnly={isCustReadOnly}
                      />
                      <span style={{ width: "15px" }}> </span>
                      <Input
                        size="sm"
                        id="zip"
                        type="text"
                        placeholder="Enter Zip"
                        defaultValue={cust.contact.zipcode}
                        readOnly={isCustReadOnly}
                      />
                    </div>
                  </div>

                  <div className="p-4 flex flex-col">
                    <div className="flex justify-between">
                      <Button
                        onClick={() => {
                          setIsLoading(true);
                          fabric.Image.prototype.toDatalessObject =
                            fabric.Image.prototype.toObject;
                          fabric.Image.prototype.toObject = (function (
                            toObject
                          ) {
                            return function () {
                              return fabric.util.object.extend(
                                toObject.call(this),
                                {
                                  id: this.id,
                                  src: this.toDataURL(),
                                }
                              );
                            };
                          })(fabric.Image.prototype.toObject);
                          fabric.Image.prototype.getSvgSrc = function () {
                            return this.toDataURLforSVG();
                          };

                          fabric.Image.prototype.toDataURLforSVG = function (
                            options
                          ) {
                            var el = fabric.util.createCanvasElement();
                            el.width =
                              this._element.naturalWidth || this._element.width;
                            el.height =
                              this._element.naturalHeight ||
                              this._element.height;
                            el.getContext("2d").drawImage(this._element, 0, 0);
                            var data = el.toDataURL(options);
                            return data;
                          };
                          var canvas = document.getElementById("renderer");
                          var strMime = "image/png";
                          const imgData = canvas.toDataURL(strMime);
                          var myHeaders = new Headers();
                          myHeaders.append(
                            "Content-Type",
                            "application/x-www-form-urlencoded"
                          );
                          myHeaders.append("Accept", "application/json");

                          var urlencoded = new URLSearchParams();
                          urlencoded.append(
                            "_garment.id",
                            getState().garment.id
                          );
                          urlencoded.append(
                            "_garment.sport_id",
                            getState().garment.sport_id
                          );
                          urlencoded.append(
                            "_garment.fit_id",
                            getState().garment.fit_id
                          );
                          urlencoded.append(
                            "_garment.product_id",
                            getState().garment.product_id
                          );
                          urlencoded.append(
                            "_garment.design_id",
                            getState().garment.design_id
                          );
                          urlencoded.append(
                            "_garment.company_id",
                            getState().garment.company_id
                          );
                          urlencoded.append("_garment.status", 1);
                          urlencoded.append(
                            "_garment.roster",
                            JSON.stringify(getState().allPlayers)
                          );
                          urlencoded.append(
                            "_garment.guestemail",
                            document.getElementById("email").value
                          );
                          urlencoded.append(
                            "_garment.package_id",
                            getState().garment.package_id
                          );
                          urlencoded.append(
                            "_garment.fabricstyles_id",
                            getState().tempFabric.id
                          );
                          urlencoded.append(
                            "_garment.svgimage",
                            fabricCanvas.toSVG()
                          );
                          urlencoded.append("_garment.front", imgData);
                          urlencoded.append("_garment.back", imgData);

                          var jsondata = fabricCanvas.toJSON(["id", "name"]);
                          urlencoded.append(
                            "_garment.modeljson",
                            JSON.stringify(jsondata)
                          );
                          urlencoded.append(
                            "_garment.notes",
                            "Test Model Json"
                          );
                          urlencoded.append(
                            "_garment.customer_id",
                            document.getElementById("customerid").value
                          );
                          urlencoded.append(
                            "_customer.id",
                            document.getElementById("customerid").value
                          );
                          urlencoded.append(
                            "_customer.name",
                            document.getElementById("name").value
                          );
                          urlencoded.append(
                            "_customer.contact.id",
                            document.getElementById("contactid").value
                          );
                          urlencoded.append(
                            "_customer.contact_id",
                            document.getElementById("contactid").value
                          );
                          urlencoded.append(
                            "_customer.contact.email",
                            document.getElementById("email").value
                          );
                          urlencoded.append(
                            "_customer.contact.phone",
                            document.getElementById("phone").value
                          );
                          urlencoded.append(
                            "_customer.contact.address",
                            document.getElementById("website").value
                          );
                          urlencoded.append(
                            "_customer.contact.city",
                            document.getElementById("city").value
                          );
                          urlencoded.append(
                            "_customer.contact.state",
                            document.getElementById("state").value
                          );
                          urlencoded.append(
                            "_customer.contact.zipcode",
                            document.getElementById("zip").value
                          );
                          var requestOptions = {
                            method: "POST",
                            headers: myHeaders,
                            body: urlencoded,
                            redirect: "follow",
                          };
                          fetch(
                            //"https://staging.tolloapp.com/designapi/api/Garments/PostQuote/",
                            "https://staging.tolloapp.com/designapi/api/Garments/PostQuote/",
                            requestOptions
                          )
                            .then((response) => response.text())
                            .then((result) => {
                              console.log(result);
                              setIsLoading(false);
                              showSuccessToast();
                              fetchQuotesData(
                                document.getElementById("email").value
                              );
                            })
                            .catch((error) => {
                              console.log("error", error);
                              setIsLoading(false);
                              showErrorToast();
                            });
                        }}
                        colorScheme="teal"
                        borderColor="teal.300"
                        color="teal.300"
                        variant="outline"
                        className="py-4 px-5 rounded-md bg-sky-700 text-white flex items-center"
                      >
                        <span className="text-sm me-5">Save Design</span>
                        <ChevronRightIcon />
                      </Button>

                      <Button
                        onClick={() => {
                          setIsLoading(true);
                          fabric.Image.prototype.toDatalessObject =
                            fabric.Image.prototype.toObject;
                          fabric.Image.prototype.toObject = (function (
                            toObject
                          ) {
                            return function () {
                              return fabric.util.object.extend(
                                toObject.call(this),
                                {
                                  id: this.id,
                                  src: this.toDataURL(),
                                }
                              );
                            };
                          })(fabric.Image.prototype.toObject);
                          fabric.Image.prototype.getSvgSrc = function () {
                            return this.toDataURLforSVG();
                          };

                          fabric.Image.prototype.toDataURLforSVG = function (
                            options
                          ) {
                            var el = fabric.util.createCanvasElement();
                            el.width =
                              this._element.naturalWidth || this._element.width;
                            el.height =
                              this._element.naturalHeight ||
                              this._element.height;
                            el.getContext("2d").drawImage(this._element, 0, 0);
                            var data = el.toDataURL(options);
                            return data;
                          };
                          var canvas = document.getElementById("renderer");
                          var strMime = "image/png";
                          const imgData = canvas.toDataURL(strMime);
                          var myHeaders = new Headers();
                          myHeaders.append(
                            "Content-Type",
                            "application/x-www-form-urlencoded"
                          );
                          myHeaders.append("Accept", "application/json");

                          var urlencoded = new URLSearchParams();
                          urlencoded.append(
                            "_garment.id",
                            getState().garment.id
                          );
                          urlencoded.append(
                            "_garment.sport_id",
                            getState().garment.sport_id
                          );
                          urlencoded.append(
                            "_garment.fit_id",
                            getState().garment.fit_id
                          );
                          urlencoded.append(
                            "_garment.product_id",
                            getState().garment.product_id
                          );
                          urlencoded.append(
                            "_garment.design_id",
                            getState().garment.design_id
                          );
                          urlencoded.append(
                            "_garment.company_id",
                            getState().garment.company_id
                          );
                          urlencoded.append("_garment.status", 2);
                          urlencoded.append(
                            "_garment.roster",
                            JSON.stringify(getState().allPlayers)
                          );
                          urlencoded.append(
                            "_garment.guestemail",
                            document.getElementById("email").value
                          );
                          urlencoded.append(
                            "_garment.package_id",
                            getState().garment.package_id
                          );
                          urlencoded.append(
                            "_garment.fabricstyles_id",
                            getState().tempFabric.id
                          );
                          urlencoded.append(
                            "_garment.svgimage",
                            fabricCanvas.toSVG()
                          );
                          urlencoded.append("_garment.front", imgData);
                          urlencoded.append("_garment.back", imgData);

                          var jsondata = fabricCanvas.toJSON(["id", "name"]);
                          urlencoded.append(
                            "_garment.modeljson",
                            JSON.stringify(jsondata)
                          );
                          urlencoded.append(
                            "_garment.notes",
                            "Test Model Json"
                          );
                          urlencoded.append(
                            "_garment.customer_id",
                            document.getElementById("customerid").value
                          );
                          urlencoded.append(
                            "_customer.id",
                            document.getElementById("customerid").value
                          );
                          urlencoded.append(
                            "_customer.name",
                            document.getElementById("name").value
                          );
                          urlencoded.append(
                            "_customer.contact.id",
                            document.getElementById("contactid").value
                          );
                          urlencoded.append(
                            "_customer.contact_id",
                            document.getElementById("contactid").value
                          );
                          urlencoded.append(
                            "_customer.contact.email",
                            document.getElementById("email").value
                          );
                          urlencoded.append(
                            "_customer.contact.phone",
                            document.getElementById("phone").value
                          );
                          urlencoded.append(
                            "_customer.contact.address",
                            document.getElementById("website").value
                          );
                          urlencoded.append(
                            "_customer.contact.city",
                            document.getElementById("city").value
                          );
                          urlencoded.append(
                            "_customer.contact.state",
                            document.getElementById("state").value
                          );
                          urlencoded.append(
                            "_customer.contact.zipcode",
                            document.getElementById("zip").value
                          );
                          var requestOptions = {
                            method: "POST",
                            headers: myHeaders,
                            body: urlencoded,
                            redirect: "follow",
                          };
                          fetch(
                            //"https://staging.tolloapp.com/designapi/api/Garments/PostQuote/",
                            "https://staging.tolloapp.com/designapi/api/Garments/PostQuote/",
                            requestOptions
                          )
                            .then((response) => response.text())
                            .then((result) => {
                              console.log(result);
                              setIsLoading(false);
                              showSuccessToast();
                              fetchQuotesData(
                                document.getElementById("email").value
                              );
                            })
                            .catch((error) => {
                              console.log("error", error);
                              setIsLoading(false);
                              showErrorToast();
                            });
                        }}
                        colorScheme="teal"
                        borderColor="teal.300"
                        color="teal.300"
                        variant="outline"
                        className="py-4 px-5 rounded-md bg-sky-700 text-white flex items-center"
                      >
                        <span className="text-sm me-5">Submit for Quote</span>
                        <ChevronRightIcon />
                      </Button>
                    </div>
                  </div>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </TabPanel>
        </TabPanels>
      </Tabs>
      {isLoading && (
        <div
          style={{
            width: "100%",
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            background: "rgba(200,200,200,0.7)",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <img
            src="https://www.owayo.com/konfigurator_html/img/preloader_shirt_final.gif"
            alt=""
          />
        </div>
      )}
    </div>
  );
};

export default PropertyEditor;
