/* eslint-disable react/prop-types */
import {
  Box,
  Button,
  Input,
  Text,
  Grid,
  Img,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import useStore, { setState, getState } from "../../../../hooks/useStore";
import { InfoIcon, DeleteIcon, EditIcon } from "@chakra-ui/icons";
const ImageContent = ({ fabricCanvas }) => {
  const [currentSvg, setCurrentSvg] = useState(null);
  function getBlob(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = () =>
        resolve(new Blob([reader.result], { type: file.type }));
      reader.onerror = (error) => reject(error);
    });
  }

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    const blob = await getBlob(file);
    const image = {
      id: `image-${getState().allImages.length + 1}`,
      image: blob,
      filename: file.name,
      ext: file.name.split(".").pop(),
    };
    setState((prevState) => ({
      allImages: [...prevState.allImages, image],
    }));
    event.target.value = "";
  };

  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  return (
    <Box className="flex flex-col">
      <Box
        className="flex justify-center border flex-col cursor-pointer"
        borderRadius="20px"
        p="10px"
        onClick={handleButtonClick}
      >
        <Img
          src="https://static.thenounproject.com/png/4322871-200.png"
          width={50}
          className="mx-auto"
        />
        <Text className="text-center">Click to upload (png, svg, jpeg)</Text>
        <Text className="text-center text-xs">Maximum file size 10 MB.</Text>
        <Input
          onChange={handleFileChange}
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          accept=".png,.svg,.jpg,.jpeg"
        />
      </Box>
      <div className="imgproppanel ">
        <Grid
          templateColumns={{
            sm: "repeat(4, 1fr)",
            md: "repeat(4, 1fr)",
            xl: "repeat(4, 1fr)",
          }}
          gap="2px"
        >
          {getState().allImages.length > 0 &&
            getState().allImages.map((imageData, index) => {
              const url = URL.createObjectURL(imageData.image);
              return (
                <>
                  <div
                    key={index}
                    style={{ width: "125px" }}
                    className="relative hover:border-sky-700 cursor-pointer border m-1 p-1 w-1/3 border-1 rounded justify-center items-center"
                  >
                    {imageData.ext == "svg" && (
                      <Popover
                        closeOnBlur={true}
                        closeOnEsc={true}
                        gutter={25}
                        placement="left"
                        variant="picker"
                      >
                        <PopoverTrigger>
                          <Button
                            onClick={(e) => {
                              let activeObject = null;
                              if (
                                fabricCanvas.getActiveObject() &&
                                fabricCanvas
                                  .getActiveObject()
                                  .id.includes("image")
                              ) {
                                activeObject = fabricCanvas.getActiveObject();
                              } else {
                                activeObject = fabricCanvas
                                  .getObjects()
                                  .find((obj) => obj.id == imageData.id);
                              }
                              setCurrentSvg(activeObject._objects);
                            }}
                            variant="ghost"
                            className="absolute left-0 top-0 z-100"
                            position={"absolute"}
                          >
                            <EditIcon color="teal.300" />
                          </Button>
                        </PopoverTrigger>
                        <PopoverContent>
                          <PopoverArrow />
                          <PopoverHeader>Change Image Colors</PopoverHeader>
                          <PopoverCloseButton />
                          <PopoverBody>
                            {currentSvg &&
                              currentSvg.map((obj, index) => (
                                <Box
                                  key={index}
                                  className="flex justify-between mb-3"
                                >
                                  <Box className="w-1/2 ms-1">
                                    <Text className="mb-1 text-slate-400">
                                      Color {index + 1}
                                    </Text>
                                    <Input
                                      key={index}
                                      type="color"
                                      name=""
                                      value={obj.fill}
                                      onChange={(e) => {
                                        obj.set("fill", e.target.value);
                                        fabricCanvas.renderAll();
                                      }}
                                    />
                                  </Box>
                                  <Box className="w-1/2 ms-1">
                                    <Text className="mb-1 text-slate-400">
                                      Stroke {index + 1}
                                    </Text>
                                    <Input
                                      key={index}
                                      type="color"
                                      name=""
                                      value={obj.stroke}
                                      onChange={(e) => {
                                        obj.set("stroke", e.target.value);
                                        fabricCanvas.renderAll();
                                      }}
                                    />
                                  </Box>
                                </Box>
                              ))}
                          </PopoverBody>
                        </PopoverContent>
                      </Popover>
                    )}
                    <Button
                      variant="ghost"
                      className="absolute right-0 top-0 z-100"
                      position={"absolute"}
                      onClick={(e) => {
                        const currentImgObj = fabricCanvas
                          .getObjects()
                          .find((data) => data.id === imageData.id);
                        fabricCanvas.remove(currentImgObj);
                        setState((prevState) => ({
                          allImages: prevState.allImages.filter(
                            (obj) => obj.id !== imageData.id
                          ),
                        }));
                      }}
                    >
                      <DeleteIcon color="red.500" />
                    </Button>
                    <Box
                      onClick={() => {
                        setState({
                          tempImage: {
                            url,
                            ext: imageData.ext,
                          },
                          addImageEnabled: true,
                          evented: true,
                        });
                      }}
                    >
                      <Img className="w-full" src={url} />
                      <Text className="text-xs mt-1 text-center">
                        {imageData.filename}
                      </Text>
                    </Box>
                  </div>
                </>
              );
            })}
        </Grid>
      </div>
      <br />
      <div className="p-5 border-y-2 flex items-center">
        <InfoIcon color="gray.400" />
        <Text className="ms-3" color="gray.400">
          Note: Drag and drop the image to place it on the 3d model
        </Text>
      </div>
    </Box>
  );
};

export default ImageContent;
