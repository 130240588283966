import { shallow } from "zustand/shallow";
import { createWithEqualityFn } from "zustand/traditional";
// These States only for Designing
const useStoreImpl = createWithEqualityFn((set, get) => ({
  activeLayer: null,
  inputText: "",
  tempText: "",
  tempImage: null,
  tempArtwork: null,
  allTexts: [],
  allFabrics:[],
  allImages: [],
  allArtworks: [],
  allPlayers: [],
  garment:{},
  quotes:{},
  customer:{},
  initialLoad: true,
  changedColor: false,
  tempFabric:null,
  addFabricEnabled: false,
  addImageEnabled: false,
  modelLoaded: false,
  quantity: 1,
  is2D: false,
  dimension: {
    width: 2048,
    height: 2048,
  },
  isInteract: false,
  isLoading: true,
  svgGroup: null,
  sports: [],
  mascots: [],
  sponsors: [],
  colors: [],
  company: {},
  category:[],
  designModel: {},
  designModelObjAxis:{},
  evented: false,
  addTextEnabled: false,
  texturePath: 1,
  newColor: "#FFFFFF",
  isTakingScreenshot: false,
  setAddTextEnabled: (value) => {
    set({
      addTextEnabled: value,
    });
  },
  setQuantity: (value) => {
    set({
      quantity: value,
    });
  },
  setSvgGroup: (value) => {
    set({
      svgGroup: value,
    });
  },
}));

const useStore = (sel) => useStoreImpl(sel, shallow);
Object.assign(useStore, useStoreImpl);
const { getState, setState, subscribe } = useStoreImpl;

export { getState, setState, subscribe };
export default useStore;
