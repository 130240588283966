import useStore, { getState, setState } from "../../../../hooks/useStore";
const DesignList = ({ fabricCanvas, fabricRef }) => {
  const isMobile = getState().dimension.width == 1024;
  setState({
    is2D: true,
  });
  setTimeout(() => {
    const canvasContainer = document.getElementById("2dCanvas");
    // fabricCanvas.wrapperEl.style.display = 'block !important'
    if (fabricCanvas != undefined && fabricCanvas.wrapperEl != undefined) {
      fabricCanvas.wrapperEl.classList.remove("canvas-container");
      fabricCanvas.wrapperEl.children[0].style.display = "block";
      fabricCanvas.wrapperEl.children[0].style.width = "90%";
      fabricCanvas.wrapperEl.children[1].style.display = "block";
      fabricCanvas.wrapperEl.children[1].style.width = "90%";
      if (canvasContainer != undefined && canvasContainer.style != undefined) {
        canvasContainer.innerHTML = "";
        canvasContainer.style.transform = "scale(0.35) translate(-75%,-90%)";
        canvasContainer.appendChild(fabricCanvas.wrapperEl);
      }
    }
  }, 1000);

  return (
    <>
      <div id="2dCanvas"></div>      
    </>
  );
};

export default DesignList;
