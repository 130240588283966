import { useEffect, useState, useRef } from "react";
import ThreeScene from "../../../configurator/ThreeScene";
import {
  Img,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Flex,
  Box,
} from "@chakra-ui/react";
import DesignList from "../Design/components/DesignList";
import useStore, { getState, setState } from "../../../hooks/useStore";
import "../../../index.css";
import "../../../output.css";
import PropertyEditor from "../Design/components/PropertyEditor";
import MobileNav from "../Design/components/MobileNav";
import threedImg from "assets/img/3dcube.png";
import twodImg from "assets/img/2dcube.png";
import { useHistory } from "react-router-dom";
function Design(props) {
  const history = useHistory();
  const [fabricCanvas, setFabricCanvas] = useState(null);
  const [threeObject, setThreeObject] = useState(null);
  const fabricRef = useRef(null);
  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [designModel, setDesignModel] = useState({});
  let garmentId = "";
  var myHeaders = new Headers();
  myHeaders.append("Accept", "application/json");
  myHeaders.append("Content-Type", "application/json");

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };
  const [
    addTextEnabled,
    addImageEnabled,
    setAddTextEnabled,
    inputText,
    evented,
  ] = useStore((state) => [
    state.addTextEnabled,
    state.addImageEnabled,
    state.setAddTextEnabled,
    state.inputText,
    state.evented,
  ]);
  const [cust, setCust] = useState({});
  const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
    if (b64Data.indexOf(",") > -1) {
      b64Data = b64Data.split(",")[1];
    }
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  function sessionSet(key, value, expirationInMin = 60) {
    let expirationDate = new Date(
      new Date().getTime() + 60000 * expirationInMin
    );
    let newValue = {
      value: value,
      expirationDate: expirationDate.toISOString(),
    };
    sessionStorage.setItem(key, JSON.stringify(newValue));
  }
  const fetchQuotesData = async (emailid) => {
    try {
      const response = await fetch(
        //"https://localhost:44392/api/Garments/GetQuotebyEmail?email=" + emailid,
        "https://staging.tolloapp.com/designapi/api/Garments/GetQuotebyEmail?email=" +
          emailid,
        requestOptions
      );
      const result = await response.json();
      sessionSet("quotes", result);
      setState({ quotes: result });
      setState({ customer: result._customer });
      setCust(result._customer);
      //history.pushState(null, null, window.location.pathname);
    } catch (error) {
      console.log(error);
    }
  };

  if (window.location.hash.split("/").length > 3) {
    garmentId = window.location.hash.split("/")[6];
  }
  useEffect(() => {
    const getGarment = async () => {
      if (garmentId != null && garmentId != undefined) {
        setIsLoading(true);
        let response = await fetch(
          "https://staging.tolloapp.com/designapi/api/Garments/" + garmentId,
          requestOptions
        );
        response = await response.json();
        var resp = {
          modeljson: JSON.parse(response.modeljson),
          roster: JSON.parse(response.roster),
          guestemail: response.guestemail,
          id: garmentId,
        };
        sessionStorage.setItem("garment", JSON.stringify(resp));
        setIsLoading(false);
      } else {
        sessionStorage.removeItem("garment");
      }
    };
    getGarment();
    const updateMousePosition = (event) => {
      setMousePosition({ x: event.clientX, y: event.clientY });
    };
    window.addEventListener("mousemove", updateMousePosition);
    return () => {
      window.removeEventListener("mousemove", updateMousePosition);
    };
  }, []);

  useEffect(() => {
    if (
      sessionStorage.getItem("garment") != undefined &&
      sessionStorage.getItem("garment") != null &&
      fabricCanvas != null
    ) {
      let currentColors = getState().colors;
      fabricCanvas.loadFromJSON(
        JSON.parse(sessionStorage.getItem("garment")).modeljson,
        fabricCanvas.renderAll.bind(fabricCanvas)
      );

      //colors
      let objects = JSON.parse(sessionStorage.getItem("garment")).modeljson
        .objects[0].objects;
      for (let i = 0; i < objects.length; i++) {
        currentColors = currentColors.map((item) =>
          item.id == objects[i].id ? objects[i] : item
        );
      }
      setState({
        colors: currentColors,
      });
      //colors
      //text
      let objTexts = JSON.parse(
        sessionStorage.getItem("garment")
      ).modeljson.objects.filter((x) => x.type == "i-text");
      var tempTexts = [];
      for (let i = 0; i < objTexts.length; i++) {
        tempTexts.push({
          text: objTexts[i].text,
          id: objTexts[i].id,
          fill: objTexts[i].fill,
          fontFamily: objTexts[i].fontFamily,
          strokeWidth: objTexts[i].strokeWidth,
          strokeColor: "",
        });
      }
      setState({
        allTexts: tempTexts,
      });
      //text
      //images
      let objImages = JSON.parse(
        sessionStorage.getItem("garment")
      ).modeljson.objects.filter((x) => x.type == "image");
      var tempImages = [];
      for (let i = 0; i < objImages.length; i++) {
        tempImages.push({
          id:
            objImages[i].id == undefined
              ? `image-${getState().allImages.length + 1}`
              : objImages[i].id,
          image: b64toBlob(objImages[i].src, ""),
          filename: objImages[i].id,
          ext: "",
        });
        setState({
          allImages: tempImages,
        });
      }

      //images

      // Roster
      setState({
        allPlayers: JSON.parse(sessionStorage.getItem("garment")).roster,
      });
      // Roster
      //customer
      if (sessionStorage.getItem("quotes") == undefined) {
        fetchQuotesData(
          JSON.parse(sessionStorage.getItem("garment")).guestemail
        );
      }
      //customer
      if (fabricCanvas._objects.length > 0) {
        fabricCanvas._objects[0].selectable = false;
        fabricCanvas._objects[0].evented = false;
        fabricCanvas._objects[0].lockMovementX = true;
        fabricCanvas._objects[0].lockMovementY = true;
      }
      fabricCanvas.renderAll();
    }
    const handleKeyDown = (event) => {
      if (event.key === "Delete") {
        fabricCanvas.remove(fabricCanvas.getActiveObject());
        fabricCanvas.renderAll();
      }
    };
    if (window.innerWidth < 700) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
    document.addEventListener("keydown", handleKeyDown);

    // setTimeout(() => {
    //   setIsLoading(false);
    // }, 2000);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [fabricCanvas, isLoading, sessionStorage.getItem("garment")]);

  return (
    <>
      <div className="buildcontainer">
        <Flex
          style={{ height: "50px" }}
          pe={{ sm: "0px", md: "16px" }}
          w={{ sm: "100%", md: "auto" }}
          alignItems="center"
          flexDirection="row"
        >
          <p  style={{ margin: "auto" }} className="chakra-text">3d Builder</p>
        </Flex>
        <div
          className="flex flex-col md:flex-row h-screen"
          style={{ background: "#F4F4F4" }}
        >
          {!isMobile && addTextEnabled && (
            <p
              style={{
                zIndex: "2000",
                fontSize: "12px",
                padding: "5px 10px",
                background: "#F4F4F4",
                borderRadius: "5px",
                position: "fixed",
                left: mousePosition.x + 5,
                top: mousePosition.y + 15,
              }}
            >
              {inputText}
            </p>
          )}
          {!isMobile && addImageEnabled && (
            <Img
              width={32}
              style={{
                zIndex: "2000",
                padding: "5px 10px",
                background: "#F4F4F4",
                borderRadius: "5px",
                position: "fixed",
                left: mousePosition.x + 5,
                top: mousePosition.y + 15,
              }}
              src={getState().tempImage.url}
            />
          )}

          <div
            style={{
              flex: "1",
              position: "relative",
              width: isMobile ? "100%" : "75%",
            }}
            className="position-relative h-screen flex align-items-center justify-start"
          >
            {(addTextEnabled || addImageEnabled) && (
              <div
                style={{
                  width: "100%",
                  height: "100vh",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "fixed",
                  background: "rgba(200,200,200,0.5)",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              ></div>
            )}
            {isLoading && (
              <div
                style={{
                  width: "100%",
                  height: "100vh",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "fixed",
                  background: "rgba(200,200,200,0.7)",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                <img
                  src="https://www.owayo.com/konfigurator_html/img/preloader_shirt_final.gif"
                  alt=""
                />
                <span className="mt-3">{progress.toFixed(0)}% Loaded</span>
              </div>
            )}
            <Box className="ml15">
              <Tabs variant="unstyled">
                <TabList>
                  <Tab _selected={{ color: "white" }}>
                    <img src={threedImg} className="tabimg" alt="3D" />
                  </Tab>
                  <Tab _selected={{ color: "white" }}>
                    <img src={twodImg} className="tabimg" alt="2D" />
                  </Tab>
                </TabList>

                <TabPanels>
                  <TabPanel>
                    <ThreeScene
                      setFabricCanvas={setFabricCanvas}
                      setThreeObject={setThreeObject}
                      setIsLoading={setIsLoading}
                      setProgress={setProgress}
                      setAddTextEnabled={setAddTextEnabled}
                      addTextEnabled={addTextEnabled}
                      inputText={inputText}
                      setState={setState}
                      isMobile={isMobile}
                      evented={evented}
                      designModel={designModel}
                    />
                  </TabPanel>
                  <TabPanel>
                    <Box style={{ maxWidth: "900px", maxHeight: "870px" }}>
                      <DesignList
                        fabricRef={fabricRef}
                        fabricCanvas={fabricCanvas}
                      />
                    </Box>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Box>
          </div>
          {!isMobile && (
            <PropertyEditor
              fabricCanvas={fabricCanvas}
              isMobile={isMobile}
              threeObject={threeObject}
            />
          )}
        </div>
        {isMobile && <MobileNav fabricCanvas={fabricCanvas} />}
      </div>
      <canvas
        id="designCanvas"
        ref={fabricRef}
        style={{
          width: getState().dimension.width + "px",
          height: getState().dimension.height + "px",
          display: "none",
        }}
      ></canvas>
    </>
  );
}

export default Design;
