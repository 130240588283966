// Chakra imports
import { Flex } from "@chakra-ui/react";
import Projects from "./components/Projects";
import React from "react";
function Garments(props) {
  const data = props.location.state;
  let prodId, catId;
  if (data == undefined) {
    if (window.location.hash.split("/").length > 0) {
      catId = window.location.hash.split("/")[3];
      prodId = window.location.hash.split("/")[4];
    }
  } else {
    prodId = data.prod_id == null ? 0 : data.prod_id;
    catId = data.cat_id == null ? 0 : data.cat_id;
  }
  return (
    <Flex className="scrollcontainer" direction="column">
      <Projects
        catId={catId}
        prodId={prodId}
        title={"Garments"}
        description={"Customisation of garments"}
      />
    </Flex>
  );
}

export default Garments;
