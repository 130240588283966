/* eslint-disable no-redeclare */
import { fabric } from "fabric";
import { getState, setState } from "../hooks/useStore";
import * as customControls from "./customControl";
export const initFabricCanvas = () => {};
export const loadSvg = (fabricCanvas, texturePath) => {
  fabric.loadSVGFromURL(
    process.env.PUBLIC_URL +
      `/textures/Tshirt-0${texturePath}${
        getState().dimension.width == 2048 ? "" : "-Mobile"
      }.svg`,
    (objects) => {
      var svgData = fabric.util.groupSVGElements(objects, {
        width: getState().dimension.width,
        height: getState().dimension.height,
        selectable: false,
        crossOrigin: "anonymous",
      });
      svgData.top = 0;
      svgData.left = 0;
      let currentColors = [];
      for (let i = 0; i < objects.length; i++) {
        currentColors.push({
          id: objects[i].id,
          fill: objects[i].fill,
        });
      }
      setState({
        svgGroup: svgData,
        colors: currentColors,
      });
      fabricCanvas.remove(fabricCanvas._objects[0]);
      fabricCanvas.add(svgData);
      fabricCanvas.sendToBack(svgData);
      fabricCanvas.renderAll();
    }
  );
};

export const patchFabricJs = (
  fabricCanvas,
  renderer,
  getUv,
  getMousePosition,
  onClickPosition,
  getIntersects,
  object,
  getRealPosition
) => {
  fabric.Object.prototype.transparentCorners = false;
  fabric.Object.prototype.cornerSize =
    getState().dimension.width == 2048 ? 35 : 25;
  fabric.Object.prototype.borderColor = "black";
  fabric.Object.prototype.borderOpacityWhenMoving = 1;
  fabric.Object.prototype.borderScaleFactor = 1.1;

  const patchPinControl = () => {
    const imgIcon = document.createElement("img");
    imgIcon.src = "/pin.png";
    const imgIconTest = document.createElement("img");
    imgIconTest.src = "/unpin.png";
    fabric.Object.prototype.controls.pinControl = new fabric.Control({
      x: 0,
      y: -0.5,
      cursorStyle: "",
      mouseDownHandler: (e) => {
        const activeObject = fabricCanvas.getActiveObject();
        activeObject.set("lockMovementX", !activeObject.lockMovementX);
        activeObject.set("lockMovementY", !activeObject.lockMovementY);
      },
      actionName: "pin",
      render: renderIcon,
      cornerSize: getState().dimension.width == 2048 ? 35 : 25,
      withConnection: true,
    });
    function renderIcon(ctx, left, top, styleOverride, fabricObject) {
      var size = this.cornerSize;
      ctx.save();
      ctx.translate(left, top);
      if (fabricObject.lockMovementX) {
        ctx.drawImage(imgIconTest, -size / 2, -size / 2, size, size);
      } else {
        ctx.drawImage(imgIcon, -size / 2, -size / 2, size, size);
      }
      ctx.restore();
    }
  };
  const patchRotateControl = () => {
    const imgIcon = document.createElement("img");
    imgIcon.src = `<svg fill="#000000" height="100px" width="100px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-214.37 -214.37 643.11 643.11" xml:space="preserve" transform="matrix(1, 0, 0, 1, 0, 0)rotate(180)"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC" stroke-width="0.42873399999999995"></g><g id="SVGRepo_iconCarrier"> <path d="M202.403,95.22c0,46.312-33.237,85.002-77.109,93.484v25.663l-69.76-40l69.76-40v23.494 c27.176-7.87,47.109-32.964,47.109-62.642c0-35.962-29.258-65.22-65.22-65.22s-65.22,29.258-65.22,65.22 c0,9.686,2.068,19.001,6.148,27.688l-27.154,12.754c-5.968-12.707-8.994-26.313-8.994-40.441C11.964,42.716,54.68,0,107.184,0 S202.403,42.716,202.403,95.22z"></path> </g></svg>`;
    fabric.Object.prototype.controls.mtr = new fabric.Control({
      x: 0.5,
      y: -0.5,
      cursorStyle: "",
      // actionHandler: fabric.controlsUtils.rotationWithSnapping,
      actionName: "rotate",
      render: renderIcon,
      cornerSize: getState().dimension.width == 2048 ? 35 : 25,
      withConnection: true,
    });
    function renderIcon(ctx, left, top, styleOverride, fabricObject) {
      var size = this.cornerSize;
      ctx.save();
      ctx.translate(left, top);
      ctx.drawImage(imgIcon, -size / 2, -size / 2, size, size);
      ctx.restore();
    }
  };
  const patchScaleControl = () => {
    var img = document.createElement("img");
    img.src = "/scale.png";
    fabric.Object.prototype.controls.scaleControl = new fabric.Control({
      x: 0.5,
      y: 0.5,
      offsetY: 0.5,
      offsetX: 0.5,
      cursorStyle: "nw-resize",
      actionHandler: fabric.controlsUtils.scalingEqually,
      render: renderIcon,
      cornerSize: getState().dimension.width == 2048 ? 35 : 25,
      withConnection: true,
    });
    function renderIcon(ctx, left, top, styleOverride, fabricObject) {
      var size = this.cornerSize;
      ctx.save();
      ctx.translate(left, top);
      ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
      ctx.drawImage(img, -size / 2, -size / 2, size, size);
      ctx.restore();
    }
  };
  function patchDeleteControl() {
    var img = new Image();
    img.src = "/delete.png";

    fabric.Object.prototype.controls.deleteControl = new fabric.Control({
      x: -0.5,
      y: -0.5,
      offsetY: 0.5,
      offsetX: 0.5,
      cursorStyle: "pointer",
      action: deleteObject,
      render: renderIcon,
      cornerSize: getState().dimension.width == 2048 ? 35 : 25,
    });

    function deleteObject(eventData, target) {
      var canvas = target.canvas;
      canvas.remove(target);
      canvas.requestRenderAll();
    }

    function renderIcon(ctx, left, top, styleOverride, fabricObject) {
      var size = this.cornerSize;
      ctx.save();
      ctx.translate(left, top);
      ctx.drawImage(img, -size / 2, -size / 2, size, size);
      ctx.restore();
    }
  }

  function patchDuplicateControl() {
    var img = document.createElement("img");
    img.src = "/duplicate.png";
    fabric.Object.prototype.controls.duplicateControl = new fabric.Control({
      x: -0.5,
      y: 0.5,
      offsetY: 0.5,
      offsetX: 0.5,
      cursorStyle: "pointer",
      mouseUpHandler: duplicateObject,
      render: renderIcon,
      cornerSize: getState().dimension.width == 2048 ? 35 : 25,
    });

    function duplicateObject(eventData, transform) {
      var target = transform.target;
      var canvas = target.canvas;
      target.clone(function (cloned) {
        let id = "";
        if (cloned.text) {
          id = "text-" + getState().allTexts.length + 1;
        } else {
          id = "image-" + getState().allImages.length + 1;
        }
        cloned.id = id;
        cloned.left += 10;
        cloned.top += 10;
        cloned.setControlVisible("ml", false);
        cloned.setControlVisible("mr", false);
        cloned.setControlVisible("mt", false);
        cloned.setControlVisible("mb", false);
        canvas.add(cloned);
      });
    }

    function renderIcon(ctx, left, top, styleOverride, fabricObject) {
      var size = this.cornerSize;
      ctx.save();
      ctx.translate(left, top);
      ctx.drawImage(img, -size / 2, -size / 2, size, size);
      ctx.restore();
    }
  }

  // patchScaleControl();
  // patchDeleteControl();
  // patchRotateControl();
  // patchDuplicateControl();
  // patchPinControl();

  fabric.Canvas.prototype.customiseControls({
    tl: {
      action: "remove",
    },
    tr: {
      action: "scale",
      cursor: "pointer",
    },
    br: {
      action: "scale",
      cursor: "pointer",
    },
    bl: {
      action: "scale",
      cursor: "pointer",
    },
    mb: {
      action: "moveDown",
      cursor: "pointer",
    },
    mr: {
      action: "rotate",
      cursor: "pointer",
    },
    mt: {
      action: {
        rotateByDegrees: 30,
      },
      cursor: "pointer",
    },
  });

  fabric.Object.prototype.customiseCornerIcons(
    {
      settings: {
        borderColor: "black",
        cornerSize: 25,
        cornerBackgroundColor: "black",
        cornerShape: "circle",
        cornerPadding: 10,
      },
      tl: {
        icon: process.env.PUBLIC_URL + "/controls/remove.svg",
      },
      bl: {
        icon: process.env.PUBLIC_URL + "/controls/resize.svg",
      },
      tr: {
        icon: process.env.PUBLIC_URL + "/controls/resize.svg",
      },
      mtr: {
        icon: process.env.PUBLIC_URL + "/controls/rotate.svg",
      },
      br: {
        icon: process.env.PUBLIC_URL + "/controls/resize.svg",
      },
    },
    function () {
      fabricCanvas.renderAll();
    }
  );

  var upperCanvasEl = fabricCanvas.upperCanvasEl;
  fabric.Object.prototype.transparentCorners = false;
  fabric.Object.prototype.objectCaching = false;
  let raycastContainer = renderer.domElement;
  const getPositionOnScene = (sceneContainer, evt) => {
    var array = getMousePosition(sceneContainer, evt.clientX, evt.clientY);
    onClickPosition.fromArray(array);
    var intersects = getIntersects(onClickPosition, object.children);
    if (intersects.length > 0 && intersects[0].uv) {
      var uv = intersects[0].uv;
      getUv = uv;
      intersects[0].object.material.map.transformUv(uv);
      return {
        x: getRealPosition("x", uv.x),
        y: getRealPosition("y", uv.y),
      };
    }
    return null;
  };
  fabric.Canvas.prototype.getPointer = function (e, ignoreZoom) {
    if (this._absolutePointer && !ignoreZoom) {
      return this._absolutePointer;
    }
    if (this._pointer && ignoreZoom) {
      return this._pointer;
    }
    var simEvt;
    if (e.touches != undefined) {
      simEvt = new MouseEvent(
        {
          touchstart: "mousedown",
          touchmove: "mousemove",
          touchend: "mouseup",
        }[e.type],
        {
          bubbles: true,
          cancelable: true,
          view: window,
          detail: 1,
          screenX: Math.round(e.changedTouches[0].screenX),
          screenY: Math.round(e.changedTouches[0].screenY),
          clientX: Math.round(e.changedTouches[0].clientX),
          clientY: Math.round(e.changedTouches[0].clientY),
          ctrlKey: false,
          altKey: false,
          shiftKey: false,
          metaKey: false,
          button: 0,
          relatedTarget: null,
        }
      );
      var pointer = fabric.util.getPointer(simEvt),
        bounds = upperCanvasEl.getBoundingClientRect(),
        boundsWidth = bounds.width || 0,
        boundsHeight = bounds.height || 0,
        cssScale;
    } else {
      var pointer = fabric.util.getPointer(e),
        bounds = upperCanvasEl.getBoundingClientRect(),
        boundsWidth = bounds.width || 0,
        boundsHeight = bounds.height || 0,
        cssScale;
    }
    if (!boundsWidth || !boundsHeight) {
      if ("top" in bounds && "bottom" in bounds) {
        boundsHeight = Math.abs(bounds.top - bounds.bottom);
      }
      if ("right" in bounds && "left" in bounds) {
        boundsWidth = Math.abs(bounds.right - bounds.left);
      }
    }
    this.calcOffset();
    pointer.x = Math.round(pointer.x) - this._offset.left;
    pointer.y = Math.round(pointer.y) - this._offset.top;
    /* BEGIN PATCH CODE */
    if (e.target !== this.upperCanvasEl) {
      var positionOnScene;
      if (window.innerWidth < 700) {
        positionOnScene = getPositionOnScene(raycastContainer, simEvt);
        if (positionOnScene) {
          pointer.x = positionOnScene.x;
          pointer.y = positionOnScene.y;
        }
      } else {
        positionOnScene = getPositionOnScene(raycastContainer, e);
        if (positionOnScene) {
          pointer.x = positionOnScene.x;
          pointer.y = positionOnScene.y;
        }
      }
    }
    /* END PATCH CODE */
    if (!ignoreZoom) {
      pointer = this.restorePointerVpt(pointer);
    }

    if (boundsWidth === 0 || boundsHeight === 0) {
      cssScale = { width: 1, height: 1 };
    } else {
      cssScale = {
        width: upperCanvasEl.width / boundsWidth,
        height: upperCanvasEl.height / boundsHeight,
      };
    }

    return {
      x: pointer.x * cssScale.width,
      y: pointer.y * cssScale.height,
    };
  };
};

export const addImage = (fabricCanvas, getUv) => {
  const imageUrl = getState().tempImage.url;
  fabric.Image.fromURL(imageUrl, (img) => {
    img.set({
      id: `image-${getState().allImages.length}`,
      left: getUv.x * (getState().dimension.width - 40),
      top: getUv.y * (getState().dimension.height - 20),
      originX: "center",
      originY: "center",
      selectable: true,
      editable: false,
      centeredScaling: true,
    });
    img.setControlVisible("ml", false);
    img.setControlVisible("mr", false);
    img.setControlVisible("mt", false);
    img.setControlVisible("mb", false);
    img.scaleToWidth(320);
    fabricCanvas.add(img);
    fabricCanvas.setActiveObject(img);
    fabricCanvas.renderAll();
  });
  setState({
    addImageEnabled: false,
    tempImage: null,
  });
};
export const addSvgImage = (fabricCanvas, getUv) => {
  const svgUrl = getState().tempImage.url;
  fabric.loadSVGFromURL(svgUrl, (objects, options) => {
    var svg = fabric.util.groupSVGElements(objects, options);
    svg.set({
      id: `image-${getState().allImages.length}`,
      left: getUv.x * (getState().dimension.width - 40),
      top: getUv.y * (getState().dimension.height - 20),
      originX: "center",
      originY: "center",
      selectable: true,
      editable: false,
      centeredScaling: true,
    });
    svg.setControlVisible("ml", false);
    svg.setControlVisible("mr", false);
    svg.setControlVisible("mt", false);
    svg.setControlVisible("mb", false);
    svg.scaleToWidth(320);
    fabricCanvas.add(svg);
    fabricCanvas.setActiveObject(svg);
    fabricCanvas.renderAll();
  });
  setState({
    addImageEnabled: false,
    tempImage: null,
  });
};

export const addText = (fabricCanvas, getUv) => {
  const jerseyName = new fabric.IText(getState().inputText, {
    fontSize: 50,
    id: `text-${getState().allTexts.length}`,
    textAlign: "center",
    fontWeight: "bold",
    left: 400, //getUv.x * (getState().dimension.width - 40),
    top: 411, //getUv.y * (getState().dimension.height - 20),
    originX: "center",
    originY: "center",
    selectable: true,
    editable: false,
    centeredScaling: true,
  });
  jerseyName.setControlVisible("ml", false);
  jerseyName.setControlVisible("mr", false);
  jerseyName.setControlVisible("mt", false);
  jerseyName.setControlVisible("mb", false);
  fabricCanvas.add(jerseyName);
  fabricCanvas.setActiveObject(jerseyName);
  const tempTexts = getState().allTexts;
  tempTexts.push({
    text: getState().inputText,
    id: `text-${tempTexts.length}`,
    fill: "#000000",
    fontFamily: "Arial",
    strokeWidth: 0,
    strokeColor: "",
  });
  setState({
    inputText: "",
    addTextEnabled: false,
    allTexts: tempTexts,
  });
};
