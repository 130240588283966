// Chakra imports
import { Flex } from "@chakra-ui/react";
import Projects from "./components/Projects";
import React from "react";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
function Category() {
  return (
    <Flex className="scrollcontainer" direction="column">
      <Projects title={"Sports"} description={"List of Sports"} />
    </Flex>
  );
}

export default Category;
