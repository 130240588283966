// Chakra imports
import { Flex, Grid, useColorModeValue } from "@chakra-ui/react";
import avatar4 from "assets/img/avatars/avatar4.png";
import ProfileBgImage from "assets/img/ProfileBackground.png";
import React, { useState, useEffect } from "react";
import Header from "./components/Header";
import Projects from "./components/Projects";
import useStore, { setState, getState } from "../../hooks/useStore";
function Profile() {
  // Chakra color mode
  const textColor = useColorModeValue("gray.700", "white");
  const bgProfile = useColorModeValue(
    "hsla(0,0%,100%,.8)",
    "linear-gradient(112.83deg, rgba(255, 255, 255, 0.21) 0%, rgba(255, 255, 255, 0) 110.84%)"
  );
  const [cust, setCust] = useState({});
  function sessionGet(key) {
    let stringValue = sessionStorage.getItem(key);
    if (stringValue !== null) {
      let value = JSON.parse(stringValue);
      let expirationDate = new Date(value.expirationDate);
      if (expirationDate > new Date()) {
        return value.value;
      } else {
        sessionStorage.removeItem(key);
      }
    }
    return null;
  }
  useEffect(() => {
    const data = sessionGet("quotes");
    setState({ quotes: data });

    if (data._customer != undefined && data._customer.contact != undefined) {
      data._customer.email = data._customer.contact.email;
      data._customer.phone = data._customer.contact.phone;
    }

    setState({ customer: data._customer });
    setCust(data._customer);
  }, []);

  return (
    <Flex direction="column">
      <Header
        backgroundHeader={ProfileBgImage}
        backgroundProfile={bgProfile}
        avatarImage={avatar4}
        name={cust.name}
        email={cust.email}
        phone={cust.phone}
      />
      <Projects title={"Quotes"} description={""} />
    </Flex>
  );
}

export default Profile;
